import { FC } from 'react';
import useBlockScroll from 'utils/useBlockScroll';

import { SUserModal } from './UserModal.styled';

const UserModal: FC<{ wide?: boolean }> = ({ wide, children }) => {
  useBlockScroll(true, 'no-scroll');

  return (
    <SUserModal {...{ wide }}>
      <div className="modal-content">{children}</div>
    </SUserModal>
  );
};

export default UserModal;
