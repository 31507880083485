export const getTagColor = (tagName: string) => {
  switch (tagName.trim()) {
    case 'Groomer': {
      return { text: '255, 107, 0', background: 'rgba(255, 107, 0, 0.1)' };
    }
    case 'Hotele dla pupila': {
      return { text: '6, 92, 167', background: 'rgba(230, 244, 254, 1)' };
    }
    case 'Szkolenie psów': {
      return { text: '44, 166, 1', background: 'rgba(51, 197, 0, 0.1)' };
    }
    case 'Behawiorystyka': {
      return { text: '146, 2, 197', background: 'rgba(114, 0, 154, 0.15)' };
    }
    case 'Fizjoterapeuta': {
      return { text: '0, 174, 174', background: 'rgba(2, 141, 141, 0.15)' };
    }
    default: {
      return { text: '0, 0, 0', background: '' };
    }
  }
};
