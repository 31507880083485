import { FC, useState } from 'react';

import { Service, ServiceCreator, Slot, WorkplaceCategory } from 'const';
import {
  AppWorkplaceService,
  AppWorkplaceServiceEditor,
  Button,
} from 'components';

import { SAppWorkplaceCategory } from './AppWorkplaceCategory.styled';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from 'Root';
import { addCategoryService } from 'state/reducers/user/userActions';

interface IAppWorkplaceCategory {
  category: WorkplaceCategory;
  slots: Slot[];
}

const AppWorkplaceCategory: FC<IAppWorkplaceCategory> = ({
  category,
  slots,
}) => {
  const dispatch = useAppDispatch();

  const [newServiceEditorVisible, setNewServiceEditorVisible] =
    useState<boolean>(false);

  const newServiceMethods = useForm<ServiceCreator>({
    defaultValues: {
      name: '',
      price: 0,
      duration: '',
      placesAmount: 0,
      slots: [],
    },
  });

  const hideNewServiceEditor = () => setNewServiceEditorVisible(false);
  const showNewServiceEditor = () => setNewServiceEditorVisible(true);

  const handleCreateNew = async (values: ServiceCreator) => {
    const [hours, minutes] = values?.duration?.split(':') ?? ['0', '0'];
    const duration = parseInt(hours) * 60 + parseInt(minutes);

    const body = {
      ...values,
      duration,
      price: parseFloat(String(values.price).replace(/ /g, '')),
      slots:
        category.appointment_type === 'normal'
          ? values?.slots?.map((o) => o.id) ?? []
          : undefined,
    };

    await dispatch(
      addCategoryService({ categoryId: category.id, service: body })
    );
  };

  return (
    <SAppWorkplaceCategory empty={!category.services.length}>
      <h3 className="category-name">{category.name}</h3>
      {category.services.length ? (
        category.services.map((service: Service) => (
          <AppWorkplaceService
            key={`workplace-category-service-${service.id}`}
            {...{ service, category, slots }}
          />
        ))
      ) : (
        <div className="category-empty">
          <p>Brak usług przypisanych do tej kategorii</p>
          <button
            type="button"
            className="add-service"
            onClick={showNewServiceEditor}
          >
            + Dodaj usługę
          </button>
        </div>
      )}

      {newServiceEditorVisible ? (
        <FormProvider {...newServiceMethods}>
          <AppWorkplaceServiceEditor
            {...{
              slots,
              category,
              onSubmit: handleCreateNew,
              onCancel: hideNewServiceEditor,
            }}
          />
        </FormProvider>
      ) : null}
      {!newServiceEditorVisible && category.services.length ? (
        <button
          type="button"
          className="add-service"
          onClick={showNewServiceEditor}
        >
          + Dodaj usługę
        </button>
      ) : null}
    </SAppWorkplaceCategory>
  );
};

export default AppWorkplaceCategory;
