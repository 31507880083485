export const CITIES = [
  'Warszawa',
  'Poznań',
  'Wrocław',
  'Kraków',
  'Gdańsk',
  'Katowice',
  'Łódź',
];

export const POLAND_CENTER_COORDINATES = {
  latitude: 51.9194,
  longitude: 19.1451,
};

export const locations = [
  { id: 85, name: 'Wrocław' },
  { id: 492, name: 'Łódź' },
  { id: 530, name: 'Kraków' },
  { id: 678, name: 'Warszawa' },
  { id: 1010, name: 'Gdańsk' },
  { id: 1117, name: 'Katowice' },
  { id: 1449, name: 'Poznań' },
];

export const predefinedLocations = [
  { district: false, value: 85, label: 'Wrocław' },
  { district: false, value: 492, label: 'Łódź' },
  { district: false, value: 530, label: 'Kraków' },
  { district: false, value: 678, label: 'Warszawa' },
  { district: false, value: 1010, label: 'Gdańsk' },
  { district: false, value: 1117, label: 'Katowice' },
  { district: false, value: 1449, label: 'Poznań' },
];
