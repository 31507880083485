import styled, { css } from 'styled-components'

export const SAuthProcess = styled.div`
  ${({ theme }) => css`
    .auth-process {
      &-header {
        height: 72px;

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        border-bottom: 2px solid #eceef5;
      }

      &-heading {
        font-size: 1.125rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.68rem;

        color: #3a3335;
      }

      &-back {
        position: absolute;
        top: 50%;
        left: ${theme.space.lg}px;

        margin: 0;
        padding: 0;

        transform: translateY(-50%);

        background: none;
        border: 0;
        box-shadow: none;

        cursor: pointer;

        span {
          color: #3a3335;
        }
      }

      &-close {
        position: absolute;
        top: 50%;
        right: ${theme.space.lg}px;

        margin: 0;
        padding: 0;

        transform: translateY(-50%);

        background: none;
        border: 0;
        box-shadow: none;

        cursor: pointer;

        span {
          color: #3a3335;
        }
      }

      &-content {
        padding: ${theme.space.lg}px;

        form {
          display: flex;
          flex-direction: column;
        }

        .row {
          display: flex;
          & > * {
            margin-right: ${theme.space.sm}px;

            &:last-of-type {
              margin-right: 0;
            }
          }
        }

        .field {
          width: 100%;
          margin-bottom: ${theme.space.sm}px;
        }

        .terms {
          margin-top: ${theme.space.md}px;
        }

        .forgot-password {
          align-self: flex-end;

          font-weight: ${theme.weight.bold};
          font-size: 0.875rem;
          line-height: 1.3rem;

          color: #eb5e55;

          margin: 0;
          padding: 0;

          margin-top: ${theme.space.sm}px;

          background: none;
          border: 0;
          box-shadow: none;

          cursor: pointer;
        }

        .button {
          width: 100%;
          height: 54px;

          margin-top: ${theme.space.xl}px;
        }

        .or-separator {
          font-size: 0.876rem;
          line-height: 1.3rem;
          text-align: center;
          color: #908e8e;

          position: relative;

          margin: ${theme.space.lg}px 0;

          &::before {
            content: '';
            width: 100%;
            height: 1px;

            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);

            background-color: #ede8e9;
          }

          span {
            position: relative;

            background-color: white;

            padding: 0 ${theme.space.sm}px;
            z-index: 1;
          }
        }

        .fb {
          height: 54px;

          position: relative;

          background: white;
          border: 1px solid #ede8e9;
          border-radius: 10px;

          font-size: 0.876rem;
          font-weight: ${theme.weight.bold};
          line-height: 1.3rem;
          text-align: center;
          color: #908e8e;

          cursor: pointer;

          .icon {
            width: 9px;

            position: absolute;
            top: 50%;
            left: 22px;
            transform: translateY(-40%);

            color: #908e8e;
          }
        }

        .register-success {
          display: flex;
          flex-direction: column;
          align-items: center;

          &-heading {
            font-size: 2.125rem;
            font-weight: ${theme.weight.heavy};
            line-height: 3rem;

            text-align: center;

            color: #3a3335;
          }

          &-description {
            font-size: 0.875rem;
            line-height: 1.31rem;
            text-align: center;

            color: #9d9c9c;

            margin-top: ${theme.space.md}px;
          }
        }

        .forgot {
          display: flex;
          flex-direction: column;
          align-items: center;

          margin-bottom: ${theme.space.xxl}px;

          &-heading {
            font-size: 1.5rem;
            font-weight: ${theme.weight.heavy};
            line-height: 2.25rem;

            text-align: center;

            color: #3a3335;
          }

          &-description {
            font-size: 0.875rem;
            line-height: 1.31rem;
            text-align: center;

            color: #9d9c9c;

            margin-top: ${theme.space.sm}px;
          }
        }
      }

      &-footer {
        min-height: 68px;

        display: flex;
        align-items: center;
        justify-content: center;

        background: linear-gradient(
            0deg,
            rgba(58, 51, 53, 0.04),
            rgba(58, 51, 53, 0.04)
          ),
          #ffffff;

        button,
        a {
          font-size: 1rem;
          line-height: 1.5rem;

          text-decoration: none;
          color: ${theme.colors.primary};

          background: none;
          border: 0;
          box-shadow: none;

          cursor: pointer;
        }
      }
    }
  `}
`
