import React from 'react';

const StarIcon = ({
  color = '#FFB800',
  size = 24,
}: {
  color?: string;
  size?: number;
}) => (
  <svg
    width={size}
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0L14.6942 8.2918H23.4127L16.3593 13.4164L19.0534 21.7082L12 16.5836L4.94658 21.7082L7.64074 13.4164L0.587322 8.2918H9.30583L12 0Z"
      fill={color}
    />
  </svg>
);

export default StarIcon;
