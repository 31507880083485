import { FC, useEffect } from 'react';

import { AppRoute, Review } from 'const';
import { useAppDispatch, useAppSelector } from 'Root';

import {
  ProfileList,
  SectionLoader,
  Wrapper,
  ProfileReviewItem,
} from 'components';
import {
  getClientReviews,
  showReviewEditor,
} from 'state/reducers/client/clientActions';
import EmptyState from '../components/EmptyState/EmptyState';

const ProfileReviews: FC = () => {
  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector(({ client }) => client.reviews.list);

  const editReview = (review: Review) => () => {
    dispatch(showReviewEditor({ appointment: null, review }));
  };

  useEffect(() => {
    dispatch(getClientReviews());
  }, []);

  if (loading) {
    return (
      <Wrapper>
        <ProfileList>
          <SectionLoader />
        </ProfileList>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ProfileList base>
        {data.length === 0 ? (
          <EmptyState
            title="Brak opinii w tym momencie"
            description="Create new apartments and add details about your location. You
              will get notified once it will be booked."
            button={{
              label: 'Dodaj opinie',
              href: '/pl/list/1',
            }}
          />
        ) : (
          <>
            <div className="header">
              <h2 className="heading">Opinie ({data.length})</h2>
            </div>
            <div className="list">
              {data.map((review: Review) => (
                <ProfileReviewItem
                  key={`profile-reservation-${review.id}`}
                  {...{ ...review, editReview: editReview(review) }}
                />
              ))}
            </div>
          </>
        )}
      </ProfileList>
    </Wrapper>
  );
};

export { ProfileReviews };
