import { FC } from 'react';

import { Button } from 'components';
import AppAuthPageIcon from 'components/AppAuthPageIcon/AppAuthPageIcon';

interface IAuthSignUpSuccess {
  close: () => void;
  text: string;
  buttonText: string;
}

const AuthSignUpSuccess: FC<IAuthSignUpSuccess> = ({
  close,
  text,
  buttonText,
}) => {
  return (
    <div className="register-success">
      <AppAuthPageIcon>
        <span className="material-icons">check</span>
      </AppAuthPageIcon>
      <h3 className="register-success-heading">Dzięki!</h3>
      <p className="register-success-description">{text}</p>
      <Button primary className="button" onClick={close}>
        {buttonText}
      </Button>
    </div>
  );
};

export default AuthSignUpSuccess;
