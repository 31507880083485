import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SDiscover = styled.section`
  ${({ theme }) => css`
    padding: 52px 0 43px;
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;

    ${media.md`
         padding: 53px 0 62px;
    `}

    .header {
      align-items: center;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      .heading {
        font-size: 30px;
        font-weight: ${theme.weight.bold};
        line-height: 44px;
        padding: 0 28px;

        color: ${theme.colors.black};

        ${media.md`
         line-height: 32px;
        `}
      }
      .subheading {
        max-width: 505px;

        font-size: 1em;
        line-height: 28px;
        color: ${theme.colors.text};

        margin-top: 13px;
        padding: 0 17px;

        ${media.md`
         max-width: 750px;
    `}
      }
    }

    .map-categories {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 33px 0 32px;
      width: 100%;
    }

    .categories {
      display: flex;
      flex-flow: wrap;
      justify-content: center;
      column-gap: 12px;
      padding: 0 13px 0;
      row-gap: 16px;

      ${media.md`
      column-gap: 16px;
        row-gap: 0px
      `}
    }

    .input-container {
      display: flex;
    }

    .map-padding-wrapper {
      display: flex;
      justify-content: center;
      padding: 0 24px;
      border-radius: 35px;
      width: 100%;

      ${media.md`
        padding: 0;
      `}
    }

    .map-wrapper {
      border-radius: 35px;
      height: 550px;
      overflow: hidden;
      will-change: transform;
      position: relative;
      max-width: 1136px;
      width: 100%;
    }

    .details {
      background-color: #fff;
      position: absolute;
      left: 50px;
      bottom: 50px;
      height: 300px;
      width: 200px;
      z-index: 10;
    }

    .map-controls {
      bottom: 20px;
      display: flex;
      flex-flow: column;
      position: absolute;
      right: 20px;
      row-gap: 8px;
      z-index: 10;
    }

    .control-button {
      align-items: center;
      background-color: ${theme.colors.white};
      border-radius: 50%;
      display: flex;
      color: ${theme.colors.primary};
      font-size: 18px;
      height: 42px;
      justify-content: center;
      width: 42px;
    }

    .content {
      display: grid;
      grid-template-columns: 1fr;
      gap: 18px 28px;

      margin-top: ${theme.space.lg}px;
      margin-bottom: ${theme.space.xxl}px;
    }

    .action {
      display: flex;
      justify-content: center;
    }

    .load-more {
      height: 46px;

      text-transform: lowercase;
      line-height: 1.31rem;

      padding: 0 ${theme.space.md + 5}px;

      .icon {
        font-size: 1.125rem;

        margin-top: 1px;
        margin-right: ${theme.space.sm}px;
      }
    }

    ${media.md`
      .content {
        grid-template-columns: 1fr 1fr;
        margin-top: ${theme.space.xxl}px;
      }
    `}
  `}
`;
