import React, { useMemo, useState } from 'react';
import SectionTemplate from './SectionTemplate';
import Opinion from './Opinion';
import { SReviews } from './Reviews.styled';
import type { SalonReview } from '../../const';
import { getAverageReviewsRate } from 'utils/getAverageReviewsRate';

interface IReviewsProps {
  items: SalonReview[];
}

const Reviews = ({ items }: IReviewsProps) => {
  const [page, setPage] = useState(0);

  const INITIAL_AMOUNT = 3;

  const getRange = () => page * 5 + INITIAL_AMOUNT;

  const averageRate = useMemo(() => {
    if (items.length === 0) return '';

    return getAverageReviewsRate(items);
  }, [items]);

  const visibleReviews = items.slice(0, getRange());

  return (
    <SectionTemplate title="Opinie" rating={averageRate}>
      <SReviews>
        {visibleReviews.length > 0 ? (
          visibleReviews.map((item, index) => (
            <Opinion
              key={item.id}
              {...item}
              isLast={index + 1 === items.length}
            />
          ))
        ) : (
          <p>Brak opinii</p>
        )}

        {items.length > INITIAL_AMOUNT && items.length > visibleReviews.length && (
          <button
            className="read-more"
            onClick={() => setPage((prevState) => prevState + 1)}
          >
            Czytaj więcej
          </button>
        )}
      </SReviews>
    </SectionTemplate>
  );
};

export default Reviews;
