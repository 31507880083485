export const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1140,
  xxl: 1400,
  xxxl: 1680,
  xxxxl: 1755,
};

export const space = {
  xs: 4,
  sm: 8,
  md: 16,
  lg: 24,
  xl: 32,
  xxl: 48,
  xxxl: 64,
};

interface Colors {
  white: string;
  primary: string;
  text: string;
  error: string;
  black: string;
}

interface Weight {
  heavy: number;
  bold: number;
  medium: number;
  semibold: number;
  normal: number;
}

interface Shadows {
  default: string;
}

interface GlobalTheme {
  breakpoints: any;
  space: any;
  colors: Colors;
  weight: Weight;
  shadows: Shadows;
}

const theme: GlobalTheme = {
  breakpoints,
  space,
  colors: {
    white: '#fff',
    primary: '#FF6B00',
    text: '#696F8C',
    error: '#EB5E55',
    black: '#304659',
  },
  weight: {
    heavy: 800,
    bold: 700,
    semibold: 600,
    medium: 500,
    normal: 400,
  },
  shadows: {
    default: '0px 4px 16px rgba(0, 0, 0, 0.08)',
  },
};

export type Theme = typeof theme;
export default theme;
