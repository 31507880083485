import { FC } from 'react'
import { useTheme } from 'styled-components'
import Loader from 'react-spinners/PuffLoader'

import theme from '../../theme'
import { SPageLoader } from './PageLoader.styled'

const PageLoader: FC = () => {
  return (
    <SPageLoader>
      <Loader color={theme.colors.primary} />
    </SPageLoader>
  )
}

export default PageLoader
