import React from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../Root';
import { SalonService } from '../../const';
import Service from '../ListingItem/Service';
import ServicesEmptyState from './ServicesEmptyState';
import SectionTemplate from './SectionTemplate';
import {
  addServiceToReservation,
  openReservationsModal,
} from '../../state/reducers/reservation/reservationActions';
import { useServiceCategories } from './useServiceCategories';

interface IServicesProps {
  name: string;
  id: number;
  services?: SalonService[];
}

const Services = ({ services, name, id }: IServicesProps) => {
  const dispatch = useDispatch();
  const { reservations } = useAppSelector((state) => state.reservation);

  const handleAddService = (event: MouseEvent, service: SalonService) => {
    event.preventDefault();
    if (reservations.length === 1) {
      dispatch(openReservationsModal());
      return;
    }
    dispatch(addServiceToReservation({ salonId: id, service, slotId: 0 }));
  };

  const {
    groomer,
    behaviorists,
    hotels,
    trainings,
    physiotherapists,
    noServices,
  } = useServiceCategories(services);

  if (!services || services.length === 0) return null;

  return (
    <SectionTemplate title="Nasze usługi">
      {noServices && <ServicesEmptyState name={name} />}

      {behaviorists.length > 0 && (
        <div className="service-category">
          <h3 className="service-category-header">Behawiorystyka</h3>
          {behaviorists.map((service, index) => (
            <Service
              key={`salon-category-${service.name}-service-${service.id}`}
              onReserve={handleAddService}
              {...service}
              isLast={index + 1 === services.length}
            />
          ))}
        </div>
      )}
      {physiotherapists.length > 0 && (
        <div className="service-category">
          <h3 className="service-category-header">Fizjoterapeuta</h3>
          {physiotherapists.map((service, index) => (
            <Service
              key={`salon-category-${service.name}-service-${service.id}`}
              onReserve={handleAddService}
              {...service}
              isLast={index + 1 === services.length}
            />
          ))}
        </div>
      )}
      {groomer.length > 0 && (
        <div className="service-category">
          <h3 className="service-category-header">Groomer</h3>
          {groomer.map((service, index) => (
            <Service
              key={`salon-category-${service.name}-service-${service.id}`}
              onReserve={handleAddService}
              {...service}
              isLast={index + 1 === services.length}
            />
          ))}
        </div>
      )}
      {hotels.length > 0 && (
        <div className="service-category">
          <h3 className="service-category-header">Hotele dla pupila</h3>
          {hotels.map((service, index) => (
            <Service
              key={`salon-category-${service.name}-service-${service.id}`}
              onReserve={handleAddService}
              {...service}
              isLast={index + 1 === services.length}
            />
          ))}
        </div>
      )}
      {trainings.length > 0 && (
        <div className="service-category">
          <h3 className="service-category-header">Szkolenia dla psów</h3>
          {trainings.map((service, index) => (
            <Service
              key={`salon-category-${service.name}-service-${service.id}`}
              onReserve={handleAddService}
              {...service}
              isLast={index + 1 === services.length}
            />
          ))}
        </div>
      )}
    </SectionTemplate>
  );
};

export default Services;
