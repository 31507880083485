import styled, { css } from 'styled-components'
import media from 'theme/respondTo'

export default styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.space.xxxl}px;
    padding-bottom: ${theme.space.xl}px;

    .settings {
      &-header {
        display: flex;
        align-items: center;

        &-back {
          width: 28px;
          height: 28px;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 28px;
          color: ${theme.colors.primary};

          background: none;
          box-shadow: none;
          border: 0;

          margin-right: ${theme.space.md}px;

          cursor: pointer;
        }
      }

      &-heading {
        font-size: 1.5rem;
        font-weight: ${theme.weight.heavy};
        line-height: 2.25rem;

        color: #3a3335;
      }

      &-summary {
        display: flex;
        margin-bottom: ${theme.space.xxl}px;

        &-thumbnail {
          position: relative;

          margin-right: ${theme.space.xxl}px;

          img {
            width: 114px;
            height: 114px;

            object-fit: cover;

            border-radius: 20px;
          }

          &-edit {
            width: 42px;
            height: 42px;

            display: flex;
            align-items: center;
            justify-content: center;

            position: absolute;
            bottom: -6px;
            right: -12px;

            background: none;
            border: 0;
            box-shadow: none;

            background: #e8e5e5;
            border-radius: 50%;
            border: 5px solid white;

            cursor: pointer;

            input {
              width: 1px;
              height: 1px;

              position: absolute;
              top: 0;
              left: 0;

              opacity: 0;
              visibility: hidden;
            }
          }
        }

        &-info {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        &-name {
          font-size: 1.5rem;
          font-weight: ${theme.weight.heavy};
          line-height: 2.25rem;

          color: #3a3335;
        }

        &-phone {
          font-size: 0.875rem;
          line-height: 1.31rem;

          color: #908e8e;
        }

        &-button {
          width: 127px;
          height: 36px;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 0.75rem;
          font-weight: ${theme.weight.semibold};
          line-height: 1.125rem;
          text-decoration: none;
          color: #2ac084;

          margin-top: auto;

          background: none;
          background: linear-gradient(
              0deg,
              rgba(198, 216, 211, 0.3),
              rgba(198, 216, 211, 0.3)
            ),
            #ffffff;
          box-shadow: none;
          border: 0;
          border-radius: 8px;

          cursor: pointer;

          &:hover {
            background: linear-gradient(
                0deg,
                rgba(198, 216, 211, 0.4),
                rgba(198, 216, 211, 0.4)
              ),
              #ffffff;
          }
        }
      }

      &-container {
        padding-top: ${theme.space.xxl}px;

        ${media.lg`
          display: grid;
          grid-template-columns: 1fr 361px;
        `}
      }

      &-content {
        max-width: 552px;
      }

      &-field {
        margin-bottom: ${theme.space.md}px;
      }

      &-submit {
        width: 100%;
        height: 46px;

        margin-left: auto;

        ${media.sm`
          width: 194px;
        `}
      }

      &-sidebar {
        max-width: 552px;

        &-content {
          padding: ${theme.space.xl}px;

          background: #ffffff;
          box-shadow: 0px 3px 4px rgba(153, 155, 168, 0.15);
          border-radius: 6px;
        }

        &-icon {
          width: 48px;
          height: 48px;

          display: flex;
          align-items: center;
          justify-content: center;

          background: linear-gradient(
              0deg,
              rgba(58, 51, 53, 0.04),
              rgba(58, 51, 53, 0.04)
            ),
            #ffffff;
          border-radius: 8px;

          span {
            font-size: 1.37rem;
            color: #908e8e;
          }
        }

        &-heading {
          font-size: 1rem;
          font-weight: ${theme.weight.bold};
          line-height: 1.5rem;
          color: #3a3335;

          margin-top: ${theme.space.md}px;
        }

        &-description {
          font-size: 0.875rem;
          line-height: 1.31rem;
          color: #908e8e;

          margin-top: ${theme.space.xs / 2}px;
        }
      }
    }
  `}
`
