import styled, { css } from 'styled-components'

export const SReservation = styled.div`
  ${({ theme }) => css`
    max-height: 90vh;

    display: flex;
    flex-direction: column;

    .reservation {
      &-header {
        flex-shrink: 0;

        height: 72px;

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        border-bottom: 2px solid #eceef5;
      }

      &-heading {
        font-size: 1.125rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.68rem;

        color: #3a3335;
      }

      &-close {
        position: absolute;
        top: 50%;
        right: ${theme.space.lg}px;

        margin: 0;
        padding: 0;

        transform: translateY(-50%);

        background: none;
        border: 0;
        box-shadow: none;

        cursor: pointer;

        span {
          color: #3a3335;
        }
      }

      &-content {
        overflow-y: auto;
        padding: ${theme.space.lg}px;
      }

      &-footer {
        padding: ${theme.space.lg}px;
      }

      &-add {
        font-size: 0.875rem;
        font-weight: ${theme.weight.semibold};
        line-height: 1.31rem;
        color: ${theme.colors.primary};

        background: none;
        box-shadow: none;
        border: 0;

        padding: 0;
        margin: 0;
        margin-bottom: ${theme.space.md}px;

        cursor: pointer;

        &:disabled {
          color: #a8a8a8;
          cursor: not-allowed;
        }
      }

      &-submit {
        width: 100%;
      }
    }
  `}
`
