import PropTypes from 'prop-types'
import { useContext, useEffect, useState } from 'react'
import { Breakpoints } from '..'

import { BreakpointContext, defaultValue } from './BreakpointContext'

const BreakpointProvider = ({
  children,
  queries,
}: {
  children: any
  queries: { [key: string]: string }
}) => {
  const [queryMatch, setQueryMatch] =
    useState<Breakpoints<boolean>>(defaultValue)

  useEffect(() => {
    const mediaQueryLists = {}
    const keys = Object.keys(queries)
    let isAttached = false

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce(
        (acc, media) => {
          acc[media] = !!(
            mediaQueryLists[media] && mediaQueryLists[media].matches
          )
          return acc
        },
        { ...defaultValue }
      )
      setQueryMatch(updatedMatches)
    }

    if (window && window.matchMedia) {
      const matches = { ...defaultValue }
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(queries[media])
          matches[media] = mediaQueryLists[media].matches
        } else {
          matches[media] = false
        }
      })
      setQueryMatch(matches)
      isAttached = true
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media].addListener(handleQueryListener)
        }
      })
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === 'string') {
            mediaQueryLists[media].removeListener(handleQueryListener)
          }
        })
      }
    }
  }, [queries])

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  )
}

BreakpointProvider.propTypes = {
  children: PropTypes.element.isRequired,
  queries: PropTypes.object.isRequired,
}

function useBreakpoint() {
  const context = useContext(BreakpointContext)
  return context
}

export { BreakpointProvider, useBreakpoint }
