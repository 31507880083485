import styled, { css } from 'styled-components'

export const SSelectableInput = styled.label`
  ${({ theme }) => css`
    .label {
      width: 100%;
      height: 72px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      position: relative;

      padding: 0 ${theme.space.md}px 0 66px;

      border: 1px solid #ede8e9;
      border-radius: 8px;

      cursor: pointer;

      font-size: 0.875rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.31rem;

      color: #908e8e;

      &::before {
        content: '';
        width: 24px;
        height: 24px;

        position: absolute;
        left: ${theme.space.lg}px;

        background: #e6e6e6;
        border-radius: 50%;
      }
    }

    &:hover {
      .label {
        border-color: ${theme.colors.primary};

        &::before {
          background-color: ${theme.colors.primary};
          opacity: 0.2;
        }
      }
    }

    input {
      width: 1px;
      height: 1px;

      position: absolute;

      opacity: 0;
      visibility: hidden;

      &:checked {
        & + .label {
          color: #3a3335;

          border-color: ${theme.colors.primary};

          &::before {
            background-color: ${theme.colors.primary};
            opacity: 0.2;
          }

          &::after {
            content: '';
            width: 14px;
            height: 14px;

            position: absolute;
            top: 28px;
            left: 29px;

            background: ${theme.colors.primary};
            border-radius: 50%;
          }
        }
      }
    }
  `}
`
