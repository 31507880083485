import styled from 'styled-components';

export const SBenefit = styled.li`
  align-items: center;
  display: flex;
  flex-flow: column;
  row-gap: 20px;
  width: 100%;

  .title {
    color: #304659;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    margin-bottom: 7px;
  }

  .description {
    color: #696f8c;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    width: 274px;
  }
`;
