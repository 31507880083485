import { useIntl } from 'react-intl';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';

import { useAppDispatch } from 'Root';
import client from 'api/client';
import config from 'const/config';
import { AppAuthLayout, Button, TextInput } from 'components';
import useLocalizeRoute from 'utils/useLocalizeRoute';

import { AppRoute, SalonLogin } from 'const';

import { getUser } from 'state/reducers/user/userActions';
import background from 'assets/images/salon-auth-background-1.jpg';
import formErrorsHandler from 'utils/formErrorsHandler';

const defaultFormValues = {
  email: '',
  password: '',
};

function AppAuthLogin() {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();
  const { localizeRouteKey } = useLocalizeRoute();

  const methods = useForm<SalonLogin>({
    defaultValues: defaultFormValues,
  });

  const handleSubmit = async (values: SalonLogin) => {
    await client('provider/auth/login')
      .errorType('json')
      .catcher(
        422,
        formErrorsHandler<SalonLogin>(
          Object.keys(defaultFormValues),
          methods.setError
        )
      )
      .post(values)
      .json(async (json) => {
        const token = json?.data?.token ?? null;
        localStorage.setItem(config.TOKEN_KEY, token);

        const response = await dispatch(getUser());
        // @ts-ignore
        if (response.type === getUser.fulfilled.toString()) {
          methods.reset(defaultFormValues);
          history.push(localizeRouteKey(AppRoute.AppProfile));

          return;
        }

        methods.setError('email', { type: 'required' });
      })
      .catch((error) => {
        methods.setError('email', { type: 'required' });
        console.log('[POST] app login error', error);
      });
  };

  return (
    <AppAuthLayout {...{ background }}>
      <p className="heading">
        {formatMessage({ id: 'app-auth.login-heading' })}
      </p>
      <p className="description">
        {formatMessage({
          id: 'app-auth.login-description',
        })}
      </p>

      <div className="content">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <TextInput
              {...{
                id: 'email',
                label: formatMessage({ id: 'app-auth.login-email' }),
                icon: 'mail',
              }}
              className="row"
            />
            <TextInput
              {...{
                id: 'password',
                type: 'password',
                label: formatMessage({ id: 'app-auth.login-password' }),
                icon: 'lock',
              }}
              className="row"
            />
            <div className="sign-in-helpers">
              <Link to={localizeRouteKey(AppRoute.AppAuthForgotPassword)}>
                Odzyskaj hasło
              </Link>
            </div>
            <Button
              type="submit"
              wide
              disabled={methods.formState.isSubmitting}
              loading={methods.formState.isSubmitting}
              className="submit-button"
            >
              {formatMessage({ id: 'general.sign-in' })}
            </Button>
          </form>
          <p className="information">
            {formatMessage(
              { id: 'app-auth.login-register' },
              {
                a: (chunk) => (
                  <Link to={localizeRouteKey(AppRoute.AppAuthRegister)}>
                    {chunk}
                  </Link>
                ),
              }
            )}
          </p>
        </FormProvider>
      </div>
    </AppAuthLayout>
  );
}

export { AppAuthLogin };
