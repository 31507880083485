import React from 'react'
import { IntlProvider } from 'react-intl'
import { Route, Redirect } from 'react-router-dom'
import { AppLanguage } from 'const'

import { LanguageStrings, pl as base } from '../localizations'

interface Props {
  RouterComponent: React.ComponentClass<any>
  languages: { [k: number]: string }
  appStrings: { [prop: string]: LanguageStrings }
  defaultLanguage?: AppLanguage
}

export const LocalizedRouter: React.FC<Props> = ({
  children,
  RouterComponent,
  appStrings,
  defaultLanguage,
}) => (
  <RouterComponent>
    <Route path="/:lang([a-zA-Z]{2})">
      {({ match, location }) => {
        const params = match ? match.params : { lang: AppLanguage.Polish }
        const { lang = defaultLanguage || AppLanguage.Polish } = params

        const { pathname, search } = location
        if (!pathname.includes(`/${lang}/`)) {
          const supported = Object.keys(base).some(
            (key) => base[key] === pathname
          )

          if (supported) {
            if (search) {
              return <Redirect to={`/${lang}${pathname}${search}`} />
            }
            return <Redirect to={`/${lang}${pathname}`} />
          }
          return <Redirect to={`/${lang}/`} />
        }

        window.scrollTo({ top: 0 })

        return (
          <IntlProvider locale={lang} messages={appStrings[lang]}>
            {children}
          </IntlProvider>
        )
      }}
    </Route>
  </RouterComponent>
)
