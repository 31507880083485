import { ThemedCssFunction, css } from 'styled-components'
import { breakpoints, Theme } from '.'

// Iterate through the sizes and create a media template
export const media = (
  Object.keys(breakpoints) as (keyof typeof breakpoints)[]
).reduce((acc, label) => {
  acc[label] = (first: any, ...interpolations: any[]) => css`
    @media (min-width: ${breakpoints[label]}px) {
      ${css(first, ...interpolations)}
    }
  `

  return acc
}, {} as { [key in keyof typeof breakpoints]: ThemedCssFunction<Theme> })

export default media
