import { Wrapper } from 'components';
import React, { FC } from 'react';

import Layout from '../components/Shared/Layout';

const Regulations: FC = () => {
    return (
        <Layout hasHeader sticky={{ zIndex: 3 }}>
            <Wrapper>
                <div style={{padding: '80px 0'}}>
               <p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>POLITYKA PRYWATNOŚCI SERWISU INTERNETOWEGO</strong></p>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><br /></p>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>WWW.DOGZI.PL</strong></p>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><br /></p>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>&sect; 1</strong></p>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>POSTANOWIENIA OG&Oacute;LNE</strong></p>
<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Administratorem danych osobowych zbieranych za pośrednictwem Serwisu internetowego www.dogzi.pl jest CARBOO SP&Oacute;ŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ wpisana do Rejestru Przedsiębiorc&oacute;w przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000832514, NIP: 5223180409, REGON: 385729814, adres wykonywania działalności oraz adres do doręczeń: ul. Cietrzewia 33/5, 02-492 Warszawa, adres poczty elektronicznej (e-mail<strong style={{ fontWeight: 700 }}>):&nbsp;</strong>kontakt@dogzi.pl, zwana dalej &bdquo;Administratorem&quot; i będąca jednocześnie &bdquo;Usługodawcą&rdquo;.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Dane osobowe zbierane przez Administratora za pośrednictwem strony internetowej są przetwarzane zgodnie z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (og&oacute;lne rozporządzenie o ochronie danych), zwane dalej <strong style={{ fontWeight: 700 }}>RODO.</strong></li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Wszelkie wyrazy lub wyrażenia pisane w treści niniejszej Polityki Prywatności z dużej litery należy rozumieć zgodnie z ich definicją zawartą w Regulaminie Serwisu internetowego www.dogzi.pl</li>
</ol>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>&sect; 2</strong></p>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH, CEL I ZAKRES ZBIERANIA DANYCH</strong></p>
<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>CEL PRZETWARZANIA I PODSTAWA PRAWNA.</strong> Administrator przetwarza dane osobowe Usługobiorc&oacute;w Serwisu www.dogzi.pl w przypadku:<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>rejestracji Konta w&nbsp;Serwisie, w&nbsp;celu utworzenia indywidualnego konta i&nbsp;zarządzania tym Kontem, na podstawie art. 6 ust. 1 lit. b) RODO (realizacja umowy o świadczenie usługi drogą elektroniczną zgodnie z&nbsp;Regulaminem Serwisu),</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>dokonania Subskrypcji, w celu umożliwienia Partnerowi umieszczania ogłoszeń, na podstawie art. 6 ust. 1 lit. b) RODO (realizacja umowy o świadczenie usługi drogą elektroniczną zgodnie z&nbsp;Regulaminem Serwisu).</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>dokonywania Rezerwacji w&nbsp;Serwisie, w&nbsp;celu zawarcia Umowy o świadczenie Usług z Partnerem, na podstawie art. 6 ust. 1 lit. b) RODO (realizacja umowy o świadczenie usługi drogą elektroniczną zgodnie z&nbsp;Regulaminem Serwisu),</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>zapisania się do Newslettera w celu przesyłania informacji handlowych drogą elektroniczną. Dane osobowe są przetwarzane po wyrażeniu odrębnej zgody, na podstawie art. 6 ust. 1 lit. a) RODO,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>skorzystania z Forum Dyskusyjnego, na podstawie art. 6 ust. 1 lit. f) RODO (prawnie uzasadniony interes przedsiębiorcy),</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>skorzystania z Systemu Opinii, na podstawie art. 6 ust. 1 lit. f) RODO (prawnie uzasadniony interes przedsiębiorcy),</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>skorzystania z Formularza Kontaktowego, w&nbsp;celu wysłania wiadomości do Administratora, na podstawie art. 6 ust. 1 lit. f) RODO (prawnie uzasadniony interes przedsiębiorcy).</li>
        </ol>
    </li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH.</strong> Usługobiorca podaje, w przypadku:<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>Konta</strong>: imię i nazwisko, login, adres, adres e-mail,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>Subskrypcji</strong>: imię i nazwisko, firma, NIP, adres e-mail, numer telefonu,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>Rezerwacji</strong>: imię i nazwisko, adres e-mail, numer telefonu,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>Newslettera</strong>: imię, adres mailowy,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>Forum Dyskusyjnego:&nbsp;</strong>imię, login, hasło,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>Systemu Opinii:&nbsp;</strong>imię i nazwisko, login,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>Formularza Kontaktowego</strong>: imię, adres mailowy.</li>
        </ol>
    </li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>OKRES ARCHIWIZACJI DANYCH OSOBOWYCH.</strong> Dane osobowe Usługobiorc&oacute;w przechowywane są przez Administratora:&nbsp;<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>w przypadku, gdy podstawą przetwarzania danych jest wykonanie umowy, tak długo, jak jest to niezbędne do wykonania umowy, a&nbsp;po tym czasie przez okres odpowiadający okresowi przedawnienia roszczeń. Jeżeli przepis szczeg&oacute;lny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a&nbsp;dla roszczeń o&nbsp;świadczenia okresowe oraz roszczeń związanych z&nbsp;prowadzeniem działalności gospodarczej - trzy lata,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>w przypadku, gdy podstawą przetwarzania danych jest zgoda, tak długo, aż zgoda nie zostanie odwołana, a&nbsp;po odwołaniu zgody przez okres czasu odpowiadający okresowi przedawnienia roszczeń jakie może podnosić Administrator i&nbsp;jakie mogą być podnoszone wobec niego. Jeżeli przepis szczeg&oacute;lny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a&nbsp;dla roszczeń o&nbsp;świadczenia okresowe oraz roszczeń związanych z&nbsp;prowadzeniem działalności gospodarczej - trzy lata,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>w przypadku, gdy podstawą przetwarzania danych jest prawnie uzasadniony interes przedsiębiorcy, przez okres istnienia prawnie uzasadnionego interesu realizowanego przez Administratora, a po tym czasie przez okres odpowiadający okresowi przedawnienia roszczeń. Jeżeli przepis szczeg&oacute;lny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a&nbsp;dla roszczeń o&nbsp;świadczenia okresowe oraz roszczeń związanych z&nbsp;prowadzeniem działalności gospodarczej - trzy lata. Administrator nie może przetwarzać danych na wskazanej podstawie w przypadku wyrażenia skutecznego sprzeciwu w tym zakresie przez osobę, kt&oacute;rej dane dotyczą.</li>
        </ol>
    </li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Podczas korzystania z Serwisu mogą być pobierane dodatkowe informacje, w&nbsp;szczeg&oacute;lności: adres IP przypisany do komputera Usługobiorcy lub zewnętrzny adres IP dostawcy Internetu, nazwa domeny, rodzaj przeglądarki, czas dostępu, typ systemu operacyjnego.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Po wyrażeniu odrębnej zgody, na podstawie art. 6 ust. 1 lit. a) RODO dane mogą być przetwarzane r&oacute;wnież w celu przesyłania informacji handlowych drogą elektroniczną lub wykonywania telefonicznych połączeń w celu marketingu bezpośredniego &ndash; odpowiednio w związku z art. 10 ust. 2 Ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną lub art. 172 ust. 1 Ustawy z dnia 16 lipca 2004 roku &ndash; Prawo Telekomunikacyjne, w tym kierowanych w wyniku profilowania, o ile Usługobiorca wyraził stosowną zgodę.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Od Usługobiorc&oacute;w mogą być także gromadzone dane nawigacyjne, w&nbsp;tym informacje o&nbsp;linkach i&nbsp;odnośnikach, w&nbsp;kt&oacute;re zdecydują się kliknąć lub innych czynnościach, podejmowanych w Serwisie. Podstawą prawną tego rodzaju czynności jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), polegający na ułatwieniu korzystania z&nbsp;usług świadczonych drogą elektroniczną oraz na poprawie funkcjonalności tych usług.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Podanie danych osobowych przez Usługobiorcę jest dobrowolne.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Administrator dokłada szczeg&oacute;lnej staranności w celu ochrony interes&oacute;w os&oacute;b, kt&oacute;rych dane dotyczą, a w szczeg&oacute;lności zapewnia, że zbierane przez niego dane są:<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>przetwarzane zgodnie z prawem,&nbsp;</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>zbierane dla oznaczonych, zgodnych z prawem cel&oacute;w i niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi celami,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>merytorycznie poprawne i adekwatne w stosunku do cel&oacute;w, w jakich są przetwarzane oraz przechowywane w postaci umożliwiającej identyfikację os&oacute;b, kt&oacute;rych dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania.</li>
        </ol>
    </li>
</ol>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>&sect; 3</strong></p>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>UDOSTĘPNIENIE DANYCH OSOBOWYCH</strong></p>
<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Dane osobowe Usługobiorc&oacute;w przekazywane są dostawcom usług, z&nbsp;kt&oacute;rych korzysta Administrator przy prowadzeniu Serwisu, a w szczeg&oacute;lności do:<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Partner&oacute;w,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>dostawc&oacute;w system&oacute;w płatności,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>biura księgowego,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>hostingodawcy,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>dostawcy oprogramowania umożliwiającego prowadzenie działalności,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>podmiot&oacute;w zapewniających system mailingowy,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>dostawcy oprogramowania potrzebnego do prowadzenia serwisu internetowego.</li>
        </ol>
    </li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Dostawcy usług, o kt&oacute;rych mowa w pkt 1 niniejszego paragrafu, kt&oacute;rym przekazywane są dane osobowe, w&nbsp;zależności od uzgodnień umownych i&nbsp;okoliczności, albo podlegają poleceniom Administratora co do cel&oacute;w i&nbsp;sposob&oacute;w przetwarzania tych danych (podmioty przetwarzające) albo samodzielnie określają cele i&nbsp;sposoby ich przetwarzania (administratorzy).</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Dane osobowe Usługobiorc&oacute;w są przechowywane wyłącznie na terenie Europejskiego Obszaru Gospodarczego (EOG), z&nbsp;zastrzeżeniem &sect;5 pkt 5 oraz &sect;6 Polityki Prywatności.</li>
</ol>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>&sect; 4</strong></p>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>PRAWO KONTROLI, DOSTĘPU DO TREŚCI WŁASNYCH DANYCH ORAZ ICH POPRAWIANIA</strong></p>
<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Osoba, kt&oacute;rej dane dotyczą, ma prawo dostępu do treści swoich danych osobowych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, kt&oacute;rego dokonano na podstawie zgody przed jej cofnięciem.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>Osoba, kt&oacute;rej dane dotyczą ma prawo usunięcia wszystkich danych osobowych zebranych przez Serwis.</strong></li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Podstawy prawne żądania Usługobiorcy:&nbsp;<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>dostęp do danych</strong> &ndash; art. 15 RODO,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>sprostowanie danych</strong> &ndash; art. 16 RODO,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>usunięcie danych (tzw. prawo do bycia zapomnianym)&nbsp;</strong>&ndash; art. 17 RODO,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>ograniczenie przetwarzania</strong> &ndash; art. 18 RODO,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>przeniesienie danych&nbsp;</strong>&ndash; art. 20 RODO,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>sprzeciw&nbsp;</strong>&ndash; art. 21 RODO,</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>cofnięcie zgody&nbsp;</strong>&ndash; art. 7 ust. 3 RODO.</li>
        </ol>
    </li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>W celu realizacji uprawnień, o kt&oacute;rych mowa w pkt 3 można wysłać stosowną wiadomość e-mail na adres: <strong style={{ fontWeight: 700 }}>kontakt@dogzi.pl</strong></li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>W sytuacji wystąpienia przez Usługobiorcę z&nbsp;uprawnieniem wynikającym z&nbsp;powyższych praw, Administrator spełnia żądanie albo odmawia jego spełnienia niezwłocznie, nie p&oacute;źniej jednak niż w&nbsp;ciągu miesiąca po jego otrzymaniu. Jeżeli jednak - z&nbsp;uwagi na skomplikowany charakter żądania lub liczbę żądań &ndash; Administrator nie będzie m&oacute;gł spełnić żądania w&nbsp;ciągu miesiąca, spełni je w&nbsp;ciągu kolejnych dw&oacute;ch miesięcy informując Usługobiorcę uprzednio w&nbsp;terminie miesiąca od otrzymania żądania - o&nbsp;zamierzonym przedłużeniu terminu oraz jego przyczynach.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>W przypadku stwierdzenia, że przetwarzanie danych osobowych narusza przepisy RODO, osoba, kt&oacute;rej dane dotyczą, ma prawo wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.</li>
</ol>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>&sect; 5</strong></p>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>PLIKI &quot;COOKIES&quot;</strong></p>
<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Strona Administratora&nbsp;używa plik&oacute;w&nbsp;&bdquo;<em>cookies&rdquo;</em>.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Instalacja plik&oacute;w &bdquo;<em>cookies</em>&rdquo; jest konieczna do prawidłowego świadczenia usług na stronie internetowej Serwisu. W plikach &bdquo;<em>cookies</em>&quot; znajdują się informacje niezbędne do prawidłowego funkcjonowania strony, a także dają one także możliwość opracowywania og&oacute;lnych statystyk odwiedzin strony internetowej.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>W ramach strony stosowane są dwa rodzaje plik&oacute;w &bdquo;<em>cookies</em>&rdquo;: &bdquo;sesyjne&rdquo; oraz &bdquo;stałe&rdquo;.<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>&bdquo;<em>Cookies</em>&rdquo; &bdquo;sesyjne&rdquo; są plikami tymczasowymi, kt&oacute;re przechowywane są w urządzeniu końcowym Usługobiorcy do czasu wylogowania (opuszczenia strony).&nbsp;</li>
            <li style={{ marginBottom: '20px', lineHeight: '24px'}}>&bdquo;Stałe&rdquo; pliki &bdquo;<em>cookies</em>&rdquo; przechowywane są w urządzeniu końcowym Usługobiorcy przez czas określony w parametrach plik&oacute;w &bdquo;<em>cookies</em>&rdquo; lub do czasu ich usunięcia przez Usługobiorcę.</li>
        </ol>
    </li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Administrator wykorzystuje własne pliki cookies w celu lepszego poznania sposobu interakcji Usługobiorc&oacute;w w zakresie zawartości strony. Pliki gromadzą informacje o sposobie korzystania ze strony internetowej przez Usługobiorcę, typie strony, z jakiej Usługobiorca został przekierowany oraz liczbie odwiedzin i czasie wizyty Usługobiorcy na stronie internetowej. Informacje te nie rejestrują konkretnych danych osobowych Usługobiorcy, lecz służą do opracowania statystyk korzystania ze strony.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Administrator wykorzystuje zewnętrzne pliki cookies<em>&nbsp;</em>w celu zbierania og&oacute;lnych i&nbsp;anonimowych danych statycznych za pośrednictwem narzędzi analitycznych Google Analytics (administrator cookies zewnętrznego: Google LLC. z&nbsp;siedzibą w&nbsp;USA).</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Pliki cookies mogą być r&oacute;wnież wykorzystywane przez sieci reklamowe, w szczeg&oacute;lności sieć Google, w celu wyświetlania reklam dopasowanych do sposobu, w jaki Usługobiorca korzysta z Serwisu. W tym celu mogą zachować informację o ścieżce nawigacji Usługobiorcy lub czasie pozostawania na danej stronie.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Usługobiorca ma prawo zadecydowania w zakresie dostępu plik&oacute;w &bdquo;<em>cookies</em>&rdquo; do swojego komputera poprzez ich uprzedni wyb&oacute;r w oknie swojej przeglądarki. &nbsp;Szczeg&oacute;łowe informacje o możliwości i sposobach obsługi plik&oacute;w&nbsp;&bdquo;<em>cookies</em>&rdquo; dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).</li>
</ol>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>&sect; 6</strong></p>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>USŁUGI DODATKOWE ZWIĄZANE Z AKTYWNOŚCIĄ UŻYTKOWNIKA W SERWISIE</strong></p>
<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>W Serwisie wykorzystywane są tzw. wtyczki społecznościowe (&bdquo;wtyczki&ldquo;) serwis&oacute;w społecznościowych.&nbsp;Wyświetlając stronę internetową www.dogzi.pl, zawierającą taką wtyczkę, przeglądarka Usługobiorcy nawiąże bezpośrednie połączenie z serwerami Facebook.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Zawartość wtyczki jest przekazywana przez danego usługodawcę bezpośrednio do przeglądarki Usługobiorcy i integrowana ze stroną. Dzięki tej integracji, usługodawcy otrzymują informację, że przeglądarka Usługobiorcy wyświetliła stronę www.dogzi.pl, nawet jeśli Usługobiorca nie posiada profilu u danego usługodawcy, czy nie jest u niego akurat zalogowany. Taka informacja (wraz z adresem IP Usługobiorcy) jest przesyłana przez przeglądarkę bezpośrednio do serwera danego usługodawcy (niekt&oacute;re serwery znajdują się w USA) i tam przechowywana.&nbsp;</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Jeśli Usługobiorca zaloguje się do jednego z powyższych serwis&oacute;w społecznościowych, to usługodawca ten będzie m&oacute;gł bezpośrednio przyporządkować wizytę na stronie www.dogzi.pl do profilu Usługobiorcy w danym serwisie społecznościowym.&nbsp;</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Jeśli Usługobiorca użyje danej wtyczki, np. klikając na przycisk &bdquo;Lubię to&rdquo; lub przycisk &bdquo;Udostępnij&rdquo;, to odpowiednia informacja zostanie r&oacute;wnież przesłana bezpośrednio na serwer danego usługodawcy i tam zachowana.&nbsp;</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Cel i zakres gromadzenia danych oraz ich dalszego przetwarzania i wykorzystania przez usługodawc&oacute;w, jak r&oacute;wnież możliwość kontaktu oraz prawa Usługobiorcy w tym zakresie i możliwość dokonania ustawień zapewniających ochronę prywatności Usługobiorcy zostały opisane w polityce prywatności usługodawcy: <a href="https://www.facebook.com/policy.php"><em>https://www.facebook.com/policy.php</em></a></li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Jeśli Usługobiorca nie chce, aby serwisy społecznościowe przyporządkowywały dane zebrane w trakcie odwiedzin na stronie www.dogzi.pl bezpośrednio jego profilowi w danym serwisie, to przed wizytą na stronie www.dogzi.pl musi wylogować się z tego serwisu. Usługobiorca może r&oacute;wnież całkowicie uniemożliwić załadowanie na stronie wtyczek stosując odpowiednie rozszerzenia dla przeglądarki, np. blokowanie skrypt&oacute;w za pomocą &bdquo;NoScript&ldquo;.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Administrator wykorzystuje na swojej stronie narzędzia remarketingowe tj. Google Ads, wiąże się to z wykorzystywaniem plik&oacute;w cookies firmy Google LLC dotyczących usługi Google Ads. W ramach mechanizmu do zarządzania ustawieniami plik&oacute;w cookies Usługobiorca ma możliwość zdecydowania, czy Usługodawca będzie m&oacute;gł korzystać z Google Ads (administrator cookies zewnętrznego: Google Inc. z siedzibą w USA) w stosunku do niego.</li>
</ol>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>&sect; 7</strong></p>
<p style={{ marginBottom: '20px', lineHeight: '24px'}}><strong style={{ fontWeight: 700 }}>POSTANOWIENIA KOŃCOWE</strong></p>
<ol style={{ margin: '20px 0', lineHeight: '24px', listStyleType: 'decimal', paddingLeft: '17px'}}>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych osobowych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczeg&oacute;lności zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem obowiązujących przepis&oacute;w oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>Administrator udostępnia odpowiednie środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione, danych osobowych przesyłanych drogą elektroniczną.</li>
    <li style={{ marginBottom: '20px', lineHeight: '24px'}}>W sprawach nieuregulowanych niniejszą Polityką prywatności stosuje się odpowiednio przepisy RODO oraz inne właściwe przepisy prawa polskiego.</li>
</ol>
                </div>
            </Wrapper>
        </Layout>
    );
};

export { Regulations };
