import React from 'react';
import { Slot } from '../../const';

interface SlotProps extends Slot {
  onClick: (slot: Slot) => void;
  onEdit: (id: number, name: string, avatar: string | null) => void;
}

const WorkplaceSlot = ({
  id,
  onClick,
  onEdit,
  avatar,
  number,
  name,
}: SlotProps) => (
  <div className="slot">
    {avatar && (
      <div className="slot-avatar">
        <img src={avatar} alt={name || ''} />
      </div>
    )}
    {name || ''}
    <button onClick={() => onEdit(id, name, avatar)}>Edytuj</button>
    <button onClick={() => onClick({ id, avatar, name, number })}>Usuń</button>
  </div>
);

export default WorkplaceSlot;
