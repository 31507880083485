import { useState, FC, ChangeEvent } from 'react';
import { useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Autocomplete, {
  IAutocomplete,
} from 'components/Autocomplete/Autocomplete';
import { SAutocomplateInput } from './AutocompleteInput.styled';
import { OptionTypeBase, StylesConfig } from 'react-select';

type SelectIsMulti = boolean;
const selectStyles: StylesConfig<OptionTypeBase, SelectIsMulti> = {
  container: (styles) => ({
    ...styles,
    width: '100%',
  }),
  control: (styles) => ({
    ...styles,
    borderColor: 'transparent',
    boxShadow: 'none',
    borderWidth: 0,
    borderRadius: 0,
    fontSize: '.875rem',
    minHeight: 18,
    '&:hover': {
      borderColor: 'transparent',
    },
  }),
  input: (styles) => ({
    margin: 0,
    paddding: 0,
    fontSize: '.75rem',
    fontWeight: 700,
    lineHeight: '16px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 2,
  }),
  singleValue: (styles) => ({
    ...styles,
    fontSize: '.75rem',
    fontWeight: 700,
    lineHeight: '16px',
    marginLeft: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    paddingTop: 4,
    paddingBottom: 4,
  }),
  placeholder: (styles) => ({
    ...styles,
    fontWeight: 700,
    lineHeight: '16px',
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: 0,
  }),
};
interface AutocompleteInputProps {
  id: string;
  label?: string;
  icon?: string;
  control: any;
}

const AutocomplateInput: FC<AutocompleteInputProps & IAutocomplete> = ({
  id,
  label,
  icon,
  load,
  control,
}) => {
  const {
    field: { value, onChange, onBlur },
    formState: { errors },
  } = useController({ name: id, control });

  const error = errors[id];
  const empty = !value;

  const { formatMessage } = useIntl();

  const [focused, setFocused] = useState<boolean>(false);

  const handleBlur = () => {
    setFocused(false);
    onBlur();
  };
  const handleFocus = () => setFocused(true);
  const handleChange = (value: OptionTypeBase) => {
    onChange({ target: { value } });
  };

  const labelKey = `form.label-${id}`;
  const fieldLabel = label || formatMessage({ id: labelKey });

  return (
    <SAutocomplateInput focused={focused} empty={empty} error={!!error}>
      {focused || !empty ? <p className="label">{fieldLabel}</p> : null}
      {!focused && empty ? (
        <span className="placeholder">{fieldLabel}</span>
      ) : null}
      <Autocomplete
        {...{
          load,
          onChange: handleChange,
          onBlur: handleBlur,
          onFocus: handleFocus,
          placeholder: focused ? '' : formatMessage({ id: 'form.label-city' }),
          customStyles: selectStyles,
          value,
          isClearable: true,
        }}
      />
      <span className="icon material-icons">{error ? 'error' : icon}</span>
    </SAutocomplateInput>
  );
};

export default AutocomplateInput;
