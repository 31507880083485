import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, TextInput, Checkbox, ErrorMessageText } from 'components';
import {
  hideAuthModal,
  showAuthModal,
  updateDogName,
  updatePhoneNumber,
} from 'state/reducers/client/clientActions';
import { useAppDispatch, useAppSelector } from 'Root';
import { submitReservationWithoutLoggin } from 'state/reducers/reservation/reservationActions';
import { AuthModalType } from 'components/Header/Header';
import { ErrorMessage } from '@hookform/error-message';

const AuthPhoneReservation: FC = () => {
  const dispatch = useAppDispatch();

  const defaultFormValues = {
    note: useAppSelector(({ client }) => client.dogName) || '',
    phone: useAppSelector(({ client }) => client.phoneNumber) || '',
  };

  const methods = useForm<{
    note: string;
    phone: string;
    policy: boolean;
  }>({
    defaultValues: defaultFormValues,
  });

  const confirmReservation = async (values: any) => {
    dispatch(updatePhoneNumber(values.phone));
    dispatch(updateDogName(values.note));
    try {
      const data = await dispatch(submitReservationWithoutLoggin());
      if (Object.keys(data).some((value) => value === 'error')) {
        throw new Error('Error while fetching data');
      }
      dispatch(showAuthModal(AuthModalType.PHONE_NUMBER_RESERVATION_SUCCESS));
    } catch (error) {
      dispatch(hideAuthModal());
      alert('Wystąpił błąd podczas rezerwacji. Spróbuj ponownie później.');
      console.error(error);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(confirmReservation)}>
        <TextInput
          {...{
            id: 'note',
            name: 'note',
            label: 'Imię psiaka',
            icon: 'pets',
            className: 'field',
          }}
          {...methods.register('note', {
            required: 'Pole jest wymagane.',
          })}
        />

        <TextInput
          {...{
            id: 'phone',
            name: 'phone',
            label: 'Numer telefonu',
            icon: 'phone',
            type: 'text',
            className: 'field',
          }}
          {...methods.register('phone', {
            required: 'Pole jest wymagane.',
          })}
        />

        <Checkbox>
          <input
            {...{
              id: 'policy',
              name: 'policy',
              type: 'checkbox',
            }}
            {...methods.register('policy', {
              required: 'Pole jest wymagane.',
            })}
          />
          <label htmlFor="policy">
            Zapoznałem się z{' '}
            <a href="http://dogzi.pl/pl/regulations">regulaminem</a> i akceptuję
            jego warunki.
          </label>
        </Checkbox>
        <ErrorMessage
          errors={methods.formState.errors}
          name="policy"
          render={({ message }) => (
            <ErrorMessageText>{message}</ErrorMessageText>
          )}
        />

        <Button
          primary
          className="button"
          loading={methods.formState.isSubmitting}
          disabled={methods.formState.isSubmitting}
        >
          Potwierdź wizytę
        </Button>
      </form>
    </FormProvider>
  );
};

export default AuthPhoneReservation;
