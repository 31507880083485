import { StylesConfig, OptionTypeBase } from 'react-select';

type SelectIsMulti = boolean;

export const selectStyles: StylesConfig<OptionTypeBase, SelectIsMulti> = {
  container: (styles) => ({
    ...styles,
    border: '1px solid #EDE8E9',
    borderRadius: 24,
    cursor: 'pointer',
    padding: '5px 10px',
    width: '264px',
  }),
  control: (styles) => ({
    ...styles,
    borderColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    fontSize: '.875rem',
    minHeight: 24,
    '&:hover': {
      borderColor: 'transparent',
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#908E8E',
  }),
  menu: (styles) => ({
    ...styles,
    left: 0,
  }),
  option: (styles) => ({
    ...styles,
    textAlign: 'left',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingTop: 0,
    borderWidth: 1,
    borderColor: '#000',
    cursor: 'pointer',
    paddingBottom: 0,
    paddingLeft: 0,
  }),
  singleValue: (styles) => ({
    ...styles,
    fontWeight: 700,
    lineHeight: 16,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    paddingTop: 4,
    color: '#FF6B00',
    cursor: 'pointer',
    paddingBottom: 4,
    '&:hover': {
      color: '#FF6B00',
    },
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: 0,
  }),
};
