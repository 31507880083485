import React, { useState } from 'react';
import { SFilters } from './Filters.styled';
import { ICategory } from '../../const/filter-categories';
import CategoryPill from '../Shared/CategoryPill';
import loadAutocompleteLocations from '../../api/loadAutocompleteLocations';
import { Autocomplete } from '../index';
import { StylesConfig, OptionTypeBase } from 'react-select';
import { useIntl } from 'react-intl';
import { useAppSelector, useAppDispatch } from '../../Root';
import { parseCategories } from '../../const/filter-categories';
import { clearSalonsSearch, setSalonsSearch } from '../../state/reducers/salons/salonsActions';
import SwiperCore, { Controller, Navigation, Swiper } from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import FiltersSliderControl from './FiltersSliderControl';

SwiperCore.use([Controller, Navigation]);

type SelectIsMulti = boolean;

const selectStyles: StylesConfig<OptionTypeBase, SelectIsMulti> = {
  container: (styles) => ({
    ...styles,
    border: '1px solid #EDE8E9',
    borderRadius: 24,
    cursor: 'pointer',
    padding: '5px 10px',
    width: '195px',
  }),
  control: (styles) => ({
    ...styles,
    borderColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    fontSize: '.875rem',
    minHeight: 24,
    '&:hover': {
      borderColor: 'transparent',
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingTop: 0,
    borderWidth: 1,
    borderColor: '#000',
    cursor: 'pointer',
    paddingBottom: 0,
    paddingLeft: 0,
  }),
  singleValue: (styles) => ({
    ...styles,
    fontWeight: 700,
    lineHeight: 16,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    paddingTop: 4,
    color: '#FF6B00',
    cursor: 'pointer',
    paddingBottom: 4,
    '&:hover': {
      color: '#FF6B00',
    },
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: 0,
  }),
};

interface FiltersProps {
  selected: number[];
  onToggle: (category: OptionTypeBase) => void;
}

const Filters = ({ selected, onToggle }: FiltersProps) => {
  const [expanded, setExpanded] = useState(false);
  const { formatMessage } = useIntl();
  const { categories } = useAppSelector(({ app }) => app);
  const search = useAppSelector(({ salons }) => salons.search);

  const [swiper, setSwiper] = useState<Swiper>();

  const dispatch = useAppDispatch();

  const selectLocation = (value: OptionTypeBase) => {
    dispatch(
      setSalonsSearch({
        ...search,
        location: value,
      })
    );
  };

  const listingCategories: ICategory[] = [
    // {
    //   name: 'Wszystko',
    //   displayName: 'Wszystko',
    //   id: 0,
    //   background: { from: '97, 126, 169', to: '97, 126, 169' },
    //   textColor: 'rgba(114, 114, 114, 1)',
    // },
    ...parseCategories(categories),
  ];

  return (
    <SFilters expanded={expanded}>
      <div className="top">
        <Autocomplete
          load={loadAutocompleteLocations}
          placeholder={formatMessage({
            id: 'app.basic-search.location',
          })}
          customStyles={{
            ...selectStyles,
            container: (styles) => ({
              ...styles,
              border: '1px solid #EDE8E9',
              borderRadius: 24,
              height: '40px',
              cursor: 'pointer',
              padding: '5px 10px',
              width: '195px',
            }),
          }}
          onChange={selectLocation}
          value={search.location}
        />

        <div className="right-side-wrapper">
          <ul className="categories">
            {!expanded ? (
              <SwiperComponent
                slidesPerView="auto"
                spaceBetween={12}
                onSwiper={setSwiper}
                preventClicks={true}
              >
                {listingCategories.map((category) => (
                  <SwiperSlide key={category.id}>
                    <CategoryPill
                      {...category}
                      active={selected.includes(category.id || 0)}
                      onSelect={() => {
                        dispatch(clearSalonsSearch())
                        onToggle(category)
                      }
                      }
                    />
                  </SwiperSlide>
                ))}
              </SwiperComponent>
            ) : (
              listingCategories.map((category) => (
                <CategoryPill
                  key={category.id}
                  {...category}
                  active={selected.includes(category.id || 0)}
                  onSelect={() => {
                    dispatch(clearSalonsSearch())
                    onToggle(category)
                  }
                  }
                />
              ))
            )}
          </ul>

          {!expanded && (
            <FiltersSliderControl
              onPrev={() => swiper?.slidePrev()}
              onNext={() => swiper?.slideNext()}
            />
          )}
        </div>

        <button
          className="all-filters-button"
          onClick={() => setExpanded((prevState) => !prevState)}
        >
          {expanded ? 'Zamknij kategorie X' : 'Zobacz wszystkie kategorie'}
        </button>
      </div>
    </SFilters>
  );
};

export default Filters;
