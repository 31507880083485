import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'Root';
import { activeSalonOffer } from 'state/reducers/user/userActions';

export const AppAcceptOffer: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const queryParams = new URLSearchParams(window.location.search);
  const token = String(queryParams.get('token'));

  useEffect(() => {
    dispatch(activeSalonOffer({ id, token }));
  }, []);

  return <></>;
};
