import { FC } from 'react'

const CalendarIcon: FC = (props) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.666 4a3.333 3.333 0 106.667 0 3.333 3.333 0 00-6.667 0zm5 0a1.667 1.667 0 11-3.333 0 1.667 1.667 0 013.333 0zM9 17.334c-4.551 0-8.333-1.681-8.333-4.167C.666 10.68 4.448 9 8.999 9c4.552 0 8.334 1.681 8.334 4.167 0 2.486-3.782 4.167-8.334 4.167zm6.667-4.167c0 1.196-2.934 2.5-6.666 2.5-3.733 0-6.667-1.304-6.667-2.5s2.934-2.5 6.667-2.5c3.732 0 6.666 1.304 6.666 2.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CalendarIcon
