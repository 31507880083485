import { createAction } from '@reduxjs/toolkit'
import { Image } from 'const'

const SET_GALLERY_IMAGES = 'gallery/setImages'
export const setGalleryImages = createAction<{
  images: Image[]
  active: Image
}>(SET_GALLERY_IMAGES)

const CLEAR_GALLERY_IMAGES = 'gallery/clear'
export const clearGalleryImages = createAction(CLEAR_GALLERY_IMAGES)
