import styled, { css } from 'styled-components';

export const SEmptyState = styled.div`
  ${({ theme }) => css`
    .empty {
      max-width: 572px;

      margin: 0 auto;
      padding-top: ${theme.space.xxxl}px;

      img {
        width: 100%;
        height: auto;
      }

      &-heading {
        font-size: 2.125rem;
        font-weight: ${theme.weight.heavy};
        line-height: 3rem;
        text-align: center;
        color: #3a3335;

        margin-top: ${theme.space.xl}px;
      }

      &-description {
        max-width: 404px;

        font-size: 0.875rem;
        line-height: 1.31rem;
        text-align: center;
        color: #908e8e;

        margin: 0 auto;
        margin-top: ${theme.space.sm}px;
      }

      &-link {
        width: 194px;
        height: 46px;

        margin: 0 auto;
        margin-top: ${theme.space.xl}px;
      }
    }
  `}
`;
