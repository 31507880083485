import styled from 'styled-components';
import media from '../../theme/respondTo';

export const SBenefits = styled.section`
  width: 100%;

  .wrapper {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: inherit;
  }

  .icon-wrapper {
    height: 92px;
    width: 92px;
  }

  .header {
    color: #304659;
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    width: 332px;

    ${media.md`
        font-size: 30px;
        width: 80%;
    `}
  }

  .subheader {
    color: #696f8c;
    font-size: 16px;
    margin-top: 11px;
    line-height: 28px;
    text-align: center;
    width: 80%;

    ${media.md`
        line-height: 21px;
        width: 100%;
    `}
  }

  .list {
    display: none;
    margin-top: 78px;

    ${media.md`
        display: grid;
        grid-template-columns: repeat(2, 300px);
        grid-row-gap: 40px;
        grid-column-gap: 82px;
    `}
    ${media.lg`
        display: grid;
        grid-template-columns: repeat(3, 300px);
        grid-row-gap: 40px;
        grid-column-gap: 82px;
    `}
  }

  .divider {
    background-color: #ede8e9;
    height: 1px;
    display: flex;
    margin-top: 52px;
    width: 100%;
  }

  .swiper-container {
    margin: 49px 34px 0;
    width: 100%;

    ${media.md`
        display: none;
    `}
  }

  .benefits-pagination {
    display: flex;
    margin-top: 36px;

    ${media.md`
        display: none;
    `}
  }
`;
