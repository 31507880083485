import React from 'react';

import { AppHeader } from 'components';

const AppLayout: React.FC = ({ children }) => (
  <>
    <AppHeader />
    <main>{children}</main>
  </>
);

export default AppLayout;
