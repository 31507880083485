import { FC, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Cross as Hamburger } from 'hamburger-react';
import classNames from 'classnames';

import { AppRoute } from 'const';
import useLocalizeRoute from 'utils/useLocalizeRoute';

import { Wrapper, CalendarIcon, PersonIcon, Header } from 'components';
import { SAppHeader } from './ProfileHeader.styled';
import { useBreakpoint } from 'modules/breakpoint';

/*
{
        name: 'Program Partnerski',
        icon: <span className="material-icons icon">star_border</span>,
        exact: true,
        active:
          location.pathname ===
          localizeRouteKey(AppRoute.ProfilePartnersProgram),
        path: localizeRouteKey(AppRoute.ProfilePartnersProgram),
      },
 */

const AppHeader: FC = () => {
  const { formatMessage } = useIntl();
  const { localizeRouteKey } = useLocalizeRoute();

  const location = useLocation();
  const breakpoint = useBreakpoint();

  const [mobileNavigationVisible, setMobileNavigationVisible] =
    useState<boolean>(false);

  const links = useMemo(() => {
    return [
      {
        name: 'Rezerwacje',
        icon: <CalendarIcon />,
        exact: true,
        active: location.pathname === localizeRouteKey(AppRoute.Profile),
        path: localizeRouteKey(AppRoute.Profile),
      },
      {
        name: 'Ulubione',
        icon: <span className="material-icons icon">favorite_border</span>,
        exact: true,
        active:
          location.pathname === localizeRouteKey(AppRoute.ProfileFavorite),
        path: localizeRouteKey(AppRoute.ProfileFavorite),
      },
      {
        name: 'Ustawienia konta',
        icon: <PersonIcon />,
        exact: true,
        active:
          location.pathname === localizeRouteKey(AppRoute.ProfileSettings) ||
          location.pathname === localizeRouteKey(AppRoute.ProfilePassword),
        path: localizeRouteKey(AppRoute.ProfileSettings),
      },
      {
        name: 'Opinie',
        icon: <span className="material-icons icon">star_border</span>,
        exact: true,
        active: location.pathname === localizeRouteKey(AppRoute.ProfileReviews),
        path: localizeRouteKey(AppRoute.ProfileReviews),
      },
      {
        name: 'Wyloguj',
        icon: <span className="material-icons icon">close</span>,
        exact: true,
        active: location.pathname === localizeRouteKey(AppRoute.Logout),
        path: localizeRouteKey(AppRoute.Logout),
      },
    ];
  }, [location]);

  useEffect(() => {
    setMobileNavigationVisible(false);
  }, [location]);

  const toggleMobileNavigation = () =>
    setMobileNavigationVisible(!mobileNavigationVisible);

  const renderMobileNavigation = () => {
    const activeLink = links.find((link) => link.active);
    if (!activeLink) return null;

    return (
      <div
        className={classNames('mobile-navigation', {
          active: mobileNavigationVisible,
        })}
      >
        {mobileNavigationVisible ? (
          <div className="mobile-navigation-background" />
        ) : null}
        <div className="mobile-navigation-active">
          <p>
            {!mobileNavigationVisible ? (
              <>
                {activeLink.icon} {activeLink.name}
              </>
            ) : null}
          </p>
          <button
            className="mobile-navigation-burger"
            onClick={toggleMobileNavigation}
          >
            {mobileNavigationVisible ? 'Zamknij' : 'Menu'}
            <Hamburger
              toggled={mobileNavigationVisible}
              size={22}
              color="#908E8E"
            />
          </button>
        </div>
        {mobileNavigationVisible ? (
          <div className="mobile-navigation-list">{renderNavigation(true)}</div>
        ) : null}
      </div>
    );
  };

  const renderNavigation = (inside?: boolean) => {
    if (breakpoint.lg && !inside) return renderMobileNavigation();

    return (
      <ul className="navigation">
        {links.map((link) => (
          <li>
            <NavLink
              exact={link.exact}
              activeClassName="active"
              to={link.path}
              isActive={() => link.active}
            >
              {link.icon} {link.name}
            </NavLink>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <SAppHeader>
      <Header hasColor />
      <div className="bottom">
        <Wrapper>{renderNavigation()}</Wrapper>
      </div>
    </SAppHeader>
  );
};

export default AppHeader;
