import { FC, useRef, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import Sticky from 'react-stickynode';

import {
  ConfirmationBaner,
  Footer,
  Header,
  PageLoader,
  SalonDetails,
} from 'components';
import useCalculateSectionHeight from 'utils/useCalculateSectionHeight';
import { useAppDispatch, useAppSelector } from 'Root';
import { getSalon } from 'state/reducers/salons/salonsActions';
import { setGalleryImages } from 'state/reducers/gallery/galleryActions';
import { Image } from 'const';

const Salon: FC = () => {
  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.track('ViewContent', true);
      });
  }, []);

  const sectionRef = useRef<HTMLDivElement>(null);

  const match = useRouteMatch<{ id: string }>();
  const { id } = match.params;

  useCalculateSectionHeight(sectionRef);

  const dispatch = useAppDispatch();
  const { data, reviews, favorite } = useAppSelector(
    ({ salons }) => salons.single
  );

  const showGallery = (image: Image) => {
    if (!data?.images.length) return;
    dispatch(setGalleryImages({ images: data.images, active: image }));
  };

  const queryParams = new URLSearchParams(window.location.search);
  const token = String(queryParams.get('token'));

  useEffect(() => {
    dispatch(getSalon({ id, token }));
  }, [id]);

  if (!data) return <PageLoader />;

  return (
    <>
      <Sticky innerZ={3}>
        {() => <Header hasColor {...{ status: Sticky.STATUS_FIXED }} />}
      </Sticky>

      {data.published == false && <ConfirmationBaner />}
      <div ref={sectionRef}>
        <SalonDetails
          {...{
            data,
            reviews,
            showGallery,
          }}
        />
      </div>
      <Footer />
    </>
  );
};

export { Salon };
