import { AsyncThunk, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import client from 'api/client';
import { AuthModalType } from 'components/Header/Header';
import { Booking, Review } from 'const';
import config from 'const/config';
import { without } from 'lodash';
import { RootState } from 'state/createStore';
import { showAppModal } from '../app/appActions';
import { AppDialogCloseType } from 'components/UserModal/AppDialogModal';

const SHOW_AUTH_MODAL = 'user/showAuthModal';
export const showAuthModal = createAction<AuthModalType>(SHOW_AUTH_MODAL);

const HIDE_AUTH_MODAL = 'user/hideAuthModal';
export const hideAuthModal = createAction(HIDE_AUTH_MODAL);

const LOGOUT_CLIENT = 'client/logout';
export const logoutClient = createAction(LOGOUT_CLIENT);

export const getUsersDiscounts = createAsyncThunk(
  'user/getDiscounts',
  async (_, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);

    return await client('client/account/partnerProgram')
      .auth(`Bearer ${token}`)
      .errorType('json')
      .get()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(false);
      })
      .json((json) => {
        const { data, pages } = json;
        return { items: data, totalPages: pages };
      })
      .catch(() => {
        return thunkAPI.rejectWithValue(false);
      });
  }
);

const GET_CLIENT_USER = 'user/client/get';
export const getClientUser = createAsyncThunk(
  GET_CLIENT_USER,
  async (_, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);
    if (!token) {
      return thunkAPI.rejectWithValue(false);
    }

    return await client('client/account')
      .auth(`Bearer ${token}`)
      .errorType('json')
      .get()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(false);
      })
      .json((json) => {
        const { data } = json;
        return data;
      })
      .catch(() => {
        return thunkAPI.rejectWithValue(false);
      });
  }
);

const UPDATE_CLIENT_USER = 'user/client/update';
export const updateClientUser: AsyncThunk<
  boolean,
  {
    name: string;
    email: string;
    phone: string;
    photo: File[] | null;
    emailNotifications: number;
  },
  { state: RootState }
> = createAsyncThunk(UPDATE_CLIENT_USER, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(false);
  }

  if (payload.photo) {
    const [file] = payload.photo;
    const formData = new FormData();
    formData.append('avatar', file);

    await fetch(`${config.API_URL}/client/account/avatar`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
  }

  return await client('client/account')
    .auth(`Bearer ${token}`)
    .errorType('json')
    .patch({
      name: payload.name,
      email: payload.email,
      phone: payload.phone,
      emailNotifications: payload.emailNotifications ? 1 : 0,
    })
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(false);
    })
    .json(async (json) => {
      const { data } = json;
      return data;
    })
    .catch((error) => {
      return thunkAPI.rejectWithValue(error);
    });
});

const UPDATE_CLIENT_PASSWORD = 'user/client/updatePassword';
export const updateClientPassword: AsyncThunk<
  boolean,
  {
    currentPassword: string;
    password: string;
    passwordConfirmation: string;
  },
  { state: RootState }
> = createAsyncThunk(UPDATE_CLIENT_PASSWORD, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(false);
  }

  return await client('client/account/change-password')
    .auth(`Bearer ${token}`)
    .errorType('json')
    .patch(payload)
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(false);
    })
    .json(async (json) => {
      const { data } = json;
      return data;
    })
    .catch((error) => {
      return thunkAPI.rejectWithValue(error);
    });
});

const GET_CLIENT_FAVORITES = 'user/client/getFavorites';
export const getClientFavorites = createAsyncThunk(
  GET_CLIENT_FAVORITES,
  async (_, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);
    if (!token) {
      return thunkAPI.rejectWithValue(false);
    }

    return await client('client/favourites')
      .auth(`Bearer ${token}`)
      .errorType('json')
      .get()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(false);
      })
      .json(async (json) => {
        const { data } = json;
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const SET_RESERVATIONS_FILTER = 'user/client/setReservationsFilter';
export const setReservationsFilter = createAction<
  null | 'finished' | 'incoming'
>(SET_RESERVATIONS_FILTER);

const GET_CLIENT_RESERVATIONS = 'user/client/getClientReservations';
export const getClientReservations = createAsyncThunk(
  GET_CLIENT_RESERVATIONS,
  async (_, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);
    if (!token) {
      return thunkAPI.rejectWithValue(false);
    }

    const {
      list: { filter },
    } = (thunkAPI.getState() as RootState).client.reservations;
    let endpoint = 'client/appointments';

    if (filter) {
      endpoint = `${endpoint}?filters[${filter}]=true`;
    }

    return await client(endpoint)
      .auth(`Bearer ${token}`)
      .errorType('json')
      .get()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(false);
      })
      .json(async (json) => {
        const { data } = json;
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const GET_CLIENT_REVIEWS = 'user/client/getReviews';
export const getClientReviews = createAsyncThunk(
  GET_CLIENT_REVIEWS,
  async (_, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);
    if (!token) {
      return thunkAPI.rejectWithValue(false);
    }

    return await client('client/reviews')
      .auth(`Bearer ${token}`)
      .errorType('json')
      .get()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(false);
      })
      .json(async (json) => {
        const { data } = json;
        return data;
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const SHOW_REVIEW_EDITOR = 'user/client/showReviewEditor';
export const showReviewEditor = createAction<{
  appointment: Booking | null;
  review: Review | null;
}>(SHOW_REVIEW_EDITOR);
const HIDE_REVIEW_EDITOR = 'user/client/hideReviewEditor';
export const hideReviewEditor = createAction(HIDE_REVIEW_EDITOR);

const UPDATE_REVIEW = 'user/client/updateReview';
export const updateReview: AsyncThunk<
  boolean,
  Partial<Review>,
  { state: RootState }
> = createAsyncThunk(UPDATE_REVIEW, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(false);
  }

  const body = {
    rating: payload.rating,
    content: payload.content,
  };

  return await client(`client/reviews/${payload.id}`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .patch(body)
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(false);
    })
    .json(async (json) => {
      const { data } = json;
      return data;
    })
    .catch((error) => {
      return thunkAPI.rejectWithValue(error);
    });
});

const CREATE_REVIEW = 'user/client/createReview';
export const createReview: AsyncThunk<
  boolean,
  Partial<Review>,
  { state: RootState }
> = createAsyncThunk(CREATE_REVIEW, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(false);
  }

  const body = {
    rating: payload.rating,
    content: payload.content,
  };

  return await client(`client/appointments/${payload.id}/reviews`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .post(body)
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(false);
    })
    .res(async () => {
      await Promise.all([
        thunkAPI.dispatch(getClientReservations()),
        thunkAPI.dispatch(getClientReviews()),
      ]);
      return true;
    })
    .catch((error) => {
      return thunkAPI.rejectWithValue(error);
    });
});

const SHOW_RESERVATION_CANCELATION = 'user/client/showReservationCancelation';
export const showReservationCancelation = createAction<Booking>(
  SHOW_RESERVATION_CANCELATION
);
const HIDE_RESERVATION_CANCELATION = 'user/client/hideReservationCancelation';
export const hideReservationCancelation = createAction(
  HIDE_RESERVATION_CANCELATION
);

const SHOW_RESERVATION_CANCELATION_MODAL =
  'user/client/showReservationCancelationModal';
export const showReservationCancelationModal = createAction(
  SHOW_RESERVATION_CANCELATION_MODAL
);

const CANCEL_RESERVATION = 'user/client/cancelReservation';
export const cancelReservation: AsyncThunk<
  boolean,
  number,
  { state: RootState }
> = createAsyncThunk(CANCEL_RESERVATION, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(false);
  }

  return await client(`client/appointments/${payload}`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .delete()
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(false);
    })
    .res(async () => {
      await thunkAPI.dispatch(getClientReservations());
      return true;
    })
    .catch((error) => {
      return thunkAPI.rejectWithValue(error);
    });
});

const UPDATE_PHONE_NUMBER = 'client/updatePhoneNumber';
export const updatePhoneNumber = createAction<{
  phone: string;
}>(UPDATE_PHONE_NUMBER);

const UPDATE_DOG_NAME = 'client/updateDogName';
export const updateDogName = createAction<{
  dogName: string;
}>(UPDATE_DOG_NAME);

const CONFIRM_RESERVATION = 'user/client/confirmReservation';
export const confirmReservation: AsyncThunk<
  boolean,
  { appointmentId: string; token: string },
  { state: RootState }
> = createAsyncThunk(CONFIRM_RESERVATION, async (payload, thunkAPI) => {
  if (!payload.token) {
    return thunkAPI.rejectWithValue(false);
  }
  const showSuccess = () =>
    thunkAPI.dispatch(
      showAppModal({
        appModalVisible: true,
        appModalType: 'success',
        appModalHeader: 'Sukces!',
        appModalMessage: 'Wizyta została potwierdzona',
        appModalButtonText: 'Zamknij',
        appModalCloseType: AppDialogCloseType.GO_HOME,
      })
    );
  const showError = () =>
    thunkAPI.dispatch(
      showAppModal({
        appModalVisible: true,
        appModalType: 'error',
        appModalHeader: 'Błąd!',
        appModalMessage:
          'Wystąpił błąd podczas potwierdzania wizyty, spróbuj ponownie',
        appModalButtonText: 'Zamknij',
        appModalCloseType: AppDialogCloseType.GO_HOME,
      })
    );
  return await client(
    `confirmAppointment/${payload.appointmentId}?token=${payload.token}`
  )
    .auth(`Bearer ${payload.token}`)
    .errorType('json')
    .get()
    .unauthorized(() => {
      showError();
      return thunkAPI.rejectWithValue(false);
    })
    .json(async (json) => {
      const { data } = json;
      showSuccess();
      return data;
    })
    .catch((error) => {
      showError();
      return thunkAPI.rejectWithValue(error);
    });
});
