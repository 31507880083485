import React from 'react';
import { SBasicSearchTabs } from './BasicSearchTabs.styled';

const BasicSearchTabs = () => {
  return (
    <SBasicSearchTabs>
      <ul className="tabs">
        <li className="tab">Znajdź specjalistę</li>
      </ul>
      <div className="divider" />
    </SBasicSearchTabs>
  );
};

export default BasicSearchTabs;
