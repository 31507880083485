import React from 'react';
import { Wrapper } from 'components';
import SliderArrow from 'components/icons/SliderArrow';

interface IGalleryControlsProps {
  onPrevClick: () => void;
  onNextClick: () => void;
}

const GalleryControls = ({
  onNextClick,
  onPrevClick,
}: IGalleryControlsProps) => {
  return (
    <div className="salon-details-gallery-arrows">
      <Wrapper>
        <button type="button" className="nav-button-left" onClick={onPrevClick}>
          <SliderArrow direction="left" />
        </button>
        <button
          type="button"
          className="nav-button-right"
          onClick={onNextClick}
        >
          <SliderArrow direction="right" />
        </button>
      </Wrapper>
    </div>
  );
};

export default GalleryControls;
