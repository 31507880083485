import React, { FC } from 'react';

import {
  Categories,
  // Discover,
  Benefits,
  MostPopular,
  Promoted,
  Discover,
} from 'components';
import Layout from '../components/Shared/Layout';
import HeroRounded from '../components/HeroRounded/HeroRounded';

// const Calendar = React.lazy(() => {
//   return new Promise(resolve => setTimeout(resolve, 5 * 1000)).then(
//     () =>
//       Math.floor(Math.random() * 10) >= 4
//         ? import("../components/Discover/Discover")
//         : Promise.reject(new Error())
//   );
// });

const Home: FC = () => {
  return (
    <Layout hasHeader={false}>
      <HeroRounded />
      <Categories />
      <Promoted />
      <Discover />
      <Benefits />
      <MostPopular />
    </Layout>
  );
};

export { Home };
