import { FC, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ProgressBar from '@ramonak/react-progress-bar';
import { Cross as Hamburger } from 'hamburger-react';
import classNames from 'classnames';

import theme from 'theme';
import { AppRoute, OpeningHour } from 'const';
import useLocalizeRoute from 'utils/useLocalizeRoute';

import {
  Wrapper,
  Logo,
  CalendarIcon,
  PersonIcon,
  ScissorsIcon,
} from 'components';
import { SAppHeader } from './AppHeader.styled';
import { useAppSelector } from 'Root';
import { useBreakpoint } from 'modules/breakpoint';
import { DateTime, Interval } from 'luxon';
import HotelReservationsIcon from '../icons/HotelReservationsIcon';
import AttentionIcon from '../icons/AttentionIcon';
import WalletIcon from '../icons/WalletIcon';

const AppHeader: FC = () => {
  const { formatMessage } = useIntl();
  const { localizeRouteKey } = useLocalizeRoute();

  const location = useLocation();
  const breakpoint = useBreakpoint();

  const [mobileNavigationVisible, setMobileNavigationVisible] =
    useState<boolean>(false);

  const user = useAppSelector(({ user }) => user.user);

  const startDate = DateTime.local();
  const endDate = user?.company.subscription?.endDate
    ? DateTime.fromMillis(user.company.subscription?.endDate * 1000)
    : DateTime.local();
  const length = Interval.fromDateTimes(startDate, endDate).length('days');

  const profileProgress = useMemo(() => {
    if (!user) return 0;

    let counter = 0;
    if (user.company?.name) {
      counter += 10;
    }

    if (user.company?.nip) {
      counter += 10;
    }

    if (user.company?.city) {
      counter += 10;
    }

    if (user.company?.postal) {
      counter += 10;
    }

    if (user.company?.street) {
      counter += 10;
    }

    if (user.company?.buildingNumber) {
      counter += 10;
    }

    if (user.company?.categories?.length) {
      counter += 10;
    }

    const atLeastOneHoursAvailable =
      user.company?.openingHours?.some(
        (hour: OpeningHour) => hour.timeStart?.length && hour.timeEnd?.length
      ) ?? false;
    if (atLeastOneHoursAvailable) {
      counter += 10;
    }

    if (user.company?.images?.length) {
      counter += 10;
    }

    if (user.company?.description) {
      counter += 10;
    }

    return counter;
  }, [user]);

  /*
  {
        name: 'Program partnerski',
        icon: <ScissorsIcon />,
        exact: true,
        active:
          location.pathname === localizeRouteKey(AppRoute.AppPartnersProgram),
        path: localizeRouteKey(AppRoute.AppPartnersProgram),
      },
   */

  const links = useMemo(() => {
    return [
      {
        name: 'Rezerwacje',
        icon: <CalendarIcon />,
        exact: true,
        active:
          location.pathname === localizeRouteKey(AppRoute.AppReservations),
        path: localizeRouteKey(AppRoute.AppReservations),
      },
      {
        name: 'Rezerwacje Hotelowe',
        icon: <HotelReservationsIcon />,
        exact: true,
        active:
          location.pathname === localizeRouteKey(AppRoute.AppHotelReservations),
        path: localizeRouteKey(AppRoute.AppHotelReservations),
      },
      {
        name: 'Ustawienia konta',
        icon: <PersonIcon />,
        exact: true,
        active: location.pathname === localizeRouteKey(AppRoute.AppProfile),
        path: localizeRouteKey(AppRoute.AppProfile),
      },
      {
        name: 'Stanowiska pracy',
        icon: <ScissorsIcon />,
        exact: true,
        active: location.pathname === localizeRouteKey(AppRoute.AppWorkplace),
        path: localizeRouteKey(AppRoute.AppWorkplace),
      },
      // partner program
      {
        name: (
          <>
            Abonament
            {length <= 7 && <AttentionIcon />}
          </>
        ),
        icon: <WalletIcon />,
        exact: true,
        active:
          location.pathname === localizeRouteKey(AppRoute.AppSubscription),
        path: localizeRouteKey(AppRoute.AppSubscription),
      },
      {
        name: 'Wyloguj',
        icon: <span className="material-icons icon">close</span>,
        exact: true,
        active: location.pathname === localizeRouteKey(AppRoute.AppLogout),
        path: localizeRouteKey(AppRoute.AppLogout),
      },
    ];
  }, [location]);

  useEffect(() => {
    setMobileNavigationVisible(false);
  }, [location]);

  const toggleMobileNavigation = () =>
    setMobileNavigationVisible(!mobileNavigationVisible);

  const renderSimple = () => {
    return (
      <div className="simple">
        <Link className="logo" to="/">
          <Logo mode="color" size={130} />
        </Link>
        {profileProgress < 100 ? (
          <div className="progress">
            <p className="label">
              {formatMessage({ id: 'app.header.progress' })}
            </p>
            <ProgressBar
              completed={profileProgress}
              bgColor={theme.colors.primary}
              baseBgColor="#EDE8E9"
              isLabelVisible={false}
              height="7px"
            />
          </div>
        ) : null}
      </div>
    );
  };

  const renderMobileNavigation = () => {
    const activeLink = links.find((link) => link.active);
    if (!activeLink) return null;

    return (
      <div
        className={classNames('mobile-navigation', {
          active: mobileNavigationVisible,
        })}
      >
        {mobileNavigationVisible && (
          <div className="mobile-navigation-background" />
        )}
        <div className="mobile-navigation-active">
          <p>
            {!mobileNavigationVisible && (
              <>
                {activeLink.icon} {activeLink.name}
              </>
            )}
          </p>
          <button
            className="mobile-navigation-burger"
            onClick={toggleMobileNavigation}
          >
            {mobileNavigationVisible ? 'Zamknij' : 'Menu'}
            <Hamburger
              toggled={mobileNavigationVisible}
              size={22}
              color="#908E8E"
            />
          </button>
        </div>
        {mobileNavigationVisible && (
          <div className="mobile-navigation-list">{renderNavigation(true)}</div>
        )}
      </div>
    );
  };

  const visibleLinks = user?.company.partner_program
    ? links
    : links.filter((link) => link.name !== 'Program partnerski');

  const renderNavigation = (inside?: boolean) => {
    if (breakpoint.lg && !inside) return renderMobileNavigation();

    return (
      <ul className="navigation">
        {visibleLinks.map((link) => (
          <li key={link.path}>
            <NavLink
              key={link.path}
              exact={link.exact}
              activeClassName="active"
              to={link.path}
            >
              {link.icon} {link.name}
            </NavLink>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <SAppHeader>
      <div className="top">
        <Wrapper>
          <div className="wrapper">{renderSimple()}</div>
        </Wrapper>
      </div>
      <div className="bottom">
        <Wrapper>{renderNavigation()}</Wrapper>
      </div>
    </SAppHeader>
  );
};

export default AppHeader;
