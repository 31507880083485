import React from 'react';

const AttentionIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="11" cy="11" r="11" fill="#FF0000" />
    <path
      d="M11.3979 5.54545H9.61133L9.75906 12.2209H11.2456L11.3979 5.54545ZM10.5023 15.1016C11.0471 15.1016 11.5133 14.6491 11.5179 14.0859C11.5133 13.532 11.0471 13.0795 10.5023 13.0795C9.9391 13.0795 9.48207 13.532 9.48668 14.0859C9.48207 14.6491 9.9391 15.1016 10.5023 15.1016Z"
      fill="white"
    />
  </svg>
);

export default AttentionIcon
