import styled, { css } from 'styled-components'

export const SSalonDetailsService = styled.div`
  ${({ theme }) => css`
    margin-bottom: 10px;
    padding: ${theme.space.lg}px;

    border: 1px solid #ede8e9;
    border-radius: 8px;

    cursor: pointer;

    .salon-details-service {
      &-header {
        display: flex;
        justify-content: space-between;

        button {
          width: 110px;
          height: 32px;

          font-weight: ${theme.weight.semibold};

          margin-left: ${theme.space.md}px;
        }
      }

      &-description {
        font-size: 0.875rem;
        line-height: 1.31rem;
        color: #908e8e;

        margin-top: ${theme.space.lg}px;
      }
    }
  `}
`
