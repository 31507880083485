import styled, { css } from 'styled-components';

export const SMarker = styled.div<{
  categories: string[];
  categoriesIds: number[];
  isSelected: boolean | undefined;
}>`
  ${({ categories, categoriesIds, theme, isSelected }) => css`
    width: 44px;
    height: 44px;

    background-color: ${theme.colors.white};
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.25);
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    position: relative;

    ${isSelected &&
    css`
      border: 3px solid #fff;
    `}

    .objects-count {
      background: ${theme.colors.primary};
      border-radius: 20px;
      color: #fff;
      position: absolute;
      padding: 4px 8px;
      right: -8px;
      top: -4px;
    }

    .object-name {
      align-items: flex-start;
      background-color: #fff;
      border-radius: 12px;
      display: flex;
      flex-flow: column;
      padding: 8px 12px;
      position: absolute;
      text-align: left;
      top: -100%;
      min-width: 300px;

      button {
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }

    ${(categories.includes('Groomer') || categoriesIds.includes(1)) &&
    css`
      background: linear-gradient(
        135deg,
        rgba(206, 87, 1, 1),
        rgba(255, 108, 1, 1)
      );
    `}

    ${(categories.includes('Hotele dla pupila') || categoriesIds.includes(2)) &&
    css`
      background: linear-gradient(
        135deg,
        rgba(4, 118, 201, 1),
        rgba(2, 140, 241, 1)
      );
    `}

    ${((categories.includes('Szkolenie psów') ||
      categories.includes('Trener')) || categoriesIds.includes(3)) &&
    css`
      background: linear-gradient(
        135deg,
        rgba(38, 146, 1, 1),
        rgba(44, 166, 1, 1)
      );
    `}

    ${(categories.includes('Behawiorystyka') || categoriesIds.includes(4) ) &&
    css`
    background: linear-gradient(
      135deg,
      rgba(115, 1, 155, 1),
      rgba(143, 2, 193, 1)
    );
    `}

    ${(categories.includes('Fizjoterapeuta') || categoriesIds.includes(5)) &&
    css`
      background: linear-gradient(
        135deg,
        rgba(2, 132, 132, 1),
        rgba(1, 172, 172, 1)
      );
    `}

    ${((categories.includes('Handeler') || categories.includes('Handler')) || categoriesIds.includes(6))&&
    css`
      background: linear-gradient( 90deg, rgba(141,2,52,1), rgba(224,3,82,1) );
    `}
    
    ${(categories.includes('Fotograf') || categoriesIds.includes(7)) &&
    css`
      background: linear-gradient( 90deg, rgba(176,106,0,1), rgba(253,152,3,1) )
    `}
  `}
`;
