import { FC } from 'react';
import { Link } from 'react-router-dom';
import PuffLoader from 'react-spinners/PuffLoader';
import theme from 'theme';

export interface IButton {
  loading?: boolean;
  to?: string;
  bordered?: boolean;
  small?: boolean;
  colorUnderline?: boolean;
}

const Button: FC<IButton> = ({ loading, children, bordered, to, ...res }) => {
  if (to) {
    return <Link {...{ to, ...res }}>{children}</Link>;
  }

  return (
    <button {...res}>
      {loading ? (
        <PuffLoader
          color={bordered ? theme.colors.primary : 'white'}
          size={24}
        />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
