import { createReducer } from '@reduxjs/toolkit';
import { Review, Salon, Service, Discount } from 'const';
import { IUser } from '../../../components/AppPartnersProgram/UserRow';
import {
  favoriteSalon,
  getSalon,
  getSalonReviews,
  getSalons,
  getAllSalons,
  setSalonsSearch,
  setSalonsAllFilters,
  setSingleSalon,
  setSalonsFilters,
  getProgramUsers,
  getDiscountsList,
  updateUsersPoints,
  clearSalonsFilters,
  clearSalonsSearch,
} from './salonsActions';
import { SalonSearch } from '../../../types/store';

interface UserState {
  data: Salon[];
  allSalons: Salon[];
  page: number;
  lastPage: number;
  loading: boolean;
  total: number;
  search: SalonSearch;
  discounts: Discount[];
  membersSearch: {
    name: string;
  };
  programMembers: {
    loading: boolean;
    currentPage: number;
    members: IUser[];
    pages: number;
  };
  single: {
    data: Salon | null;
    loading: boolean;
    services: {
      data: Service[];
      loading: boolean;
    };
    reviews: {
      page: number;
      lastPage: number;
      data: Review[];
      loading: boolean;
    };
    favorite: {
      loading: boolean;
    };
  };
}

const initialState: UserState = {
  data: [],
  allSalons: [],
  page: 1,
  lastPage: 1,
  loading: false,
  total: 0,
  search: {
    service: null,
    location: null,
    categories: [],
  },
  discounts: [],
  membersSearch: {
    name: '',
  },
  programMembers: {
    loading: true,
    currentPage: 0,
    members: [],
    pages: 0,
  },
  single: {
    data: null,
    loading: false,
    services: {
      data: [],
      loading: false,
    },
    reviews: {
      page: 1,
      lastPage: 1,
      data: [],
      loading: false,
    },
    favorite: {
      loading: false,
    },
  },
};

export default createReducer<UserState>(initialState, {
  [updateUsersPoints.fulfilled.toString()]: (state, { payload }) => ({
    ...state,
    programMembers: {
      ...state.programMembers,
      members: payload.members,
      loading: false,
    },
  }),
  [getDiscountsList.fulfilled.toString()]: (state, action) => {
    return {
      ...state,
      discounts: action.payload,
    };
  },
  [getProgramUsers.fulfilled.toString()]: (state, action) => {
    return {
      ...state,
      programMembers: action.payload,
    };
  },
  [setSalonsAllFilters.type]: (state, { payload }) => {
    return {
      ...state,
      search: {
        ...state.search,
        categories: payload,
      },
    };
  },
  [setSalonsFilters.type]: (state, { payload }) => {
    // picked value already exist
    if (state.search.categories.find((item) => item.value === payload.value)) {
      return {
        ...state,
        search: {
          ...state.search,
          categories: state.search.categories.filter(
            (item) => item.value !== payload.value
          ),
        },
      };
    }

    return {
      ...state,
      search: {
        ...state.search,
        categories: [payload, ...state.search.categories],
      },
    };
  },
  [clearSalonsFilters.type]: (state) => ({
    ...state,
    search: {
      ...state.search,
      categories: [],
    },
  }),
  [setSalonsSearch.type]: (state, action) => ({
    ...state,
    search: {
      ...action.payload,
    },
  }),
  [clearSalonsSearch.type]: (state) => ({
    ...state,
    search: {
      ...state.search,
      categories: [],
    },
  }),
  [setSingleSalon.type]: (state, action) => ({
    ...state,
    single: {
      ...state.single,
      data: action.payload?.base ?? state.single.data,
    },
  }),
  [getSalons.pending.toString()]: (state) => ({
    ...state,
    loading: true,
  }),
  [getSalons.fulfilled.toString()]: (state, action) => ({
    ...state,
    data: action.payload.data,
    page: action.payload.current_page,
    lastPage: action.payload.last_page,
    total: action.payload.total,
    loading: false,
  }),
  [getSalons.rejected.toString()]: (state) => ({
    ...state,
    loading: false,
  }),
  [getAllSalons.pending.toString()]: (state) => ({
    ...state,
    loading: true,
  }),
  [getAllSalons.fulfilled.toString()]: (state, action) => ({
    ...state,
    allSalons: action.payload.data,
    loading: false,
  }),
  [getAllSalons.rejected.toString()]: (state) => ({
    ...state,
    loading: false,
  }),
  [getSalon.pending.toString()]: (state) => ({
    ...state,
    single: {
      ...state.single,
      loading: !state.single.data,
      services: {
        ...state.single.services,
        loading: true,
      },
      reviews: { ...initialState.single.reviews },
    },
  }),
  [getSalon.fulfilled.toString()]: (state, action) => ({
    ...state,
    single: {
      ...state.single,
      data: action.payload,
      loading: false,
      services: {
        ...state.single.services,
        loading: false,
      },
      reviews: {
        ...state.single.reviews,
        id: action.payload.id,
      },
    },
  }),
  [getSalon.rejected.toString()]: (state) => ({
    ...state,
    single: {
      ...state.single,
      loading: false,
      services: {
        ...state.single.services,
        loading: false,
      },
    },
  }),
  [getSalonReviews.pending.toString()]: (state) => ({
    ...state,
    single: {
      ...state.single,
      reviews: {
        ...state.single.reviews,
        loading: true,
      },
    },
  }),
  [getSalonReviews.fulfilled.toString()]: (state, action) => ({
    ...state,
    single: {
      ...state.single,
      reviews: {
        ...state.single.reviews,
        data: action.payload.data,
        page: action.payload.current_page,
        lastPage: action.payload.last_page,
        loading: false,
      },
    },
  }),
  [getSalonReviews.rejected.toString()]: (state) => ({
    ...state,
    single: {
      ...state.single,
      reviews: {
        ...state.single.reviews,
        loading: false,
      },
    },
  }),
  [favoriteSalon.pending.toString()]: (state) => ({
    ...state,
    single: {
      ...state.single,
      favorite: {
        loading: true,
      },
    },
  }),
  [favoriteSalon.fulfilled.toString()]: (state) => ({
    ...state,
    single: {
      ...state.single,
      data: state.single.data
        ? {
            ...state.single.data,
            isFavourite: !state.single.data?.isFavourite,
          }
        : state.single.data,
      favorite: {
        loading: false,
      },
    },
  }),
  [favoriteSalon.rejected.toString()]: (state) => ({
    ...state,
    single: {
      ...state.single,
      favorite: {
        loading: false,
      },
    },
  }),
});
