import React, { FC, Suspense, useEffect, useMemo } from 'react';

import Select from 'react-select';

import {
  AppReservationEditor,
  AppReservationModal,
  PageLoader,
  Wrapper,
  Button,
} from 'components';
import { useAppDispatch, useAppSelector } from 'Root';
import { getSalonWorkplace } from 'state/reducers/user/userActions';
import theme from 'theme';
import { useBreakpoint } from 'modules/breakpoint';
import { parseCalendarEvents } from '../utils/parseCalendarEvents';
import { useCalendarEvents } from '../hooks/useCalendarEvents';
import { useRealtimeNotifications } from '../hooks/useRealtimeNotifications';
import 'devextreme/dist/css/dx.light.css';
import { EventState } from 'types';

const Scheduler = React.lazy(() =>
  import('devextreme-react').then((module) => {
    return { default: module.Scheduler };
  })
);

const AppReservations: FC = () => {
  const dispatch = useAppDispatch();

  const { user, salon } = useAppSelector(({ user }) => user);
  const { loading: workplaceLoading, slots, categories } = salon.workplace;

  const {
    appointment,
    events,
    deleteLoading,
    currentDate,
    activeSlot,
    clickDayCell,
    clickEvent,
    setActiveSlot,
    getEvents,
    handleDeleteAppointment,
    addAppointment,
    updateCalendarAppointment,
    clearAppointment,
    updateActiveSlot,
  } = useCalendarEvents();

  const { pusher, newCalendarEvent, unsubscribe } = useRealtimeNotifications();

  const appointmentServiceOptions = useMemo(
    () =>
      categories.map(({ name, services }) => ({
        label: name,
        options: services,
      })),
    [categories]
  );

  const breakpoint = useBreakpoint();

  useEffect(() => {
    dispatch(getSalonWorkplace());

    return () => {
      if (!pusher) return;
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!pusher) return;

    newCalendarEvent(() => getEvents(activeSlot, currentDate));
  }, [pusher]);

  useEffect(() => {
    getEvents(activeSlot, currentDate);
  }, [currentDate, activeSlot]);

  useEffect(() => {
    if (!activeSlot && slots?.length) {
      setActiveSlot(slots[0].id);
    }
  }, [slots]);

  if (workplaceLoading) return <PageLoader />;

  return (
    <AppReservationEditor>
      {appointment && (
        <AppReservationModal
          {...{
            appointment,
            appointmentServiceOptions,
            clear: clearAppointment,
            deleteAppointment: handleDeleteAppointment,
            onSubmit: (values) =>
              updateCalendarAppointment(
                {
                  ...values,
                  companyId: user?.company.id,
                },
                'normal'
              ),
            deleteLoading,
          }}
        />
      )}

      <Wrapper>
        <div className="slots-selection">
          <Select
            isSearchable={false}
            defaultValue={slots[0]}
            options={slots}
            getOptionValue={({ id }) => String(id)}
            getOptionLabel={({ name }) => name}
            styles={{
              container: (styles) => ({
                ...styles,
                maxWidth: breakpoint.lg ? 'unset' : 290,
                width: '200px',
              }),
              control: (styles) => ({
                ...styles,
                height: 47,
                border: '1px solid #EDE8E9',
                borderRadius: 8,
                boxShadow: 'none',
                '&:hover': {
                  border: '1px solid #EDE8E9',
                },
              }),
              singleValue: (styles) => ({
                ...styles,
                fontSize: '.875rem',
                fontWeight: theme.weight.bold,
                color: '#908E8E',
              }),
              indicatorSeparator: () => ({ display: 'none' }),
              dropdownIndicator: (styles) => ({
                ...styles,
                color: '#908E8E',
              }),
            }}
            onChange={updateActiveSlot}
          />

          <Button onClick={addAppointment}>Dodaj rezerwację</Button>
        </div>
      </Wrapper>
      <Suspense fallback={<div></div>}>
        <Scheduler
          dataSource={parseCalendarEvents(
            events.filter((i) => i.appointmentKind === 'normal')
          )}
          onAppointmentClick={clickEvent}
          onCellClick={clickDayCell}
          height={600}
          editing={false}
          defaultCurrentView="week"
          firstDayOfWeek={1}
          startDayHour={8}
          endDayHour={20}
          onAppointmentRendered={(e) => {
            if (e.appointmentData.confirmed === 0) {
              e.appointmentElement.style.backgroundColor = '#ccc';
            }
          }}
          views={[
            { name: 'Miesiąc', type: 'month' },
            { name: 'Tydzień', type: 'week' },
          ]}
        />
      </Suspense>
    </AppReservationEditor>
  );
};

export { AppReservations };
