import { useEffect } from 'react'
import { Redirect } from 'react-router'

import config from 'const/config'
import { useAppDispatch } from 'Root'
import { logoutUser } from 'state/reducers/user/userActions'

const AppLogout = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    localStorage.removeItem(config.TOKEN_KEY)
    dispatch(logoutUser())
  }, [])

  return <Redirect to="/app" />
}

export { AppLogout }
