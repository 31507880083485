import React, { useMemo } from 'react';
import { Salon, Geolocation } from '../const';
import { maxBy, minBy } from 'lodash';

export const useFitBounds = (
  data: Salon[]
): [[number, number], [number, number]] | [] => {
  return useMemo(() => {
    // @ts-ignore
    const tempMarkersLocation = data.reduce(
      // @ts-ignore
      (acc, current) => [...acc, current.geolocation],
      []
    ) as Geolocation[];

    const maxLat =
      maxBy<Geolocation>(tempMarkersLocation, (value) => value?.latitude)
        ?.latitude ?? 0;
    const minLat =
      minBy<Geolocation>(tempMarkersLocation, (value) => value?.latitude)
        ?.latitude ?? 0;
    const maxLng =
      maxBy<Geolocation>(tempMarkersLocation, (value) => value?.longitude)
        ?.longitude ?? 0;
    const minLng =
      minBy<Geolocation>(tempMarkersLocation, (value) => value?.longitude)
        ?.longitude ?? 0;

    const southWest: [number, number] = [minLng, minLat];
    const northEast: [number, number] = [maxLng, maxLat];

    return [southWest, northEast];
  }, [data]);
};
