import { createReducer } from '@reduxjs/toolkit'
import { Image } from 'const'
import { clearGalleryImages, setGalleryImages } from './galleryActions'

interface AppState {
  images: Image[]
  defaultActive: Image | null
}

const initialState: AppState = {
  images: [],
  defaultActive: null,
}

export default createReducer<AppState>(initialState, {
  [setGalleryImages.type]: (state, action) => ({
    ...state,
    images: action.payload.images,
    defaultActive: action.payload.active,
  }),
  [clearGalleryImages.type]: () => ({
    ...initialState,
  }),
})
