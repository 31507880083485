import { User } from 'const';
import parseLocationSuggestion from './parseLocationSuggestion';

const applySalonProfileData = (user: User | null, defaults: any) => ({
  name: user?.name ?? defaults.name,
  email: user?.email ?? defaults.email,
  password: defaults.password,
  phone: user?.company.phone || '',

  company_name: user?.company?.name ?? defaults.name,
  nip: user?.company?.nip ?? defaults.nip,

  city: parseLocationSuggestion(user?.company?.city ?? defaults.city),
  postal: user?.company?.postal ?? defaults.postal,
  street: user?.company?.street ?? defaults.street,
  buildingNumber: user?.company?.buildingNumber ?? defaults.buildingNumber,

  categories:
    user?.company?.categories?.map((category) => category.id) ??
    defaults.categories,
  openingHours: user?.company?.openingHours ?? defaults.openingHours,

  description: user?.company?.description ?? defaults.description,
  partner_program: user?.company.partner_program ?? defaults.partner_program,
});

export default applySalonProfileData;
