import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export default styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0 ${theme.space.xxxl}px;

    margin-bottom: ${theme.space.xl}px;

    .actions {
      margin-top: ${theme.space.md}px;

      button {
        height: 54px;
        width: 100%;

        margin-top: ${theme.space.md}px;
      }
    }

    .fields {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-gap: ${theme.space.xl}px;

      margin-top: ${theme.space.xl}px;
    }

    .map {
      margin-top: ${theme.space.xxl}px;
      border-radius: 10px;

      overflow: hidden;

      .marker {
        width: 49px;
        height: 49px;

        display: flex;
        align-items: center;
        justify-content: center;

        background: #ffffff;
        border-radius: 50%;
        box-shadow: 0px 4px 4px rgba(197, 190, 190, 0.25);
      }
    }

    ${media.md`
      margin-bottom: ${theme.space.xxxl}px;

      .actions {
        margin-top: 56px;

        button {
          margin-top: ${theme.space.lg}px;
        }
      }
      
      .fields {
        grid-template-columns: repeat(2, 1fr);

        &.single {
          grid-template-columns: 1fr;

        }
        &.textarea-type label{
          height: auto; 
          padding-top: 35px;
        }
      }
    `}

    ${media.lg`
      grid-template-columns: minmax(auto, 743px) auto;
    `}
  `}
`;
