import React, { useState } from 'react';
import UserRow from './UserRow';
import { SUsersList } from './UsersList.styled';
import { useUsersList } from '../../hooks/useUsersList';
import UpdatePointsModal from './UpdatePointsModal';
import Pagination from '../Pagination/Pagination';
import EmptyState from '../EmptyState/EmptyState';
import { AppRoute } from '../../const';

const COLUMN_NAMES = ['Ilość łapek', 'Imię i nazwisko', 'E-mail', 'Telefon'];

interface SelectedUser {
  id: number;
  points_amount: number;
}

const UsersList = () => {
  const [selectedUser, setSelectedUser] = useState<SelectedUser | null>(null);

  const {
    users,
    loading,
    setSearchPhrase,
    updatePointsAmount,
    searchPhrase,
    page,
    totalPages,
    nextPage,
    prevPage,
    setPage,
  } = useUsersList();

  return (
    <SUsersList>
      {selectedUser && (
        <UpdatePointsModal
          id={selectedUser.id}
          pointsAmount={selectedUser.points_amount}
          onClose={() => setSelectedUser(null)}
          onSubmit={(id, pointsAmount) => {
            updatePointsAmount(id, pointsAmount);
            setSelectedUser(null);
          }}
        />
      )}

      <div className="filters">
        <input
          type="text"
          placeholder="Szukaj..."
          value={searchPhrase}
          onChange={({ target }) => setSearchPhrase(target.value)}
        />
      </div>
      {!loading && users.length === 0 && (
        <EmptyState
          title="Brak klientów w tym momencie"
          description="Skorzystaj z usług salonów objętych programem"
          button={{
            label: 'Szukaj salonów',
            href: AppRoute.List,
          }}
        />
      )}

      {!loading && users.length > 0 && (
        <table className="users-list">
          <tr className="users-list__header">
            {COLUMN_NAMES.map((name) => (
              <th key={name}>{name}</th>
            ))}
          </tr>
          {users.map((user) => (
            <UserRow
              key={user.email}
              {...user}
              onSelect={(id, points) =>
                setSelectedUser({
                  id,
                  points_amount: points,
                })
              }
            />
          ))}
        </table>
      )}

      {totalPages > 1 && (
        <Pagination
          page={page}
          lastPage={totalPages}
          prevPage={prevPage}
          setPage={setPage}
          nextPage={nextPage}
        />
      )}
    </SUsersList>
  );
};

export default UsersList;
