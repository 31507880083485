import { FC, useState, useEffect, useRef } from 'react';

import { AppReservationEditor, AppReservationModal } from 'components';
import config from 'const/config';
import { useAppDispatch } from 'Root';
import {
  createAppointment,
  deleteAppointment,
  updateAppointment,
} from 'state/reducers/user/userActions';
import { AppointmentCreator, AppointmentDTO, Service } from 'const';
import { parseCalendarEvents } from '../utils/parseCalendarEvents';
import { add, differenceInDays, isPast, addDays } from 'date-fns';
import { useRealtimeNotifications } from '../hooks/useRealtimeNotifications';
import { Scheduler } from 'devextreme-react';
import { AppointmentClickEvent, CellClickEvent } from 'devextreme/ui/scheduler';
import { useCalendarEvents } from '../hooks/useCalendarEvents';

interface DragData {
  allDay: true;
  cls: string;
  draggable: boolean;
  duration: number;
  durationUnit: string;
  endDate: Date;
  startDate: Date;
  eventColor: string;
  exceptionDates: any;
  id: string;
  keepDuration: boolean;
  name: string;
  parentIndex: number;
  placeId: string;
  readOnly: undefined;
  resizable: boolean;
  resourceId: string;
}

const parseTimeToApi = (timestamp: Date) => {
  return new Date(timestamp).getTime() / 1000;
};

interface IApiResource {
  id: string;
  title: string;
  children: { id: string; title: string }[];
  service: Service;
}

const AppHotelReservations: FC = () => {
  const dispatch = useAppDispatch();
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [shouldPullEvents, setShouldPullEvents] = useState<boolean>(false);
  const [resources, setResources] = useState<any[]>([]);
  const [services, setServices] = useState<Service[]>([]);

  const [appointment, setAppointment] = useState<AppointmentCreator | null>(
    null
  );

  const [currentDate, setCurrentDate] = useState<{
    start: any;
    end: any;
  }>({ start: 0, end: 0 });

  const { pusher, newCalendarEvent, unsubscribe } = useRealtimeNotifications();

  const { events, getEvents } = useCalendarEvents();

  const setDateRange = (startDate: Date, endDate: Date) => {
    setCurrentDate({
      start: startDate,
      end: endDate,
    });
  };

  const getResources = async () => {
    const token = localStorage.getItem(config.TOKEN_KEY);
    const response = await fetch(
      `${config.API_URL}/provider/appointments/spots`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const { data } = await response.json();
    setServices(
      data.map(({ service }: IApiResource) => ({
        id: service.id,
        name: service.name,
      }))
    );

    setResources(
      data.map(({ service, title, children }: IApiResource) => ({
        id: service.name,
        eventColor: '#FF6B00',
        name: title,
        eventLayout: 'pack',
        children,
      }))
    );
  };

  const cellClick = (event: CellClickEvent) => {
    if (isPast(event.cellData.startDate)) return;

    setAppointment({
      appointmentKind: 'normal',
      appointmentType: 'range',
      additionalNote: '',
      email: '',
      phone: '',
      date: event.cellData.startDate,
      service: null,
      timeEnd: '0',
    });
  };

  const eventClick = (event: AppointmentClickEvent) => {
    const { startDate, endDate } = event.appointmentData;

    const d = event.appointmentData.html;

    const payload = JSON.parse(d as string);

    setAppointment({
      id: payload.id,
      appointmentKind: 'time_block',
      appointmentType: 'range',
      additionalNote: payload.name,
      date: startDate as Date,
      email: payload.email,
      phone: payload.phone,
      timeEnd: differenceInDays(endDate as Date, startDate as Date).toString(),
      service: payload.service,
    });
  };

  const clearAppointment = () => setAppointment(null);

  const handleDeleteAppointment = async () => {
    if (!appointment?.id) return;

    setDeleteLoading(true);
    await dispatch(deleteAppointment(appointment.id));

    setShouldPullEvents(true);
    clearAppointment();

    setDeleteLoading(false);
  };

  const updateCalendarAppointment = async (values: AppointmentCreator) => {
    if (!appointment?.date || !values.date) return;

    const endTime = add(new Date(appointment.date), {
      days: parseInt(values.timeEnd),
    });

    const timeStart = parseTimeToApi(appointment.date);

    const payload: AppointmentDTO = {
      appointmentKind: 'time_block',
      timeStart,
      timeEnd: parseTimeToApi(endTime),
      email: values.email,
      phone: values.phone,
      additionalNote: values.additionalNote,
      serviceId: appointment?.service?.id ?? values.service?.id,
    };

    if (appointment.id) {
      payload.id = appointment?.id;
      await dispatch(updateAppointment(payload));
    } else {
      await dispatch(createAppointment(payload));
    }

    clearAppointment();
    setShouldPullEvents(true);
  };

  useEffect(() => {
    // if (resources.length === 0) return;
    getEvents(0, currentDate);
  }, [currentDate]);

  useEffect(() => {
    if (shouldPullEvents) {
      getEvents(0, currentDate);
      setShouldPullEvents(false);
    }
  }, [shouldPullEvents]);

  useEffect(() => {
    getResources();

    return () => {
      if (!pusher) return;
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!pusher) return;

    newCalendarEvent(() => getEvents(0, currentDate));
  }, [pusher]);

  return (
    <AppReservationEditor hotel>
      {appointment && (
        <AppReservationModal
          {...{
            appointment,
            appointmentServiceOptions:
              appointment && appointment.service !== null
                ? [appointment.service]
                : services,
            clear: clearAppointment,
            deleteAppointment: handleDeleteAppointment,
            onSubmit: updateCalendarAppointment,
            deleteLoading,
          }}
        />
      )}

      <Scheduler
        dataSource={parseCalendarEvents(events)}
        onCurrentDateChange={(event) => {
          setDateRange(event, addDays(event, 20));
        }}
        editing={false}
        height={600}
        views={['month', 'week']}
        defaultCurrentView="month"
        onCellClick={cellClick}
        onAppointmentClick={(event) => eventClick(event)}
      />
    </AppReservationEditor>
  );
};

export { AppHotelReservations };
