import { FC } from 'react';
import { Link, Redirect } from 'react-router-dom';

import {
  Button,
  Checkbox,
  ProfileSettingsEditor,
  TextInput,
  Wrapper,
} from 'components';
import { useAppDispatch, useAppSelector } from 'Root';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { updateClientUser } from 'state/reducers/client/clientActions';
import formErrorsHandler from 'utils/formErrorsHandler';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import { AppRoute } from 'const';
import EditAvatar from '../components/Shared/EditAvatar';

const ProfileSettings: FC = () => {
  const { localizeRouteKey } = useLocalizeRoute();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ client }) => client);

  const methods = useForm<{
    name: string;
    email: string;
    phone: string;
    photo: File[] | null;
    emailNotifications: number;
  }>({
    defaultValues: {
      name: user?.name,
      email: user?.email,
      phone: user?.phone ?? '',
      photo: null,
      emailNotifications: user?.emailNotifications,
    },
  });
  const thumbnail = methods.watch('photo');

  const onSubmit = async (values: {
    name: string;
    email: string;
    phone: string;
    photo: File[] | null;
    emailNotifications: number;
  }) => {
    const response: any = await dispatch(updateClientUser(values));
    if (response.error) {
      formErrorsHandler<{
        name: string;
        email: string;
        phone: string;
        photo: File[] | null;
        emailNotifications: number;
      }>(
        Object.keys({
          name: user?.name,
          email: user?.email,
          phone: user?.phone ?? '',
          photo: null,
          emailNotifications: user?.emailNotifications,
        }),
        methods.setError
      )(response.payload);
      return;
    }
  };

  const getThumbnail = () => {
    if (thumbnail) {
      const [photo] = thumbnail;
      return URL.createObjectURL(photo);
    }

    return user?.photo ?? undefined;
  };

  if (!user) return <Redirect to={'/'} />;
  return (
    <Wrapper>
      <ProfileSettingsEditor>
        <div className="settings-header">
          <h2 className="settings-heading">Ustawienia konta</h2>
        </div>

        <div className="settings-container">
          <div className="settings-content">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="settings-summary">
                  <EditAvatar
                    avatar={getThumbnail()}
                    inputProps={methods.register('photo')}
                  />

                  <div className="settings-summary-info">
                    <p className="settings-summary-name">{user.name}</p>
                    <p className="settings-summary-phone">{user.phone}</p>

                    <Link
                      to={localizeRouteKey(AppRoute.ProfilePassword)}
                      className="settings-summary-button"
                    >
                      Zmiana hasła
                    </Link>
                  </div>
                </div>

                <TextInput
                  {...{ id: 'name', label: 'Imię' }}
                  className="settings-field"
                />
                <TextInput
                  {...{ id: 'email', label: 'Adres e-mail' }}
                  className="settings-field"
                />
                <TextInput
                  {...{ id: 'phone', label: 'Numer telefonu' }}
                  className="settings-field"
                />

                <Controller
                  control={methods.control}
                  name="emailNotifications"
                  render={({ field: { onChange, onBlur, ref, value } }) => (
                    <Checkbox toggle className="settings-field">
                      <input
                        type="checkbox"
                        id="notification"
                        {...{ onChange, onBlur, ref, checked: !!value }}
                      />
                      <label htmlFor="notification">
                        Powiadom mnie o zmianie statusu wizyty
                      </label>
                    </Checkbox>
                  )}
                />

                <Button
                  disabled={
                    !methods.formState.isDirty || methods.formState.isSubmitting
                  }
                  loading={methods.formState.isSubmitting}
                  type="submit"
                  className="settings-submit"
                >
                  Aktualizacja danych
                </Button>
              </form>
            </FormProvider>
          </div>
          <div className="settings-sidebar">
            <div className="settings-sidebar-content">
              <div className="settings-sidebar-icon">
                <span className="material-icons">account_circle</span>
              </div>
              <p className="settings-sidebar-heading">General information</p>
              <p className="settings-sidebar-description">
                Everybody that has ever been to a meeting, can recall the all
                familiar passing of the business cards.
              </p>
            </div>
          </div>
        </div>
      </ProfileSettingsEditor>
    </Wrapper>
  );
};

export { ProfileSettings };
