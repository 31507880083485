import React from 'react';

const CheckedCategory = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#FF6B00" />
    <path
      d="M12.8307 9.76964C11.9399 10.794 11.0742 11.9749 10.1834 13.3803C9.52474 12.673 7.38237 10.9432 6.39674 10.9432C6.26075 10.9432 6.14628 10.9755 6.05654 11.0391C6.03687 11.053 6.02126 11.0725 6.01146 11.0953C6.00165 11.1181 5.99804 11.1433 6.00101 11.1682C6.02551 11.3731 6.17783 11.5292 6.31226 11.6674C6.35992 11.7164 6.4049 11.7626 6.44031 11.8067L6.44476 11.8123L6.44981 11.8173C7.93197 13.3048 9.12011 14.9503 9.98118 16.7081C10.024 16.7962 10.089 16.87 10.169 16.9215C10.249 16.973 10.3409 17.0002 10.4346 17C10.5317 17 10.6269 16.9707 10.7089 16.9156C10.7909 16.8605 10.8563 16.7817 10.8974 16.6887C13.5275 10.7364 16.1918 8.12698 17.3295 7.01264C17.8149 6.53729 18.0157 6.34054 17.999 6.14152C17.997 6.11758 17.989 6.09465 17.9758 6.07508C17.9626 6.05552 17.9447 6.04003 17.924 6.03023C17.8765 6.00916 17.8254 5.99889 17.774 6.00009C17.4071 6.00009 16.6618 6.43707 16.1012 6.80365L16.0993 6.80491C15.5179 7.19982 14.9392 7.66034 14.3792 8.17258C13.8588 8.64973 13.3374 9.18685 12.8307 9.76964Z"
      fill="white"
    />
  </svg>
);

export default CheckedCategory;
