import styled, { css } from 'styled-components';

export const SConfirmationBaner = styled.section`
  ${({ theme }) => css`
    padding: ${theme.space.lg}px;
    width: 100%;
    max-width: 1200px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    background-color: ${theme.colors.primary};
    box-shadow: ${theme.shadows.default};
    z-index: 1000;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: ${theme.space.lg}px;
    border-radius: 8px;
    p {
      font-size: 16px;
      font-weight: ${theme.weight.medium};
      color: ${theme.colors.white};
      line-height: 1.5;
    }
    button {
      background-color: ${theme.colors.white};
      color: ${theme.colors.primary};
    }
  `}
`;
