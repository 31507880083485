import React from 'react';
import { SCategory } from './Category.styled';
import { renderIcon } from '../../../utils/renderIcon';
import CheckedCategory from '../../icons/CheckedCategory';

interface CategoryProps {
  id: number;
  name: string;
  onSelect: (id: number, name: string) => void;
  selected: boolean;
}

const Category = ({ id, name, onSelect, selected }: CategoryProps) => {
  return (
    <SCategory>
      <button onClick={() => onSelect(id, name)}>
        <span className="category-content">
          {renderIcon(name, false, 18)}
          {name}
        </span>

        {selected && <CheckedCategory />}
      </button>
    </SCategory>
  );
};

export default Category;
