import React, { FC, useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { Autocomplete } from 'components';
import { SDiscover } from './Discover.styled';

import DiscoverMap from './Map';
import CategoryPill from '../Shared/CategoryPill';
import { useAppSelector, useAppDispatch } from '../../Root';
import { Salon } from '../../const';
import { parseCategories } from '../../const/filter-categories';
import loadAutocompleteLocations from '../../api/loadAutocompleteLocations';
import { selectStyles } from '../Shared/AutocompleteInput';
import { OptionTypeBase } from 'react-select';
import {
  setSalonsSearch,
  setSalonsFilters,
  clearSalonsFilters,
} from '../../state/reducers/salons/salonsActions';
import { getSalons } from '../../state/reducers/salons/salonsActions';
import { useBreakpoint } from '../../modules/breakpoint';
import { useFitBounds } from '../../hooks/useFitBounds';

interface Point {
  type: 'Feature';
  properties: {
    cluster: boolean;
    salonId: number;
    categories: string[];
    name: string;
    address: string;
  };
  geometry: { type: 'Point'; coordinates: number[] };
}

const convertSalonsToPoints = (salon: Salon): Point => ({
  type: 'Feature',
  properties: {
    cluster: false,
    salonId: salon.id,
    categories: salon.categories.map(({ name }) => name),
    name: salon.name,
    address: `${salon.city?.name}, ${salon.street} ${salon.buildingNumber}`,
  },
  geometry: {
    type: 'Point',
    coordinates: [
      salon.geolocation?.longitude as number,
      salon.geolocation?.latitude as number,
    ],
  },
});

const Discover: FC = () => {
  const [points, setPoints] = useState<Point[]>([]);

  const allSalons = useAppSelector(({ salons }) => salons);

  const [filteredSalons, setFilteredSalons] = useState<Salon[]>(allSalons.data);

  const search = useAppSelector(({ salons }) => salons.search);

  const selected = search.categories.map(({ value }) => value);

  const breakpoints = useBreakpoint();

  const dispatch = useAppDispatch();

  const bounds = useFitBounds(allSalons.data);

  const selectLocation = (value: OptionTypeBase) => {
    dispatch(getSalons({ page: '1', pagination: true }));
    dispatch(
      setSalonsSearch({
        ...search,
        location: value,
      })
    );
  };

  const { formatMessage } = useIntl();
  const { categories } = useAppSelector(({ app }) => app);

  useEffect(() => {
    setFilteredSalons(allSalons.data);
  }, [allSalons.data]);

  useEffect(() => {
    setPoints(filteredSalons.map(convertSalonsToPoints));
  }, [filteredSalons]);

  const listingCategories = breakpoints.sm
    ? parseCategories(categories).slice(0, 7)
    : parseCategories(categories).slice(0, 5);

  return (
    <SDiscover>
      <div className="header">
        <h2 className="heading">
          {formatMessage({ id: 'app.discover.heading' })}
        </h2>
        <p className="subheading">
          {formatMessage({ id: 'app.discover.subheading' })}
        </p>
      </div>

      <nav className="map-categories">
        <ul className="categories">
          <div className="input-container">
            <Autocomplete
              load={loadAutocompleteLocations}
              placeholder={formatMessage({
                id: 'app.basic-search.location',
              })}
              customStyles={{
                ...selectStyles,
                container: (styles) => ({
                  ...styles,
                  border: '1px solid #EDE8E9',
                  borderRadius: 24,
                  cursor: 'pointer',
                  padding: '5px 10px 5px 22px',
                  width: '282px',
                }),
              }}
              onChange={selectLocation}
              value={search.location}
            />
          </div>
          {listingCategories.map(
            ({ name, displayName, id, background, textColor }) => (
              <CategoryPill
                active={selected.includes(id)}
                key={name}
                id={id}
                name={name}
                displayName={displayName}
                background={background}
                textColor={textColor}
                hasIcon={breakpoints.sm}
                size="regular"
                onSelect={() => {
                  dispatch(clearSalonsFilters());
                  dispatch(getSalons({ page: '1', pagination: false }));
                  dispatch(setSalonsFilters({ value: id, label: name }));
                }}
              />
            )
          )}
        </ul>
      </nav>

      <div className="map-padding-wrapper">
        <DiscoverMap points={points} bounds={bounds} />
      </div>
    </SDiscover>
  );
};

export default Discover;
