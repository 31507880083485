import { FC, HTMLAttributes, useState, FormEvent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { OptionTypeBase } from 'react-select';
import parseCategoriesSuggestion from 'utils/parseCategoriesSuggestion';

import { Autocomplete, Button } from 'components';
import { SBasicSearch } from './BasicSearch.styled';

import { useBreakpoint } from 'modules/breakpoint';
import loadAutocompleteLocations from 'api/loadAutocompleteLocations';
import { useAppDispatch, useAppSelector } from 'Root';
import { setSalonsSearch } from 'state/reducers/salons/salonsActions';
import { useHistory } from 'react-router';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import { AppRoute } from 'const';
import { locations } from '../../const/predefined-cities';
import loadAutocompleteCategories from 'api/loadAutocompleteCategories';
import BasicSearchTabs from '../BasicSearchTabs/BasicSearchTabs';
import { selectStyles } from '../Shared/AutocompleteInput';
import ReactPixel from 'react-facebook-pixel';

const BasicSearch: FC<
  HTMLAttributes<HTMLDivElement> & { fromHeader?: boolean; visible?: boolean }
> = (props) => {
  const dispatch = useAppDispatch();
  const search = useAppSelector(({ salons }) => salons.search);
  const { categories: visibleCategories } = useAppSelector(({ app }) => app);

  const [categories, setCategories] = useState<OptionTypeBase[]>(
    search.categories
  );

  const [location, setLocation] = useState<OptionTypeBase | null>(
    search.location
  );

  const history = useHistory();
  const { localizeRouteKey } = useLocalizeRoute();

  const { formatMessage } = useIntl();
  const breakpoint = useBreakpoint();

  const handleChange = (setter: any) => (value: OptionTypeBase) => {
    setter(value);
  };

  const updateCategories = (pickedCategory: OptionTypeBase) => {
    setCategories([pickedCategory]);
  };

  const handleSearch = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await dispatch(
      setSalonsSearch({
        service: null,
        location,
        categories,
      })
    );

    ReactPixel.track('Search', true);

    history.push(localizeRouteKey(AppRoute.List).replace(':page?', String(1)));
  };

  return (
    <SBasicSearch {...props}>
      {!props.fromHeader && <BasicSearchTabs />}
      <form id="basic-search" onSubmit={handleSearch}>
        <div className="group">
          <Autocomplete
            // load={loadAutocompleteCategories}
            options={visibleCategories.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            placeholder={formatMessage({
              id: 'app.basic-search.category-placeholder',
            })}
            customStyles={{
              ...selectStyles,
              container: (styles) => ({
                ...styles,
                border: '1px solid #EDE8E9',
                borderRadius: 8,
                height: '56px',
                cursor: 'pointer',
                padding: '12px 10px 10px 20px',
                maxWidth: '439px',
                width: '100%',
              }),
            }}
            onChange={updateCategories}
            // value={categories[0]}
          />
        </div>

        <div className="group">
          <Autocomplete
            load={loadAutocompleteLocations}
            options={locations.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            placeholder={formatMessage({
              id: 'app.basic-search.location-placeholder',
            })}
            customStyles={{
              ...selectStyles,
              container: (styles) => ({
                ...styles,
                border: '1px solid #EDE8E9',
                borderRadius: 8,
                height: '56px',
                cursor: 'pointer',
                padding: '12px 10px 10px 20px',
                maxWidth: '439px',
                width: '100%',
              }),
            }}
            onChange={handleChange(setLocation)}
            value={location}
          />
        </div>

        {!breakpoint.lg && (
          <Button className="search-button">
            {formatMessage({ id: 'general.search' })}
          </Button>
        )}
      </form>

      {breakpoint.lg && (
        <Button form="basic-search" className="search-button">
          {formatMessage({ id: 'general.search' })}
        </Button>
      )}
    </SBasicSearch>
  );
};

export default BasicSearch;
