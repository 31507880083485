import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { SHeroRounded } from './HeroRounded.styled';
import Sticky from 'react-stickynode';
import { Header, BasicSearch } from '../index';
import { useAppSelector } from '../../Root';
import AnnouncementsBar from '../Hero/AnnouncementsBar';

const HeroRounded = () => {
  const { hero } = useAppSelector(({ app }) => app.homepage);
  const [showAnnouncement, setShowAnnouncement] = useState(true);

  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => (
        <SHeroRounded heroBackground={hero.background}>
          <AnnouncementsBar />
          <Sticky enabled={!isVisible}>
            {({ status }) => (
              <Header hasColor showSearch={false} status={status} />
            )}
          </Sticky>

          <div className="hero-wrapper">
            <div className="hero-wrapper__image-container">
              <div className="hero-wrapper__content">
                <h1 className="hero-wrapper__title">{hero.heading}</h1>

                <p className="hero-wrapper__description">
                  Dogzi daje wolność wyboru. Tutaj znajdziesz prawdziwych
                  pasjonatów, którzy zaopiekują się Twoim pupilem tak jak
                  chcesz.
                </p>
              </div>

              <img
                className="hero-wrapper__image"
                src={hero.background}
                alt="hero background image"
              />
            </div>
          </div>

          <BasicSearch />
        </SHeroRounded>
      )}
    </VisibilitySensor>
  );
};

export default HeroRounded;
