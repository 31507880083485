import React from 'react';

const PinIcon = () => (
  <svg
    width="17"
    height="24"
    viewBox="0 0 17 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1908_2894)">
      <path
        d="M16.6523 8.25072C16.6523 3.69635 12.9285 0.00389346 8.33344 0C3.73838 0.00389346 0.0145937 3.69635 0.0145937 8.25072C-0.329201 12.2865 5.49493 20.6412 7.59797 23.5123C7.83445 23.8353 8.0891 23.9973 8.33467 23.999C8.58024 23.9963 8.83366 23.8343 9.07137 23.5123C11.172 20.6412 16.9953 12.2865 16.6523 8.25072ZM8.33344 13.0387C5.66805 13.0348 3.50853 10.8927 3.50853 8.25072C3.50853 5.60877 5.66805 3.46639 8.33344 3.4625C10.9988 3.46639 13.1584 5.60852 13.1584 8.25072C13.1584 10.8929 10.9988 13.0348 8.33344 13.0387Z"
        fill="#696F8C"
      />
    </g>
    <defs>
      <clipPath id="clip0_1908_2894">
        <rect width="16.6667" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PinIcon;
