import React from 'react';

const YouTubeIcon = () => (
  <svg viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g fill="#696F8C">
      <path d="M23.25 20.5L18 17.425v6.15l5.25-3.075z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20.5C0 9.178 8.954 0 20 0s20 9.178 20 20.5S31.046 41 20 41 0 31.822 0 20.5zm27.75-6.79c.875.256 1.5.896 1.75 1.793.5 1.666.5 4.997.5 4.997s0 3.331-.375 4.997c-.25.897-.875 1.537-1.75 1.794-1.625.384-7.875.384-7.875.384s-6.375 0-7.875-.384c-.875-.257-1.5-.897-1.75-1.794C10 23.83 10 20.5 10 20.5s0-3.331.25-4.997c.25-.897.875-1.537 1.75-1.794 1.625-.384 7.875-.384 7.875-.384s6.375 0 7.875.384z"
      />
    </g>
  </svg>
);

export default YouTubeIcon
