import styled, { css } from 'styled-components';
import media from '../../theme/respondTo';

export const SBasicSearchTabs = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: none;

    ${media.md`
      display: flex;
    `}

    .tabs {
      align-items: center;
      display: flex;
      justify-content: flex-start;

      li:first-child {
        margin-left: 20px;
      }
    }

    .tab {
      align-items: flex-start;
      display: flex;
      color: ${theme.colors.primary};
      font-weight: 700;
      font-size: 15px;
      position: relative;
      height: 36px;
    }

    .tab:after {
      background-color: ${theme.colors.primary};
      bottom: 0;
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      width: 100%;
      z-index: 2;
    }

    .divider {
      background-color: #ede8e9;
      bottom: 0;
      height: 1px;
      position: absolute;
      width: 100%;
    }
  `}
`;
