import styled, { css } from 'styled-components';

export const SUserModal = styled.div<{ wide?: boolean }>`
  ${({ theme, wide }) => css`
    width: 100%;
    height: 100vh;
    max-height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;

    z-index: 1003;

    .modal-content {
      max-width: 558px;
      width: 100%;

      max-height: 100vh;

      background-color: white;
      border-radius: 12px;
      overflow: hidden;
      overflow-y: auto;

      ${wide &&
      css`
        max-width: 754px;
      `}
    }
  `}
`;

export const SUserModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  background-color: rgba(43, 39, 40, 0.6);
`;

export const SAppSuccessModal = styled.div`
  padding: 25px;
  .register-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 500px;
  }
`;

export const SAppErrorModal = styled.div`
  padding: 25px;
  .register-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 500px;
  }
  .register-error-heading {
    color: ${({ theme }) => theme.colors.red};
    font-weight: 600;
  }
`;
