import React from 'react';
import { SalonService } from '../../const';

export const useServiceCategories = (services?: SalonService[]) => {
  if (!services)
    return {
      groomer: [],
      behaviorists: [],
      hotels: [],
      physiotherapists: [],
      trainings: [],
      noServices: true,
    };

  const groomer = services.filter(
    (service) => service.category?.name === 'Groomer'
  );

  const behaviorists = services.filter(
    (service) => service.category?.name === 'Behawiorystyka'
  );

  const hotels = services.filter(
    (service) => service.category?.name === 'Hotele dla pupila'
  );

  const trainings = services.filter(
    (service) => service.category?.name === 'Szkolenie psów'
  );

  const physiotherapists = services.filter(
    (service) => service.category?.name === 'Fizjoterapeuta'
  );

  const noServices =
    [...groomer, ...behaviorists, ...hotels, ...trainings, ...physiotherapists]
      .length === 0;

  return {
    groomer,
    behaviorists,
    hotels,
    trainings,
    physiotherapists,
    noServices,
  };
};
