import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SAppHeader = styled.header`
  ${({ theme }) => css`
    .top {
      height: 96px;

      display: flex;
      align-items: center;

      background-color: white;
      box-shadow: 0px 3px 4px rgba(153, 155, 168, 0.15);

      position: relative;

      z-index: 11;
    }

    .bottom {
      background: rgba(196, 196, 196, 0.1);
    }

    .logo {
      height: 56px;

      display: block;

      margin-right: ${theme.space.xl}px;

      svg {
        height: 100%;
      }
    }

    .progress {
      max-width: 357px;
      width: 100%;

      .label {
        font-size: 0.81rem;

        color: #908e8e;

        margin-bottom: ${theme.space.sm}px;
      }
    }

    .navigation {
      display: flex;
      flex-direction: column;

      li {
        a {
          height: 48px;

          font-size: 0.875rem;
          font-weight: 500;
          text-decoration: none;
          color: #908e8e;

          position: relative;

          display: flex;
          align-items: center;

          &:hover,
          &.active {
            &::after {
              content: '';
              width: 100%;
              height: 2px;

              position: absolute;
              bottom: 0;

              background-color: ${theme.colors.primary};
            }
          }

          svg:last-child:not(:first-child) {
            margin-left: 10px;
          }
        }

        &:last-of-type {
          margin-left: auto;
          margin-right: 0;
        }
      }

      svg,
      .icon {
        width: 18px;
        height: 18px;
        flex-shrink: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        color: ${theme.colors.primary};

        margin-right: 12px;
      }
    }

    .mobile-navigation {
      svg,
      .icon {
        width: 17px;
        height: 17px;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        color: ${theme.colors.primary};

        position: relative;

        margin-right: ${theme.space.sm}px;
      }

      &-background {
        width: 100%;
        height: 100%;

        position: fixed;
        top: 0;
        left: 0;

        background: rgba(0, 0, 0, 0.7);

        z-index: 10;
      }

      &-active {
        height: 72px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.31rem;
        color: #908e8e;

        margin: 0 ${-theme.space.md}px;
        padding: 0 ${theme.space.md}px;

        background-color: #f9f9f9;

        position: relative;
        z-index: 11;

        p {
          display: flex;
          align-items: center;

          svg:last-child:not(:first-child) {
            margin-left: 10px;
          }
        }
      }

      &-burger {
        display: flex;
        align-items: center;

        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.31rem;
        color: #908e8e;

        padding: 0;
        margin: 0;

        background: none;
        box-shadow: none;
        border: 0;

        cursor: pointer;

        & > div {
          position: relative;
          top: 1px;
        }
      }

      &-list {
        margin: 0 ${-theme.space.md}px;
        padding: 0 ${theme.space.md}px;
        padding-bottom: ${theme.space.xl}px;

        background-color: white;

        position: relative;
        z-index: 11;

        .navigation {
          display: flex;
          flex-direction: column;

          margin: 0;
          padding: 0;

          li {
            a {
              height: 64px;

              &:not(.active) {
                border-bottom: 1px solid #e4e4e4;
              }
            }
            &:last-of-type {
              margin-left: unset;
              a {
                &:not(.active) {
                  border-bottom: 0;
                }
              }
            }
          }
        }
      }

      &.active {
        .mobile-navigation-active {
          background-color: white;
        }
      }
    }

    .wrapper {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: space-between;
      width: 100%;
    }

    .simple {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .actions {
        display: flex;
      }
    }

    ${media.lg`
      .navigation {
        flex-direction: row;

        li {
          margin-right: ${theme.space.xxxl}px;
          a {
            height: 74px;

            &.active,
            &:hover {
              &::after {
                width: calc(100% + ${theme.space.xl}px);

                left: 50%;
                transform: translateX(-50%);
              }
            }
          }
        }
      }
    `}
  `}
`;
