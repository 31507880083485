import React from 'react';
import { SReservationEmployees } from './ReservationEmployees.styled';
import { Slot } from '../../const';
import Employee from './Employee';
import AvatarPlaceholder from '../icons/AvatarPlaceholder';
import SmallCheck from '../icons/SmallCheck';

interface ReservationEmployeesProps {
  employees: Slot[];
  onSelect: (id: number) => void;
  selectedEmployee: number;
}

const ReservationEmployees = ({
  employees,
  onSelect,
  selectedEmployee,
}: ReservationEmployeesProps) => {
  return (
    <SReservationEmployees>
      <li>
        <button onClick={() => onSelect(0)} className="random-employee">
          <div className="wrapper">
            {selectedEmployee === 0 && (
              <div className="selected-indicator">
                <SmallCheck />
              </div>
            )}
            <AvatarPlaceholder />
          </div>
          <p className="random-employee__name">Dowolna osoba</p>
        </button>
      </li>
      {employees.length > 0 && <span className="divider" />}
      {employees.length > 0
        ? employees.map((employee) => (
            <Employee
              key={employee.id}
              {...employee}
              isSelected={selectedEmployee === employee.id}
              onClick={onSelect}
            />
          ))
        : null}
    </SReservationEmployees>
  );
};

export default ReservationEmployees;
