import styled, { css } from 'styled-components';
import media from '../../theme/respondTo';

export const SGallery = styled.ul`
  ${({ theme }) => css`
    .images {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 23px;
      grid-row-gap: 25.5px;
      margin: 46px 0 25.5px;
      width: 100%;

      ${media.md`
        grid-template-columns: repeat(2, 1fr);
      `}
    }

    .image-wrapper {
      border-radius: 10px;
      height: 312px;
      width: 100%;
      overflow: hidden;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .see-more-button {
      align-items: center;
      background-color: #f7f4f8;
      border-radius: 8px;
      color: #3a3335;
      font-size: 14px;
      font-weight: ${theme.weight.medium};
      display: flex;
      height: 32px;
      justify-content: center;
      width: 100%;

      &:hover {
        background-color: #ebe7ec;
      }
    }
  `}
`;
