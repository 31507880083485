import { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sticky from 'react-stickynode';
import { useIntl } from 'react-intl';
import { useBreakpoint } from '../../modules/breakpoint';

import { Wrapper, Logo, Button, AuthProcess } from 'components';
import { SHeader } from './Header.styled';

import { AppRoute } from 'const';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import { useAppDispatch, useAppSelector } from 'Root';
import { showAuthModal } from 'state/reducers/client/clientActions';
import {
  setSalonsFilters,
  setSalonsAllFilters,
  clearSalonsFilters,
} from '../../state/reducers/salons/salonsActions';
import Filters from '../Listing/Filters';
import BurgerMenuButton from './BurgerMenuButton';
import { OptionTypeBase } from 'react-select';
import { ICategory, parseCategories } from '../../const/filter-categories';
import MobileFilters from '../Listing/MobileFilters/MobileFilters';

interface IHeader {
  showSearch?: boolean;
  showFilters?: boolean;
  hasColor?: boolean;
  status?: Sticky.StatusCode;
}

export enum AuthModalType {
  SIGN_IN,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  PHONE_NUMBER_RESERVATION,
  PHONE_NUMBER_RESERVATION_SUCCESS,
}

const Header: FC<IHeader> = ({ status, showFilters = false, hasColor }) => {
  const { formatMessage } = useIntl();
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  const { localizeRouteKey } = useLocalizeRoute();
  const breakpoint = useBreakpoint();

  const dispatch = useAppDispatch();
  const { user, triedAuthorize, isAuthorized } = useAppSelector(
    ({ client }) => client
  );

  const { user: companyOwner } = useAppSelector(({ user }) => user);
  const { categories } = useAppSelector(({ app }) => app);
  const { search } = useAppSelector(({ salons }) => salons);

  const selectedFilters = search.categories.map(({ value }) => value);

  const sticky = status === Sticky.STATUS_FIXED;

  const openSignInModal = () => {
    dispatch(showAuthModal(AuthModalType.SIGN_IN));
  };

  const listingCategories: ICategory[] = [
    // {
    //   name: 'Wszystko',
    //   displayName: 'Wszystko',
    //   id: 0,
    //   background: { from: '97, 126, 169', to: '97, 126, 169' },
    //   textColor: 'rgba(114, 114, 114, 1)',
    // },
    ...parseCategories(categories),
  ];

  const setAllCategoriesAsActive = () => {
    dispatch(
      // setSalonsAllFilters(
      //   listingCategories.map(({ id, name }) => ({
      //     value: id,
      //     label: name,
      //   }))
      // )
      setSalonsAllFilters([
        {
          value: 1,
          label: 'Groomer',
        },
      ])
    );
  };

  useEffect(() => {
    if (!showFilters || search.categories.length > 0) return;
    setAllCategoriesAsActive();
  }, [showFilters, search.categories]);

  useEffect(() => {
    if (showFilters) return;
    dispatch(
      setSalonsAllFilters([
        {
          value: 1,
          label: 'Groomer',
        },
      ])
    );
  }, []);

  const pickCategory = (category: OptionTypeBase) => {
    const formattedCategory = { value: category.id, label: category.name };

    const ALL_CATEGORY = 'Wszystko';

    if (category.name === ALL_CATEGORY) {
      const allCategoryIncluded = search.categories.find(
        ({ label }) => label === ALL_CATEGORY
      );

      return dispatch(
        setSalonsAllFilters(
          allCategoryIncluded
            ? []
            : listingCategories.map(({ id, name }) => ({
                value: id,
                label: name,
              }))
        )
      );
    }

    const ALL_VISIBLE_CATEGORIES_AMOUNT = categories.length + 1;

    if (selectedFilters.length === ALL_VISIBLE_CATEGORIES_AMOUNT) {
      return dispatch(
        setSalonsAllFilters(
          search.categories.filter((item) => item.value === category.id)
        )
      );
    }

    return dispatch(setSalonsFilters(formattedCategory));
  };

  return (
    <SHeader sticky={sticky} showFilters={showFilters}>
      <Wrapper>
        <div className="simple">
          <div className="logo-wrapper">
            <Link className="logo" to="/">
              <Logo mode={hasColor ? 'color' : 'white'} size={37} />
            </Link>

            <p className="claim">#1 Znajdź miejsce przyjazne psom</p>
          </div>

          {(triedAuthorize && isAuthorized && user) ||
          !!companyOwner?.company ? (
            <Link
              to={
                !!companyOwner?.company
                  ? localizeRouteKey(AppRoute.App)
                  : localizeRouteKey(AppRoute.Profile)
              }
              className="user-avatar"
            >
              <img src={user?.photo || companyOwner?.photo || ''} alt="" />
            </Link>
          ) : (
            <div className="actions">
              <Button className="button" to={localizeRouteKey(AppRoute.App)}>
                {formatMessage({ id: 'general.page.forSalons' })}
              </Button>
              <Button
                white
                type="button"
                className="button sign-in"
                onClick={openSignInModal}
              >
                <span>{formatMessage({ id: 'general.page.signIn' })}</span>
              </Button>
            </div>
          )}
          {(triedAuthorize && isAuthorized && user) ||
          !!companyOwner?.company ? null : (
            <BurgerMenuButton
              opened={toggleMobileMenu}
              onClick={() => setToggleMobileMenu((prevState) => !prevState)}
            />
          )}

          {toggleMobileMenu && (
            <div className="mobile-menu">
              <Button className="button" to={localizeRouteKey(AppRoute.App)}>
                {formatMessage({ id: 'general.page.forSalons' })}
              </Button>
              <Button
                white
                type="button"
                className="button sign-in"
                onClick={openSignInModal}
              >
                <span>{formatMessage({ id: 'general.page.signIn' })}</span>
              </Button>
            </div>
          )}
        </div>
      </Wrapper>
      <AuthProcess />
      {showFilters && selectedFilters && (
        <Filters selected={selectedFilters} onToggle={pickCategory} />
      )}
      {showFilters && (
        <MobileFilters selected={selectedFilters} onSelect={pickCategory} />
      )}
    </SHeader>
  );
};

export default Header;
