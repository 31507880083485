import styled, { css } from 'styled-components'

interface ICheckbox {
  readonly?: boolean
  circle?: boolean
  error?: boolean

  toggle?: boolean
}

export default styled.div<ICheckbox>`
  ${({ theme, readonly, circle, error, toggle }) => css`
    min-height: 24px;

    display: inline-block;

    ${toggle &&
    css`
      width: 100%;
    `}

    input {
      width: 1px;
      height: 1px;

      position: absolute;
      top: 0;
      left: 0;

      opacity: 0;
      visibility: hidden;

      & + label {
        font-size: 1rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.31rem;
        color: #3a3335;

        position: relative;

        padding-left: 36px;

        ${toggle &&
        css`
          width: 100%;
          display: block;
          padding-left: 0;
          padding-right: 64px;
        `}

        &::before {
          content: '';
          width: 24px;
          height: 24px;

          position: absolute;
          top: -1px;
          left: 0;

          border: 1px solid #ede8e9;
          border-radius: 5px;

          ${toggle &&
          css`
            width: 50px;

            right: 0;
            left: unset;

            border-radius: 100px;
            background-color: #ede8e9;
          `}
        }

        ${toggle &&
        css`
          &::after {
            content: '';

            position: absolute;
            top: 2px;
            left: calc(100% - 47px);
            transform: unset;

            width: 18px;
            height: 18px;

            border-radius: 50%;
            background: #ffffff;
          }
        `}
      }

      &:checked {
        & + label {
          &::before {
            border-color: ${theme.colors.primary};
            ${toggle &&
            css`
              background-color: ${theme.colors.primary};
            `}
          }

          &::after {
            content: 'done';
            font-size: 1.31rem;
            font-family: 'Material Icons';
            color: ${theme.colors.primary};

            position: absolute;
            top: 1px;
            left: 1px;

            ${toggle &&
            css`
              content: '';
              top: 2px;
              left: unset;
              right: 3px;
            `}
          }
        }
      }
    }

    a {
      color: ${theme.colors.primary};
      text-decoration: none;
    }

    ${!readonly &&
    css`
      cursor: pointer;
      input {
        & + label {
          cursor: pointer;
        }
      }

      &:hover {
        input {
          & + label {
            &::before {
              border-color: ${theme.colors.primary};
              ${toggle &&
              css`
                border-color: #ede8e9;
              `}
            }
          }
        }
      }

      &:hover {
        input:checked {
          & + label {
            &::before {
              ${toggle &&
              css`
                border-color: ${theme.colors.primary};
              `}
            }
          }
        }
      }
    `}

    ${circle &&
    css`
      input {
        & + label {
          font-size: 1rem;
          font-weight: ${theme.weight.bold};
          line-height: 1.31rem;
          color: #3a3335;

          position: relative;

          padding-left: 36px;

          ${error &&
          css`
            color: ${theme.colors.error};
          `}

          &::before {
            content: '';
            width: 20px;
            height: 20px;

            position: absolute;
            top: -1px;
            left: 0;

            background: linear-gradient(
                0deg,
                rgba(58, 51, 53, 0.04),
                rgba(58, 51, 53, 0.04)
              ),
              #ffffff;
            border-radius: 50%;
          }
        }

        &:checked {
          & + label {
            &::before {
              border-color: ${theme.colors.primary};
            }

            &::after {
              content: '';

              width: 10px;
              height: 10px;

              position: absolute;
              top: 4px;
              left: 5px;

              background-color: ${theme.colors.primary};
              border-radius: 50%;
            }
          }
        }
      }
    `}
  `}
`
