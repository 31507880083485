import React, { useState } from 'react';
import SectionTemplate from './SectionTemplate';
import { SGallery } from './Gallery.styled';
import { Image } from '../../const';
import { useBreakpoint } from 'modules/breakpoint';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import GalleryControls from './GalleryControls';
import { Wrapper } from '../index';
import SliderArrow from '../icons/SliderArrow';

interface IGalleryProps {
  images: Image[];
  onImageClick: (image: Image) => void;
}

const Gallery = ({ images, onImageClick }: IGalleryProps) => {
  const [showMore, setShowMore] = useState(false);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const { sm, md } = useBreakpoint();

  const handleGalleryPrev = () => {
    if (!swiper) return;
    swiper.slidePrev();
  };

  const handleGalleryNext = () => {
    if (!swiper) return;
    swiper.slideNext();
  };

  const renderedImages = showMore ? images : images.slice(0, 4);

  if (!images) return null;

  return (
    <SectionTemplate title="Galeria">
      {sm || md ? (
        <Swiper
          onSwiper={setSwiper}
          slidesPerView={1}
          spaceBetween={0}
          initialSlide={0}
        >
          {images.map((image) => (
            <SwiperSlide key={image.path}>
              <img
                src={image.path}
                alt="salon - zdjęcie promocyjne"
                onClick={() => {}}
              />
            </SwiperSlide>
          ))}

          <GalleryControls
            onNextClick={handleGalleryNext}
            onPrevClick={handleGalleryPrev}
          />
        </Swiper>
      ) : (
        <SGallery>
          {renderedImages.length > 0 ? (
            <ul className="images">
              {renderedImages.map((image) => (
                <li
                  role="button"
                  onClick={() => onImageClick(image)}
                  className="image-wrapper"
                  key={image.path}
                >
                  <img src={image.path} alt="" />
                </li>
              ))}
            </ul>
          ) : (
            <p>Brak zdjęć</p>
          )}
          {renderedImages.length > 0 && (
            <button
              onClick={() => setShowMore((prevState) => !prevState)}
              className="see-more-button"
            >
              {showMore ? 'Zobacz mniej' : 'Zobacz więcej'}
            </button>
          )}
        </SGallery>
      )}
    </SectionTemplate>
  );
};

export default Gallery;
