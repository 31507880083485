import { AsyncThunk, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import client from 'api/client';
import { Salon, Discount } from 'const';
import { SalonSearch } from '../../../types/store';
import config from 'const/config';
import { RootState } from 'state/createStore';
import { OptionTypeBase } from 'react-select';

const SET_SALONS_SEARCH = 'salons/setSearch';
export const setSalonsSearch = createAction<SalonSearch>(SET_SALONS_SEARCH);
export const clearSalonsSearch = createAction('salons/clearSearch');

export const setSalonsFilters =
  createAction<OptionTypeBase>('salons/setFilters');

export const clearSalonsFilters = createAction('salons/clearSalonsFilters');

export const getSalonSlots: AsyncThunk<
  { payload: any },
  { salonId: number; serviceId: number },
  { state: RootState }
> = createAsyncThunk(
  'salons/getSlots',
  async ({ salonId, serviceId }, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);

    return await client(`client/salons/${salonId}/slots?serviceId=${serviceId}`)
      .auth(`Bearer ${token}`)
      .errorType('json')
      .get()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(true);
      })
      .json((json) => {
        return json.data;
      })
      .catch(() => {
        return thunkAPI.rejectWithValue(true);
      });
  }
);

export const setSalonsAllFilters = createAction<OptionTypeBase[]>(
  'salons/setAllFilters'
);

const SET_SINGLE_SALON = 'salons/setSingle';
export const setSingleSalon = createAction<{
  base?: Salon;
}>(SET_SINGLE_SALON);

export const updateDiscount: AsyncThunk<any, Discount, { state: RootState }> =
  createAsyncThunk('salons/updateDiscount', async (payload, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_KEY);

    return await client(`provider/partnerProgram`)
      .auth(`Bearer ${token}`)
      .errorType('json')
      .patch(payload)
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(true);
      })
      .json((json) => {
        const { data } = json;
        return data;
      })
      .catch(() => {
        return thunkAPI.rejectWithValue(true);
      });
  });

export const getDiscountsList = createAsyncThunk(
  'salons/getDiscounts',
  async (payload, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_KEY);

    return await client(`provider/partnerProgram`)
      .auth(`Bearer ${token}`)
      .errorType('json')
      .get()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(true);
      })
      .json((json) => {
        const { data } = json;
        return data;
      })
      .catch(() => {
        return thunkAPI.rejectWithValue(true);
      });
  }
);

export const updateUsersPoints: AsyncThunk<
  any,
  { id: number; points: number },
  { state: RootState }
> = createAsyncThunk('salons/updateUsersPoints', async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_KEY);

  return await client(`provider/partnerProgram/users`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .patch({
      user_id: payload.id,
      points_amount: payload.points,
    })
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(true);
    })
    .json((json) => {
      const { data, current_page, pages } = json;

      return {
        currentPage: current_page,
        members: data,
        pages,
      };
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(true);
    });
});

export const getProgramUsers: AsyncThunk<
  any,
  { search: string; page: number },
  { state: RootState }
> = createAsyncThunk('salons/getUsers', async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_KEY);

  const { search, page } = payload;

  let params = '';

  if (search) {
    params = `&name=${search}`;
  }
  if (page) {
    params += `&page=${page}`;
  }

  return await client(`provider/partnerProgram/users?perPage=20${params}`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .get()
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(true);
    })
    .json((json) => {
      const { data, current_page, pages } = json;
      return {
        currentPage: current_page,
        members: data,
        pages,
      };
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(true);
    });
});

const GET_SALONS = 'salons/get';
export const getSalons: AsyncThunk<
  any,
  { page: string; pagination: boolean },
  { state: RootState }
> = createAsyncThunk(
  GET_SALONS,
  async (payload, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);

    // @ts-ignore
    const { search } = thunkAPI.getState().salons;

    let query = `?perPage=6&page=${payload.page}`;

    if (search.service) {
      query = `${query}&filters[service]=${search.service.label}`;
    }

    if (search.location) {
      query = `${query}&filters[${
        search.location?.district ? 'district' : 'location' ?? 'location'
      }][]=${search.location.value}`;
    }

    if (search.categories.length > 0) {
      query = `${query}${search.categories.map(
        ({ value }: OptionTypeBase) => `&filters[category][]=${value}`
      )}`;
    }

    return await client(`client/salons${query}`)
      .auth(`Bearer ${token}`)
      .errorType('json')
      .get()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(true);
      })
      .json((json) => {
        const {
          data,
          meta: { current_page, last_page, total },
        } = json;
        return { data, current_page, last_page, total };
      })
      .catch(() => {
        return thunkAPI.rejectWithValue(true);
      });
  },
  {
    condition: (payload, { getState }) => {
      // @ts-ignore
      const { loading } = getState().salons;

      if (loading) {
        return false;
      }
      return true;
    },
  }
);

const GET_ALL_SALONS = 'allSalons/get';
export const getAllSalons = createAsyncThunk(
  GET_ALL_SALONS,
  async (_, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);

    // @ts-ignore

    return await client(
      `client/salons?perPage=99999&page=1&filters[category][]=1`
    )
      .auth(`Bearer ${token}`)
      .errorType('json')
      .get()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(true);
      })
      .json((json) => {
        const {
          data,
          meta: { current_page, last_page, total },
        } = json;
        return { data, current_page, last_page, total };
      })
      .catch(() => {
        return thunkAPI.rejectWithValue(true);
      });
  }
);

const GET_SALON = 'salons/getSingle';
export const getSalon: AsyncThunk<
  any,
  { id: string; token: string },
  { state: RootState }
> = createAsyncThunk(GET_SALON, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);

  return await client(`client/salons/${payload.id}?token=${payload.token}`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .get()
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(true);
    })
    .json((json) => {
      if (typeof json === 'object' && json.length === 0) {
        window.location.href = '/';
        return thunkAPI.rejectWithValue(true);
      }
      return json.data;
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(true);
    });
});

const GET_SALON_REVIEWS = 'salons/getSingleReviews';
export const getSalonReviews: AsyncThunk<
  any,
  { id: string; page?: string },
  { state: RootState }
> = createAsyncThunk(GET_SALON_REVIEWS, async (payload, thunkAPI) => {
  return await client(
    `client/salons/${payload.id}/reviews?perPage=3&page=${payload.page || 1}`
  )
    .errorType('json')
    .get()
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(true);
    })
    .json((json) => {
      const {
        data,
        meta: { current_page, last_page },
      } = json;
      return { data, current_page, last_page };
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(true);
    });
});

const FAVORITE_SALON = 'salons/favoriteSalon';
export const favoriteSalon: AsyncThunk<any, number, { state: RootState }> =
  createAsyncThunk(FAVORITE_SALON, async (payload, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);
    if (!token) {
      return thunkAPI.rejectWithValue(false);
    }

    const salon = (thunkAPI.getState() as RootState).salons.single
      .data as Salon;
    const id = salon?.id || payload;

    return await client(`client/favourites/${id}/toggle`)
      .auth(`Bearer ${token}`)
      .errorType('json')
      .patch()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(true);
      })
      .res(() => {
        return true;
      })
      .catch(() => {
        return thunkAPI.rejectWithValue(true);
      });
  });
