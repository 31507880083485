import { SConfirmationBaner } from './ConfirmationBaner.styled';

const ConfirmationBaner: React.FC<{}> = () => {
  return (
    <SConfirmationBaner>
      <p>
        Ta strona jest widoczna tylko dla Ciebie. Kliknij link znajdujący się w
        mailu, aby potwierdzić ofertę. Po opublikowaniu Twoja oferta będzie
        widoczna dla wszystkich klientów.
      </p>
    </SConfirmationBaner>
  );
};

export default ConfirmationBaner;
