import styled, { css } from 'styled-components';
import media from '../../theme/respondTo';

export const SHeroRounded = styled.section<{ heroBackground: string }>`
  ${({ theme, heroBackground }) => css`
    display: flex;
    flex-flow: column;
    height: 700px;
    position: relative;
    width: 100%;

    ${media.md`
        height: 785px;
    `}

    .announcement-wrapper {
      display: none;

      ${media.xl`
        width: 1259px !important;
      `}
      ${media.md`
        bottom: 140px;
        display: flex;
        justify-content: flex-end;
        width: 80%;
        position: absolute;
      `}
    }

    .hero-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 479px;
      position: relative;
      width: 100%;

      ${media.md`
        height: 530px;
            margin: 0 auto;
            width: 90%;
      `}

      &__image-container {
        align-items: flex-start;
        display: flex;
        height: 100%;
        margin: 0 auto;
        justify-content: center;
        overflow: hidden;
        max-width: 1345px;
        width: 100%;
        position: relative;

        &:after {
          background-color: rgba(0, 0, 0, 0.4);
          content: '';
          display: block;
          left: 0;
          height: 100%;
          position: absolute;
          top: 0;
          width: 100%;
        }

        ${media.md`
            border-radius: 24px;
        `}

        ${media.lg`
          align-items: center;
          `}
        
        ${media.xxl`
          max-width: 1345px;
        `}
      }

      &__title {
        font-size: 32px;
        line-height: 40px;
        font-weight: 700;
        width: 250px;

        ${media.md`
          font-size: 64px;
          line-height: 70px;
          width: 570px;
        `}
      }

      &__description {
        font-size: 16px;
        line-height: 29px;
        margin-top: 25px;
        opacity: 0.86;
        width: 269px;

        ${media.md`
            font-size: 18px;
            line-height: 35px;
            width: 467px;
        `}
      }

      &__image {
        object-fit: cover;
        height: 100%;
        object-position: bottom right;
        transform: scale(1.7) translate(-20px, -100px);
        width: 100%;

        ${media.md`
          height: 100%;
          object-position: center 75%;
          transform: scale(1.2) translate(-100px, 0);
          width: 100%;
        `}
      }

      &__content {
        align-items: flex-start;
        color: #fff;
        display: flex;
        flex-flow: column;
        padding-left: 25px;
        position: absolute;
        margin: 74px 0 0;
        width: 100%;
        z-index: 2;

        ${media.md`
        padding: 0 100px;
        margin: 50px 0 0;
        max-width: 1345px;
        `}

        ${media.lg`
        margin: 0;
        `}
      }
    }
  `}
`;
