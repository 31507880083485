import React from 'react'

import { Reset } from 'styled-reset'

import GlobalStyles from './GlobalStyles'

function ThemeCustomization() {
  return (
    <>
      <Reset />
      <GlobalStyles />
    </>
  )
}

export default ThemeCustomization
