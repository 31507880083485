import { useEffect, FC } from 'react';
import StarRatings from 'react-star-ratings';

import { useAppDispatch, useAppSelector } from 'Root';
import { useDispatch } from 'react-redux';

import { SReviewEditor } from './ReviewEditor.styled';
import { Button, TextInput, UserModal } from 'components';
import theme from 'theme';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Review } from 'const';
import {
  createReview,
  hideReviewEditor,
  updateReview,
} from 'state/reducers/client/clientActions';
import { SUserModalOverlay } from 'components/UserModal/UserModal.styled';

const ReviewEditor: FC = () => {
  const dispatch = useDispatch();
  const {
    editor: { data, loading, visible },
  } = useAppSelector(({ client }) => client.reviews);

  const methods = useForm<Partial<Review>>({
    defaultValues: {
      rating: data?.review?.rating || 0,
      content: data?.review?.content || '',
    },
  });

  const submit = async (values: Partial<Review>) => {
    if (data?.review?.id) {
      dispatch(
        updateReview({
          id: data.review.id,
          ...values,
        })
      );

      return;
    }

    if (!data?.appointment) return;
    dispatch(
      createReview({
        id: data.appointment.id,
        ...values,
      })
    );
  };

  const close = () => dispatch(hideReviewEditor());

  useEffect(() => {
    methods.reset({
      rating: data?.review?.rating || 0,
      content: data?.review?.content || '',
    });
  }, [data]);

  const isEdit = !data?.appointment;
  const source = data?.appointment
    ? data.appointment.salon
    : data?.review?.salon;

  if (!visible || (visible && !source)) return null;
  return (
    <UserModal wide>
      <SUserModalOverlay onClick={close} />
      <SReviewEditor>
        <div className="review-editor-header">
          <h2 className="review-editor-heading">
            {isEdit ? 'Edytuj opinię' : 'Dodaj opinię'}
          </h2>
          <button className="review-editor-close" type="button" onClick={close}>
            <span className="material-icons">close</span>
          </button>
        </div>
        <div className="review-editor-content">
          <p className="review-editor-name">{source?.name}</p>
          <p className="review-editor-address">
            {source?.city?.name}, {source?.street} {source?.buildingNumber}
          </p>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submit)}>
              <Controller
                name="rating"
                render={({ field: { value, onChange } }) => (
                  <StarRatings
                    rating={value}
                    starRatedColor={theme.colors.primary}
                    starHoverColor={theme.colors.primary}
                    numberOfStars={5}
                    starDimension="56px"
                    starSpacing="4px"
                    changeRating={onChange}
                  />
                )}
              />
              <TextInput id="content" label="Twoja opinia" textarea />
              <Button
                disabled={loading}
                loading={loading}
                className="review-editor-submit"
              >
                {isEdit ? 'Aktualizuj' : 'Dodaj'}
              </Button>
            </form>
          </FormProvider>
        </div>
      </SReviewEditor>
    </UserModal>
  );
};

export default ReviewEditor;
