import styled, { css } from 'styled-components'
import media from 'theme/respondTo'

export const SAppProfileImages = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space.xl}px;
    padding: ${theme.space.lg}px;

    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23EDE8E9' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 8px;

    .images {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      grid-gap: ${theme.space.md}px;

      margin-bottom: ${theme.space.lg}px;

      .thumbnail {
        width: 100%;
        min-height: 72px;

        position: relative;

        background-color: #ede8e9;

        border-radius: 10px;
        overflow: hidden;

        .remove {
          width: 40px;
          height: 40px;

          display: flex;
          align-items: center;
          justify-content: center;

          position: absolute;
          top: ${theme.space.md}px;
          right: ${theme.space.md}px;

          background: linear-gradient(
              0deg,
              rgba(58, 51, 53, 0.04),
              rgba(58, 51, 53, 0.04)
            ),
            #ffffff;
          border: none;
          border-radius: 10px;
          box-shadow: none;

          cursor: pointer;

          .icon {
            font-size: 1.125rem;
            color: #908e8e;
          }

          span:not(.icon) {
            width: 18px;
            height: 18px;
          }

          &:hover {
            background: linear-gradient(
                0deg,
                rgba(58, 51, 53, 0.06),
                rgba(58, 51, 53, 0.06)
              ),
              #ffffff;
          }
        }

        img {
          object-fit: fill;
          width: 100%;
          height: auto;
        }
      }
    }

    .uploader {
      display: flex;
      justify-content: center;

      label {
        width: 223px;
        height: 54px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 0.875rem;
        font-weight: ${theme.weight.bold};
        color: white;

        background-color: ${theme.colors.primary};
        border-radius: 10px;

        cursor: pointer;

        .icon {
          margin-right: ${theme.space.sm}px;
        }

        &:hover {
          background-color: #bb4f01;
        }
      }

      input {
        width: 1px;
        height: 1px;

        position: absolute;
        top: 0;
        left: 0;

        opacity: 0;
        visibility: hidden;
      }
    }

    ${media.md`
      .images {
        grid-template-columns: repeat(2, 1fr);
      }
    `}

    ${media.lg`
      .images {
        grid-template-columns: repeat(3, 1fr);
      }
    `}
  `}
`
