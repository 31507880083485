import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SSalonDetails = styled.div<{ servicesEmpty: boolean }>`
  ${({ theme }) => css`
    padding: 21px 0 0;

    ${media.lg`
    padding: 45px 0 0;
    `}

    .service-category {
      margin: 36px 0 0;

      &-header {
        font-size: 20px;
        font-weight: 500;
      }
    }

    .gallery-padding {
      padding: 0 25px;
    }

    .mobile-divider {
      width: 343px !important;
      margin: 18px auto 0 !important;
    }

    .section-template {
      margin-bottom: 35px;

      ${media.lg`
      margin-bottom: 40px;
      `}

      &__header {
        align-items: center;
        display: flex;
        cursor: pointer;
        justify-content: space-between;
      }

      &__header-left {
        align-items: baseline;
        display: flex;
        column-gap: 13px;
      }

      &__rating-wrapper {
        align-items: center;
        color: ${theme.colors.black};
        display: flex;
        column-gap: 5px;

        p {
          font-size: 14px;
          font-weight: ${theme.weight.semibold};
          margin-top: 2px;
        }
      }

      &__heading {
        font-size: 24px;
        font-weight: ${theme.weight.semibold};
        line-height: 24px;
        color: #304659;
      }

      &__divider {
        background-color: #e6e8f0;
        display: block;
        height: 1px;
        margin: 18px 0 0;
        width: 100%;
      }

      &__toggle-button {
        display: none;

        ${media.sm`
        display: flex;
        height: 32px;
        width: 32px;
        `}
      }

      .swiper-slide {
        align-items: center;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        height: 300px;
        overflow: hidden;

        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
    }

    .salon-details {
      &-header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ${media.lg`
          flex-direction: row;
          align-items: center;
        
          .favorite {
            margin-top: 0;
          }
        `}
      }

      &-share-button {
        display: none;

        ${media.lg`
        align-items: center;
        display: flex;
        column-gap: 8px;
        `}
      }

      &-share-icon-wrapper {
        align-items: center;
        background-color: #f7f4f8;
        border-radius: 50%;
        display: flex;
        height: 36px;
        justify-content: center;
        width: 36px;
      }

      &-divider {
        background-color: #e6e8f0;
        height: 1px;
        margin: 0 24px;
        max-width: 1190px;
        width: calc(100% - 48px);

        ${media.lg`
        margin: 0 auto;
        width: 100%;
        `}
      }

      &-share-label {
        color: ${theme.colors.black};
        line-height: 24px;
      }

      &-heading {
        font-weight: ${theme.weight.medium};
        font-size: 28px;
        line-height: 36px;

        ${media.lg`
        font-size: 32px;
        `}

        color: ${theme.colors.black};
      }

      &-rating {
        align-items: center;
        display: flex;
        column-gap: 5px;
        color: ${theme.colors.black};
        font-size: 14px;
        font-weight: ${theme.weight.semibold};
      }

      &-address {
        align-items: center;
        display: flex;
        font-size: 0.875rem;
        height: 40px;

        color: #696f8c;

        &-divider {
          background-color: #696f8c;
          height: 13px;
          margin: 0 10px;
          width: 1px;
        }
      }

      &-mobile-gallery {
        display: block;
        ${media.md`
          display: none;
        `}
      }

      &-desktop-gallery {
        display: none;

        ${media.md`
          display: block;
          width: 100%;
        `}
      }

      &-gallery {
        position: relative;
        margin: 20px auto 0;
        padding: 0 24px;

        .swiper-slide {
          text-align: center;
          border-radius: 10px;
          height: 300px;
          padding-top: 56.25%;
          overflow: hidden;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            object-fit: cover;
            height: 100%;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }

        ${media.lg`
          height: 493px;

          position: relative;
          overflow: visible;

          margin: 11px auto;
          padding: 0;

          .swiper-slide {
            text-align: center;

            height: 493px;
            padding-top: unset;
            overflow: hidden;

            img {
              position: initial;
              top: unset;
              left: unset;
              transform: unset;
              
              width: 100%;
              height: 100%;
              object-fit: cover;
              max-height: 495.5px;
              cursor: pointer;
            }
          }
        `}

        &-arrows {
          height: 67px;

          display: flex;
          justify-content: center;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 100%;

          & > div {
            position: relative;
          }

          z-index: 1;

          .nav-button-left {
            left: -2px;

            ${media.lg`
            left: 0;
            `}
          }

          .nav-button-right {
            right: -2px;

            ${media.lg`
            right: 0;
            `}
          }

          button {
            width: 67px;
            height: 67px;

            position: absolute;

            display: flex;
            align-items: center;
            justify-content: center;

            background: #ffffff;
            box-shadow: 0 4px 28px rgba(185, 185, 187, 0.43);
            border: 0;
            border-radius: 50%;

            cursor: pointer;

            span {
              font-size: 18px;
              color: #908e8e;
            }
          }
        }
      }

      &-content {
        max-width: 725px;
        width: 100%;
      }

      &-container {
        margin: 35px 0 7px;

        ${media.lg`
          display: grid;
          grid-template-columns: 1fr 360px;
          grid-gap: 0 50px;
        `}
      }

      &-about {
        margin-bottom: ${theme.space.xxl}px;

        &-heading {
          font-size: 1.375rem;
          font-weight: ${theme.weight.semibold};
          line-height: 2rem;
          color: ${theme.colors.black};
        }

        &-divider {
          background-color: #e6e8f0;
          display: block;
          height: 1px;
          margin: 18px 0 27px;
          width: 100%;
        }

        &-description {
          font-size: 15px;
          line-height: 1.31rem;
          color: ${theme.colors.black};

          margin: 27px 0 ${theme.space.md}px;
          width: 100%;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-read-more {
          color: ${theme.colors.primary};
          margin: 8px 0 0;
          padding: 0;
          text-decoration: underline;
        }
      }

      &-services {
        display: flex;
        flex-flow: column;

        &:first-of-type {
          &-heading {
            margin-top: 0;
          }
        }

        ${media.lg`
          margin: 20px 0 0;
          row-gap: 20px;
        `}

        &-heading {
          font-size: 1.375rem;
          font-weight: ${theme.weight.heavy};
          line-height: 2rem;
          color: #3a3335;

          margin-bottom: ${theme.space.md}px;
          margin-top: ${theme.space.md}px;
        }

        &-empty {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          text-align: center;

          border: 1px solid #ede8e9;
          border-radius: 8px;

          padding: ${theme.space.lg}px ${theme.space.md}px ${theme.space.xl}px
            ${theme.space.md}px;

          img {
            max-width: 441px;
            width: 100%;
          }

          p {
            font-size: 1rem;
            font-weight: ${theme.weight.bold};
            line-height: 1.5rem;
            color: #000000;

            margin-bottom: ${theme.space.sm}px;

            & + p {
              font-weight: ${theme.weight.normal};
              color: #908e8e;
            }
          }
        }
      }

      &-reviews {
        &-heading {
          font-size: 1.375rem;
          font-weight: ${theme.weight.heavy};
          line-height: 2rem;
          color: #3a3335;

          margin-bottom: ${theme.space.md}px;
        }

        &-summary {
          display: flex;
          flex-direction: column;

          padding: ${theme.space.lg}px;

          border: 1px solid #ede8e9;
          border-radius: 12px;

          ${media.lg`
            flex-direction: row;
          `}
        }

        &-count {
          width: 100%;

          p {
            font-size: 2.125rem;
            font-weight: ${theme.weight.heavy};
            line-height: 3rem;
            color: #3a3335;

            .icon {
              font-size: inherit;
              color: ${theme.colors.primary};

              position: relative;
              top: 2px;

              margin-right: ${theme.space.xs}px;
            }
          }

          & > span {
            max-width: 124px;
            display: inline-block;

            font-size: 0.875rem;
            line-height: 1.31rem;

            color: #908e8e;
          }

          ${media.lg`
            max-width: 184px;

            border-right: 1px solid #ede8e9;
          `}
        }

        &-details {
          flex: 1;

          margin-top: ${theme.space.md}px;

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-bottom: ${theme.space.lg}px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }

          &-name {
            font-size: 0.875rem;
            font-weight: ${theme.weight.bold};
            line-height: 1.31rem;

            color: #3a3335;

            & + span {
              font-size: 0.875rem;
              line-height: 1.31rem;

              color: #908e8e;
            }
          }

          &-percent {
            font-size: 1rem;
            font-weight: ${theme.weight.bold};
            line-height: 1.5rem;
            text-align: right;
            color: #3a3335;

            margin-bottom: ${theme.space.xs}px;
          }

          ${media.lg`
            margin-top: 0;
            padding-left: ${theme.space.xl}px;
          `}
        }

        &-comments {
          margin-top: ${theme.space.xxl}px;

          li {
            margin-bottom: ${theme.space.xl}px;
            &:last-of-type {
              margin-bottom: 0;
            }
          }

          &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          &-header p:not(&-heading) {
            font-size: 0.875rem;
            font-weight: ${theme.weight.bold};
            line-height: 1.5rem;

            span {
              font-size: 1.125rem;
              color: ${theme.colors.primary};

              position: relative;
              top: 2px;
            }
          }

          &-heading {
            font-size: 1rem;
            font-weight: ${theme.weight.bold};
            line-height: 1.5rem;
            color: #3a3335;
          }

          &-review {
            display: flex;
            align-items: center;

            .icon {
              top: 0 !important;
              margin-right: 4px;
            }
          }

          &-description {
            font-size: 0.875rem;
            line-height: 1.5rem;
            color: #908e8e;

            margin-top: ${theme.space.sm}px;
          }

          &-author {
            display: flex;
            align-items: center;

            margin-top: ${theme.space.md}px;
          }

          &-avatar {
            width: 36px;
            height: 36px;

            margin-right: ${theme.space.md}px;

            border-radius: 6px;

            overflow: hidden;

            img {
              width: 100%;
              height: auto;
            }
          }

          &-name-date {
            p {
              font-size: 0.875rem;
              font-weight: ${theme.weight.bold};
              line-height: 1.31rem;
              color: #3a3335;
            }

            span {
              font-size: 0.75rem;
              line-height: 1.125rem;
              color: #908e8e;
            }
          }
        }

        &-pagination {
          margin-top: ${theme.space.xxl}px;
        }
      }

      &-sidebar {
        margin-bottom: ${theme.space.md}px;

        &-navigation-button {
          border-left: 1px solid #ede8e9;
          height: 40px;
          width: 40px;
        }

        &-short-address-wrapper {
          padding: 0 24px;
          bottom: 0;
          display: flex;
          justify-content: center;
          position: absolute;
          width: 100%;
          z-index: 2;
        }

        &-short-address {
          align-items: center;
          background-color: #fff;
          box-shadow: 0 7px 20px #f1f1f1;
          border-radius: 8px;
          display: flex;
          min-height: 77px;
          justify-content: space-between;
          padding: 13px 9px 13px 23px;
          width: 314px;

          &-heading {
            font-weight: ${theme.weight.bold};
            font-size: 18px;
            line-height: 27px;
            color: ${theme.colors.black};
          }

          &-street {
            color: #696f8c;
            font-size: 14px;
            line-height: 24px;
          }
        }

        &-map-hours {
          border: 1px solid #ede8e9;
          border-radius: 16px;
          overflow: hidden;
          padding-bottom: 28px;
        }

        &-map {
          height: 255px;
          margin-bottom: 22px;
          position: relative;
        }

        &-heading {
          font-size: 18px;
          font-weight: ${theme.weight.bold};
          line-height: 2rem;
          color: ${theme.colors.black};
        }

        &-opening-hours {
          padding: 0 24px;

          &-header {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
          }
        }

        &-hours {
          font-size: 0.875rem;
          line-height: 2rem;
          color: #000000;

          li {
            color: #696f8c;
            display: flex;
            align-items: center;
            font-size: 14px;
            justify-content: space-between;

            padding: ${theme.space.sm}px 0;

            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            span:first-of-type {
              text-transform: capitalize;
            }

            &:last-of-type {
              border-bottom: 0;
            }
          }
        }

        &-phone-wrapper {
          align-items: center;
          display: flex;
          column-gap: 7px;
          margin: 12px 0 0;
        }

        &-phone-container {
          align-items: center;
          display: flex;
        }

        &-phone {
          color: #696f8c;
          line-height: 21px;
          text-decoration: none;

          &-button {
            border: 1px solid ${theme.colors.primary};
            border-radius: 8px;
            font-size: 13px;
            font-weight: ${theme.weight.medium};
            line-height: 21px;
            padding: 6px 10px;
            margin-left: 8px;
          }

          &-hint {
            border: 1px solid ${theme.colors.primary};
            border-radius: 8px;
            font-size: 11px;
            line-height: 21px;
            margin: 12px 0 0;
            padding: 6px 11px;

            position: relative;

            &:after {
              position: absolute;
              content: '';
              width: 0px;
              height: 0px;
              border-top: 6px solid ${theme.colors.primary};
              border-right: 6px solid transparent;
              border-bottom: 6px solid transparent;
              border-left: 6px solid transparent;
              top: -12px;
              transform: rotate(180deg);
              left: 30px;
            }
          }
        }

        ${media.lg`
          margin-bottom: 0;
        `}
      }
    }
  `}
`;
