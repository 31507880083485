import React, { useEffect, useState } from 'react';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { Image } from '../../const';
import GalleryControls from './GalleryControls';
import { useBreakpoint } from '../../modules/breakpoint';

let timer: any;

interface TopSliderProps {
  images?: Image[];
  onImageClick: (image: Image) => void;
}

const debounce = (callback: () => void, timeout: number) => {
  clearTimeout(timer);

  timer = setTimeout(() => {
    callback();
  }, timeout);
};

const TopSlider = ({ onImageClick, images }: TopSliderProps) => {
  const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(
    null
  );
  const [offset, setOffset] = useState(300);
  const breakpoint = useBreakpoint();

  const handleGalleryPrev = () => {
    controlledSwiper?.slidePrev();
  };

  const handleGalleryNext = () => {
    controlledSwiper?.slideNext();
  };

  const CONTAINER_MAX_WIDTH = 1140;

  useEffect(() => {
    if (breakpoint.sm || breakpoint.md) return;

    const container = window.innerWidth - CONTAINER_MAX_WIDTH;
    setOffset(container / 2);
  }, []);

  useEffect(() => {
    if (breakpoint.sm || breakpoint.md) {
      setOffset(0);
      return;
    }

    const calcOffset = () => {
      const container = window.innerWidth - CONTAINER_MAX_WIDTH;

      debounce(() => {
        setOffset(container / 2);
      }, 200);
    };

    window.addEventListener('resize', calcOffset);

    return () => {
      window.removeEventListener('resize', calcOffset);
    };
  }, [breakpoint]);

  if (images?.length === 0) return null;

  return (
    <div className="salon-details-gallery">
      <Swiper
        onSwiper={(swiper: SwiperClass) => setControlledSwiper(swiper)}
        slidesPerView={2.2}
        spaceBetween={28}
        breakpoints={{
          300: {
            slidesPerView: 1,
            initialSlide: 0,
          },
          700: {
            slidesPerView: 1,
            initialSlide: 0,
          },
          992: {
            slidesPerView: 2.1,
            initialSlide: 1,
          },
          1200: {
            slidesPerView: 3.1,
            initialSlide: 1,
          },
        }}
        slidesOffsetBefore={offset}
        initialSlide={1}
      >
        {images?.map((image) => (
          <SwiperSlide key={image.path}>
            <img
              src={image.path}
              alt="salon - zdjęcie promocyjne"
              onClick={() => onImageClick(image)}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <GalleryControls
        onPrevClick={handleGalleryPrev}
        onNextClick={handleGalleryNext}
      />
    </div>
  );
};

export default TopSlider;
