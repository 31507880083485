import React from 'react';

const MapButtonIcon = () => (
  <svg
    width="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1974_6866)">
      <path
        d="M7.21257 3.28141C7.21257 3.71449 7.06059 4.25283 6.81836 4.83643L6.81836 15.2839L11.9099 17.9996L11.9099 5.39324L7.19047 2.87695C7.20336 3.00992 7.21257 3.14474 7.21257 3.28141Z"
        fill="#696F8C"
      />
      <path
        d="M3.27418 10.423L3.0126 10.0897C2.18909 9.02539 1.42587 7.91555 0.726562 6.76541L0.726563 18.0033L5.82087 15.2875L5.82087 6.76172C5.12156 7.91186 4.35834 9.0217 3.53483 10.086L3.27418 10.423Z"
        fill="#696F8C"
      />
      <path
        d="M12.9062 5.39543L12.9063 18.0009L17.9996 15.2851V2.67969L12.9062 5.39543Z"
        fill="#696F8C"
      />
      <path
        d="M3.2734 9.33751C4.37865 7.86467 6.5468 4.702 6.5468 3.28179C6.5468 2.41141 6.20192 1.57667 5.58804 0.961215C4.97416 0.345759 4.14156 0 3.2734 0C2.40524 0 1.57264 0.345759 0.958756 0.961215C0.344875 1.57667 0 2.41141 0 3.28179C0 4.70292 2.16262 7.86467 3.2734 9.33751ZM3.2734 1.52547C3.61951 1.52547 3.95785 1.62837 4.24564 1.82115C4.53342 2.01394 4.75772 2.28795 4.89017 2.60854C5.02263 2.92913 5.05728 3.28189 4.98976 3.62223C4.92224 3.96256 4.75557 4.27518 4.51083 4.52055C4.26609 4.76592 3.95427 4.93302 3.6148 5.00071C3.27534 5.06841 2.92347 5.03367 2.60371 4.90087C2.28394 4.76808 2.01063 4.5432 1.81834 4.25468C1.62604 3.96616 1.52341 3.62695 1.52341 3.27995C1.52341 2.81463 1.70778 2.36837 2.03597 2.03935C2.36416 1.71032 2.80927 1.52547 3.2734 1.52547Z"
        fill="#696F8C"
      />
      <path
        d="M5.01249 3.49667C5.13124 2.53402 4.44911 1.65712 3.48892 1.53807C2.52873 1.41902 1.65407 2.10289 1.53532 3.06555C1.41658 4.0282 2.0987 4.9051 3.0589 5.02416C4.01909 5.14321 4.89374 4.45933 5.01249 3.49667Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1974_6866">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MapButtonIcon;
