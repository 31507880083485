import { OptionTypeBase } from 'react-select';
import { LocationSuggestion } from 'const';
import logoColor from '../components/icons/LogoColor';

export const parseVoivodeship = (
  location: LocationSuggestion | null
): OptionTypeBase | null => {
  if (!location) return null;

  if (location.type === 'district') {
    return {
      label: location.name,
      value: location.id,
      district: true,
    };
  }

  return {
    label: location.district?.name,
    value: location.id,
    district: false,
  };
};

const parseLocationSuggestion = (
  location: LocationSuggestion | null
): OptionTypeBase | null => {
  if (!location) return null;

  if (location.district) {
    return {
      label: `${location.name}, ${location.district.name}`,
      value: location.id,
      district: location.type === 'district',
    };
  }

  return {
    label: `${location.name}`,
    value: location.id,
    district: location.type === 'district',
  };
};

export default parseLocationSuggestion;
