import styled, { css } from 'styled-components';
import media from '../../theme/respondTo';

export const SPagination = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    ${media.md`
      flex-wrap: nowrap;
    `}
    .prev,
    .next {
      width: 100%;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 0.875rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.31rem;
      color: #908e8e;

      padding: 0 ${theme.space.md}px;
      margin: 0;

      background: #ffffff;
      box-shadow: none;
      border: 1px solid #ede8e9;
      border-radius: 6px;

      cursor: pointer;

      ${media.md`
      width: auto;
      `}  

      span {
        font-size: 1.2rem;
        margin-top: 1px;

        ${media.md`
            margin-left: ${theme.space.xs}px;
        `}
      }
    }

    .prev {
      padding: 0 ${theme.space.sm}px;

      ${media.md`
       padding: 0 ${theme.space.md}px 0 ${theme.space.sm}px;
         margin-right: ${theme.space.xxl}px;
      `}
    }

    .next {
      padding: 0 ${theme.space.sm}px;

      ${media.md`
      margin-left: ${theme.space.xxl}px;
      padding: 0 ${theme.space.sm}px 0 ${theme.space.md}px;  
      `}
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 10px;
      ${media.md`
      margin-top: 0;
      flex-wrap: nowrap;
      justify-content: flex-wrap;
      `}
      li {
        button {
          width: 40px;
          height: 40px;

          font-size: 0.875rem;
          font-weight: ${theme.weight.bold};
          line-height: 1.31rem;

          background: none;
          box-shadow: none;
          border: 0;
          border-radius: 6px;
          color: ${theme.colors.black};

          cursor: pointer;
        }

        &.active {
          button {
            background-color: ${theme.colors.primary};
            color: white;
          }
        }
      }
    }
  `}
`;
