import styled, { css } from 'styled-components'

interface ISAppWorkplaceCategory {
  empty: boolean
}

export const SAppWorkplaceCategory = styled.div<ISAppWorkplaceCategory>`
  ${({ theme, empty }) => css`
    margin-bottom: ${theme.space.xxxl + theme.space.xl}px;

    ${empty &&
    css`
      margin-bottom: ${theme.space.xxl}px;
    `}

    &:first-of-type {
      margin-top: 0;
    }

    .category {
      &-name {
        font-size: 1.375rem;
        font-weight: ${theme.weight.semibold};

        margin-bottom: ${theme.space.xl}px;
      }

      &-empty {
        min-height: 105px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        font-size: 0.875rem;
        line-height: 1.31rem;

        color: #3a3335;

        background: #f7f7f7;
        border-radius: 8px;

        padding: ${theme.space.md}px;

        button {
          margin: 0;
          margin-top: ${theme.space.sm}px;
        }
      }
    }

    .add-service {
      font-size: 1rem;
      font-weight: ${theme.weight.semibold};
      color: ${theme.colors.primary};

      border: 0;
      box-shadow: none;
      background: none;

      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  `}
`
