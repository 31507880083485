import { FC } from 'react'
import StarRatings from 'react-star-ratings'

import { Review } from 'const'
import { SProfileReviewItem } from './ProfileReviewItem.styled'
import theme from 'theme'
import { Button } from 'components'

const ProfileReviewItem: FC<Review & { editReview: () => void }> = ({
  salon,
  content,
  rating,

  editReview,
}) => {
  return (
    <SProfileReviewItem>
      <p>{content}</p>
      <div className="profile-review-footer">
        <div>
          <p className="profile-review-name">{salon?.name}</p>
          <p className="profile-review-address">
            {salon?.city?.name}, {salon?.street} {salon?.buildingNumber}
          </p>
        </div>
        <div className="profile-review-actions">
          <StarRatings
            rating={rating}
            starRatedColor={theme.colors.primary}
            numberOfStars={5}
            starDimension="18px"
            starSpacing="2px"
          />
          <Button type="button" onClick={editReview}>
            Edytuj
          </Button>
        </div>
      </div>
    </SProfileReviewItem>
  )
}

export default ProfileReviewItem
