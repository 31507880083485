import React, { useState, useEffect } from 'react';
import { IUser } from '../components/AppPartnersProgram/UserRow';
import {
  getProgramUsers,
  updateUsersPoints,
} from '../state/reducers/salons/salonsActions';
import { useAppDispatch, useAppSelector } from '../Root';

let timer: any;

export const useUsersList = () => {
  const [searchPhrase, setSearchPhrase] = useState('');
  const [users, setUsers] = useState<IUser[]>([]);
  const [page, setPage] = useState(1);

  const { members, pages, loading } = useAppSelector(
    ({ salons }) => salons.programMembers
  );

  const dispatch = useAppDispatch();

  const debounce = (callback: () => void, timeout: number) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      callback();
    }, timeout);
  };

  const nextPage = () => {
    setPage((prevState) => Math.min(prevState + 1, pages));
  };

  const updatePointsAmount = (id: number, points: number) => {
    dispatch(updateUsersPoints({ id, points }));
  };

  const prevPage = () => {
    setPage((prevState) => Math.max(prevState - 1, 1));
  };

  const filterUsersByName = () => {
    dispatch(getProgramUsers({ search: searchPhrase, page }));
  };

  useEffect(() => {
    setUsers(members);
  }, [members]);

  useEffect(() => {
    dispatch(getProgramUsers({ search: searchPhrase, page }));
  }, []);

  useEffect(() => {
    debounce(() => filterUsersByName(), 500);
  }, [searchPhrase, page]);

  return {
    loading,
    users,
    setSearchPhrase,
    searchPhrase,
    page,
    totalPages: pages,
    updatePointsAmount,
    nextPage,
    prevPage,
    setPage,
  };
};
