import React, { forwardRef } from 'react';
import { SEditAvatar } from './EditAvatar.styled';

interface EditAvatarProps {
  inputProps?: any;
  avatar: string | undefined;
}

const EditAvatar = forwardRef(({ avatar, inputProps }: EditAvatarProps, ref) => {
  return (
    <SEditAvatar>
      <img src={avatar} alt="" />
      <label className="edit">
        <svg
          width={13}
          height={10}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.854 5.732c0 1.254-1.056 2.275-2.354 2.275s-2.354-1.02-2.354-2.275S5.202 3.457 6.5 3.457s2.354 1.02 2.354 2.275zM13 2.544V8.92c0 .594-.503 1.08-1.118 1.08H1.118C.503 10 0 9.514 0 8.92V2.544c0-.594.503-1.08 1.118-1.08H3.71l.392-.878A1.01 1.01 0 015.026 0h2.948c.401 0 .764.23.923.586l.392.878h2.594c.614 0 1.117.486 1.117 1.08zm-10.658.24a.268.268 0 00-.271-.263h-.748a.268.268 0 00-.272.263v.354c0 .145.122.263.272.263h.748c.15 0 .271-.118.271-.263v-.354zm7.652 2.948a3.26 3.26 0 00-.275-1.315 3.362 3.362 0 00-.748-1.073 3.491 3.491 0 00-1.11-.724A3.576 3.576 0 006.5 2.355c-.471 0-.93.089-1.36.265-.416.17-.79.414-1.11.724-.321.31-.573.67-.75 1.073a3.26 3.26 0 00-.274 1.315c0 .456.093.898.275 1.315.176.402.428.763.748 1.073.321.31.695.554 1.11.724.432.176.89.265 1.361.265s.93-.09 1.36-.265c.416-.17.79-.414 1.11-.724.321-.31.573-.671.75-1.073a3.26 3.26 0 00.274-1.315z"
            fill="#231F20"
          />
        </svg>

        <input max="1" type="file" {...inputProps} />
      </label>
    </SEditAvatar>
  );
});

export default EditAvatar;
