import React from 'react';
import { Wrapper } from '../components';
import UsersList from '../components/AppPartnersProgram/UsersList';

const AppPartnersProgram = () => {
  return (
    <Wrapper>
      <UsersList />
    </Wrapper>
  );
};

export { AppPartnersProgram };
