import { Category } from 'const'
import { without } from 'lodash'
import { ChangeEvent, FC } from 'react'
import { useController } from 'react-hook-form'

import { SSelectableInput } from './AppProfileCategories.styled'

interface IAppProfileCategories {
  control: any
  categories: Category[]
}

const AppProfileCategories: FC<IAppProfileCategories> = ({
  control,
  categories,
}) => {
  const {
    field: { value, onChange },
  } = useController({
    name: 'categories',
    control,
  })

  const handleChange =
    (id: number) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        onChange({ target: { value: [...value, id] } })
        return
      }

      onChange({ target: { value: without(value, id) } })

      // onChange({
      //   target: {
      //     value: value.reduce((acc: Category[], current: Category) => {
      //       if (current.id === id) {
      //         return [...acc, { ...current, active: event.target.checked }]
      //       }

      //       return [...acc, current]
      //     }, []),
      //   },
      // })
    }

  return (
    <>
      {categories.map((category: Category) => {
        const labelKey = `form.label-${category.id}`
        const checked = value.includes(category.id)

        return (
          <SSelectableInput key={labelKey} className="field" htmlFor={labelKey}>
            <input
              type="checkbox"
              {...{
                id: labelKey,
                onChange: handleChange(category.id),
                checked,
              }}
            />
            <p className="label">{category.name}</p>
          </SSelectableInput>
        )
      })}
    </>
  )
}

export default AppProfileCategories
