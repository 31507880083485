import styled, { css } from 'styled-components'
import media from 'theme/respondTo'

export const SProfileReviewItem = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.space.xl}px;

    background: #ffffff;
    border: 1px solid #ede8e9;
    border-radius: 14px;

    & > p {
      font-size: 0.875rem;
      line-height: 1.31rem;
      color: #908e8e;

      padding: ${theme.space.xl}px;
    }

    .profile-review {
      &-footer {
        min-height: 69px;

        display: flex;
        flex-direction: column;

        padding: ${theme.space.md}px ${theme.space.xl}px;

        background: linear-gradient(
            0deg,
            rgba(58, 51, 53, 0.04),
            rgba(58, 51, 53, 0.04)
          ),
          #ffffff;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
      }

      &-name {
        font-size: 0.875rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.5rem;
        color: #3a3335;
      }

      &-address {
        font-size: 0.875rem;
        line-height: 1.31rem;
        color: #908e8e;

        position: relative;
      }

      &-actions {
        display: flex;
        align-items: center;

        margin-top: ${theme.space.md}px;

        button {
          width: 119px;
          height: 36px;

          margin-left: ${theme.space.lg}px;
        }
      }

      ${media.md`
        &-footer {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          
          padding-top: 0;
          padding-bottom: 0;
        }
          
        &-actions {
          margin-top: 0;
        }
      `}
    }
  `}
`
