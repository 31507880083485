import React from 'react';
import servicesEmpty from '../../assets/images/salon-services-empty.png';

interface IServiceEmptyStateProps {
  name: string;
}

const ServicesEmptyState = ({ name }: IServiceEmptyStateProps) => (
  <div className="salon-details-services-empty">
    <img src={servicesEmpty} alt="brak dostępnych usług" />
    <p>Usługi wkrótce będą dodane przez {name}</p>
    <p>Niedługo zwęszysz więcej 🙂! Zapoznaj się ze z opisem o nas!</p>
  </div>
);

export default ServicesEmptyState;
