import styled, { css } from 'styled-components';

export const SEditAvatar = styled.div`
  ${({ theme }) => css`
    position: relative;

    margin-right: ${theme.space.xxl}px;

    img {
      width: 114px;
      height: 114px;

      object-fit: cover;

      border-radius: 20px;
    }

    .edit {
      width: 42px;
      height: 42px;

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      bottom: -6px;
      right: -12px;

      background: none;
      box-shadow: none;

      background: #e8e5e5;
      border-radius: 50%;
      border: 5px solid white;

      cursor: pointer;

      input {
        width: 1px;
        height: 1px;

        position: absolute;
        top: 0;
        left: 0;

        opacity: 0;
        visibility: hidden;
      }
    }
  `}
`;
