import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Duration } from 'luxon';

import { Service, ServiceCreator, Slot, WorkplaceCategory } from 'const';

import { SAppWorkplaceService } from './AppWorkplaceService.styled';
import AppWorkplaceServiceEditor from 'components/AppWorkplaceServiceEditor/AppWorkplaceServiceEditor';
import autoFormatTimeInput from 'utils/autoFormatTimeInput';
import { useAppDispatch } from 'Root';
import {
  removeSalonService,
  updateCategoryService,
} from 'state/reducers/user/userActions';
import { ConfirmationModal, SectionLoader } from 'components';

interface IAppWorkplaceService {
  service: Service;
  category: WorkplaceCategory;
  slots: Slot[];
}
const AppWorkplaceService: FC<IAppWorkplaceService> = ({
  service,
  category,
  slots,
}) => {
  const { formatNumber } = useIntl();
  const dispatch = useAppDispatch();

  const [serviceRemoveConfirmation, setSlotRemoveConfirmation] =
    useState<boolean>(false);
  const [serviceRemoveLoading, setServiceRemoveLoading] =
    useState<boolean>(false);

  const [edit, setEdit] = useState<boolean>(false);
  const methods = useForm<ServiceCreator>({
    defaultValues: {
      name: service.name,
      slots: service.slots,
      price: service.price,
      duration: service.duration
        ? autoFormatTimeInput(
            Duration.fromMillis(service.duration * 60000).toFormat('hh:mm')
          )
        : '',
      description: service.description,
      placesAmount: service.placesAmount,
    },
  });

  const activeSloteRemoveConfirmation = () => {
    setSlotRemoveConfirmation(true);
  };

  const clearSlotRemove = () => {
    setSlotRemoveConfirmation(false);
  };

  const handleRemoveSlot = async () => {
    if (!service.id) return;
    setServiceRemoveLoading(true);
    await dispatch(removeSalonService(service.id));
    setSlotRemoveConfirmation(false);
    setServiceRemoveLoading(false);
  };

  const serviceDuration =
    service.duration &&
    Duration.fromMillis(service.duration * 60000).toFormat("h'h' mm'min'");

  const toggleServiceEdit = () => setEdit(!edit);

  const closeServiceEdit = () => setEdit(false);

  const updateService = async (values: ServiceCreator) => {
    const [hours, minutes] = values?.duration?.split(':') ?? ['0', '0'];
    const duration = parseInt(hours) * 60 + parseInt(minutes);

    const slots = values?.slots?.map((o) => o.id);

    const body = {
      ...service,
      ...values,
      duration,
      price: parseFloat(String(values.price).replace(/ /g, '')),
      slots: slots ? slots : category.appointment_type === 'normal' ? [] : null,
      categoryId: category.id,
    };

    await dispatch(updateCategoryService(body));
  };

  return (
    <SAppWorkplaceService {...{ edit }}>
      <div role="button" className="service" onClick={toggleServiceEdit}>
        <div>
          <p className="service-name">{service.name}</p>
          <p className="service-text">{service.description}</p>
        </div>
        <div className="service-time-price">
          {service.duration ? <p className="time">{serviceDuration}</p> : null}
          <p className="price">
            +
            {formatNumber(service.price, {
              style: 'currency',
              currency: 'PLN',
              currencyDisplay: 'narrowSymbol',
            })}
          </p>
        </div>
      </div>

      {edit ? (
        <div className="service-editor">
          <FormProvider {...methods}>
            <AppWorkplaceServiceEditor
              edit
              {...{
                category,
                slots,
                onSubmit: updateService,
                onCancel: closeServiceEdit,

                onRemove: activeSloteRemoveConfirmation,
              }}
            />
          </FormProvider>
        </div>
      ) : null}

      {serviceRemoveConfirmation && (
        <ConfirmationModal
          disabled={serviceRemoveLoading}
          text={
            serviceRemoveLoading ? (
              <SectionLoader marginless />
            ) : (
              'Czy napewno chcesz usunąć usługę?'
            )
          }
          cancel={clearSlotRemove}
          confirm={handleRemoveSlot}
        />
      )}
    </SAppWorkplaceService>
  );
};

export default AppWorkplaceService;
