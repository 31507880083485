import { useState } from 'react'
import { AppSubscriptionEditor, Button, Wrapper } from 'components'
import { useAppDispatch, useAppSelector } from 'Root'
import { Redirect } from 'react-router'
import { useIntl } from 'react-intl'
import {
  addSubscription,
  addSubscriptionCode,
} from 'state/reducers/user/userActions'
import { useForm } from 'react-hook-form'
import { DateTime, Interval } from 'luxon'
import { head } from 'lodash'

enum CODE_STATUS {
  IDLE,
  SUCCESS,
  ERROR,
}

const AppSubscription = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(({ user }) => user)

  const [loading, setLoading] = useState<boolean>(false)
  const [codeStatus, setCodeStatus] = useState<CODE_STATUS>(CODE_STATUS.IDLE)
  const [successCodeDaysCount, setCodeDaysCount] = useState<number | null>(null)

  const { register, formState, handleSubmit, setError, reset } = useForm<{
    code: string
  }>({
    defaultValues: { code: '' },
  })
  const { formatNumber } = useIntl()

  if (
    !user ||
    (user && !user.company?.subscription) ||
    (user && !user.company?.subscriptionPrice)
  )
    return <Redirect to={'/app'} />

  const renderStatus = () => {
    if (!user.company.subscription.endDate)
      return (
        <div className="app-sub-status warning">
          <p>
            Twój abonament dobiegł końca. Przedłuż go aby nadal korzystać z
            serwisu
          </p>
        </div>
      )

    const startDate = DateTime.local()
    const endDate = DateTime.fromMillis(
      user.company.subscription.endDate * 1000
    )
    const length = Interval.fromDateTimes(startDate, endDate).length('days')

    if (length <= 7) {
      return (
        <div className="app-sub-status warning">
          <p>
            Koniec abonamentu {endDate.toFormat('dd.LL.yyyy')} (
            {length.toFixed(0)} dni)
          </p>
        </div>
      )
    }

    return (
      <div className="app-sub-status">
        <p>
          Abonamet został wykupiony - koniec abonamentu{' '}
          {endDate.toFormat('dd.LL.yyyy')} ({length.toFixed(0)} dni)
        </p>
      </div>
    )
  }

  const submitCode = async (values: { code: string }) => {
    const response: any = await dispatch(addSubscriptionCode(values.code))
    if (response.error) {
      const message = response.payload.errors.code
        ? head(response.payload.errors.code)
        : response.payload.message
      setError('code', { message })
      setCodeStatus(CODE_STATUS.ERROR)
      return
    }

    if (response.payload.length) {
      setCodeDaysCount(response.payload.length)
    }

    setCodeStatus(CODE_STATUS.SUCCESS)
    reset({ code: '' })
  }

  const subscribe = async () => {
    setLoading(true)
    const response: any = await dispatch(addSubscription(window.location.href))
    if (response.payload) {
      window.location.href = (
        response.payload as { paymentUrl: string; status: string }
      ).paymentUrl
    }
    setLoading(false)
  }

  return (
    <AppSubscriptionEditor>
      <Wrapper>
        <div className="app-sub-header">
          <h2 className="app-sub-heading">Subskrypcja Dogzi</h2>
          <p className="app-sub-subheading">
            Opłaty zaczniemy naliczać po zakończeniu 30-dniowego darmowego
            okresu testowego.
          </p>
        </div>
        {renderStatus()}
        <div className="app-sub-subscription">
          <div className="app-sub-subscription-benefits">
            <p>Twój biznes, na Twoich zasadach.</p>
            <ul className="app-sub-subscription-benefits-list">
              <li>Rezerwacje online 24/7</li>
              <li>Zarządzanie kalendarzem wizyt</li>
              <li>Przypomnienia e-mail o wizytach</li>
              <li>Profil Twojej firmy</li>
              <li>Informacje o Twoich usługach</li>
              <li>Opinie Twoich klientów</li>
            </ul>
          </div>
          <div className="app-sub-subscription-action">
            <p className="app-sub-heading">Subskrypcja Dogzi</p>
            <p className="app-sub-subscription-price">
              <span>Miesięcznie netto</span>{' '}
              <em>
                {formatNumber(user.company.subscriptionPrice, {
                  style: 'currency',
                  currency: 'PLN',
                  currencyDisplay: 'narrowSymbol',
                })}{' '}
              </em>
            </p>
            <Button
              className="app-sub-subscription-buy"
              disabled={loading}
              loading={loading}
              onClick={subscribe}
            >
              Kup teraz
            </Button>
          </div>
        </div>
        <form onSubmit={handleSubmit(submitCode)}>
          <input
            type="text"
            placeholder="Dodaj kod rabatowy"
            {...register('code', { required: true })}
          />
          <Button
            loading={formState.isSubmitting}
            disabled={formState.isSubmitting}
          >
            Dodaj
          </Button>
        </form>
        {codeStatus === CODE_STATUS.SUCCESS ? (
          <p>
            Kod jest poprawny! Dodano ({successCodeDaysCount?.toFixed(0)} dni)
            dni
          </p>
        ) : null}
        {codeStatus === CODE_STATUS.ERROR && formState.errors.code ? (
          <p className="error">{formState.errors.code.message}</p>
        ) : null}
      </Wrapper>
    </AppSubscriptionEditor>
  )
}

export { AppSubscription }
