import { FC } from 'react'
import Loader from 'react-spinners/PuffLoader'

import theme from '../../theme'
import { SSectionLoader } from './SectionLoader.styled'

interface ISectionLoader {
  marginless?: boolean
}

const SectionLoader: FC<ISectionLoader> = ({ marginless }) => {
  return (
    <SSectionLoader {...{ marginless }}>
      <Loader color={theme.colors.primary} />
    </SSectionLoader>
  )
}

export default SectionLoader
