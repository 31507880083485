import React from 'react';

const TwitterIcon = () => (
  <svg viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 41C31.8218 41 41 31.8218 41 20.5C41 9.17822 31.8218 0 20.5 0C9.17822 0 0 9.17822 0 20.5C0 31.8218 9.17822 41 20.5 41ZM29.7024 17.1018C29.7024 16.9084 29.7024 16.717 29.6891 16.5256C30.5656 15.8948 31.3231 15.1138 31.9246 14.2173C31.1075 14.5781 30.24 14.8147 29.3521 14.9189C30.287 14.3621 30.9869 13.4858 31.3214 12.4543C30.4414 12.9731 29.4797 13.3394 28.4764 13.5361C26.7803 11.7424 23.9425 11.6558 22.1382 13.343C20.9742 14.4309 20.4811 16.0527 20.842 17.6003C17.2396 17.4211 13.8837 15.7288 11.6093 12.9451C10.4205 14.9814 11.0272 17.5862 12.9957 18.8948C12.2825 18.8735 11.5852 18.6821 10.9625 18.3372V18.3936C10.9625 20.5146 12.4662 22.3411 14.5562 22.7612C13.897 22.9402 13.2051 22.9668 12.5336 22.8379C13.1208 24.6528 14.8019 25.8962 16.7189 25.9324C15.1318 27.1721 13.1722 27.8452 11.155 27.8435C10.9198 27.8428 10.6851 27.8335 10.451 27.8149C10.3301 27.8054 10.2092 27.7935 10.0887 27.7791C12.1379 29.0867 14.5215 29.7803 16.9557 29.7769V29.7803C25.1959 29.7803 29.7024 22.9897 29.7024 17.1018Z"
      fill="#696F8C"
    />
  </svg>
);

export default TwitterIcon;
