import styled, { css } from 'styled-components';

export const SMarkerDetails = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 9px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.13);
    bottom: 10%;
    position: absolute;
    width: 243px;
    left: 30px;
    z-index: 1000;

    .wrapper {
      align-items: flex-start;
      display: flex;
      flex-flow: column;
      height: 100%;
      position: relative;
      padding: 13px 17px;
      width: 100%;
    }

    .name {
      color: ${theme.colors.black};
      font-weight: ${theme.weight.semibold};
      line-height: 25px;
      text-align: left;
      text-decoration: underline;
    }

    .address {
      color: ${theme.colors.text};
      font-size: 14px;
      line-height: 24px;
      text-align: left;
    }

    .close-button {
      align-items: center;
      background-color: ${theme.colors.white};
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
      border-radius: 50%;
      display: flex;
      height: 43px;
      justify-content: center;
      position: absolute;
      right: -16px;
      top: -16px;
      width: 43px;
    }
  `}
`;
