import React, { useEffect } from 'react';
import { Wrapper } from '../components';
import { useAppSelector, useAppDispatch } from '../Root';
import { getUsersDiscounts } from '../state/reducers/client/clientActions';
import ProgramSalon from '../components/ProfilePartnersProgram/ProgramSalon';
import { SProfilePartnersProgram } from '../components/ProfilePartnersProgram/ProfilePartnersProgram.styled';
import Pagination from '../components/Pagination/Pagination';
import EmptyState from '../components/EmptyState/EmptyState';
import { AppRoute } from '../const';

const ProfilePartnersProgram = () => {
  const dispatch = useAppDispatch();

  const { totalPages, items, loading } = useAppSelector(
    ({ client }) => client.discountedSalons
  );

  useEffect(() => {
    dispatch(getUsersDiscounts());
  }, []);

  return (
    <Wrapper>
      <SProfilePartnersProgram>
        {!loading &&
          items.length > 0 &&
          items.map((item) => <ProgramSalon key={item.name} {...item} />)}

        {!loading && items.length === 0 && (
          <EmptyState
            title="Brak rabatów w tym momencie"
            description="Skorzystaj z usług salonów objętych programem"
            button={{
              label: 'Szukaj salonów',
              href: AppRoute.List,
            }}
          />
        )}
      </SProfilePartnersProgram>
      {totalPages > 1 && (
        <Pagination
          page={1}
          lastPage={totalPages}
          prevPage={() => {}}
          setPage={() => {}}
          nextPage={() => {}}
        />
      )}
    </Wrapper>
  );
};

export { ProfilePartnersProgram };
