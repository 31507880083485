import { useEffect } from 'react'
import { Redirect } from 'react-router'

import config from 'const/config'
import { useAppDispatch } from 'Root'
import { logoutClient } from 'state/reducers/client/clientActions'

const Logout = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    localStorage.removeItem(config.TOKEN_CLIENT_KEY)
    dispatch(logoutClient())
  }, [])

  return <Redirect to="/" />
}

export { Logout }
