import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import config from 'const/config';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import { AppRoute } from 'const';
import { getUser } from 'state/reducers/user/userActions';

const AppAdmin = () => {
  const history = useHistory();
  const location = useLocation<{ token: string }>();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const { localizeRouteKey } = useLocalizeRoute();

  useEffect(() => {
    const checker = async () => {
      localStorage.removeItem(config.TOKEN_KEY);
      const token = queryParams.get('token');
      if (token) {
        localStorage.setItem(config.TOKEN_KEY, token);
        await dispatch(getUser());
        history.push(localizeRouteKey(AppRoute.AppProfile));
      } else {
        history.push(localizeRouteKey(AppRoute.Home));
      }
    };

    checker();
  }, []);

  return null;
};

export { AppAdmin };
