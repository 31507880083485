import React from 'react';
import { Info } from 'luxon';
import { OpeningHour } from '../../../const';

type IOpeningHoursDayProps = OpeningHour;

const OpeningHoursDay = ({
  dayOfWeek,
  timeEnd,
  timeStart,
}: IOpeningHoursDayProps) => {
  return (
    <li>
      <span>
        {Info.weekdays('long', { locale: 'pl' })[parseInt(dayOfWeek) - 1]}
      </span>
      <span>
        {timeStart} - {timeEnd}
      </span>
    </li>
  );
};

export default OpeningHoursDay;
