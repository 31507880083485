import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export default styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.space.xl}px;

    .slots {
      display: flex;

      flex-wrap: wrap;

      margin-top: ${theme.space.xl}px;

      .slot {
        align-items: center;
        background-color: ${theme.colors.primary};
        border-radius: 8px;
        display: flex;
        column-gap: 4px;
        margin-right: ${theme.space.md}px;
        margin-bottom: ${theme.space.md}px;
        padding: 4px 8px;
      }

      .slot-avatar {
        height: 24px;
        width: 24px;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    .categories {
      margin-top: ${theme.space.xxxl}px;
    }

    ${media.md`
      margin-bottom: ${theme.space.xxxl}px;
    `}

    ${media.lg`
      max-width: calc(100% - 401px);
    `}
  `}
`;
