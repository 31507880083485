import React, { useState } from 'react';
import { SBenefits } from './Benefits.styled';
import { Wrapper } from '../index';
import { useIntl } from 'react-intl';
import Benefit from '../Benefit/Benefit';
import SwiperCore, { Controller, Pagination, Swiper } from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import SliderPagination from '../SliderPagination/SliderPagination';

const BENEFITS = [
  {
    icon: '',
    name: 'Niezwykle wygodne umawianie wizyt',
    description:
      'Korzystaj z komputera lub telefonu i wybieraj dogodne terminy.',
  },
  {
    icon: '',
    name: `Specjaliści<br />w zasięgu ręki`,
    description:
      'Szukaj spośród specjalistów w Twojej okolicy. Wubierz tych, do których dotarcie dla Ciebie i Twojego pupila będzie najwygodniejsze!',
  },
  {
    icon: '',
    name: `Wizyty<br />pod kontrolą`,
    description:
      'Dzięki korzystaniu z Dogzi, będzisz mieć dostęp do wszystkich umówionych wizyt, a dzięki powiadomieniom, nigdy nie zapomnisz o żadnej z nich.',
  },
  {
    icon: '',
    name: `Ocena<br />wizyt`,
    description:
      'Oceniaj wizyty, dzięki czemu dasz sygnał innym użytkownikom, jak sprawdza się dany specjalista.',
  },
  {
    icon: '',
    name: 'Wyłącznie sprawdzeni specjaliści',
    description:
      'Wiemy, jak ważny jest dla Ciebie Twój zwierzak, dlatego w Dogzi nie ma miejsca na przypadek. Każdy Specjalista przechofzi u nas proces weryfikacji.',
  },
  {
    icon: '',
    name: 'Oszczędność czasu i pieniędzy',
    description:
      'Korzystaj z Dogzi nie tylko szybko, ale i całkowicie za darmo!',
  },
];

SwiperCore.use([Controller, Pagination]);

const Benefits = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { formatMessage } = useIntl();

  const [swiper, setSwiper] = useState<Swiper>();

  const initializeSwiper = (swiperInstance: Swiper) => {
    setSwiper(swiperInstance);
  };

  return (
    <SBenefits>
      <Wrapper>
        <section className="wrapper">
          <h2 className="header">
            {formatMessage({ id: 'general.benefits.header' })}
          </h2>
          <p className="subheader">
            {formatMessage({ id: 'general.benefits.subheader' })}
          </p>

          <ul className="list">
            {BENEFITS.map((benefit) => (
              <Benefit key={benefit.name} {...benefit} />
            ))}
          </ul>

          <SwiperComponent
            onSwiper={initializeSwiper}
            onSlideChange={({ activeIndex }: Swiper) => {
              setCurrentSlide(activeIndex);
            }}
            controller={{ control: swiper }}
            slidesPerView={1}
            spaceBetween={49}
          >
            {BENEFITS.map((benefit) => (
              <SwiperSlide key={benefit.name}>
                <Benefit {...benefit} />
              </SwiperSlide>
            ))}
          </SwiperComponent>

          <div className="benefits-pagination">
            <SliderPagination
              slides={BENEFITS}
              currentSlide={currentSlide}
              onClick={(index) => swiper?.slideTo(index)}
            />
          </div>

          <div className="divider" />
        </section>
      </Wrapper>
    </SBenefits>
  );
};

export default Benefits;
