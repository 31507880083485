import React, { FC, useState } from 'react';
import { AppRoute, Salon, SalonService } from 'const';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import { useAppDispatch, useAppSelector } from 'Root';
import { setSingleSalon } from 'state/reducers/salons/salonsActions';
import {
  addServiceToReservation,
  openReservationsModal,
} from 'state/reducers/reservation/reservationActions';
import { parseCategories } from '../../const/filter-categories';

import { SListingItem } from './ListingItem.styled';
import Tag from './Tag';
import Service from './Service';
import { getTagColor } from '../../utils/getTagColor';
import ImageWrapper from './ImageWrapper';
import PartnerProgramIcon from '../icons/PartnerProgramIcon';

interface ListingItemProps extends Salon {
  showImage: boolean;
}

const ListingItem: FC<ListingItemProps> = (props) => {
  const [servicesRange, setServicesRange] = useState({ from: 0, to: 3 });
  const [expandDescription, setExpandDescription] = useState(false);
  const dispatch = useAppDispatch();

  const { reservations } = useAppSelector((state) => state.reservation);

  const avatar = props.images?.[0]?.path ?? null;
  const { formatNumber } = useIntl();
  const { localizeRouteKey } = useLocalizeRoute();

  const selectSalon = () => {
    dispatch(setSingleSalon({ base: props }));
  };

  const toggleDescription = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    setExpandDescription((prevState) => !prevState);
  };

  const addService = (event: MouseEvent, service: SalonService) => {
    event.preventDefault();
    event.stopPropagation();

    if (reservations.length === 1) {
      dispatch(openReservationsModal());
      return;
    }

    dispatch(
      addServiceToReservation({ salonId: props.id, service, slotId: 0 })
    );
  };

  const services = props.services;

  const visibleServices =
    services?.slice(servicesRange.from, servicesRange.to) || [];

  const reviewsValue = formatNumber(props.reviewsSummary?.total ?? 0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).replace(',', '.');

  const renderDescription = () => {
    if (!props.description) return '';

    if (expandDescription) {
      return props.description || '';
    }
    if (props.description?.length > 150) {
      return `${(props.description || '')?.slice(0, 150)}...`;
    }
    return props.description;
  };

  return (
    <SListingItem showImage={props.showImage}>
      {props.showImage && (
        <ImageWrapper
          id={props.id}
          onClick={selectSalon}
          reviewsValue={reviewsValue}
          avatar={avatar}
          name={props.name}
        />
      )}

      <div className="listing-item__content">
        <div className="listing-item__info">
          <Link
            onClick={selectSalon}
            to={localizeRouteKey(AppRoute.Salon).replace(
              ':id',
              String(props.id)
            )}
          >
            <div className="listing-item__heading">
              <ul className="listing-item__tags">
                {props.categories.map((category) => (
                  <Tag
                    key={category.id}
                    label={category.name}
                    colors={getTagColor(category.name)}
                  />
                ))}
              </ul>

              <h3 className="listing-item__name">{props.name}</h3>
            </div>

            <p className="listing-item__address">
              {props.city?.name}, {props.street} {props.buildingNumber}
            </p>

            <div className="listing-item__mobile-tags">
              <p className="listing-item__mobile-tags-label">Usługi:</p>
              <ul className="listing-item__mobile-tags-list">
                {parseCategories(props.categories).map(({ id, name }) => (
                  <Tag key={id} label={name} colors={getTagColor(name)} />
                ))}
              </ul>
            </div>

            {props.description && (
              <p className="listing-item__description">
                {renderDescription()}{' '}
                {props.description.length >= 150 && (
                  <button
                    onClick={toggleDescription}
                    className="listing-item__toggle-description"
                  >
                    {expandDescription ? 'Czytaj mniej' : 'Czytaj więcej'}
                  </button>
                )}
              </p>
            )}
          </Link>
        </div>

        {visibleServices.length > 0 && (
          <ul className="listing-item__services">
            {visibleServices.map((service, index) => (
              <Service
                key={service.id}
                onReserve={addService}
                {...service}
                isLast={index + 1 === visibleServices.length}
              />
            ))}
          </ul>
        )}

        {services &&
          visibleServices.length < services.length &&
          services.length > 3 && (
            <button
              onClick={() =>
                setServicesRange((prevState) => ({
                  ...prevState,
                  to: prevState.to + 3,
                }))
              }
              className="listing-item__services-show-more"
            >
              Zobacz więcej
            </button>
          )}
      </div>
    </SListingItem>
  );
};

export default ListingItem;
