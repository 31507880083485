import React, { useState, useEffect } from 'react';
import Pusher from 'pusher-js';
import { toast } from 'react-toastify';
import { useAppSelector } from '../Root';
import { useLocation } from 'react-router-dom';

export const useRealtimeNotifications = () => {
  const [pusher, setPusher] = useState<any>(null);
  const location = useLocation();

  const salonId = useAppSelector(({ user }) => user.user?.company.id);

  useEffect(() => {
    setPusher(
      new Pusher(process.env.REACT_APP_PUSHER_APP_KEY as string, {
        cluster: 'eu',
      })
    );
  }, []);

  const channelName = `salon${salonId}`;
  const event = 'appointment';

  const newCalendarEvent = (callback: () => void) => {
    const channel = pusher.subscribe(channelName);

    channel.bind(event, (data: any) => {
      if (
        data.appointment.author === 'salon' ||
        location.pathname !== '/pl/app/hotel'
      ) {
        return;
      }

      toast('Pojawiła się nowa rezerwacja', { toastId: data.appointment.id });
      callback();
    });
  };

  const unsubscribe = () => {
    pusher.unsubscribe(channelName);
    pusher.unbind(event);
  };

  return {
    pusher,
    newCalendarEvent,
    unsubscribe,
  };
};
