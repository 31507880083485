import { FC } from 'react'

const CalendarIcon: FC = (props) => {
  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.584 5a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zM1.667 6.25A2.917 2.917 0 117.15 7.637l1.529 1.53h4.166l.245.243 4.88 4.88a1.25 1.25 0 01-.643 2.111l-.952.187a4.208 4.208 0 01-3.786-1.154l-2.345-2.345-4.273-4.273A2.917 2.917 0 011.667 6.25zm14.388 8.702a2.542 2.542 0 01-2.287-.697l-3.423-3.422h1.81l4.083 4.083-.183.036z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.605 4.548a4.167 4.167 0 013.748-1.142l.974.191a1.25 1.25 0 01.643 2.11l-4.88 4.88-.245.244H8.68l-1.53 1.53a2.917 2.917 0 11-1.179-1.179l.351-.35 1.422-1.423 4.861-4.86zm-2.259 4.617l1.077-1.077 2.36-2.361a2.5 2.5 0 012.25-.685l.205.04-4.083 4.083h-1.809zm-5.762 3.334a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CalendarIcon
