import React from 'react';
import { UserModal } from '../index';
import { SUserModalOverlay } from '../UserModal/UserModal.styled';
import { SShareModal } from './ShareModal.styled';
import LinkedInIcon from '../Footer/LinkedInIcon';
import FacebookIcon from '../Footer/FacebookIcon';
import TwitterIcon from '../Footer/TwitterIcon';

interface ShareModalProps {
  onClose: () => void;
  salonId: number;
}

const ShareModal = ({ onClose, salonId }: ShareModalProps) => {
  const salonUrl = `https://dogzi.pl/pl/salon/${salonId}`;

  return (
    <UserModal wide>
      <SUserModalOverlay onClick={onClose} />
      <SShareModal>
        <div className="header">
          <h2 className="title">Udostepnij salon</h2>
          <button className="close" type="button" onClick={onClose}>
            <span className="material-icons">close</span>
          </button>
        </div>

        <ul className="social-medias">
          <li>
            <a
              className="media"
              href={`https://facebook.com/sharer/sharer.php?u=${salonUrl}`}
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
          </li>

          <li>
            <a
              className="media"
              href={`https://twitter.com/intent/tweet?text=${salonUrl}`}
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
          </li>
          <li>
            <a
              className="media"
              href={`https://linkedin.com/cws/share?url=${salonUrl}`}
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon />
            </a>
          </li>
        </ul>
      </SShareModal>
    </UserModal>
  );
};

export default ShareModal;
