import React from 'react';

interface LogoWhiteProps {
  size: number;
}

const LogoWhite = ({ size }: LogoWhiteProps) => (
  <svg
    height={size}
    viewBox="0 0 162 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M78.3623 13.039C76.0974 13.039 72.5828 13.039 72.5828 13.039H62V13.3123C63.8354 13.3123 65.9051 14.8348 65.9051 16.8649V38.3754C65.9051 40.4444 63.8354 41.9279 62 41.9279V42.2402H77.5423C86.2116 42.2402 92.1083 36.5015 92.1083 27.6787C92.1083 18.8949 85.1181 13.039 78.3623 13.039ZM76.4098 41.8889H74.5353C73.3638 41.8499 72.5437 40.4835 72.5047 38.4144V13.5075C73.3638 13.5075 74.4572 13.4685 75.5897 13.4685C79.6901 13.4685 84.8448 16.3183 84.8448 27.6006C84.8448 39.1171 80.9787 41.8889 76.4098 41.8889Z"
      fill="white"
    />
    <path
      d="M102.08 24.0871C96.6523 24.0871 91.9271 28.1081 91.9271 33.4955C91.9271 38.9219 96.6523 42.9039 102.08 42.9039C107.469 42.9039 112.234 38.9219 112.234 33.4955C112.234 28.1081 107.469 24.0871 102.08 24.0871ZM102.08 42.5135C98.5658 42.5135 97.7067 38.4144 97.7067 33.4955C97.7067 28.5766 98.5658 24.4775 102.08 24.4775C105.595 24.4775 106.532 28.5766 106.532 33.4955C106.532 38.4144 105.595 42.5135 102.08 42.5135Z"
      fill="white"
    />
    <path
      d="M126.72 41.03H120.238H117.777C115.669 41.03 114.341 40.1321 114.341 38.4535C114.341 37.5556 114.614 36.8919 115.083 36.3063C115.942 37.0871 117.035 37.6727 118.207 38.024L119.652 38.3363C120.003 38.3754 120.394 38.3754 120.745 38.3754C124.533 38.3754 128.595 35.955 128.595 31.4264C128.595 27.7958 126.017 25.5315 123.049 24.7508C123.752 24.1652 124.455 23.7748 125.47 23.7748C126.915 23.7748 127.774 24.7508 127.97 26.3514L129.102 26.2342C131.992 25.961 131.875 21.6276 128.946 21.5886C128.946 21.5886 128.907 21.5886 128.868 21.5886C126.72 21.5886 123.986 23.5015 122.503 24.5946C122.034 24.5165 121.565 24.4775 121.097 24.4384H120.667C116.918 24.4775 112.935 26.9369 112.935 31.4264C112.935 33.3003 113.638 34.8619 114.77 35.994C113.677 37.5165 112.154 39.9369 112.154 41.8498C112.154 44.5045 114.107 46.3784 116.372 46.4565H122.854H125.314C127.423 46.4565 128.751 47.3544 128.751 49.033C128.751 49.8138 128.516 50.4384 128.165 50.985C128.048 51.1411 127.97 51.2583 127.853 51.4144L127.618 51.6877C127.579 51.7267 127.579 51.7658 127.579 51.8048C127.579 51.9219 127.657 52 127.774 52C127.853 52 127.931 52 127.97 51.9219C128.126 51.7267 128.282 51.5315 128.438 51.2973C128.516 51.1802 128.595 51.1021 128.673 50.985C128.868 50.7117 129.063 50.3994 129.258 50.0871C129.336 49.9309 129.454 49.8138 129.532 49.6577C130.274 48.3694 130.938 46.8859 130.938 45.6366C130.938 42.982 128.985 41.1081 126.72 41.03ZM120.745 24.985C122.62 24.985 123.44 27.4835 123.44 31.3483C123.44 35.2132 122.62 37.7508 120.745 37.7508C118.91 37.7508 118.129 35.2132 118.129 31.3483C118.129 27.4835 118.91 24.985 120.745 24.985Z"
      fill="white"
    />
    <path
      d="M148.635 33.4565C148.518 33.4565 148.479 33.4955 148.479 33.4955C147.385 41.3814 141.762 41.967 137.037 41.967L148.127 24.985V24.6336H132.78L130.867 31.973C130.867 31.973 130.867 32.012 130.984 32.012C131.062 32.012 131.101 31.973 131.101 31.973C132.936 24.985 137.271 25.024 141.254 25.024L130.437 41.3423C130.164 41.7718 130.476 42.3574 130.984 42.3574H147.229L148.752 33.4955C148.752 33.4955 148.713 33.4565 148.635 33.4565Z"
      fill="white"
    />
    <path
      d="M153.721 19.6757C155.557 19.6757 157.041 18.1922 157.041 16.3574C157.041 14.5225 155.557 13 153.721 13C151.886 13 150.363 14.5225 150.363 16.3574C150.363 18.1922 151.886 19.6757 153.721 19.6757ZM162 39.6246C162 39.5075 161.922 39.4294 161.805 39.4294C161.727 39.4294 161.688 39.4294 161.649 39.4685C160.828 40.1712 160.165 40.6396 158.954 40.6396C157.392 40.6396 156.533 39.4685 156.416 37.5946V24.048H156.142C156.142 24.048 151.925 26.0781 148.293 26.5075V26.8198C150.285 26.8198 150.87 28.1471 150.949 29.3964V38.6096C151.027 40.8739 152.901 42.8258 155.557 42.8258C157.704 42.8258 160.477 40.9129 161.922 39.8198C161.961 39.7808 162 39.7027 162 39.6246Z"
      fill="white"
    />
    <g clipPath="url(#clip0_1855_489)">
      <path
        d="M36.809 18.8624C36.449 23.7869 38.8729 27.982 42.2348 28.2344C45.5967 28.4868 48.6055 24.6944 48.9655 19.77C49.3256 14.8456 46.9016 10.6504 43.5397 10.4004C40.1778 10.1503 37.1713 13.938 36.809 18.8624Z"
        fill="white"
      />
      <path
        d="M20.8321 10.4746C21.41 15.8343 24.8981 19.8466 28.6292 19.4368C32.3603 19.027 34.9013 14.3433 34.3234 8.99056C33.7455 3.63781 30.2574 -0.379072 26.5263 0.0307191C22.7952 0.44051 20.2519 5.11491 20.8321 10.4746Z"
        fill="white"
      />
      <path
        d="M6.68049 19.3004C9.41405 23.6483 14.1749 25.5399 17.3166 23.528C20.4584 21.516 20.7955 16.3578 18.0436 12.0121C15.2917 7.66649 10.5469 5.77265 7.40745 7.78457C4.26798 9.79648 3.94693 14.9548 6.68049 19.3004Z"
        fill="white"
      />
      <path
        d="M3.43266 36.9454C6.92529 40.1867 11.5989 40.7794 13.8693 38.2813C16.1396 35.7832 15.1489 31.1365 11.6563 27.8976C8.16366 24.6586 3.4923 24.0636 1.21968 26.5617C-1.05294 29.0598 -0.0599661 33.7087 3.43266 36.9454Z"
        fill="white"
      />
      <path
        d="M34.9608 46.4155C34.9608 46.4155 53.598 48.3278 46.5279 36.2957C40.8728 26.6737 28.1291 25.6249 23.2353 28.8361C18.6007 31.876 10.5445 49.1405 17.0986 53.419C25.6111 58.9824 24.2948 48.2306 34.9608 46.4155Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1855_489">
        <rect
          width="49"
          height="55"
          fill="white"
          transform="matrix(-1 0 0 1 49 0)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LogoWhite;
