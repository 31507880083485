import React, { FC, useState } from 'react';
import SwiperCore, { Controller, Pagination, Swiper, Navigation } from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import { useIntl } from 'react-intl';

import { SalonTile } from 'components';
import { SPromoted } from './Promoted.styled';

import { useAppSelector } from 'Root';
import { Salon } from 'const';
import SliderArrow from '../SliderArrow/SliderArrow';
import { useBreakpoint } from '../../modules/breakpoint';
import SliderPagination from '../SliderPagination/SliderPagination';

SwiperCore.use([Controller, Pagination, Navigation]);

const Promoted: FC = () => {
  const { formatMessage } = useIntl();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [swiper, setSwiper] = useState<Swiper>();

  const initializeSwiper = (swiperInstance: Swiper) => {
    setSwiper(swiperInstance);
  };

  const { promoted } = useAppSelector(({ app }) => app.homepage);

  const updateSlide = (direction: 'left' | 'right') => {
    if (direction === 'left') swiper?.slidePrev();
    if (direction === 'right') swiper?.slideNext();
  };

  return (
    <SPromoted>
      <div className="content">
        <div className="heading-wrapper">
          <h2 className="heading">
            {formatMessage({ id: 'app.promoted.heading' })}
          </h2>

          <div className="mobile-buttons">
            <SliderArrow direction="left" onClick={updateSlide} />
            <SliderArrow direction="right" onClick={updateSlide} />
          </div>
        </div>

        <div className="slider-wrapper">
          <div className="slider">
            <SwiperComponent
              onSwiper={initializeSwiper}
              onSlideChange={({ realIndex }: Swiper) => {
                setCurrentSlide(realIndex);
              }}
              controller={{ control: swiper }}
              slidesPerView={1}
              breakpoints={{
                700: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              spaceBetween={10}
            >
              {promoted.map(({ id, images, name, city, street }: Salon) => (
                <SwiperSlide key={`promoted-salon-${id}`}>
                  <SalonTile
                    className="slide"
                    id={id}
                    image={images[0]?.path ?? null}
                    name={name}
                    address={`${city?.name ?? ''}, ${street}`}
                  />
                </SwiperSlide>
              ))}
            </SwiperComponent>

            <div className="pagination-wrapper">
              <SliderPagination
                slides={promoted}
                currentSlide={currentSlide}
                onClick={(index) => swiper?.slideTo(index)}
              />
            </div>
          </div>
        </div>
      </div>
    </SPromoted>
  );
};

export default Promoted;
