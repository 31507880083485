import { ChangeEvent, FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import theme from 'theme';

import { SAppReservationModal } from './AppReservationModal.styled';
import DatePickerInput from 'components/DatePickerInput/DatePickerInput';
import Cleave from 'cleave.js/react';
import { Button } from 'components';
import { AppointmentCreator, Service } from 'const';
import autoFormatTimeInput from 'utils/autoFormatTimeInput';
import { addMinutes } from 'date-fns';
import app from '../../App';

interface IAppReservationModal {
  appointment: AppointmentCreator | null;
  appointmentServiceOptions?: any;
  clear: () => void;
  deleteAppointment: () => void;
  onSubmit: (v: AppointmentCreator) => void;
  deleteLoading: boolean;
}

const defaultValues = {
  email: '',
  phone: '',
  additionalNote: '',
  date: new Date(),
  service: null,
  timeStart: '',
  timeEnd: '',
};

const parseAppointmentFormData = (
  appointment: AppointmentCreator | null,
  options: any
) => {
  if (!appointment) return defaultValues;

  let service;

  if (appointment.appointmentKind === 'time_block') {
    service = {
      id: 0,
      name: 'Rezerwacja czasu',
    };
  }

  if (appointment.service?.appointmentType === 'range') {
    service = options.find(
      (option: Service) => option.id === appointment?.service?.id
    );
  }

  if (service?.length) {
    return {
      ...appointment,
      service: service[0],
    };
  }

  return {
    ...appointment,
    service,
  };
};

const AppReservationModal: FC<IAppReservationModal> = ({
  appointment,
  appointmentServiceOptions,
  clear,
  deleteAppointment,
  onSubmit,
  deleteLoading,
}) => {
  const isHotelReservation = appointment?.appointmentType === 'range';

  const { register, control, handleSubmit, reset, formState, watch, setValue } =
    useForm<AppointmentCreator>({
      defaultValues: {
        ...defaultValues,
        email: 'test',
        service: appointment?.service || null,
      },
    });

  const service = watch('service');
  const date = watch('date') as Date;

  const setReservationEndTime = () => {
    if (!service) return;
    const pickedDateTimestamp = new Date(date).getTime();
    const duration = addMinutes(
      new Date(pickedDateTimestamp),
      service?.duration as number
    );

    const hour = duration.getHours();
    const hourFormat = hour > 9 ? hour : `0${hour}`;
    const minute = duration.getMinutes();
    const minuteFormat = minute > 9 ? minute : `0${minute}`;

    if (isNaN(hour) || isNaN(minute)) return;

    setValue('timeEnd', `${hourFormat}:${minuteFormat}`);
  };

  const handleDurationInput = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    return { target: { value: autoFormatTimeInput(value) } };
  };

  useEffect(() => {
    setReservationEndTime();
  }, [service]);

  useEffect(() => {
    appointmentServiceOptions
      ? reset(parseAppointmentFormData(appointment, appointmentServiceOptions))
      : reset({ ...appointment });
  }, [appointment]);

  const isTimeReservation = service?.name === 'Rezerwacja czasu';

  return (
    <SAppReservationModal hotel={isHotelReservation}>
      <button type="button" className="close" onClick={clear}>
        <span className="material-icons">close</span>
      </button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          placeholder="Nazwa rezerwacji"
          className="name input"
          {...register('additionalNote', { required: true })}
        />

        {appointmentServiceOptions && (
          <Controller
            control={control}
            name="service"
            render={({ field: { onChange, onBlur, ref, value } }) => (
              <Select
                ref={ref}
                placeholder="Wybierz usługę"
                styles={{
                  control: (styles) => ({
                    ...styles,
                    height: '48px',
                    minHeight: '48px',
                    borderColor: '#EDE8E9',
                    boxShadow: 'none',
                    borderWidth: 1,
                    borderRadius: 8,
                    fontSize: '1rem',
                    '&:hover': {
                      borderColor: '#EDE8E9',
                    },
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    fontWeight: theme.weight.bold,
                    lineHeight: 16,
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    paddingLeft: theme.space.md,
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    fontSize: 14,
                    fontWeight: theme.weight.bold,
                    marginLeft: 0,
                  }),
                  input: (styles) => ({ ...styles, margin: 0 }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  dropdownIndicator: (styles) => ({
                    ...styles,
                    color: '#3A3335',
                  }),
                }}
                options={[
                  { id: 0, name: 'Rezerwacja czasu' },
                  ...appointmentServiceOptions,
                ]}
                onChange={(data: any) => {
                  onChange(data);
                }}
                onBlur={onBlur}
                value={value}
                className="service"
                getOptionValue={(o) => String(o.id)}
                getOptionLabel={(o) => o.name}
              />
            )}
          />
        )}

        <input
          type="email"
          placeholder="Email klienta"
          disabled={service?.name === 'Rezerwacja czasu'}
          className="email input"
          {...register('email', { required: false })}
        />

        <input
          type="tel"
          pattern="[0-9]{3}[0-9]{3}[0-9]{3}"
          placeholder="Telefon klienta"
          disabled={isTimeReservation}
          className="phone input"
          {...register('phone', { required: !isTimeReservation })}
        />

        <div className="duration">
          <span className="date">
            <Controller
              control={control}
              name="date"
              render={({ field: { value, ref, onChange } }) => (
                <DatePicker
                  ref={ref}
                  selected={value}
                  onChange={onChange}
                  locale="pl"
                  customInput={<DatePickerInput />}
                />
              )}
            />
          </span>

          {!isHotelReservation && (
            <span className="timeStart">
              <Controller
                control={control}
                name="timeStart"
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, ref, value } }) => (
                  <Cleave
                    ref={ref}
                    placeholder="Od"
                    options={{
                      time: true,
                      timePattern: ['h', 'm'],
                    }}
                    className="input"
                    value={value === '' ? undefined : value}
                    onBlur={(e) => {
                      onChange(handleDurationInput(e));
                      onBlur();
                    }}
                  />
                )}
              />
            </span>
          )}

          {!isHotelReservation ? (
            <span className="timeEnd">
              <Controller
                control={control}
                name="timeEnd"
                render={({ field: { onChange, onBlur, ref, value } }) => (
                  <Cleave
                    ref={ref}
                    placeholder="Do"
                    options={{
                      time: true,
                      timePattern: ['h', 'm'],
                    }}
                    className="input"
                    value={value === '' ? undefined : value}
                    onBlur={(e) => {
                      onChange(handleDurationInput(e));
                      onBlur();
                    }}
                  />
                )}
              />
            </span>
          ) : (
            <span className="timeEnd">
              <Controller
                control={control}
                name="timeEnd"
                render={({ field: { onChange, onBlur, ref, value } }) => {
                  return (
                    <Cleave
                      ref={ref}
                      placeholder="Ilość dni"
                      options={{
                        numeral: true,
                        numeralPositiveOnly: true,
                      }}
                      className="input"
                      value={value === '0' ? undefined : value}
                      onBlur={(e) => {
                        onChange(e);
                        onBlur();
                      }}
                    />
                  );
                }}
              />
            </span>
          )}
        </div>
        <div className="actions">
          {appointment?.id ? (
            <>
              <Button
                type="button"
                bordered
                className="button"
                disabled={deleteLoading || formState.isSubmitting}
                loading={deleteLoading}
                onClick={deleteAppointment}
              >
                Usuń
              </Button>
              <Button
                loading={formState.isSubmitting}
                disabled={deleteLoading || formState.isSubmitting}
                className="button"
              >
                Zmień
              </Button>
            </>
          ) : (
            <>
              <Button
                loading={formState.isSubmitting}
                disabled={formState.isSubmitting}
                className="button"
                type="submit"
              >
                Dodaj
              </Button>
            </>
          )}
        </div>
      </form>
    </SAppReservationModal>
  );
};

export default AppReservationModal;
