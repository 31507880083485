import React from 'react';
import { Link } from 'react-router-dom';
import useLocalizeRoute from '../../utils/useLocalizeRoute';
import { AppRoute } from '../../const';
import { useAppDispatch, useAppSelector } from '../../Root';
import {
  setSalonsSearch,
  setSalonsAllFilters,
} from '../../state/reducers/salons/salonsActions';
import { locations } from '../../const/predefined-cities';

interface SitemapColumnProps {
  title: string;
  links: { service: string; city: string }[];
}

const SitemapColumn = ({ title, links }: SitemapColumnProps) => {
  const { localizeRouteKey } = useLocalizeRoute();
  const dispatch = useAppDispatch();
  const { categories } = useAppSelector(({ app }) => app);

  const redirectToCategory = (service: string, city: string) => {
    const pickedCategory = categories.find(
      (item) => item.name.trim() === service
    );

    const category =
      [{ value: pickedCategory?.id, label: pickedCategory?.name }] || [];

    const pickedLocation = locations.find((item) => item.name === city);

    const location =
      { value: pickedLocation?.id, label: pickedLocation?.name } || null;

    dispatch(
      setSalonsSearch({
        location,
        service: null,
        categories: [],
      })
    );

    dispatch(setSalonsAllFilters(category));
  };

  return (
    <li className="column-wrapper">
      <p className="column-title">{title}</p>
      <ul className="column-links">
        {links.map((link, index) => (
          <Link
            key={index}
            to={localizeRouteKey(AppRoute.List).replace(':page?', '1')}
            onClick={() => redirectToCategory(link.service, link.city)}
          >
            <li className="column-link">
              {link.service} {link.city}
            </li>
          </Link>
        ))}
      </ul>
    </li>
  );
};

export default SitemapColumn;
