import React from 'react';
import { SSliderPagination } from './SliderPagination.styled';
import classNames from 'classnames';

interface SliderPaginationProps {
  slides: any[];
  currentSlide: number;
  onClick: (index: number) => void;
}

const SliderPagination = ({
  slides,
  currentSlide,
  onClick,
}: SliderPaginationProps) => (
  <SSliderPagination>
    <ul className="swiper-pagination">
      {slides.map((_, index) => (
        <li
          key={index}
          role="button"
          onClick={() => onClick(index)}
          className={classNames('swiper-pagination-bullet', {
            'swiper-pagination-bullet--clickable': currentSlide !== index,
          })}
        >
          {currentSlide === index && (
            <span className="swiper-pagination-bullet--active" />
          )}
        </li>
      ))}
    </ul>
  </SSliderPagination>
);

export default SliderPagination;
