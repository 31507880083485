const baseStrings = {
  /** Routes */
  'routes.home': '/',

  'routes.list': '/list/:page?',
  'routes.salon': '/salon/:id',

  'routes.profile': '/profile',
  'routes.regulations': '/regulations',
  'routes.search': '/search/:localization/:category/:page?',

  'routes.profile-password': '/profile/password',
  'routes.profile-favorite': '/profile/favorite',
  'routes.profile-reviews': '/profile/reviews',
  'routes.profile-settings': '/profile/settings',
  'routes.profile-partners': '/profile/partners-program',
  'routes.logout': '/profile/logout',
  'routes.cancel-reservation': '/cancelAppointment/:reservationId',
  'routes.confirm-reservation': '/confirmAppointment/:appointmentId',
  'routes.accept-offer': '/acceptOffer/:id',

  'routes.app': '/app',
  'routes.app-profile': '/app/profile',
  'routes.app-reservations': '/app/reservations',
  'routes.app-hotel-reservations': '/app/hotel',
  'routes.app-workplace': '/app/workplace',
  'routes.app-subscription': '/app/subscription',
  'routes.app-partners-program': '/app/partners-program',

  'routes.app-auth-login': '/app/login',
  'routes.app-auth-register': '/app/register',
  'routes.app-auth-register-success': '/app/register-success',
  'routes.app-auth-register-profile': '/app/register-profile',
  'routes.app-auth-forgot-password': '/app/password-forgot',
  'routes.app-auth-reset-password': '/app/password-reset',

  'routes.app-admin': '/admin',

  'routes.app-logout': '/app/logout',

  // APP AUTH
  'app-auth.login-heading': 'Witaj ponownie',
  'app-auth.login-description':
    'Zaloguj się na swoim koncie i sprawdź rezerwacje.',
  'app-auth.login-register': 'Nie masz konta? <a>Załóż je teraz!</a>',

  'app-auth.login-email': 'Email',
  'app-auth.login-password': 'Hasło',

  'app-auth.registration-heading': 'Przedsiębiorco, załap się na okres próbny!',
  'app-auth.registration-description':
    'Załóż konto i wypełnij dane. Zdobądź najlepszych futrzastych klientów.',
  'app-auth.registration-information':
    'Rejestrując się, zgadzasz się na warunki korzystania z serwisu oraz polityka prywatności',
  'app-auth.registration-createaccount': 'Stwórz konto',

  'app-auth.registration-background-heading':
    'Dla tych, którzy lubią nawet wyszczekanych klientów!',
  'app-auth.registration-background-description':
    'Zarejestruj swój biznes na najlepszym portalu dla zwierząt. Właściciele psów i kotów z łatwością znajdą Twoją usługę i zarezerwują ją w prostym kalendarzu.',

  'app-auth.registration-success-heading': 'GOTOWE!',
  'app-auth.registration-success-description':
    'Wypełnij kilka informacji, aby można było rezerwować u Ciebie wizyty.',

  'app-auth.registration-success-background-heading':
    'Do rezerwacji jeden krok…',
  'app-auth.registration-success-background-description':
    'A nikt nie robi bardziej radosnych kroków niż zwierzaki. Już bardzo mało dzieli Cię od zdobycia nowych czworonożnych klientów.',

  'app-auth.registration-profile-heading': 'Uzupełnij dane firmy',
  'app-auth.registration-profile-description':
    'Już wiemy, jak się wabisz ;). Pora na końcowe informacje i możemy zaczynać!',

  'app-auth.registration-profile-background-heading':
    'Pierwsze koty za płoty! Zacznijmy współpracę.',
  'app-auth.registration-profile-background-description':
    'Mamy słabość do zwierzaków. Jeśli zapewniasz im dobrą opiekę i usługi, doskonale trafiłeś.',

  'app-auth.forgot-password-heading': 'Odzyskaj hasło',
  'app-auth.forgot-password-description':
    'Zapomniałeś hasła? Zdarza się najlepszym. Wprowadź dane, aby odzyskać swoje konto.',

  'app-auth.forgot-password-background-heading':
    'Z nami bezpiecznie jak na smyczy!',
  'app-auth.forgot-password-background-description':
    'Już niedługo odzyskasz swoje hasło i będziesz mógł dalej sprawdzać wizyty zarezerwowane w Twoim salonie.',

  'app-auth.forgot-password-heading-success': 'Sprawdź skrzynkę',
  'app-auth.forgot-password-description-success':
    'Na podany adres e-mail wysłana została wiadomość z instrukcją.',

  'app-auth.reset-password-heading': 'Resetowanie hasła',
  'app-auth.reset-password-description':
    'Ustal nowe, bezpieczne hasło do logowania',

  'form.label-companyName': 'Nazwa firmy',
  'form.label-fullName': 'Podaj imię i nazwisko',
  'form.label-email': 'Podaj adres e-mail',
  'form.label-password': 'Ustaw swoje hasło',
  'form.label-phone': 'Nr telefonu',
  'form.label-city': 'Miejscowość',
  'form.label-postal': 'Kod pocztowy',
  'form.label-street': 'Ulica',
  'form.label-buildingNumber': 'Numer budynku',
  'form.label-nip': 'NIP',
  'form.label-description': 'Czym się zajmujemy',
  'form.required': 'To pole jest wymagane',
  'form.minimum-time': 'Minimalny czas wizyty to 1 minuta',
  'form.invalid-email': 'Podaj poprawny adres e-mail',
  'form.invalid-phone': 'Podaj poprawny numer telefonu',
  'form.invalid-nip': 'Podaj poprawny numer NIP',
  'form.invalid-password': 'Hasło jest za słabe',

  'app.hero.heading': 'Znajdź najlepszą usługę dla Twojego pupila',
  'app.hero.seeDogzi': 'Dogzi to miejsce przyjazne wszystkim czworonogom!',
  'app.hero.watch': 'Rezerwacja wizyt dla psów i kotów online',

  'app.basic-search.service': 'Znajdź i zarezerwuj usługę',
  'app.basic-search.service-placeholder': 'Wybierz usługę',
  'app.basic-search.category': 'Znajdź i zarezerwuj usługę',
  'app.basic-search.category-placeholder': 'Wybierz specjalizacje',
  'app.basic-search.location': 'Lokalizacja',
  'app.basic-search.location-placeholder': 'Wybierz miasto',
  'app.filters-search.location-placeholder': 'Wpisz miasto',
  'app.basic-search.voivodeship-placeholder': 'Szukaj po województwie',

  'app.most-popular.heading': 'Najczęściej wybierane',
  'app.promoted.heading': 'Polecane usługi',

  'app.discover.heading': 'Odkrywaj usługi w swoim województwie',
  'app.discover.subheading':
    'Gdziekolwiek masz ochotę zabrać swojego psa, kota lub innego futrzastego przyjaciela tutaj znajdziesz wszystkie miejsca przyjazne. Kliknij szukaj miejsca (m.in. restauracje, plaże, wybiegi) \n' +
    'lub szukaj noclegu (m.in. hotele, pokoje, campingi).',

  'app.header.progress': 'Uzupełnij dane i ciesz się Dogzi',

  'app.footer.about': 'About',
  'app.footer.community': 'Community',
  'app.footer.host': 'Become host',
  'app.footer.support': 'Bookings support',

  'app.profile.day-1': 'Poniedziałek',
  'app.profile.day-2': 'Wtorek',
  'app.profile.day-3': 'Środa',
  'app.profile.day-4': 'Czwartek',
  'app.profile.day-5': 'Piątek',
  'app.profile.day-6': 'Sobota',
  'app.profile.day-7': 'Niedziela',

  'general.page.forSalons': 'Dla biznesu',
  'general.page.signIn': 'Zaloguj się / załóż konto',

  'general.facilities': 'Placówek',
  'general.salons': 'Salonów',

  'general.benefits.header': 'Dołącz do grona użytkowników Dogzi!',
  'general.benefits.subheader':
    'Korzystaj z bezpłatnego systemu do rezerwacji wizyt online',

  'general.next': 'Dalej',
  'general.sign-in': 'Zaloguj',
  'general.recover': 'Odzyskaj',
  'general.confirm': 'Potwierdź',
  'general.see': 'Zobacz',
  'general.seeMore': 'Zobacz więcej',
  'general.noResults': 'Brak wyników',
  'general.search': 'Wyszukaj usługę',

  'general.allRightsReserved': 'All rights reserved',
};

export type LanguageStrings = typeof baseStrings;
export const pl = baseStrings;
