import styled from 'styled-components';
import media from '../../theme/respondTo';

export const SAnnouncementsBar = styled.div`
  align-items: center;
  background-color: #59287a;
  color: #fff;
  display: flex;
  min-height: 39px;
  justify-content: center;
  width: 100%;

  .content-wrapper {
    align-items: center;
    display: flex;
    max-width: 1150px;
    position: relative;
    width: 100%;
  }

  .announcement-content {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin: 4px auto;
    text-transform: uppercase;
    width: 300px;

    ${media.md`
        width: 100%;
    `}
  }

  .close-button {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    position: absolute;
    right: 0;
    width: 32px;
  }
`;
