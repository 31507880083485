import { FC } from 'react'
import classNames from 'classnames'

import { AppRoute, Salon } from 'const'
import { Button } from 'components'
import { SFavoriteSalonItem } from './FavoriteSalonItem.styled'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import { Link } from 'react-router-dom'

interface IFavoriteSalonItem {
  salon: Salon
  favoriteLoading: boolean

  favoriteSalon: () => void
}

const FavoriteSalonItem: FC<IFavoriteSalonItem> = (props) => {
  const { salon, favoriteLoading, favoriteSalon } = props
  const avatar = salon.images?.[0]?.path ?? null

  const { localizeRouteKey } = useLocalizeRoute()

  return (
    <SFavoriteSalonItem>
      {avatar ? (
        <Link
          to={localizeRouteKey(AppRoute.Salon).replace(':id', String(salon.id))}
        >
          <div className="favorite-salon-thumbnail">
            <img src={avatar} alt="salon avatar" />
          </div>
        </Link>
      ) : null}
      <div className="favorite-salon-content">
        <div>
          <p className="favorite-salon-name">{salon.name}</p>
          <p className="favorite-salon-address">
            {salon.city?.name}, {salon.street} {salon.buildingNumber}
          </p>
        </div>

        <Button
          loading={favoriteLoading}
          disabled={favoriteLoading}
          onClick={favoriteLoading ? undefined : favoriteSalon}
          type="button"
          className={classNames('favorite-salon-favorite', {
            active: salon.isFavourite,
          })}
        >
          <span className="material-icons icon">favorite</span>
          {salon.isFavourite ? 'Usuń z ulubionych' : 'Dodaj do ulubionych'}
        </Button>
      </div>
    </SFavoriteSalonItem>
  )
}

export default FavoriteSalonItem
