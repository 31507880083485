import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, TextInput } from 'components'
import { useAppDispatch } from 'Root'
import formErrorsHandler from 'utils/formErrorsHandler'
import client from 'api/client'
import AppAuthPageIcon from 'components/AppAuthPageIcon/AppAuthPageIcon'
import { hideAuthModal } from 'state/reducers/client/clientActions'

const defaultFormValues = {
  email: '',
}

const AuthForgot: FC = () => {
  const dispatch = useAppDispatch()

  const methods = useForm<{ email: string }>({
    defaultValues: defaultFormValues,
  })

  const forgot = async (values: any) => {
    await client('client/auth/password-reset')
      .errorType('json')
      .catcher(
        422,
        formErrorsHandler<{ email: string }>(
          Object.keys(defaultFormValues),
          methods.setError
        )
      )
      .post(values)
      .res(() => {
        methods.reset(defaultFormValues)
        dispatch(hideAuthModal())
      })
      .catch((error) => {
        console.log('[POST] auth forgot error', error)
      })
  }

  return (
    <FormProvider {...methods}>
      <div className="forgot">
        <AppAuthPageIcon>
          <span className="material-icons">lock</span>
        </AppAuthPageIcon>
        <h3 className="forgot-heading">
          Zapomniałeś hasła?
          <br />
          Podaj swoje dane, aby je odzyskać!
        </h3>
        <p className="forgot-description">
          Wprowadź swoje dane, aby przejść dalej
        </p>
      </div>
      <form onSubmit={methods.handleSubmit(forgot)}>
        <TextInput
          {...{
            id: 'email',
            name: 'email',
            label: 'Email',
            icon: 'email',
            className: 'field',
          }}
        />
        <Button
          primary
          className="button"
          loading={methods.formState.isSubmitting}
          disabled={methods.formState.isSubmitting}
        >
          Odzyskaj
        </Button>
      </form>
    </FormProvider>
  )
}

export default AuthForgot
