import styled, { css } from 'styled-components'
import media from 'theme/respondTo'

export const SProfileReservationItem = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.xl}px 0;
    border-bottom: 1px solid #ede8e9;

    ${media.lg`
      padding: ${theme.space.xxl}px 0;
      display: flex;
    `}

    .profile-reservation {
      &-status {
        max-width: 243px;
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &-date {
        font-size: 0.875rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.31rem;
        text-transform: capitalize;
        color: #3a3335;
      }

      &-time {
        font-size: 0.875rem;
        line-height: 1.31rem;
        color: #908e8e;
      }

      &-state {
        height: 24px;

        display: inline-block;

        font-size: 0.75rem;
        font-weight: ${theme.weight.semibold};
        line-height: 1.5rem;
        color: #a8a8a8;

        margin-top: ${theme.space.md}px;
        padding: 0 ${theme.space.md}px;

        background-color: #eef3f2;
        border-radius: 100px;

        &-incoming {
          color: white;
          background-color: #00cb20;
        }
      }

      &-thumbnail {
        width: 92px;
        height: 92px;

        margin-right: ${theme.space.md}px;

        border-radius: 6px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-content {
        flex: 1;
        display: flex;
        padding-top: ${theme.space.xs}px;
        margin-top: ${theme.space.md}px;

        ${media.lg`
          margin-top: 0;
        `}
      }

      &-name {
        font-size: 0.875rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.5rem;
        color: #3a3335;
      }

      &-address {
        font-size: 0.875rem;
        line-height: 1.31rem;
        color: #908e8e;

        position: relative;

        padding-bottom: ${theme.space.sm}px;
        /* margin-top: ${theme.space.xs}px; */

        &::before {
          content: '';
          width: 42px;
          height: 1px;

          position: absolute;
          left: 0;
          bottom: 0;

          background-color: ${theme.colors.primary};
        }
      }

      &-service {
        font-size: 0.875rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.31rem;
        color: #3a3335;

        margin-top: ${theme.space.sm}px;

        span {
          font-weight: ${theme.weight.normal};
          color: ${theme.colors.primary};
        }
      }

      &-actions {
        display: flex;
        align-self: flex-end;
        justify-content: flex-end;

        margin-top: ${theme.space.lg}px;

        ${media.lg`
          margin-top: 0;
        `}

        button {
          width: 100%;
          height: 36px;

          font-size: 0.75rem;
          font-weight: ${theme.weight.semibold};
          line-height: 1.125rem;

          margin-right: ${theme.space.md}px;

          ${media.md`
            width: auto;
          `}

          &.gray {
            background-color: #eef3f2;
            border-color: #eef3f2;

            &:not(:disabled):hover {
              background-color: #d8dbda;
              border-color: #d8dbda;
            }

            &.cancel {
              color: #eb5e55;
            }
            &.edit {
              color: #2ac084;
            }
            &.add {
              color: ${theme.colors.primary};
            }
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  `}
`
