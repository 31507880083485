import React, { useState, useEffect, useMemo } from 'react';
import { SMobileFilters } from './MobileFilters.styled';
import { ICategory, parseCategories } from '../../../const/filter-categories';
import { useAppSelector, useAppDispatch } from '../../../Root';
import { OptionTypeBase } from 'react-select';
import { getCities } from '../../../state/reducers/app/appActions';
import { Autocomplete } from '../../index';
import loadAutocompleteLocations from '../../../api/loadAutocompleteLocations';
import { selectStyles } from '../../Shared/AutocompleteInput';
import { CITIES } from '../../../const/predefined-cities';
import { useIntl } from 'react-intl';
import {
  setSalonsSearch,
  setSalonsFilters,
  setSalonsAllFilters,
  clearSalonsSearch,
} from '../../../state/reducers/salons/salonsActions';
import Map from './Map';
import MapButtonIcon from '../../icons/MapButtonIcon';
import FiltersHeader from './FiltersHeader';
import Cities from './Cities';
import Category from './Category';

interface MobileFiltersProps {
  selected: number[];
  onSelect: (category: OptionTypeBase) => void;
}

const MobileFilters = ({ onSelect, selected }: MobileFiltersProps) => {
  const [mode, setMode] = useState<'city' | 'category' | 'map' | null>(null);
  const [showFilters, setShowFilters] = useState(false);

  const { categories, homepage } = useAppSelector(({ app }) => app);
  const search = useAppSelector(({ salons }) => salons.search);

  const selectedCategoryIds = search.categories.map(({ value }) => value);

  const categoriesLengthWithoutAll = selectedCategoryIds.filter(
    (id) => id !== 0
  ).length;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCities());
  }, []);

  const closeFilters = () => {
    setMode(null);
    setShowFilters(false);
  };

  const showCities = () => {
    setMode('city');
    setShowFilters(true);
  };

  const showCategoriesList = () => {
    setMode('category');
    setShowFilters(true);
  };

  const showMap = () => {
    setMode('map');
    setShowFilters(true);
  };

  const selectAllCategories = () => {
    const allSelected = selectedCategoryIds.length === listingCategories.length;

    if (allSelected) {
      dispatch(setSalonsAllFilters([]));
      return;
    }

    dispatch(
      setSalonsAllFilters(
        listingCategories.map(({ id, name }) => ({
          value: id,
          label: name,
        }))
      )
    );
  };

  const setSuggestedCity = (id: number, name: string) => {
    dispatch(
      setSalonsSearch({
        ...search,
        location: {
          value: id,
          label: name,
          district: false,
        },
      })
    );
  };

  const { formatMessage } = useIntl();

  const listingCategories: ICategory[] = parseCategories(categories);

  const selectLocation = (value: OptionTypeBase) => {
    dispatch(
      setSalonsSearch({
        ...search,
        location: value,
      })
    );
  };

  const show = () => {
    if (selectedCategoryIds.includes(0)) {
      return selectedCategoryIds.length - 1;
    } else {
      return selectedCategoryIds.length;
    }
  };

  const cities = useMemo(() => {
    return homepage.cities.filter((city) => CITIES.includes(city.name));
  }, [homepage.cities]);

  const setFilterHeader = () => {
    if (mode === 'city') return 'Wybierz miasto';
    if (mode === 'category') return 'Wybierz kategorię';
    if (mode === 'map') return 'Szukaj po mapie';
    return '';
  };

  return (
    <SMobileFilters categoriesLength={selectedCategoryIds.length}>
      <div className="default-view">
        <p>Wybierz:</p>

        <div className="filter-buttons">
          <button className="filter-button" onClick={showCities}>
            Miasto
          </button>
          <button
            className="filter-button filter-button--category"
            onClick={showCategoriesList}
          >
            {selectedCategoryIds.length > 0 && (
              <span className="selected-filters-length">
                {selectedCategoryIds.length}
              </span>
            )}
            Kategoria
          </button>

          <button className="map-button" onClick={showMap}>
            <MapButtonIcon />
          </button>
        </div>
      </div>

      {showFilters && (
        <section className="filters-overlay">
          <FiltersHeader header={setFilterHeader()} onClose={closeFilters} />

          {mode === 'map' ? (
            <Map
              categories={parseCategories(categories)}
              selected={selectedCategoryIds}
              onSelect={(category) =>
               {
                dispatch(clearSalonsSearch())
                dispatch(
                  setSalonsFilters({ value: category.id, label: category.name })
                )
               }
              }
            />
          ) : (
            <div className="filters-overlay__filters">
              {mode === 'city' && (
                <Autocomplete
                  load={loadAutocompleteLocations}
                  placeholder={formatMessage({
                    id: 'app.filters-search.location-placeholder',
                  })}
                  customStyles={{
                    ...selectStyles,
                    container: (styles) => ({
                      ...styles,
                      border: '1px solid #EDE8E9',
                      borderRadius: 24,
                      cursor: 'pointer',
                      padding: '5px 10px',
                      width: '100%',
                    }),
                  }}
                  onChange={selectLocation}
                  value={!search.location?.district ? search.location : null}
                />
              )}
            </div>
          )}

          {mode === 'city' && (
            <Cities
              onSelect={selectLocation}
              onPickSuggestedCity={setSuggestedCity}
              cities={cities}
              onSubmit={closeFilters}
            />
          )}
          {mode === 'category' && (
            <div className="filters-overlay__categories-wrapper">
              {/* <button className="pick-all-button" onClick={selectAllCategories}>
                {show() === listingCategories.length
                  ? 'Wyczyść filtry'
                  : 'Zaznacz wszystko'}
              </button> */}

              <ul className="filters-overlay__categories">
                {listingCategories.map((category) => (
                  <Category
                    key={category.id}
                    id={category.id}
                    selected={selectedCategoryIds.includes(category.id)}
                    name={category.name}
                    onSelect={(id, name) =>{
                      dispatch(clearSalonsSearch());
                      dispatch(setSalonsFilters({ value: id, label: name }))
                    }
                    }
                  />
                ))}
              </ul>

              <div className="button-wrapper">
                <button className="submit-button" onClick={closeFilters}>
                  Wybierz
                  {categoriesLengthWithoutAll > 0 && (
                    <span className="selected-amount">
                      {categoriesLengthWithoutAll}
                    </span>
                  )}
                </button>
              </div>
            </div>
          )}
        </section>
      )}
    </SMobileFilters>
  );
};

export default MobileFilters;
