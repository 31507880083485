import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SEmployee = styled.li<{ isLast: boolean }>`
  ${({ theme, isLast }) => css`
    align-items: center;
    display: flex;
    height: 85px;
    flex-flow: row;
    justify-content: flex-start;
    column-gap: 14px;
    position: relative;

    ${!isLast &&
    css`
      border-bottom: 1px solid #e6e8f0;
    `}

    ${media.lg`
      flex-flow: column;
      row-gap: 8px;
      height: auto;
      border-bottom: none;
      `}

    .employee {
      &__image-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        height: 53px;
        background-color: transparent;
        width: 53px;
        overflow: hidden;
      }

      &__avatar {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      &__name {
        color: ${theme.colors.black};
        line-height: 24px;
        text-align: left;
        width: max-content;

        ${media.lg`
        text-align: center;
        max-width: 100px;
      `}
      }
    }
  `}
`;
