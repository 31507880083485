import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SProfileReservationSuccess = styled.div`
  ${({ theme }) => css`
    .reservation-success {
      &-header {
        height: 72px;

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        border-bottom: 2px solid #eceef5;
      }

      &-heading {
        font-size: 1.125rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.68rem;
        text-align: center;

        color: #3a3335;
      }

      &-close {
        position: absolute;
        top: 50%;
        right: ${theme.space.lg}px;

        margin: 0;
        padding: 0;

        transform: translateY(-50%);

        background: none;
        border: 0;
        box-shadow: none;

        cursor: pointer;

        span {
          color: #3a3335;
        }
      }

      &-content {
        max-width: 420px;

        display: flex;
        flex-direction: column;
        align-items: center;

        margin: 0 auto;
        padding: ${theme.space.lg}px;
        padding-bottom: ${theme.space.xxxl}px;

        overflow-y: auto;
      }

      &-service {
        &-content {
          width: 100%;
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }

        &-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-self: stretch;

          padding: ${theme.space.md}px 0;

          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        &-time {
          display: inline-block;

          font-size: 0.75rem;
          line-height: 1.125rem;
          color: #908e8e;

          margin-bottom: 2px;
        }

        &-name {
          font-size: 0.875rem;
          font-weight: ${theme.weight.bold};
          line-height: 1.31rem;
          color: #3a3335;

          span {
            font-weight: ${theme.weight.normal};
            color: ${theme.colors.primary};

            margin-left: ${theme.space.sm}px;
          }
        }

        &-price {
          flex-shrink: 0;

          font-size: 1rem;
          font-weight: ${theme.weight.bold};
          line-height: 1.5rem;
          color: #3a3335;
        }
      }

      &-icon {
        .icon {
          background-color: #04b800;
          svg {
            width: 22px;
          }
        }
      }

      &-icon-heading {
        font-size: 2.125rem;
        font-weight: ${theme.weight.heavy};
        line-height: 3rem;
        color: #3a3335;
        text-align: center;
        margin-bottom: ${theme.space.lg}px;
      }

      &-actions {
        display: flex;
        flex-flow: column;
        row-gap: 20px;
        max-width: 420px;
        width: 100%;

        margin-top: ${theme.space.xxl}px;

        button {
          width: 100%;
          height: 54px;
        }
      }
    }
  `}
`;
