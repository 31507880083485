import config from 'const/config';
import { FC, ReactNode, useState } from 'react';
import ReactMapGL, { WebMercatorViewport } from 'react-map-gl';

interface IListingMap {
  markers?: ReactNode[];
  fitBounds: [[number, number], [number, number]] | [];
}

const ListingMap: FC<IListingMap> = ({ markers, fitBounds }) => {
  const [viewport, setViewport] = useState(
    new WebMercatorViewport({
      width: 421,
      height: 843,
    }).fitBounds(fitBounds as [[number, number], [number, number]], {
      padding: 60,
      offset: [10, 0],
      maxZoom: 16,
    })
  );

  return (
    <ReactMapGL
      {...viewport}
      className="map"
      mapStyle="mapbox://styles/mapbox/streets-v11"
      mapboxApiAccessToken={config.MAPBOX_TOKEN}
      minZoom={4.5}
      onViewportChange={(nextViewport: any) => setViewport(nextViewport)}
    >
      {markers}
    </ReactMapGL>
  );
};

export default ListingMap;
