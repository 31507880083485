import styled, { css } from 'styled-components';

export const SPopularTile = styled.li`
  ${({ theme }) => css`
    width: 100%;

    border: 1px solid #ede8e9;
    display: flex;
    flex-direction: column;

    background: #fff;
    box-shadow: 0 8px 15px 0 #00000003;
    border-radius: 16px;

    overflow: hidden;

    &:hover {
      .thumbnail {
        img {
          transform: scale(1.06);
          transition: all 200ms ease-in;
        }
      }
    }

    a {
      text-decoration: none;
      display: block;
    }

    .thumbnail {
      width: 100%;
      height: 255px;
      border-radius: 16px 16px 0 0;

      position: relative;

      overflow: hidden;
      will-change: transform;

      img {
        object-fit: cover;
        width: 100%;
        min-height: 100%;

        transition: all 200ms ease-in;
      }
    }

    .review {
      align-items: center;
      background-color: ${theme.colors.white};
      border-radius: 8px;
      display: flex;
      column-gap: 8px;
      color: ${theme.colors.black};
      font-weight: ${theme.weight.bold};
      position: absolute;
      right: 12px;
      top: 12px;
      padding: 9px 10px;
      z-index: 2;
    }

    .header {
      padding: 24px 24px 31px;

      .heading {
        color: ${theme.colors.black};
        font-size: 18px;
        font-weight: ${theme.weight.bold};
        line-height: 27px;
      }

      .popular-text {
        font-size: 14px;
        color: #696f8c;
        line-height: 21px;

        margin-top: ${theme.space.xs}px;
      }
    }
  `}
`;
