import { ChangeEvent, FC } from 'react';
import Cleave from 'cleave.js/react';
import TextareaAutosize from 'react-textarea-autosize';
import Select from 'react-select';

import { SAppWorkplaceServiceEditor } from './AppWorkplaceServiceEditor.styled';
import theme from 'theme';
import { Controller, useFormContext } from 'react-hook-form';
import { ServiceCreator, WorkplaceCategory, Slot } from 'const';
import autoFormatTimeInput from 'utils/autoFormatTimeInput';
import { Button } from 'components';

interface IAppWorkplaceServiceEditor {
  category: WorkplaceCategory;
  slots: Slot[];
  onSubmit: (v: ServiceCreator) => void;
  onCancel: () => void;
  onRemove?: () => void;

  edit?: boolean;
}

const AppWorkplaceServiceEditor: FC<IAppWorkplaceServiceEditor> = ({
  category,
  slots,
  onSubmit,
  onCancel,
  onRemove,
  edit,
}) => {
  const { control, register, handleSubmit, formState } =
    useFormContext<ServiceCreator>();

  const handleDurationInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    return { target: { value: autoFormatTimeInput(value) } };
  };

  const handleFormSubmit = async (values: ServiceCreator) =>
    await onSubmit(values);

  return (
    <SAppWorkplaceServiceEditor onSubmit={handleSubmit(handleFormSubmit)}>
      <input
        type="text"
        placeholder="Nazwa usługi"
        className="name"
        {...register('name', { required: true })}
      />
      {category.appointment_type === 'normal' ? (
        <Controller
          control={control}
          name="duration"
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <Cleave
              placeholder="Czas"
              options={{
                time: true,
                timePattern: ['h', 'm'],
              }}
              className="duration-o-count"
              value={value === '' ? undefined : value}
              onBlur={(e) => {
                onChange(handleDurationInput(e));
                onBlur();
              }}
            />
          )}
        />
      ) : (
        <Controller
          control={control}
          name="placesAmount"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Cleave
              placeholder="Ilość miejsc"
              options={{ numericOnly: true }}
              className="duration-o-count"
              value={value === 0 ? undefined : value}
              onChange={(e) => {
                onChange(e.target.value ? parseInt(e.target.value) : 0);
              }}
            />
          )}
        />
      )}
      <Controller
        control={control}
        name="price"
        rules={{ required: true }}
        render={({ field: { onChange, onBlur, value } }) => (
          <Cleave
            placeholder="Kwota"
            options={{
              numeral: true,
              numeralDecimalMark: ',',
              delimiter: ' ',
            }}
            className="price"
            value={value === 0 ? undefined : value}
            onBlur={(e) => {
              onChange(e);
              onBlur();
            }}
          />
        )}
      />
      <TextareaAutosize
        placeholder="Dodatkowy opis"
        className="description"
        {...register('description', { required: false })}
      />
      {category.appointment_type === 'normal' ? (
        <Controller
          control={control}
          name="slots"
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <Select
                placeholder="Wybierz stanowiska"
                styles={{
                  control: (styles) => ({
                    ...styles,
                    height: '48px',
                    minHeight: '48px',
                    borderColor: '#EDE8E9',
                    boxShadow: 'none',
                    borderWidth: 1,
                    borderRadius: 8,
                    fontSize: '1rem',
                    '&:hover': {
                      borderColor: '#EDE8E9',
                    },
                  }),
                  valueContainer: (styles) => ({
                    ...styles,
                    paddingLeft: theme.space.md,
                  }),
                  placeholder: (styles) => ({
                    ...styles,
                    fontWeight: theme.weight.bold,
                    marginLeft: 0,
                  }),
                  input: (styles) => ({ ...styles, margin: 0 }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                }}
                isMulti={true}
                options={slots}
                className="service-slots"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                getOptionValue={({ id }) => String(id)}
                getOptionLabel={(item) => item.name}
                required
              />
            );
          }}
        />
      ) : null}

      <div className="actions">
        {edit ? (
          <Button
            bordered
            className="cancel-button"
            type="button"
            onClick={onRemove}
          >
            Usuń
          </Button>
        ) : (
          <Button
            bordered
            className="cancel-button"
            type="button"
            onClick={onCancel}
          >
            Anuluj
          </Button>
        )}
        <Button
          className="save-button"
          disabled={formState.isSubmitting}
          loading={formState.isSubmitting}
        >
          Zapisz
        </Button>
      </div>
    </SAppWorkplaceServiceEditor>
  );
};

export default AppWorkplaceServiceEditor;
