import React from 'react';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../const';
import StarIcon from '../icons/StarIcon';
import useLocalizeRoute from '../../utils/useLocalizeRoute';

interface ImageWrapperProps {
  id: number;
  onClick: () => void;
  reviewsValue: string;
  avatar: string;
  name: string;
}

const ImageWrapper = ({
  onClick,
  id,
  reviewsValue,
  name,
  avatar,
}: ImageWrapperProps) => {
  const { localizeRouteKey } = useLocalizeRoute();

  return (
    <div className="left-side">
      <Link
        onClick={onClick}
        to={localizeRouteKey(AppRoute.Salon).replace(':id', String(id))}
        className="listing-item__thumbnail"
      >
        {parseInt(reviewsValue) > 0 && (
          <div className="listing-item__mobile-review">
            <StarIcon />
            <p>{reviewsValue}</p>
          </div>
        )}
        <img
          className="listing-item__image"
          src={avatar}
          alt={`salon ${name} avatar`}
        />
      </Link>
    </div>
  );
};

export default ImageWrapper;
