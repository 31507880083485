import styled, { css } from 'styled-components';

export const STag = styled.li<{ colors: { text: string; background: string } }>`
  ${({ colors }) => css`
    background-color: ${colors.background};
    border-radius: 25px;
    color: rgb(${colors.text});
    font-size: 12px;
    font-weight: 600;
    text-decoration: none;
    padding: 6px 12px;
  `}
`;
