import { FC } from 'react';
import { AppRoute } from 'const';
import useLocalizeRoute from 'utils/useLocalizeRoute';

import { SSalonTile } from './SalonTile.styled';
import StarIcon from '../icons/StarIcon';

interface SalonTileProps {
  id: number;
  image: string;
  name: string;
  address: string;
  className: string;
}

const SalonTile: FC<SalonTileProps> = ({
  id,
  image,
  name,
  address,
  ...res
}) => {
  const { localizeRouteKey } = useLocalizeRoute();

  return (
    <SSalonTile
      to={localizeRouteKey(AppRoute.Salon).replace(':id', String(id))}
      {...res}
    >
      <div className="thumbnail">
        <div className="salon-review">
          <StarIcon /> 5.0
        </div>

        <img src={image} alt={name} loading="lazy" />
      </div>

      <div className="tile-content">
        <h2 className="salon-name">{name}</h2>
        <p className="salon-address">{address}</p>
      </div>
    </SSalonTile>
  );
};

export default SalonTile;
