import { FC, useEffect } from 'react';

import { AppRoute, Salon } from 'const';
import { useAppDispatch, useAppSelector } from 'Root';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import { getClientFavorites } from 'state/reducers/client/clientActions';

import {
  Button,
  FavoriteSalonItem,
  ProfileList,
  SectionLoader,
  Wrapper,
} from 'components';
import empty from '../assets/images/profile-empty-state.svg';
import { favoriteSalon } from 'state/reducers/salons/salonsActions';
import EmptyState from '../components/EmptyState/EmptyState';

const ProfileFavorite: FC = () => {
  const dispatch = useAppDispatch();
  const { data, loading, pendingFavorite } = useAppSelector(
    ({ client }) => client.favorite.list
  );

  const { localizeRouteKey } = useLocalizeRoute();

  const toggleFavoriteSalon = (id: number) => () => {
    dispatch(favoriteSalon(id));
  };

  useEffect(() => {
    dispatch(getClientFavorites());
  }, []);

  if (loading) {
    return (
      <Wrapper>
        <ProfileList>
          <SectionLoader />
        </ProfileList>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ProfileList>
        {!data.length ? (
          <EmptyState
            title="Brak ulubionych w tym momencie"
            description="Create new apartments and add details about your location. You
              will get notified once it will be booked."
            button={{ label: 'Dodaj do ulubionych', href: '/pl/list/1' }}
          />
        ) : (
          <>
            <div className="header">
              <h2 className="heading">Ulubione</h2>
            </div>
            <div className="list">
              {data.map((salon: Salon) => (
                <FavoriteSalonItem
                  key={`favorite-item-${salon.id}`}
                  {...{
                    salon,
                    favoriteLoading: pendingFavorite.includes(salon.id),
                    favoriteSalon: toggleFavoriteSalon(salon.id),
                  }}
                />
              ))}
            </div>
          </>
        )}
      </ProfileList>
    </Wrapper>
  );
};

export { ProfileFavorite };
