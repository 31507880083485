import React from 'react';
import { SMarker } from '../ListingMap/Marker.styled';
import { Marker } from 'react-map-gl';
import GroomerIcon from '../icons/GroomerIcon';
import HotelIcon from '../icons/HotelIcon';
import TrainerIcon from '../icons/TrainerIcon';
import BehavioristIcon from '../icons/BehavioristIcon';
import PhysiotherapistIcon from '../icons/PhysiotherapistIcon';
import HandelerIcon from '../icons/HandelerIcon';
import PhotographerIcon from '../icons/PhotographerIcon';
import { useAppDispatch, useAppSelector } from '../../Root';
import { setSingleSalon } from '../../state/reducers/salons/salonsActions';
import MarkerDetails from './MarkerDetails/MarkerDetails';

interface MapMarkerProps {
  latitude: number;
  longitude: number;
  name?: string;
  address?: string;
  salonId?: number;
  categories: string[];
  categoriesIds?: number[];
  onClick?: (
    { latitude, longitude }: { latitude: number; longitude: number },
    salon: any
  ) => void;
  selected?: boolean;
  onClose?: () => void;
}

const MapMarker = ({
  latitude,
  salonId,
  longitude,
  categories,
  categoriesIds,
  onClick,
  selected,
}: MapMarkerProps) => {

  const dispatch = useAppDispatch();
  const { data, search } = useAppSelector(({ salons }) => salons);


  const salon = data.find((item) => item.id === salonId);

  const selectedCategories = search.categories.length > 0 ? search.categories.map((cat) => cat.label) : ['All'];
  const selectedCategoriesIds = search.categories.length > 0 ? search.categories.map((cat) => cat.value) : [];
  const renderIcon = () => {
    if (!categories) return;

    if (selectedCategories.includes('Groomer') || selectedCategoriesIds.includes(1))
      return <GroomerIcon active size={22} />;
    if (selectedCategories.includes('Hotele dla pupila' || selectedCategoriesIds.includes(2)))
    return <HotelIcon active size={22} />;
    if (selectedCategories.includes('Trener') || selectedCategoriesIds.includes(3))
      return <TrainerIcon active size={22} />;
    if (selectedCategories.includes('Behawiorystyka') || selectedCategoriesIds.includes(4))
      return <BehavioristIcon active size={22} />;
    if (selectedCategories.includes('Fizjoterapeuta') || selectedCategoriesIds.includes(5))
      return <PhysiotherapistIcon active size={22} />;
    if (
      (selectedCategories.includes('Handler') ||
      selectedCategories.includes('Handeler')) || selectedCategoriesIds.includes(6)
    )
      return <HandelerIcon active size={22} />;
    if (selectedCategories.includes('Fotograf') || selectedCategoriesIds.includes(7))
      return <PhotographerIcon active size={22} />;
    if(selectedCategories.includes('All'))
      return <svg
      width={22}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.64 3.926c.04-1.303.416-2.461 1.41-3.32 1.151-.993 2.601-.74 3.413.568 1.023 1.649.684 4.196-.733 5.498-1.137 1.046-2.633.853-3.477-.457-.444-.69-.623-1.463-.613-2.29zm10.356 4.65c-.042 1.137-.384 2.15-1.201 2.945-1.214 1.182-2.813.812-3.435-.784-.561-1.44-.085-3.407 1.067-4.4 1.394-1.205 3.143-.475 3.496 1.46.047.256.09.514.073.779zM10.36 3.926c-.039-1.303-.416-2.461-1.41-3.32-1.151-.993-2.601-.74-3.413.568-1.023 1.649-.683 4.196.733 5.498 1.137 1.046 2.633.853 3.477-.457.444-.69.624-1.463.613-2.29zM.003 8.576c.043 1.137.385 2.15 1.202 2.945 1.214 1.182 2.813.812 3.435-.784.561-1.44.084-3.407-1.067-4.4C2.18 5.131.43 5.861.077 7.796c-.047.256-.09.514-.074.779zM11 18.11c4.289 4.761 9.788-.409 6.188-5.21a7.134 7.134 0 00-.67-.764l-4.063-4.058a2.03 2.03 0 00-2.91 0l-4.062 4.058c-.24.24-.467.492-.67.764-3.601 4.8 1.898 9.97 6.187 5.21z"
        fill="#FF6B00"
      />
    </svg>;
    return <TrainerIcon active size={22} />;
  };

  return (
    <Marker
      onClick={() => {
        if (!onClick) return;
        onClick({ latitude, longitude }, salon);
      }}
      longitude={longitude}
      latitude={latitude}
    >
      <SMarker
        isSelected={selected}
        categories={selectedCategories}
        categoriesIds={selectedCategoriesIds}
        className="marker"
      >
        {renderIcon()}
      </SMarker>
    </Marker>
  );
};

export default MapMarker;
