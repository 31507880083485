import styled, { css } from 'styled-components'

interface IProps {
  marginless?: boolean
}

export const SSectionLoader = styled.div<IProps>`
  ${({ theme, marginless }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    margin: ${theme.space.xl}px 0;

    ${marginless &&
    css`
      margin: 0;
    `}
  `}
`
