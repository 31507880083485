import styled, { css } from 'styled-components';
import media from '../../theme/respondTo';

export const SListing = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;

    .wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .loader {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100vh;
      width: 100%;

      padding-top: ${theme.space.lg}px;
    }

    .listing {
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: ${theme.space.xl}px;

        button {
          height: 42px;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 0.875rem;
          font-weight: ${theme.weight.bold}
          line-height: 1.31rem;

          padding: 0 ${theme.space.lg}px;

          background: linear-gradient(0deg,
          rgba(58, 51, 53, 0.04),
          rgba(58, 51, 53, 0.04)),
          #ffffff;
          border: 0;
          border-radius: 10px;
          box-shadow: none;

          cursor: pointer;
        }
      }

      &-heading {
        font-weight: ${theme.weight.heavy};
        font-size: 2.125rem;
        line-height: 3rem;

        color: #3a3335;
      }
    }

    .content {
      flex: 1;
      display: flex;
      margin: 25px 0 0;

      ${media.md`
       column-gap: 25px;
        margin: 24px 0 0;
      `}
      .list {
        display: flex;
        flex-flow: column;
        margin-bottom: ${theme.space.xl}px;
        row-gap: 24px;
        width: 100%;
      }

      .pagination {
        margin-top: ${theme.space.xl}px;
      }

      .sticky-map-wrapper {
        align-items: flex-start;
        border-radius: 16px;
        will-change: transform;
        display: flex;
      }

      .listing-map-wrapper {
        display: none;

        ${media.md`
          display: flex;
          border-radius: 16px;
          margin-bottom: ${theme.space.xl}px;
          height: 843px;
          position: sticky;
          top: 198px;
        `}
        
        
        .map {
          width: 100%;
          height: 100%;
          min-height: 300px;
          max-width: 912px;

          border-radius: 12px;
          will-change: transform;
          overflow: hidden;
        }
      }

      .close-map-button {
        display: none;

        ${media.md`
            background-color: #fff;
        border: none;
        box-shadow: 0 1px 23px 0 #00000021;
        align-items: center;
        border-radius: 26px;
        position: absolute;
        right: -93.5px;
        color: ${theme.colors.primary};
        cursor: pointer;
        font-weight: 500;
        line-height: 21px;
        column-gap: 12px;
        display: flex;
        height: 46px;
        justify-content: center;
        padding: 14px 20px;
        transform: rotate(90deg);
        transform-origin: center center;
        top: 50%;
        margin-top: -23px;
        z-index: 2;
        
          &:hover {
          background-color: #ececec;
        }
        `}

      }
    }

    .map-placeholder {
      display: none;

      &:hover {
        background-color: rgba(206, 92, 9, 0.08);
      }

      ${media.md`
            align-items: center;
      background: #FF6B000D;
      border-radius: 16px;
      display: flex;
      flex-flow: column;
      cursor: pointer;
      margin-bottom: 32px;
      justify-content: center;
      position: sticky;
      top: 198px;
      height: 74vh;
      width: 69px;
      
      button {
        align-items: center;
        display: flex;
        color: ${theme.colors.primary};
        column-gap: 14px;
        font-weight: 500;
        line-height: 21px;
        transform: rotate(90deg);
        width: max-content;
      }  
      `}

    }

  `}
`;
