import React, { useState } from 'react';
import { SAnnouncementsBar } from './AnnouncementsBar.styled';
import closeIcon from '../../assets/close-icon.png';

const AnnouncementsBar = () => {
  const [visible, setVisible] = useState(true);

  if (visible) {
    return (
      <SAnnouncementsBar>
        <div className="content-wrapper">
          <p className="announcement-content">Znajdź miejsca przyjazne psom</p>
          <button className="close-button" onClick={() => setVisible(false)}>
            <img src={closeIcon} alt="zamknij ogłoszenie" />
          </button>
        </div>
      </SAnnouncementsBar>
    );
  }

  return null;
};

export default AnnouncementsBar;
