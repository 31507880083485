import React from 'react';

import LinkedInIcon from './LinkedInIcon';
import YouTubeIcon from './YouTubeIcon';
import FacebookIcon from './FacebookIcon';

const Socials = () => (
  <ul className="socials">
    <li>
      <a href="#" target="_blank" rel="noreferrer">
        <LinkedInIcon />
      </a>
    </li>

    <li>
      <a href="#" target="_blank" rel="noreferrer">
        <YouTubeIcon />
      </a>
    </li>

    <li>
      <a
        href="https://www.facebook.com/dogzi.booking"
        target="_blank"
        rel="noreferrer"
      >
        <FacebookIcon />
      </a>
    </li>
  </ul>
);

export default Socials;
