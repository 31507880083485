import { FC, useEffect } from 'react';
import { UserModal, AppAuthPageIcon, Button } from 'components';
import { SAppErrorModal, SAppSuccessModal } from './UserModal.styled';
import { useAppSelector, useAppDispatch } from 'Root';
import { closeAppModal } from 'state/reducers/app/appActions';
import { useLocation } from 'react-router-dom';

export enum AppDialogCloseType {
  NONE = 'none',
  RELOAD = 'reload',
  GO_HOME = 'go_home',
}
interface IAppDialogModal {
  close?: AppDialogCloseType;
}

const AppDialogModal: FC<IAppDialogModal> = () => {
  const { appDialogModal } = useAppSelector(({ app }) => app);
  const {
    appModalType,
    appModalMessage,
    appModalButtonText,
    appModalHeader,
    appModalCloseType,
  } = appDialogModal;

  const location = useLocation();
  const dispatch = useAppDispatch();

  function handleClose() {
    dispatch(closeAppModal);

    switch (appModalCloseType) {
      case AppDialogCloseType.RELOAD:
        window.location.reload();
        break;
      case AppDialogCloseType.GO_HOME:
        window.location.href = '/';
        break;
      case AppDialogCloseType.NONE:
        break;
      default:
        break;
    }
  }
  useEffect(() => {
    dispatch(closeAppModal);
  }, [location]);

  if (appModalType === 'success') {
    return (
      <UserModal wide>
        <SAppSuccessModal>
          <div className="register-success">
            <AppAuthPageIcon>
              <span className="material-icons">check</span>
            </AppAuthPageIcon>
            <h3 className="register-success-heading">{appModalHeader}</h3>
            <p className="register-success-description">{appModalMessage}</p>
            <Button primary className="button" onClick={handleClose}>
              {appModalButtonText}
            </Button>
          </div>
        </SAppSuccessModal>
      </UserModal>
    );
  } else if (appModalType === 'error') {
    return (
      <UserModal wide>
        <SAppErrorModal>
          <div className="register-error">
            <AppAuthPageIcon>
              <span className="material-icons">error</span>
            </AppAuthPageIcon>
            <h3 className="register-error-heading">{appModalHeader}</h3>
            <p className="register-error-description">{appModalMessage}</p>
            <Button primary className="button" onClick={handleClose}>
              {appModalButtonText}
            </Button>
          </div>
        </SAppErrorModal>
      </UserModal>
    );
  } else {
    return <div />;
  }
};

export default AppDialogModal;
