import parseLocationSuggestion, {
  parseVoivodeship,
} from 'utils/parseLocationSuggestion';
import client from './client';
import { predefinedLocations } from '../const/predefined-cities';

const loadAutocompleteLocations = (inputValue: string, callback: any): void => {
  client(`platform/location/suggestions?query=${inputValue}`)
    .errorType('json')
    .get()
    .json((json) => {
      const { data } = json;

      if (inputValue === '') {
        return callback(predefinedLocations);
      }

      const locations = data.map(parseLocationSuggestion);

      callback(locations);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const loadAutocompleteVoivodeship = (
  inputValue: string,
  callback: any
) => {
  client(`platform/location/suggestions?query=${inputValue}`)
    .errorType('json')
    .get()
    .json((json) => {
      const { data } = json;
      const locations = data
        .map(parseVoivodeship)
        .filter((item: any) => item.district);

      callback(locations);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default loadAutocompleteLocations;
