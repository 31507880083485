import { ProfileReservationCancelation } from 'components';
import { useEffect } from 'react';
import { useAppDispatch } from 'Root';
import { showReservationCancelationModal } from 'state/reducers/client/clientActions';

export const AppCancelReservation: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(showReservationCancelationModal());
  }, []);
  return <ProfileReservationCancelation />;
};
