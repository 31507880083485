import styled, { css, keyframes } from 'styled-components';
import media from 'theme/respondTo';

interface IStyledHeader {
  sticky: boolean;
  showFilters: boolean;
}

const slide = keyframes`
  0% {
    transform: translateY(-100%)
  }

  100% {
    transform: translateY(0);
  }
`;

export const SHeader = styled.header<IStyledHeader>`
  ${({ theme, sticky, showFilters }) => css`
    background-color: ${theme.colors.white};
    display: flex;
    flex-flow: column;
    height: ${showFilters ? 'auto' : '66px'};
    align-items: center;
    width: 100%;
    z-index: 2;

    ${media.md`
      height: ${showFilters ? 'auto' : '87px'};
    `}
    ${sticky &&
    css`
      position: relative;

      background-color: white;
      box-shadow: 0 3px 4px rgba(153, 155, 168, 0.15);

      z-index: 1;

      animation-name: ${slide};
      animation-duration: 300ms;
    `}
    .logo-wrapper {
      align-items: center;
      column-gap: 13px;
      display: flex;
      justify-content: flex-start;
    }

    .claim {
      display: none;

      ${media.md`
          align-items: center;
          display: flex;
          color: #898ca6;
          font-size: 14px;
          line-height: 21px;
          margin-top: 4px;
          
        `}
    }

    .logo {
      height: 37px;
      display: block;
    }

    .button {
      height: 40px;
    }

    .simple {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: ${showFilters ? '75px' : '100%'};

      .search {
        max-width: 556px;
        width: 100%;
      }

      .user-avatar {
        width: 44px;
        height: 44px;

        padding: 0;
        margin: 0;

        background: none;
        border: 0;
        box-shadow: none;
        border-radius: 10px;

        cursor: pointer;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }

      .actions {
        display: none;

        ${media.md`
          display: flex;
          column-gap: 19px;
        `}
      }

      .button {
        font-weight: 500;
      }
    }

    .mobile-menu {
      background: linear-gradient(180deg, #f3f3f7, #fff);
      display: flex;
      flex-flow: column;
      width: 100%;
      top: 66px;
      padding: 24px;
      position: absolute;
      row-gap: 16px;
      right: 0;
      z-index: 5;

      button,
      a {
        width: 100%;
      }

      ${media.md`
         display: none;
        `}
    }

    .mobile-menu-button {
      align-items: center;
      background-color: #fff;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      height: 23px;
      justify-content: center;
      padding: 0;
      width: 23px;

      ${media.md`
          display: none;
        `}
    }

    ${media.lg`
    .simple {
        .actions {
          .button {
            &.sign-in {
              padding-right: ${theme.space.md}px;
            }
          }
        }
      }
    `}
  `}
`;
