import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SFooter = styled.footer`
  ${({ theme }) => css`
    background-color: rgba(255, 107, 0, 0.06);

    .elements-wrapper {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      max-width: 1440px;
      width: 100%;
    }
    .elements {
      //      max-width: 1440px;
      width: 100%;
    }

    .top {
      display: flex;
      flex-direction: column-reverse;

      min-height: 118px;
      padding: ${theme.space.xl}px 0;
    }

    .divider {
      background-color: #e7e5e5;
      height: 1px;
      width: 100%;

      ${media.md`
        margin-bottom: 33px;
      `}
    }

    .sitemap {
      margin-bottom: 70px;
      width: 100%;
    }

    .column-title {
      color: ${theme.colors.text};
      font-weight: 600;
      font-size: 15px;
    }

    .column-links {
      color: ${theme.colors.text};
      margin: 12px 0 0;

      a {
        color: ${theme.colors.text};
        text-decoration: none;
      }
    }

    .column-link {
      font-size: 13px;
      font-weight: 400;
      line-height: 33px;

      ${media.lg`
      &:hover {
        color: #343748;
      }        
      `}

      a {
        color: ${theme.colors.text};
        text-decoration: none;
      }
    }

    .listing {
      display: grid;
      margin-top: 20px;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;

      ${media.sm`
          display: grid;
        grid-template-columns: repeat(3, 1fr);
      `}
      ${media.md`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      `}

      ${media.lg`
        display: flex;
        justify-content: space-between;
      `}
    }

    .socials {
      display: grid;
      grid-template-columns: repeat(3, 41px);
      grid-gap: 0 27px;
      margin: 0 auto ${theme.space.md}px;

      ${media.md`
         margin: 0 0 ${theme.space.md}px;
      `}
      a {
        width: 41px;
        height: 41px;

        display: block;

        ${media.lg`
        &:hover {
          svg {
            path {
              transition: 300ms all ease-in-out;
              fill: #3d4154;
            }
          }
        }  
        `}
        svg {
          width: 100%;
          height: 100%;

          path {
            transition: 300ms all ease-in-out;
          }
        }
      }
    }

    .nav {
      display: flex;
      flex-direction: column;

      padding: 29px 0 22px;
    }

    .heading {
      font-size: 0.875rem;
      font-weight: ${theme.weight.bold};
      margin-bottom: ${theme.space.lg}px;
    }

    .menu {
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      column-gap: 16px;
      margin: 0 auto;
      justify-content: center;
      text-align: center;
      width: 297px;

      ${media.md`
        width: 100%;
      `}
      li {
        line-height: 42px;

        a {
          font-size: 0.875rem;
          text-decoration: none;
          color: ${theme.colors.text};

          &:hover {
            text-decoration: underline;
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .column-wrapper {
      margin-top: 38px;
    }

    .privacy {
      font-size: 0.875rem;
      display: flex;
      flex-flow: column;
      row-gap: 14px;
      align-items: flex-start;
      padding-bottom: 24px;
      position: relative;
      padding-left: 0;
      z-index: 1;

      ${media.md`
      align-items: center;
      flex-flow: row;
      column-gap: 22px;
        height: 56px;
      `}
    }

    .privacy-rights {
      color: #908e8e;
    }

    .physics-wrapper {
      height: 300px;
      width: 100%;
    }

    .random-elements {
      display: none;
      ${media.md`
        display: flex;
        width: 100%
      `}
    }

    .random-elements-mobile {
      display: flex;
      width: 100%;

      ${media.md`
        display: none;
      `}
    }

    ${media.lg`
      .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        padding: 0;
      }

      .socials {
        margin-bottom: 0;
      }

      .menu {
        display: flex;
        align-items: center;

        li {
          margin-right: ${theme.space.xl}px;
          margin-bottom: 0;
        }
      }
    `}
  `}
`;
