import styled from 'styled-components';

const SErrorMessageText = styled.p`
  font-size: 0.75rem;
  font-weight: 500;
  color: #eb5e55;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export default SErrorMessageText;
