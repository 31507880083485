import React, { useMemo, useState } from 'react';
import { Salon } from '../const';
import { IPickedMarker } from '../types';
import MapMarker from '../components/Shared/MapMarker';

export const useSalonsMarkers = (array: Salon[]) => {
  const [pickedSalon, setPickedSalon] = useState<IPickedMarker | null>(null);
  return {
    markers: useMemo(
      () =>
        array.map((salon) => {
          const {
            geolocation,
            id,
            categories,
            name,
            city,
            street,
            buildingNumber,
          } = salon;
          if (geolocation?.longitude && geolocation?.latitude) {
            const salonCategory = categories.map((category) => category.name);

            return (
              <MapMarker
                key={id}
                {...geolocation}
                salonId={id}
                categories={salonCategory}
                name={name}
                address={`${city?.name || ''}, ${street} ${buildingNumber}`}
                selected={pickedSalon?.name === name}
                onClick={(geo) => {
                  setPickedSalon({ ...geo, name, salon });
                }}
                onClose={() => setPickedSalon(null)}
              />
            );
          }

          return null;
        }),
      [array, pickedSalon]
    ),
    pickedSalon,
  };
};
