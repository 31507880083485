import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SService = styled.li<{ isLast?: boolean }>`
  ${({ theme, isLast }) => css`
    display: flex;
    flex-flow: column;
    min-height: 68px;
    justify-content: space-between;
    padding: 24px 0 25px;
    width: 100%;

    ${!isLast &&
    css`
      border-bottom: 1px solid #ede8e9;
    `}

    ${media.lg`
      border-bottom: 1px solid #ede8e9;
      flex-flow: row;
      padding: 14px 0;
    `}

    .listing-item__content {
      display: flex;
      flex-flow: column;
      width: 100%;

      ${media.lg`
        width: 60%;
      `}
    }

    .listing-item-services-name {
      color: ${theme.colors.black};
      font-size: 16px;
      font-weight: ${theme.weight.normal};
      line-height: 24px;

      ${media.lg`
      line-height: 40px;
      `}
    }

    .description {
      color: #888da3;
      font-size: 13px;
      line-height: 21px;

      &__button {
        background: transparent;
        border: none;
        color: rgba(136, 141, 163, 1);
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        text-decoration: underline;
      }
    }

    .right-side {
      align-items: center;
      display: flex;
      justify-content: space-between;
      column-gap: 17px;
      width: auto;
      min-width: 250px;
      margin-top: 24px;

      ${media.lg`
      margin-top: 0;
      justify-content: flex-end;
      `}

      &__top {
        display: flex;
        justify-content: center;
        column-gap: 10px;
      }

      button {
        width: 158px;

        ${media.lg`
          height: 32px;
          width: 90px;
      `}
      }
    }

    .time-wrapper {
      align-items: center;
      display: flex;
      color: ${theme.colors.primary};
      column-gap: 4px;

      &__divider {
        background-color: rgba(237, 232, 233, 1);
        width: 1px;
        height: 18px;
      }
    }

    .price {
      color: ${theme.colors.black};
      font-size: 18px;
      font-weight: 700;
    }
  `}

  .listing-item-services-button {
    line-height: 16px;
    font-weight: 600;
    padding: 9px 20px;
  }
`;
