import React from 'react';

const FacebookIcon = () => (
  <svg viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 20.5C0 9.178 9.178 0 20.5 0S41 9.178 41 20.5 31.822 41 20.5 41 0 31.822 0 20.5zm20.5-10.25c5.637 0 10.25 4.613 10.25 10.25 0 5.125-3.716 9.481-8.84 10.25v-7.303h2.434l.512-2.947h-2.819v-1.922c0-.769.385-1.537 1.666-1.537h1.281v-2.563s-1.153-.256-2.306-.256c-2.306 0-3.844 1.41-3.844 3.972V20.5h-2.562v2.947h2.562v7.175c-4.868-.769-8.584-4.997-8.584-10.122 0-5.637 4.613-10.25 10.25-10.25z"
      fill="#696F8C"
    />
  </svg>
);

export default FacebookIcon;
