import React from 'react';
import { SUserRow } from './UserRow.styled';

export interface IUser {
  user: {
    id: number;
    name: string;
  };
  email: string;
  phone: string;
  points_amount: number;
}

interface UserRowProps extends IUser {
  onSelect: (id: number, points: number) => void;
}

const UserRow = ({
  points_amount,
  user,
  email,
  phone,
  onSelect,
}: UserRowProps) => (
  <SUserRow>
    <td>
      {points_amount}{' '}
      <button onClick={() => onSelect(user.id, points_amount)}>Edytuj</button>
    </td>
    <td>{user.name}</td>
    <td>{email}</td>
    <td>{phone}</td>
  </SUserRow>
);

export default UserRow;
