import React from 'react';

interface LogoColorProps {
  size: number;
}

const LogoColor = ({ size }: LogoColorProps) => (
  <svg
    height={size}
    viewBox="0 0 123 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59.4975 9.90073C57.7778 9.90073 55.1093 9.90073 55.1093 9.90073H47.0742V10.1082C48.4678 10.1082 50.0392 11.2642 50.0392 12.8055V29.1376C50.0392 30.7085 48.4678 31.8349 47.0742 31.8349V32.072H58.8748C65.4571 32.072 69.9342 27.7148 69.9342 21.016C69.9342 14.3468 64.6269 9.90073 59.4975 9.90073ZM58.015 31.8053H56.5918C55.7023 31.7756 55.0797 30.7382 55.05 29.1672V10.2564C55.7023 10.2564 56.5325 10.2268 57.3923 10.2268C60.5056 10.2268 64.4193 12.3906 64.4193 20.9567C64.4193 29.7008 61.484 31.8053 58.015 31.8053Z"
      fill="#3A3335"
    />
    <path
      d="M77.5056 18.2891C73.3843 18.2891 69.7967 21.3421 69.7967 25.4325C69.7967 29.5526 73.3843 32.5759 77.5056 32.5759C81.5973 32.5759 85.2146 29.5526 85.2146 25.4325C85.2146 21.3421 81.5973 18.2891 77.5056 18.2891ZM77.5056 32.2795C74.8371 32.2795 74.1848 29.1672 74.1848 25.4325C74.1848 21.6978 74.8371 18.5855 77.5056 18.5855C80.1741 18.5855 80.8857 21.6978 80.8857 25.4325C80.8857 29.1672 80.1741 32.2795 77.5056 32.2795Z"
      fill="#3A3335"
    />
    <path
      d="M96.2135 31.1532H91.2917H89.4237C87.8226 31.1532 86.8145 30.4714 86.8145 29.1969C86.8145 28.5151 87.0221 28.0112 87.3779 27.5666C88.0302 28.1594 88.8604 28.604 89.7499 28.8708L90.8469 29.1079C91.1138 29.1376 91.4103 29.1376 91.6771 29.1376C94.5531 29.1376 97.6367 27.2999 97.6367 23.8615C97.6367 21.1049 95.6798 19.3858 93.4264 18.793C93.9601 18.3483 94.4938 18.0519 95.2647 18.0519C96.3618 18.0519 97.0141 18.793 97.1623 20.0082L98.0222 19.9193C100.216 19.7118 100.127 16.4217 97.9036 16.3921C97.9036 16.3921 97.8739 16.3921 97.8443 16.3921C96.2135 16.3921 94.138 17.8445 93.0114 18.6744C92.6556 18.6151 92.2998 18.5855 91.944 18.5558H91.6178C88.7714 18.5855 85.7472 20.4528 85.7472 23.8615C85.7472 25.2843 86.2809 26.4699 87.1407 27.3295C86.3105 28.4855 85.1542 30.3232 85.1542 31.7756C85.1542 33.7912 86.6367 35.2139 88.3563 35.2732H93.2782H95.1461C96.7472 35.2732 97.7553 35.955 97.7553 37.2295C97.7553 37.8223 97.5774 38.2966 97.3106 38.7115C97.2216 38.8301 97.1623 38.919 97.0734 39.0376L96.8955 39.2451C96.8658 39.2747 96.8658 39.3044 96.8658 39.334C96.8658 39.4229 96.9251 39.4822 97.0141 39.4822C97.0734 39.4822 97.1327 39.4822 97.1623 39.4229C97.2809 39.2747 97.3995 39.1265 97.5181 38.9487C97.5774 38.8598 97.6367 38.8005 97.696 38.7115C97.8443 38.5041 97.9925 38.2669 98.1408 38.0298C98.2001 37.9112 98.289 37.8223 98.3483 37.7038C98.9117 36.7256 99.4157 35.5993 99.4157 34.6508C99.4157 32.6352 97.9332 31.2124 96.2135 31.1532ZM91.6771 18.9708C93.1003 18.9708 93.7229 20.8678 93.7229 23.8022C93.7229 26.7367 93.1003 28.6633 91.6771 28.6633C90.2836 28.6633 89.6906 26.7367 89.6906 23.8022C89.6906 20.8678 90.2836 18.9708 91.6771 18.9708Z"
      fill="#3A3335"
    />
    <path
      d="M112.852 25.4028C112.763 25.4028 112.734 25.4325 112.734 25.4325C111.904 31.4199 107.634 31.8645 104.046 31.8645L112.467 18.9708V18.704H100.815L99.3618 24.2765C99.3618 24.2765 99.3618 24.3061 99.4508 24.3061C99.5101 24.3061 99.5397 24.2765 99.5397 24.2765C100.933 18.9708 104.224 19.0004 107.249 19.0004L99.0357 31.3903C98.8281 31.7163 99.0653 32.1609 99.4508 32.1609H111.785L112.941 25.4325C112.941 25.4325 112.912 25.4028 112.852 25.4028Z"
      fill="#3A3335"
    />
    <path
      d="M116.714 14.9397C118.108 14.9397 119.235 13.8133 119.235 12.4202C119.235 11.0271 118.108 9.87109 116.714 9.87109C115.321 9.87109 114.165 11.0271 114.165 12.4202C114.165 13.8133 115.321 14.9397 116.714 14.9397ZM123 30.0861C123 29.9972 122.941 29.9379 122.852 29.9379C122.793 29.9379 122.763 29.9379 122.733 29.9675C122.111 30.5011 121.607 30.8567 120.687 30.8567C119.501 30.8567 118.849 29.9675 118.76 28.5448V18.2594H118.553C118.553 18.2594 115.351 19.8007 112.593 20.1268V20.3639C114.105 20.3639 114.55 21.3717 114.609 22.3202V29.3154C114.669 31.0346 116.092 32.5166 118.108 32.5166C119.739 32.5166 121.844 31.0642 122.941 30.2343C122.97 30.2047 123 30.1454 123 30.0861Z"
      fill="#3A3335"
    />
    <g clipPath="url(#clip0_1855_497)">
      <path
        d="M27.9482 14.3205C27.6748 18.0594 29.5153 21.2446 32.0678 21.4362C34.6204 21.6279 36.9048 18.7485 37.1782 15.0096C37.4515 11.2707 35.6111 8.08545 33.0585 7.8956C30.506 7.70576 28.2233 10.5816 27.9482 14.3205Z"
        fill="#FF6B00"
      />
      <path
        d="M15.8175 7.95342C16.2563 12.0228 18.9046 15.0692 21.7375 14.758C24.5704 14.4469 26.4996 10.8908 26.0609 6.82664C25.6221 2.76251 22.9737 -0.287344 20.1409 0.0237939C17.308 0.334932 15.377 3.88402 15.8175 7.95342Z"
        fill="#FF6B00"
      />
      <path
        d="M5.07197 14.6528C7.14745 17.9541 10.7621 19.3902 13.1475 17.8627C15.533 16.3351 15.7889 12.4186 13.6995 9.11916C11.6101 5.81969 8.00759 4.38178 5.62392 5.90935C3.24025 7.43691 2.99649 11.3534 5.07197 14.6528Z"
        fill="#FF6B00"
      />
      <path
        d="M2.60695 28.0524C5.25876 30.5134 8.80728 30.9634 10.531 29.0667C12.2548 27.17 11.5026 23.642 8.85081 21.1828C6.199 18.7235 2.65223 18.2718 0.926722 20.1685C-0.798783 22.0652 -0.0448608 25.5949 2.60695 28.0524Z"
        fill="#FF6B00"
      />
      <path
        d="M26.5449 35.2414C26.5449 35.2414 40.6955 36.6934 35.3274 27.5579C31.0337 20.2523 21.358 19.456 17.6423 21.8942C14.1234 24.2022 8.00662 37.3104 12.9829 40.5589C19.4461 44.783 18.4467 36.6196 26.5449 35.2414Z"
        fill="#FF6B00"
      />
    </g>
    <defs>
      <clipPath id="clip0_1855_497">
        <rect
          width="37.2037"
          height="41.7593"
          fill="white"
          transform="matrix(-1 0 0 1 37.2041 0)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LogoColor;
