import { FC, useRef, useEffect } from 'react';
import { AppRoute } from 'const';
import { Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import { Listing } from 'components';
import { useAppDispatch, useAppSelector } from 'Root';
import { getSalons, getAllSalons } from 'state/reducers/salons/salonsActions';
import useCalculateSectionHeight from 'utils/useCalculateSectionHeight';
import Layout from '../components/Shared/Layout';

const Salons: FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);

  const { localizeRouteKey } = useLocalizeRoute();

  const dispatch = useAppDispatch();
  const allSalons = useAppSelector(({ salons }) => salons.allSalons);
  const salonsOnPage = useAppSelector(({ salons }) => salons);
  const reservations = useAppSelector(
    ({ reservation }) => reservation.reservations
  );

  const history = useHistory();
  const match = useRouteMatch<{ page: string }>();
  const { params } = match;

  useCalculateSectionHeight(sectionRef);

  useEffect(() => {
    dispatch(getSalons({ page: params.page, pagination: true }));
  }, [salonsOnPage.search]);

  useEffect(() => {
    setPage(1);
  }, [salonsOnPage.search.location, salonsOnPage.search.categories]);

  useEffect(() => {
    dispatch(getAllSalons());
  }, []);

  const setURLParams = (page: number) => {
    dispatch(getSalons({ page: String(page), pagination: true }));
    history.push(
      localizeRouteKey(AppRoute.List).replace(':page?', String(page))
    );
  };

  const prevPage = () => {
    const currentPage = salonsOnPage.page;
    const newPage = currentPage > 1 ? currentPage - 1 : 1;
    setURLParams(newPage);
  };

  const setPage = (page: number) => {
    const newPage = page > 0 && page <= salonsOnPage.lastPage ? page : 1;
    setURLParams(newPage);
  };

  const nextPage = () => {
    const currentPage = salonsOnPage.page;
    const newPage = currentPage < salonsOnPage.lastPage ? currentPage + 1 : 1;
    setURLParams(newPage);
  };

  if (!params.page) {
    return (
      <Redirect
        to={localizeRouteKey(AppRoute.List).replace(':page?', String(1))}
      />
    );
  }

  return (
    <Layout hasHeader showFilters sticky={{ zIndex: 3 }} skipLoadSalons={true}>
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        ref={sectionRef}
      >
        <Listing
          {...salonsOnPage}
          allSalons={allSalons}
          prevPage={prevPage}
          setPage={setPage}
          nextPage={nextPage}
          reservations={reservations}
        />
      </div>
    </Layout>
  );
};

export { Salons };
