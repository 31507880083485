import styled, { css } from 'styled-components'
import media from 'theme/respondTo'

export const SOpeningHoursEditor = styled.div`
  ${({ theme }) => css`
    .row {
      min-height: 128px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      padding: ${theme.space.md}px 0;

      border-bottom: 1px solid #ede8e9;

      [type='checkbox']:checked + label {
        cursor: pointer;
      }

      label {
        display: block;
        width: 180px;
      }
    }

    .opening-hours-input {
      display: flex;
    }

    .time-input {
      max-width: 174px;
      width: 100%;
      height: 48px;

      font-size: 1rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.31rem;
      color: #908e8e;

      padding: 0 ${theme.space.md}px;
      margin-right: ${theme.space.xl}px;
      margin-top: ${theme.space.md}px;

      border: 1px solid #ede8e9;
      border-radius: 8px;

      &:focus {
        outline: none;
        border-color: ${theme.colors.primary};
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    ${media.md`
      .row {
        flex-direction: row;
        align-items: center;

        padding: 0;
      }

      .time-input {
        margin-top: 0;
      }
    `}
  `}
`
