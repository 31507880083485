import React from 'react';
import { SEmployee } from './Employee.styled';
import { Slot } from '../../const';
import SmallCheck from '../icons/SmallCheck';
import AvatarPlaceholder from 'components/icons/AvatarPlaceholder';

interface EmployeeProps extends Slot {
  onClick: (id: number) => void;
  isSelected: boolean;
}

const Employee = ({ name, avatar, id, onClick, isSelected }: EmployeeProps) => {
  return (
    <SEmployee isSelected={isSelected}>
      <button className="employee" onClick={() => onClick(id)}>
        <div className="wrapper">
          {isSelected && (
            <div className="selected-indicator">
              <SmallCheck />
            </div>
          )}
          <div className="avatar-wrapper">
            {avatar ? <img src={avatar} alt={name} /> : <AvatarPlaceholder />}
          </div>
        </div>
        <p className="name">{name}</p>
      </button>
    </SEmployee>
  );
};

export default Employee;
