import React, { useState, useEffect } from 'react';
import CategoryPill from '../../Shared/CategoryPill';
import ReactMapGL, { WebMercatorViewport } from 'react-map-gl';
import { ICategory } from '../../../const/filter-categories';
import { SMap } from './Map.styled';
import config from '../../../const/config';
import { useAppSelector } from '../../../Root';
import { useSalonsMarkers } from '../../../hooks/useSalonsMarkers';
import { useFitBounds } from '../../../hooks/useFitBounds';

interface MapProps {
  categories: ICategory[];
  selected: number[];
  onSelect: (category: ICategory) => void;
}

const Map = ({ categories, selected, onSelect }: MapProps) => {
  const objects = useAppSelector(({ salons }) => salons.data);

  const fitBounds = useFitBounds(objects);

  const [viewport, setViewport] = useState(
    new WebMercatorViewport({
      width: 394,
      height: 950,
    }).fitBounds(fitBounds as [[number, number], [number, number]], {
      padding: 60,
      offset: [10, 0],
      maxZoom: 16,
    })
  );

  useEffect(() => {
    if (fitBounds.length === 0) {
      setViewport(
        new WebMercatorViewport({
          width: 394,
          height: 950,
        })
      );

      return;
    }

    setViewport(
      new WebMercatorViewport({
        width: 394,
        height: 950,
      }).fitBounds(fitBounds, { padding: 60, offset: [10, 0], maxZoom: 16 })
    );
  }, [fitBounds]);

  const { markers } = useSalonsMarkers(objects);

  return (
    <SMap>
      <div className="map-filters">
        {categories.map((category) => (
          <CategoryPill
            key={category.id}
            {...category}
            active={selected.includes(category.id)}
            onSelect={() => onSelect(category)}
          />
        ))}
      </div>
      {markers.length === 0 ? (
        <div>Brak obiektów. Wybierz kategorie</div>
      ) : (
        <ReactMapGL
          {...viewport}
          className="map"
          mapStyle="mapbox://styles/mapbox/streets-v11"
          mapboxApiAccessToken={config.MAPBOX_TOKEN}
          minZoom={4.5}
          width="100%"
          onViewportChange={(nextViewport: any) => {
            setViewport(nextViewport);
          }}
        >
          {markers}
        </ReactMapGL>
      )}
    </SMap>
  );
};

export default Map;
