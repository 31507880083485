import React from 'react';
import { Autocomplete } from '../../index';
import { loadAutocompleteVoivodeship } from '../../../api/loadAutocompleteLocations';
import { selectStyles } from '../../Shared/AutocompleteInput';
import SuggestedCity from './SuggestedCity';
import { useIntl } from 'react-intl';
import { useAppSelector } from '../../../Root';
import { OptionTypeBase } from 'react-select';

interface CitiesProps {
  onSelect: (value: OptionTypeBase) => void;
  onPickSuggestedCity: (id: number, name: string) => void;
  cities: { id: number; name: string }[];
  onSubmit: () => void;
}

const Cities = ({
  onSelect,
  cities,
  onPickSuggestedCity,
  onSubmit,
}: CitiesProps) => {
  const { formatMessage } = useIntl();

  const search = useAppSelector(({ salons }) => salons.search);

  return (
    <div className="cities-wrapper">
      <div className="filters-overlay__voivodeship">
        <Autocomplete
          load={loadAutocompleteVoivodeship}
          placeholder={formatMessage({
            id: 'app.basic-search.voivodeship-placeholder',
          })}
          customStyles={{
            ...selectStyles,
            container: (styles) => ({
              ...styles,
              border: '1px solid #EDE8E9',
              borderRadius: 24,
              cursor: 'pointer',
              padding: '5px 10px',
              width: '100%',
            }),
          }}
          onChange={onSelect}
          value={search.location?.district ? search.location : null}
        />
      </div>

      <ul className="cities-list">
        {cities.map(({ id, name }) => (
          <SuggestedCity
            key={id}
            name={name}
            onClick={() => onPickSuggestedCity(id, name)}
          />
        ))}
      </ul>

      <div className="button-wrapper">
        <button onClick={onSubmit} className="submit-button">
          Wybierz
        </button>
      </div>
    </div>
  );
};

export default Cities;
