import styled from 'styled-components';

export const SCategory = styled.li`
  align-items: center;
  border-bottom: 1px solid #ede8e9;
  display: flex;
  padding: 17px 0;

  button {
    align-items: center;
    color: #000;
    display: flex;
    justify-content: space-between;
    text-align: left;
    width: 100%;
  }

  .category-content {
    display: flex;
    column-gap: 8px;
    line-height: 28px;
  }

  svg {
    display: flex;
    justify-self: flex-end;
  }
`;
