import styled, { css } from 'styled-components';
import media from '../../theme/respondTo';

export const SPromoted = styled.section`
  ${({ theme }) => css`
    background-color: #f6f6f6;
    border-radius: 0;
    display: flex;
    justify-content: center;
    padding: 40px 25px 30px;
    margin: 0 auto;
    overflow: hidden;
    max-width: 1334px;
    width: 100%;

    ${media.md`
        border-radius: 20px;
        padding: 51px 0 43px;
      `}

    .content {
      align-items: center;
      display: flex;
      flex-flow: column;
      position: relative;
      max-width: 1300px;
      width: 100%;
      z-index: 1;
    }

    .heading-wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      ${media.md`
        justify-content: center;
      `}
    }

    .heading {
      font-size: 28px;
      font-weight: ${theme.weight.bold};
      line-height: 2.25rem;
      text-align: left;
      color: #304659;

      ${media.md`
        text-align: center;
        font-size: 30px;
      `}
    }

    .mobile-buttons {
      display: flex;
      column-gap: 29px;
      justify-content: flex-end;

      ${media.md`
        display: none;
      `}
    }

    .slider-wrapper {
      display: flex;
      column-gap: 28px;
      max-width: 1138px;
      width: 100%;

      button {
        display: none;

        ${media.md`
        display: flex;
      `}
      }
    }

    .slider {
      overflow: hidden;
      margin: 32px 0 0;
      width: 100%;

      .swiper-container {
        min-height: 288px;
        overflow: visible;
      }
    }

    .swiper-slide {
      max-width: 100%;
      width: 370px;
    }

    .swiper-pagination-bullet {
      align-items: center;
      border: 1px solid #8f95b2;
      border-radius: 50%;
      display: flex;
      height: 20px;
      justify-content: center;
      width: 20px;
    }

    .active-fill {
      background-color: ${theme.colors.primary};
      border-radius: 50%;
      height: 10px;
      width: 10px;
    }

    .pagination-wrapper {
      display: flex;
      margin-top: 24px;
      justify-content: center;
      width: 100%;

      ${media.md`
         margin-top: 35px;
      `}
    }

    .swiper-pagination {
      display: flex;
      column-gap: 8px;
    }
  `}
`;
