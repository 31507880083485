import { ChangeEvent, FC } from 'react';
import { useIntl } from 'react-intl';
import Cleave from 'cleave.js/react';

import { OpeningHour } from 'const';
import { SOpeningHoursEditor } from './OpeningHoursEditor.styled';
import { Checkbox } from 'components';
import { useController } from 'react-hook-form';
import autoFormatTimeInput from 'utils/autoFormatTimeInput';

interface IOpeningHoursEditor {
  openingHours?: OpeningHour[];
  control: any;
}

const OpeningHoursEditor: FC<IOpeningHoursEditor> = ({ control }) => {
  const { formatMessage } = useIntl();
  const {
    field: { value, onChange },
  } = useController({
    name: 'openingHours',
    control,
  });

  const handleChange =
    (name: string, day: string) => (event: ChangeEvent<HTMLInputElement>) => {
      onChange({
        target: {
          value: value.reduce((acc: OpeningHour[], current: OpeningHour) => {
            if (current.dayOfWeek === day) {
              return [...acc, { ...current, [name]: event.target.value }];
            }

            return [...acc, current];
          }, []),
        },
      });
    };

  const handleBlur =
    (name: string, day: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const correctValue = autoFormatTimeInput(event.target.value);

      onChange({
        target: {
          value: value.reduce((acc: OpeningHour[], current: OpeningHour) => {
            if (current.dayOfWeek === day) {
              return [...acc, { ...current, [name]: correctValue }];
            }

            return [...acc, current];
          }, []),
        },
      });
    };

  const handleCheckboxChange =
    (day: string) => (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) return;
      onChange({
        target: {
          value: value.reduce((acc: OpeningHour[], current: OpeningHour) => {
            if (current.dayOfWeek === day) {
              return [...acc, { ...current, timeStart: '', timeEnd: '' }];
            }

            return [...acc, current];
          }, []),
        },
      });
    };

  if (!value) return null;
  return (
    <SOpeningHoursEditor>
      {value.map((openingHour: OpeningHour) => {
        const valid = !!openingHour.timeStart && !!openingHour.timeEnd;

        return (
          <div
            className="row"
            key={`opening-hour-row-${openingHour.dayOfWeek}`}
          >
            <Checkbox>
              <input
                checked={valid}
                type="checkbox"
                id={`opening-hour-${openingHour.dayOfWeek}`}
                onChange={handleCheckboxChange(openingHour.dayOfWeek)}
              />
              <label htmlFor={`opening-hour-${openingHour.dayOfWeek}`}>
                {formatMessage({
                  id: `app.profile.day-${openingHour.dayOfWeek}`,
                })}
              </label>
            </Checkbox>
            <div className="opening-hours-input">
              <Cleave
                value={openingHour.timeStart}
                placeholder="HH:MM"
                options={{ time: true, timePattern: ['h', 'm'] }}
                className="time-input"
                onChange={handleChange('timeStart', openingHour.dayOfWeek)}
                onBlur={handleBlur('timeStart', openingHour.dayOfWeek)}
              />
              <Cleave
                value={openingHour.timeEnd}
                placeholder="HH:MM"
                options={{ time: true, timePattern: ['h', 'm'] }}
                className="time-input"
                onChange={handleChange('timeEnd', openingHour.dayOfWeek)}
                onBlur={handleBlur('timeEnd', openingHour.dayOfWeek)}
              />
            </div>
          </div>
        );
      })}
    </SOpeningHoursEditor>
  );
};

export default OpeningHoursEditor;
