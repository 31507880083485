import styled from 'styled-components';
import media from 'theme/respondTo';

export const SCategories = styled.div`
  display: flex;
  justify-content: center;
  padding: 82px 0 40px 25px;
  position: relative;
  z-index: 0;

  ${media.md`
       padding: 52px 0 60px;
    `}

  .content {
    display: flex;
    column-gap: 27px;
    max-width: 1259px;
    width: 100%;

    button {
      display: none;

      ${media.lg`
          display: flex;
        `}
    }
  }

  .swiper-container {
    width: 100%;
  }

  .swiper-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;
