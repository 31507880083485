import styled, { css } from 'styled-components';

export const SSliderPagination = styled.div`
  ${({ theme }) => css`
    .swiper-pagination {
      display: flex;
      column-gap: 8px;
      width: 100%;
    }

    .swiper-pagination-bullet {
      align-items: center;
      border: 1px solid #8f95b2;
      border-radius: 50%;
      display: flex;
      height: 20px;
      justify-content: center;
      width: 20px;

      &--clickable {
        cursor: pointer;
      }

      &--active {
        background-color: ${theme.colors.primary};
        border-radius: 50%;
        height: 10px;
        width: 10px;
      }
    }
  `}
`;
