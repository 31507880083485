import React from 'react';

interface BurgerMenuButtonProps {
  onClick: () => void;
  opened: boolean;
}

const BurgerMenuButton = ({ onClick, opened }: BurgerMenuButtonProps) => (
  <button onClick={onClick} className="mobile-menu-button">
    {opened ? (
      <svg
        width="28"
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="28"
          height="3"
          rx="1.5"
          transform="matrix(-0.734622 0.678477 0.678477 0.734622 20.5703 0)"
          fill="#FF6B00"
        />
        <rect
          x="2.03516"
          width="28"
          height="3"
          rx="1.5"
          transform="rotate(42.7247 2.03516 0)"
          fill="#FF6B00"
        />
      </svg>
    ) : (
      <svg
        width="28"
        viewBox="0 0 28 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="28" height="3" rx="1.5" fill="#FF6B00" />
        <rect y="10" width="28" height="3" rx="1.5" fill="#FF6B00" />
        <rect y="20" width="28" height="3" rx="1.5" fill="#FF6B00" />
      </svg>
    )}
  </button>
);

export default BurgerMenuButton;
