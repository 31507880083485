import React from 'react';

const SmallCheck = () => (
  <svg
    width="6"
    height="4"
    viewBox="0 0 6 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2411_3326)">
      <path
        d="M2.28032 4.00974C2.13944 4.00933 2.00436 3.95691 1.90412 3.86374L0.154921 2.20974C0.104107 2.16369 0.0635494 2.10857 0.0356141 2.04761C0.0076787 1.98664 -0.00707472 1.92105 -0.00778553 1.85467C-0.00849634 1.78828 0.00484992 1.72243 0.0314742 1.66094C0.0580985 1.59946 0.0974675 1.54359 0.147285 1.49658C0.197102 1.44957 0.25637 1.41236 0.321631 1.38713C0.386891 1.36191 0.456837 1.34916 0.527387 1.34964C0.597938 1.35012 0.667681 1.36382 0.732545 1.38994C0.79741 1.41605 0.856099 1.45406 0.905187 1.50174L2.28032 2.79374L5.09222 0.145745C5.19257 0.0547901 5.32687 0.00456388 5.46619 0.00588478C5.60551 0.00720569 5.73871 0.0599677 5.83709 0.152806C5.93547 0.245645 5.99116 0.371132 5.99217 0.502236C5.99318 0.633341 5.93943 0.759574 5.84249 0.853745L2.65439 3.85374C2.60616 3.90181 2.54825 3.94036 2.48402 3.96714C2.4198 3.99392 2.35055 4.00841 2.28032 4.00974Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2411_3326">
        <rect width="6" height="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SmallCheck;
