import React, { useEffect, useState } from 'react';
import { Duration } from 'luxon';
import { Button } from '../index';
import { SalonService } from '../../const';
import { SService } from './Service.styled';
import ClockIcon from '../icons/ClockIcon';
import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';

interface ServiceProps extends SalonService {
  onReserve: (event: MouseEvent, service: SalonService) => void;
  isLast?: boolean;
}

const Service = ({
  name,
  duration,
  category,
  price,
  id,
  description,
  onReserve,
  time,
  appointmentType,
  isLast,
}: ServiceProps) => {
  const [expandDescription, setExpandDescription] = useState(false);

  const DESCRIPTION_MAX_LENGTH = 40;

  const showVisibleDescription = () => {
    if (!description) return '';
    if (!expandDescription && description.length > DESCRIPTION_MAX_LENGTH) {
      return `${description.slice(0, 40)}...`;
    }
    return description;
  };

  return (
    <SService isLast={isLast}>
      <div className="listing-item__content">
        <p className="listing-item-services-name">{name}</p>

        <p className="description">
          {showVisibleDescription()}
          {description && description.length > DESCRIPTION_MAX_LENGTH && (
            <button
              className="description__button"
              onClick={() => setExpandDescription((prevState) => !prevState)}
            >
              {expandDescription ? 'Mniej' : 'Więcej'}
            </button>
          )}
        </p>
      </div>

      <div className="right-side">
        <div className="right-side__top">
          {!!duration && duration > 0 && (
            <div className="time-wrapper">
              <span className="time-wrapper__value">
                {Duration.fromObject({
                  minutes: duration,
                }).toFormat('h:mm')}
              </span>

              <ClockIcon />
            </div>
          )}

          <span className="time-wrapper__divider" />

          {price && <span className="price">{price} zł</span>}
        </div>

        <Button
          small
          primary
          className="listing-item-services-button"
          onClick={(event: MouseEvent) => {
            
              TagManager.dataLayer({
                dataLayer: {
                  event: 'click_umow'
                },
              });

              ReactPixel.track('trackCustom', {value: 'UmowWizyte'});

              onReserve(event, {
                category,
                name,
                duration,
                price,
                id,
                description,
                time,
                appointmentType,
              })
            }
          }
        >
          Umów
        </Button>
      </div>
    </SService>
  );
};

export default Service;
