import styled, { css } from 'styled-components';

export const SShareModal = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background-color: ${theme.colors.white};
    min-height: 100px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding: 0 0 20px;
    width: 100%;

    .header {
      flex-shrink: 0;

      height: 72px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      position: relative;

      border-bottom: 2px solid #eceef5;
      width: 100%;
    }

    .title {
      color: ${theme.colors.black};
      font-size: 28px;
      font-weight: ${theme.weight.bold};
      padding-left: 20px;
    }
    .close {
      padding-right: 20px;
    }

    .social-medias {
      display: flex;
      margin-top: 40px;
      column-gap: 20px;
    }

    li {
      height: 50px;
      width: 50px;
    }

    .media {
      height: 50px;
      width: 50px;
    }
  `}
`;
