import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FacebookLogin from '@greatsumini/react-facebook-login';

import { Button, TextInput } from 'components';
import {
  getClientUser,
  hideAuthModal,
  showAuthModal,
} from 'state/reducers/client/clientActions';
import { AuthModalType } from 'components/Header/Header';
import { useAppDispatch } from 'Root';
import client from 'api/client';
import formErrorsHandler from 'utils/formErrorsHandler';
import config from 'const/config';

const defaultFormValues = {
  email: '',
  password: '',
};

const AuthSignIn: FC = () => {
  const dispatch = useAppDispatch();

  const methods = useForm<{
    email: string;
    password: string;
  }>({
    defaultValues: defaultFormValues,
  });

  const showForgot = () => {
    dispatch(showAuthModal(AuthModalType.FORGOT_PASSWORD));
  };

  const signIn = async (values: any) => {
    await client('client/auth/login')
      .errorType('json')
      .catcher(
        422,
        formErrorsHandler<{
          email: string;
          password: string;
        }>(Object.keys(defaultFormValues), methods.setError)
      )
      .post(values)
      .unauthorized(() => {
        methods.setError('email', { type: 'required' });
        methods.setError('password', { type: 'required' });
      })
      .json((json) => {
        methods.reset(defaultFormValues);
        dispatch(hideAuthModal());
        const { data } = json;
        const { token } = data;

        localStorage.setItem(config.TOKEN_CLIENT_KEY, token);
        dispatch(getClientUser());
      })
      .catch((error) => {
        console.log('[POST] auth sign in error', error);
      });
  };

  const responseFacebook = async (response: any) => {
    const { accessToken } = response;
    if (!accessToken) return;

    await client('client/auth/social/login/facebook/callback')
      .errorType('json')
      .post({ accessToken })
      .json((json) => {
        dispatch(hideAuthModal());
        const { data } = json;
        const { token } = data;

        localStorage.setItem(config.TOKEN_CLIENT_KEY, token);
        dispatch(getClientUser());
      })
      .catch((error) => {
        console.log('[POST] auth fb sign in error', error);
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(signIn)}>
        <TextInput
          {...{
            id: 'email',
            name: 'email',
            label: 'Twój adres e-mail',
            icon: 'email',
            className: 'field',
          }}
        />
        <TextInput
          {...{
            id: 'password',
            name: 'password',
            label: 'Twoje hasło',
            type: 'password',
            className: 'field',
          }}
        />
        <button type="button" className="forgot-password" onClick={showForgot}>
          Odzyskaj hasło
        </button>
        <Button
          primary
          className="button"
          loading={methods.formState.isSubmitting}
          disabled={methods.formState.isSubmitting}
        >
          Zaloguj się
        </Button>

        <div className="or-separator">
          <span>lub</span>
        </div>

        {config.FB_APP_ID ? (
          <FacebookLogin
            appId={config.FB_APP_ID}
            onSuccess={responseFacebook}
            onFail={(error: any) => console.log(error)}
            render={(renderProps: any) => (
              <button className="fb" onClick={renderProps.onClick}>
                <span className="icon">
                  <svg
                    viewBox="0 0 9 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.40624 16.6086V9.45237H0V6.60863H2.40624V4.35863C2.40624 3.17114 2.73958 2.24926 3.40624 1.59302C4.07291 0.936767 4.95832 0.608643 6.06249 0.608643C6.95832 0.608643 7.68748 0.650309 8.24998 0.733642V3.26489H6.74998C6.18749 3.26489 5.80207 3.38989 5.59374 3.63989C5.42707 3.84822 5.34374 4.18155 5.34374 4.63988V6.60863H7.99998L7.62498 9.45237H5.34374V16.6086H2.40624Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
                Zaloguj się za pomocą Facebook
              </button>
            )}
          />
        ) : null}
      </form>
    </FormProvider>
  );
};

export default AuthSignIn;
