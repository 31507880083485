import React from 'react';

const MapIcon = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1974_4984)">
      <path
        d="M11.6214 5.28638C11.6214 5.98412 11.3766 6.85146 10.9863 7.79169L10.9863 24.6237L19.1894 28.999L19.1894 8.68879L11.5858 4.63477C11.6066 4.849 11.6214 5.0662 11.6214 5.28638Z"
        fill="#FF6B00"
      />
      <path
        d="M5.27441 16.7933L4.85298 16.2562C3.52621 14.5416 2.29659 12.7535 1.16992 10.9005L1.16992 29.006L9.37741 24.6306L9.37741 10.8945C8.25075 12.7475 7.02112 14.5356 5.69435 16.2503L5.27441 16.7933Z"
        fill="#FF6B00"
      />
      <path
        d="M20.7949 8.69177L20.7949 29.0006L29.0009 24.6252L29.0009 4.31641L20.7949 8.69177Z"
        fill="#FF6B00"
      />
      <path
        d="M5.27381 15.0438C7.0545 12.6709 10.5476 7.57544 10.5476 5.28734C10.5476 3.88505 9.99198 2.54019 9.00295 1.54862C8.01392 0.557057 6.67251 0 5.27381 0C3.87511 0 2.53369 0.557057 1.54466 1.54862C0.555631 2.54019 0 3.88505 0 5.28734C0 7.57693 3.48422 12.6709 5.27381 15.0438ZM5.27381 2.45771C5.83144 2.45771 6.37654 2.62348 6.84019 2.93408C7.30385 3.24468 7.66522 3.68614 7.87861 4.20265C8.09201 4.71915 8.14785 5.28749 8.03906 5.83581C7.93027 6.38413 7.66175 6.88779 7.26744 7.28311C6.87314 7.67842 6.37076 7.94764 5.82385 8.0567C5.27693 8.16577 4.71004 8.10979 4.19486 7.89585C3.67968 7.68191 3.23934 7.31961 2.92954 6.85476C2.61974 6.38992 2.45438 5.84342 2.45438 5.28436C2.45438 4.53469 2.75143 3.81572 3.28017 3.28561C3.80892 2.75551 4.52605 2.45771 5.27381 2.45771V2.45771Z"
        fill="#FF6B00"
      />
      <path
        d="M8.07546 5.63244C8.26678 4.0815 7.1678 2.66872 5.62082 2.47691C4.07384 2.28511 2.66468 3.38691 2.47336 4.93785C2.28205 6.4888 3.38103 7.90158 4.928 8.09339C6.47498 8.2852 7.88415 7.18339 8.07546 5.63244Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1974_4984">
        <rect width="29" height="29" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MapIcon;
