import { FC } from 'react';

import { SProfileReservationSuccess } from './ReservationSuccess.styled';
import { AppAuthPageIcon, Button, UserModal } from 'components';
import { Booking } from 'const';
import { DateTime, Duration } from 'luxon';
import { useIntl } from 'react-intl';
import { SUserModalOverlay } from 'components/UserModal/UserModal.styled';
import { formatDistanceStrict } from 'date-fns';
import { useAppDispatch } from 'Root';
import { showAuthModal } from 'state/reducers/client/clientActions';
import { AuthModalType } from 'components/Header/Header';

interface IReservationSuccess {
  summary: Booking[];
  close: () => void;
}
const ReservationSuccess: FC<IReservationSuccess> = ({ summary, close }) => {
  const { formatNumber } = useIntl();

  const dispatch = useAppDispatch();
  const reserveNext = () => {
    close();
    dispatch(showAuthModal(AuthModalType.PHONE_NUMBER_RESERVATION));
  };
  return (
    <UserModal wide>
      <SUserModalOverlay onClick={close} />
      <SProfileReservationSuccess>
        <div className="reservation-success-header">
          <h2 className="reservation-success-heading">Potwierdzenie</h2>
          <button
            className="reservation-success-close"
            type="button"
            onClick={close}
          >
            <span className="material-icons">close</span>
          </button>
        </div>
        <div className="reservation-success-content">
          <AppAuthPageIcon className="reservation-success-icon">
            <span className="material-icons">check</span>
          </AppAuthPageIcon>
          <h3 className="reservation-success-icon-heading">
            Wizyta potwierdzona
          </h3>

          <div className="reservation-success-service-content">
            {summary.map((item: Booking, index) => {
              const isHotel = item.service?.appointmentType === 'range';
              const time = DateTime.fromMillis(item.timeStart * 1000).toFormat(
                isHotel ? 'dd LLLL kkkk' : "dd LLLL kkkk '|' HH:mm"
              );
              const endDate = isHotel
                ? DateTime.fromMillis(item.timeEnd * 1000).toFormat(
                    'dd LLLL kkkk'
                  )
                : null;

              const duration = item.service.duration
                ? Duration.fromMillis(item.service.duration * 60000).toFormat(
                    "h'h' mm'min'"
                  )
                : null;

              const [days] = formatDistanceStrict(
                new Date(item.timeStart * 1000),
                new Date(item.timeEnd * 1000)
              ).split(' ');

              const price =
                item.service.appointmentType === 'range'
                  ? item.service.price * (Number(days) + 1)
                  : item.service.price;

              return (
                <div key={index} className="reservation-success-service-item">
                  <div>
                    <span className="reservation-success-service-time">
                      {time}
                      {isHotel ? ` - ${endDate}` : ''}
                    </span>
                    <p className="reservation-success-service-name">
                      {item.service.name}{' '}
                      {duration ? <span>{duration}</span> : null}
                    </p>
                  </div>
                  <p className="reservation-success-service-price">
                    +{' '}
                    {formatNumber(price, {
                      style: 'currency',
                      currency: 'PLN',
                      currencyDisplay: 'narrowSymbol',
                    })}
                  </p>
                </div>
              );
            })}
          </div>

          <div className="reservation-success-actions">
            <Button
              bordered
              type="button"
              className="reservation-success-cancel"
              onClick={reserveNext}
            >
              Umów kolejną rezerwację
            </Button>
            <Button
              type="button"
              className="reservation-success-cancel"
              onClick={close}
            >
              Ok
            </Button>
          </div>
        </div>
      </SProfileReservationSuccess>
    </UserModal>
  );
};

export default ReservationSuccess;
