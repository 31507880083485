import 'components/AppReservationEditor/calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import {
  Provider,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ModalProvider } from 'styled-react-modal';

import { AppLanguage } from 'const';
import { LocalizedRouter, appStrings } from 'modules/i18n';
import { BreakpointProvider, defaultQueries } from 'modules/breakpoint';

import ThemeCustomization from 'theme/ThemeCustomization';
import createStore, { RootState } from 'state/createStore';
import theme from 'theme';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import { CookiesAlert, Gallery, ReservationModal } from 'components';
import config from 'const/config';
import { Settings } from 'luxon';

(mapboxgl as any).workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = config.MAPBOX_TOKEN as string;
const store = createStore();
registerLocale('pl', pl);
Settings.defaultLocale = 'pl';
Settings.defaultZoneName = 'Europe/Warsaw';

export const useAppDispatch = () => useDispatch();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const Root: React.FC = () => {
  return (
    <LocalizedRouter
      RouterComponent={BrowserRouter}
      languages={AppLanguage}
      appStrings={appStrings}
    >
      <Provider store={store}>
        <BreakpointProvider queries={defaultQueries}>
          <ThemeProvider theme={theme}>
            <ModalProvider>
              <ThemeCustomization />
              <Gallery />
              <ReservationModal />
              <App />
              <CookiesAlert />
            </ModalProvider>
          </ThemeProvider>
        </BreakpointProvider>
        <ToastContainer position="top-right" />
      </Provider>
    </LocalizedRouter>
  );
};
