import { useEffect, useState, FC } from 'react';
import { useAppSelector } from '../../Root';
import { DateTime } from 'luxon';
import { SDatepicker } from './Datepicker.styled';
import { Button } from 'components';
import { head } from 'lodash';
import { getDay } from 'date-fns';

enum PickerType {
  WEEK,
  MONTH,
}

interface IDatepicker {
  selectedDate: [number] | [number, number] | null;
  onSelectDate: (date: Date) => void;

  multi?: boolean;
  onChangeMonth?: (month: number) => void;
  availableDays?: number[];
}

const Datepicker: FC<IDatepicker> = ({
  selectedDate,
  onSelectDate,
  multi,
  onChangeMonth,
  availableDays,
}) => {
  const [displayType, setDisplayType] = useState<PickerType>(PickerType.WEEK);
  const [offsetDays, setOffsetDays] = useState<undefined[]>([]);
  const [currentWeek, setCurrentWeek] = useState<DateTime>(
    DateTime.fromMillis(
      selectedDate?.length ? selectedDate[0] : DateTime.local().toMillis()
    )
  );
  const [dates, setDates] = useState<number[]>([]);

  const { reservationHours } = useAppSelector((state) => state.reservation);

  const handlePrev = () =>
    setCurrentWeek(
      DateTime.fromMillis(currentWeek.toMillis()).minus(
        displayType === PickerType.WEEK ? { week: 1 } : { month: 1 }
      )
    );

  const handleNext = () =>
    setCurrentWeek(
      DateTime.fromMillis(currentWeek.toMillis()).plus(
        displayType === PickerType.WEEK ? { week: 1 } : { month: 1 }
      )
    );

  const toggleDisplayType = () =>
    setDisplayType(
      displayType === PickerType.WEEK ? PickerType.MONTH : PickerType.WEEK
    );

  useEffect(() => {
    const weekStartDay = currentWeek
      .startOf(displayType === PickerType.WEEK ? 'week' : 'month')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const weekEndDay = currentWeek
      .endOf(displayType === PickerType.WEEK ? 'week' : 'month')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    let day = 0;
    const days = [weekStartDay.toMillis()];
    do {
      const next = DateTime.fromJSDate(
        day ? new Date(day) : new Date(weekStartDay.toMillis())
      )
        .plus({ day: 1 })
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toMillis();

      day = next;
      days.push(next);
    } while (day < weekEndDay.toMillis());

    setDates(days);
  }, [currentWeek, displayType, reservationHours]);

  useEffect(() => {
    if (!multi) return;
    onChangeMonth && onChangeMonth(currentWeek.month);
  }, [currentWeek.month]);

  useEffect(() => {
    if (dates.length === 0) return;

    const dayOfWeek = getDay(new Date(dates[0]));
    const sunday = dayOfWeek === 0;

    setOffsetDays(Array.from({ length: sunday ? 6 : dayOfWeek - 1 }));
  }, [dates]);

  return (
    <SDatepicker>
      <div className="picker-months">
        <button
          className="picker-months-prev"
          type="button"
          onClick={handlePrev}
        >
          <span className="material-icons">arrow_back</span>
        </button>
        <p className="picker-months-selected">
          {currentWeek.monthLong} {currentWeek.year}
        </p>
        <button
          className="picker-months-next"
          type="button"
          onClick={handleNext}
        >
          <span className="material-icons">arrow_forward</span>
        </button>
      </div>
      <ul className="picker-weekdays">
        <li className="picker-weekday">Pn</li>
        <li className="picker-weekday">Wt</li>
        <li className="picker-weekday">Śr</li>
        <li className="picker-weekday">Czw</li>
        <li className="picker-weekday">Pt</li>
        <li className="picker-weekday picker-weekday--saturday">Sob</li>
        <li className="picker-weekday picker-weekday--sunday">Nd</li>
      </ul>
      <div className="picker-days">
        {offsetDays.map((_, index) => (
          <span key={index} />
        ))}
        {dates.map((dateTimestamp) => {
          const date = DateTime.fromMillis(dateTimestamp);
          const day = date.day;

          const selected = head(selectedDate);
          const selectedTimestamp = selected
            ? DateTime.fromMillis(selected)
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .toMillis()
            : null;

          let active = dateTimestamp === selectedTimestamp;

          if (
            multi &&
            selectedTimestamp &&
            selectedDate &&
            (selectedDate?.length ?? 0) > 1
          ) {
            const rangeEndSelected = (selectedDate as [number, number])[1];
            const rangeEndSelectedTimestamp = DateTime.fromMillis(
              rangeEndSelected
            )
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .toMillis();

            active =
              dateTimestamp >= selectedTimestamp &&
              dateTimestamp <= rangeEndSelectedTimestamp;
          }

          const today = DateTime.local()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toMillis();
          const past = dateTimestamp < today;

          return (
            <Button
              key={dateTimestamp}
              disabled={
                (multi && !availableDays?.includes(dateTimestamp)) || past
              }
              bordered={!active}
              className="picker-day"
              type="button"
              onClick={onSelectDate(date.toJSDate())}
            >
              {day}
            </Button>
          );
        })}
      </div>
      <button
        type="button"
        className="picker-toggle"
        onClick={toggleDisplayType}
      >
        {displayType === PickerType.WEEK
          ? 'Zobacz wszystkie dni'
          : 'Zobacz tydzień'}
      </button>
    </SDatepicker>
  );
};

export default Datepicker;
