import React from 'react'

import {
  ProfileHeader,
  ProfileReservationCancelation,
  ReviewEditor,
} from 'components'

const AppLayout: React.FC = ({ children }) => {
  return (
    <>
      <ProfileHeader />
      <main>{children}</main>
      <ReviewEditor />
      <ProfileReservationCancelation />
    </>
  )
}

export default AppLayout
