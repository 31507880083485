import React from 'react';

interface FiltersSliderControlProps {
  onPrev: () => void;
  onNext: () => void;
}

const FiltersSliderControl = ({
  onPrev,
  onNext,
}: FiltersSliderControlProps) => {
  return (
    <div className="control-wrapper">
      <button className="slider-control" onClick={onPrev}>
        <svg
          width="8"
          height="15"
          viewBox="0 0 8 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.36627 0.21967C7.65916 0.512563 7.65916 0.987437 7.36627 1.28033L1.57337 7.07322C1.47574 7.17085 1.47574 7.32915 1.57337 7.42678L7.36627 13.2197C7.65916 13.5126 7.65916 13.9874 7.36627 14.2803C7.07337 14.5732 6.5985 14.5732 6.30561 14.2803L0.512714 8.48744C-0.170704 7.80402 -0.170702 6.69598 0.512715 6.01256L6.30561 0.21967C6.5985 -0.0732234 7.07337 -0.0732233 7.36627 0.21967Z"
            fill="#FF6B00"
          />
        </svg>
      </button>

      <button className="slider-control" onClick={onNext}>
        <svg
          width="8"
          height="15"
          viewBox="0 0 8 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.21967 14.2803C-0.0732233 13.9874 -0.0732234 13.5126 0.21967 13.2197L6.01256 7.42678C6.11019 7.32915 6.11019 7.17085 6.01256 7.07322L0.219669 1.28033C-0.0732239 0.987437 -0.0732239 0.512563 0.219669 0.21967C0.512562 -0.0732231 0.987436 -0.0732232 1.28033 0.21967L7.07322 6.01256C7.75664 6.69598 7.75664 7.80402 7.07322 8.48744L1.28033 14.2803C0.987437 14.5732 0.512563 14.5732 0.21967 14.2803Z"
            fill="#FF6B00"
          />
        </svg>
      </button>
    </div>
  );
};

export default FiltersSliderControl;
