import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Checkbox, TextInput } from 'components';
import { ClientRegisterBase } from 'const';
import { useAppDispatch } from 'Root';
import { showAuthModal } from 'state/reducers/client/clientActions';
import { AuthModalType } from 'components/Header/Header';
import formErrorsHandler from 'utils/formErrorsHandler';
import client from 'api/client';

const defaultFormValues: ClientRegisterBase = {
  email: '',
  name: '',
  surname: '',
  password: '',
  passwordConfirmation: '',
  phone: '',
  terms: false,
};

const AuthSignUp: FC<{ appLinks: { [key: string]: string } }> = ({
  appLinks,
}) => {
  const dispatch = useAppDispatch();

  const methods = useForm<ClientRegisterBase>({
    defaultValues: defaultFormValues,
  });

  const signUp = async (values: ClientRegisterBase) => {
    await client('client/auth/register')
      .errorType('json')
      .catcher(
        422,
        formErrorsHandler<ClientRegisterBase>(
          Object.keys(defaultFormValues),
          methods.setError
        )
      )
      .post(values)
      .json((response) => {
        if(response.errors){
          if(response.errors.email){
            methods.setError('email', { type: 'required' });
          }
          if(response.errors.name){
            methods.setError('name', { type: 'required' });
          }
          if(response.errors.surname){
            methods.setError('surname', { type: 'required' });
          }
          if(response.errors.password){
            methods.setError('password', { type: 'required' });
          }
          if(response.errors.passwordConfirmation){
            methods.setError('passwordConfirmation', { type: 'required' });
          }
          if(response.errors.phone){
            methods.setError('phone', { type: 'required' });
          }
          if(response.errors.terms){
            methods.setError('terms', { type: 'required' });
          }
        }else{
          methods.reset(defaultFormValues);
          dispatch(showAuthModal(AuthModalType.SIGN_UP_SUCCESS));
        }
      })
      .catch((error) => {
        console.log('[POST] auth register error', error);
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(signUp)}>
        <TextInput
          {...{
            id: 'email',
            name: 'email',
            label: 'Email',
            icon: 'email',
            className: 'field',
          }}
        />
        <div className="row">
          <TextInput
            {...{
              id: 'name',
              name: 'name',
              label: 'Imię',
              icon: 'person',
              className: 'field',
            }}
          />
          <TextInput
            {...{
              id: 'surname',
              name: 'surname',
              label: 'Nazwisko',
              icon: 'person',
              className: 'field',
            }}
          />
        </div>
        <TextInput
          {...{
            id: 'password',
            name: 'password',
            label: 'Hasło',
            type: 'password',
            className: 'field',
          }}
        />
        <TextInput
          {...{
            id: 'passwordConfirmation',
            name: 'passwordConfirmation',
            label: 'Powtórz Hasło',
            type: 'password',
            className: 'field',
          }}
        />
        <TextInput
          {...{
            id: 'phone',
            name: 'phone',
            label: 'Nr telefonu',
            type: 'text',
            className: 'field',
          }}
        />
        <Checkbox
          circle
          error={!!methods.formState.errors?.terms}
          className="terms"
        >
          <input type="checkbox" id="terms" {...methods.register('terms')} />
          <label htmlFor="terms">
            Zgadzam się z{' '}
            <a href={appLinks.terms} target="_blank" rel="noopener noreferrer">
              regulaminem
            </a>
          </label>
        </Checkbox>
        <Button
          primary
          className="button"
          loading={methods.formState.isSubmitting}
          disabled={methods.formState.isSubmitting}
        >
          Dalej
        </Button>
      </form>
    </FormProvider>
  );
};

export default AuthSignUp;
