import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export default styled.div<{ base?: boolean }>`
  ${({ theme, base }) => css`
    padding-top: ${theme.space.xxxl}px;
    padding-bottom: ${theme.space.xl}px;

    .header {
      ${media.md`
        display: flex;
        align-items: center;
        justify-content: space-between;
      `}

      .heading {
        font-size: 1.5rem;
        font-weight: ${theme.weight.heavy};
        line-height: 2.25rem;
        color: #3a3335;
      }

      .filters {
        display: flex;
        flex-direction: column;
        margin-top: ${theme.space.lg}px;

        ${media.md`
          display: block;
          margin-top: 0;
        `}

        button {
          height: 40px;

          font-size: 0.875rem;
          font-weight: ${theme.weight.bold};
          line-height: 1.31rem;
          text-align: center;
          color: #908e8e;

          padding: 0 ${theme.space.xl}px;

          background: transparent;
          box-shadow: none;
          border: 0;

          cursor: pointer;

          &:hover {
            color: #3a3335;
          }

          &.active {
            color: #3a3335;
            border: 1px solid #ede8e9;
            border-radius: 8px;
          }
        }
      }
    }

    .list {
      ${media.lg`
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: ${theme.space.xl}px;

        ${
          base &&
          css`
            grid-template-columns: 1fr;
            grid-gap: 0;
          `
        }
      `}
    }
  `}
`;
