import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Wrapper } from 'components';
import { useAppSelector } from 'Root';
import { Link, LinkGroup } from 'const';
import { SFooter } from './Footer.styled';
import Socials from './Socials';
import Sitemap from './Sitemap';
import Logo from '../Logo/Logo';
import elementsImage from '../../assets/images/footer/footer-elements.png';
import PhysicsSection from './2DPhysics';

export const FOOTER_DOM_ID = 'site-footer';

const Footer: FC = () => {
  const { formatMessage } = useIntl();
  const { links } = useAppSelector(({ app }) => app.homepage);

  return (
    <SFooter id={FOOTER_DOM_ID}>
      <Wrapper>
        <section className="top">
          <div className="nav">
            {links.map((group: LinkGroup) => (
              <div key={`footer-group-${group.id}`}>
                <ul className="menu">
                  {group.links.map((link: Link) => (
                    <li key={`${group.name}-link-${link.id}`}>
                      <a target="_blank" rel="noreferrer" href={link.url}>
                        {link.title}
                      </a>
                    </li>
                  ))}

                  <li>
                    <a target="_blank" rel="noreferrer" href="#">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            ))}
          </div>

          <Socials />
        </section>

        <div className="divider" />

        <Sitemap />

        <div className="privacy">
          <Logo mode="color" size={39} />

          <p className="privacy-rights">
            © 2022 {formatMessage({ id: 'general.allRightsReserved' })}
          </p>
        </div>
      </Wrapper>

      <div className="elements-wrapper">
        <img className="elements" src={elementsImage} alt="" loading="lazy"/>
      </div>
    </SFooter>
  );
};

export default Footer;
