import { Event } from '../types';
import { Service } from '../const';

export interface CalendarEvent {
  name: string;
  id: number;
  startDate: Date;
  endDate: Date;
  placeId: string;
  service: Service;
  resourceId: string;
  email: string;
  phone: string;
  type: 'normal' | 'range';
}

export interface NewCalendarEvent {
  text: string;
  description: string;
  appointmentId: number;
  startDate: Date;
  endDate: Date;
}

const dateParser = (timestamp: number) => {
  return new Date(timestamp * 1000);
};

const DEFAULT_COLOR = '#59b53b';
const TIME_RESERVATION_COLOR = '#4f4f4f';

export const parseEvents = (events: Event[]): CalendarEvent[] => {
  return events.map((event) => {
    return {
      name: event.additionalNote || event?.customer?.name || '',
      id: event.id,
      startDate: dateParser(event.timeStart),
      endDate: dateParser(event.timeEnd),
      placeId: event.resourceId || '',
      resourceId: event.service?.name || '',
      email: event?.customer?.email || event?.email || '',
      phone: event?.customer?.phone || event?.phone || '',
      service: event.service,
      eventColor: event.service ? DEFAULT_COLOR : TIME_RESERVATION_COLOR,
      type: event.service?.appointmentType || '',
    };
  });
};

export const parseCalendarEvents = (events: Event[]) => {
  return events.map((event) => ({
    text: event.additionalNote || event.customer.name || '',
    description: '',
    html: JSON.stringify({
      name: event.additionalNote || event.customer.name || '',
      email: event?.email || event?.customer?.email || '',
      phone: event?.customer?.phone || event?.phone || '',
      service: event.service || null,
      id: event.id || 0,
    }),
    startDate: dateParser(event.timeStart),
    endDate: dateParser(event.timeEnd),
    state: event.state,
    confirmed: event.confirmed,
  }));
};
