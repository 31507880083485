import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export default styled.div<{ hotel?: boolean }>`
  ${({ theme, hotel }) => css`
    margin-top: ${theme.space.xl}px;

    .dx-overlay-wrapper {
      display: none;
    }

    .slots-selection {
      display: flex;
      column-gap: 20px;
      position: relative;
      margin-bottom: ${theme.space.xl}px;

      z-index: 2;
    }

    .day-header {
      height: 48px;

      display: flex;
      align-items: center;
      text-align: left;

      &-number {
        font-size: 1.625rem;

        padding: 0 ${theme.space.sm}px;
      }

      &-content {
        font-size: 0.75rem;
      }

      &-name {
        word-wrap: anywhere;
        text-transform: capitalize;

        padding-right: ${theme.space.xs}px;
      }

      &-reservations {
        color: #908e8e;

        span {
          color: ${theme.colors.primary};
        }
      }
    }

    .b-sidebar .b-calendar-cell.b-selected-date {
      background-color: ${theme.colors.primary};
      border-color: ${theme.colors.primary};
    }

    .b-monthview
      .b-weeks-container
      .b-calendar-row
      .b-calendar-cell.b-today
      .b-day-num {
      background-color: ${theme.colors.primary};
    }

    .b-buttongroup,
    .b-toolbar {
      .b-button {
        border-radius: 8px;
        background-color: transparent;
        color: ${theme.colors.primary};

        &:hover {
          background-color: rgba(255, 107, 0, 0.11);
        }
      }

      .b-button:not(.b-raised).b-pressed {
        border-radius: 8px;
        color: #fff;
        background-color: ${theme.colors.primary};
      }
    }

    .b-calendarrow .b-cal-cell-header.b-today .b-day-name-date {
      color: #fff;
      background-color: ${theme.colors.primary};
    }

    .fc {
      &-toolbar {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        &-chunk {
          display: flex;

          & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          &:first-of-type {
            display: flex;
            flex-direction: column;

            .fc-button {
              width: 40px;
              height: 40px;

              color: #908e8e;

              padding: 0;
              margin: 0;

              border: none;
              background: linear-gradient(
                  0deg,
                  rgba(58, 51, 53, 0.04),
                  rgba(58, 51, 53, 0.04)
                ),
                #ffffff;
              border-radius: 10px !important;

              &:active {
                color: white;
                background-color: ${theme.colors.primary};
              }

              &:first-of-type {
                margin-right: ${theme.space.sm}px;
              }
            }
          }

          &:last-of-type {
            .fc-button {
              height: 40px;

              font-size: 0.875rem;
              font-weight: ${theme.weight.bold};
              color: #908e8e;

              padding-left: ${theme.space.md}px;
              padding-right: ${theme.space.md}px;

              border-color: #ede8e9;
              background: transparent;

              &-active {
                color: ${theme.colors.primary};
              }
            }
          }
        }

        &-title {
          font-size: 1.5rem;
          font-weight: ${theme.weight.heavy};
          line-height: 2.25rem;

          margin: ${theme.space.sm}px 0 !important;

          ${hotel &&
          css`
            text-align: center;
          `}
        }
      }
    }

    ${!hotel &&
    css`
      .event {
        height: 100%;

        padding: 6px 12px;

        cursor: pointer;

        &-time-block,
        &-normal {
          .type {
            font-weight: ${theme.weight.semibold};
            font-size: 0.625rem;
            line-height: 0.93rem;
            text-transform: uppercase;
            color: #009518;
          }

          .title {
            font-size: 0.875rem;
            line-height: 0.93rem;
            color: #3a3335;

            margin-top: ${theme.space.xs}px;
          }
        }

        &-time-block {
          .type {
            color: #979897;
          }

          .title {
            color: #3a3335;
          }
        }
      }

      .fc-timegrid-slot {
        height: 48px !important;
        border-bottom: 0;
      }

      .fc {
        &-button-primary {
          &:focus {
            box-shadow: none !important;
          }
        }

        &-timegrid-col {
          &-events {
            margin: 0 !important;
          }
        }

        &-scrollgrid {
          &-section-header {
            .fc-scroller {
              overflow: hidden !important;
            }
          }
        }

        &-theme-standard {
          td {
            &:first-of-type {
              border-top: 0;
              border-bottom: 0;
              vertical-align: top;
            }
          }
        }
      }
    `}

    ${hotel &&
    css`
      div[id='b-buttongroup-7'] {
        // display: none;
      }

      .event {
        height: 100%;

        padding: 6px 12px;

        cursor: pointer;

        &-time-block,
        &-normal {
          .type {
            font-weight: ${theme.weight.semibold};
            font-size: 0.625rem;
            line-height: 0.93rem;
            text-transform: uppercase;
            color: #009518;
            word-break: break-all;
          }

          .title {
            font-size: 0.875rem;
            line-height: 0.93rem;
            color: #3a3335;

            word-break: break-all;

            margin-top: ${theme.space.xs}px;
          }
        }

        &-time-block {
          .type {
            color: #979897;
          }

          .title {
            color: #3a3335;
          }
        }
      }

      .fc {
        &-button-primary {
          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .fc-scrollgrid-section-header {
        .fc-scroller {
          overflow-y: hidden !important;
        }
      }

      .fc-datagrid-header .fc-datagrid-cell-main,
      .fc-datagrid-body
        :not(.fc-datagrid-expander-placeholder)
        + .fc-datagrid-cell-main {
        font-size: 1.125rem;
        font-weight: ${theme.weight.semibold};
      }

      .fc-datagrid-cell-frame,
      .fc-datagrid-cell-cushion {
        display: flex;
        align-items: center;
      }

      .fc-datagrid-body .fc-datagrid-cell-frame,
      .fc-timeline-lane .fc-timeline-lane-frame {
        min-height: 48px;
      }

      .fc-icon-minus-square,
      .fc-icon-plus-square {
        width: 24px !important;
        height: 24px;

        position: relative;
        padding: 0;
        margin-right: ${theme.space.xs}px;

        border: none;
        background: linear-gradient(
            0deg,
            rgba(58, 51, 53, 0.1),
            rgba(58, 51, 53, 0.1)
          ),
          #ffffff;
        border-radius: 8px !important;

        &::before {
          content: '';
          width: 14px;
          height: 2px;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          background: #908e8e;
        }
      }

      .fc-icon-plus-square {
        &::after {
          content: '';
          width: 2px;
          height: 14px;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          background: #908e8e;
        }
      }
    `}

    ${media.lg`
       .fc {
         &-toolbar {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: unset;

          &-chunk {
            align-items: center;

            &:first-of-type {
              flex-direction: row;
            }
          }

          &-title {
            margin: 0 !important;
            margin-left: ${theme.space.md}px !important;
          }
        }
       }
    `}
  `}
`;
