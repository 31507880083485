import React from 'react';
import { STag } from './Tag.styled';

interface TagProps {
  label: string;
  colors: { text: string; background: string };
}

const Tag = ({ label, colors }: TagProps) => <STag colors={colors}>{label}</STag>;

export default Tag;
