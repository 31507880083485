import styled, { css } from 'styled-components'
import media from 'theme/respondTo'

export default styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.xxl}px 0;

    .app-sub {
      &-header {
        margin-bottom: ${theme.space.lg}px;
      }
      &-heading {
        font-size: 1.5rem;
        font-weight: ${theme.weight.heavy};
        line-height: 2.25rem;
        color: #3a3335;

        margin-bottom: ${theme.space.xs}px;
      }
      &-subheading {
        font-size: 1rem;
        line-height: 1.31rem;
        color: #908e8e;
      }

      &-status {
        min-height: 48px;

        display: flex;
        align-items: center;

        font-size: 1rem;
        line-height: 1.31rem;
        color: #009834;

        padding: ${theme.space.sm}px ${theme.space.md}px;
        margin-bottom: 12px;

        background: rgba(0, 197, 31, 0.15);
        border-radius: 12px;

        &.warning {
          background: #ffd9d9;
          color: #ff0000;
        }
      }

      &-subscription {
        padding: ${theme.space.md}px;
        margin-bottom: ${theme.space.xxl}px;

        border: 1px solid #ede8e9;
        border-radius: 15px;

        overflow: hidden;

        ${media.lg`
          display: flex;
          padding: ${theme.space.xl}px;
        `}

        &-benefits {
          flex: 1;
          font-size: 1rem;
          line-height: 1.31rem;
          color: #908e8e;

          margin-bottom: ${theme.space.xxl}px;

          ${media.lg`
            padding-right: ${theme.space.xl}px;
          `}

          p {
            margin-bottom: ${theme.space.lg}px;
          }

          &-list {
            display: grid;
            grid-gap: 20px 0;

            ${media.md`
              grid-template-columns: repeat(2, auto);
              grid-template-rows: auto;
            `}

            li {
              min-height: 28px;

              display: flex;
              align-items: center;

              position: relative;

              padding-left: 56px;

              &::before,
              &::after {
                content: '';
                width: 28px;
                height: 28px;

                position: absolute;
                top: 0;
                left: 0;

                background: rgba(255, 107, 0, 0.2);
                border-radius: 50%;
              }

              &::after {
                left: 14px;
                background: transparent;
                border: 1px solid ${theme.colors.primary};
              }
            }
          }
        }

        &-action {
          flex-shrink: 0;

          margin: ${-theme.space.md}px;
          padding: ${theme.space.md}px;

          background: #f3f3f3;

          ${media.lg`
            margin: ${-theme.space.xl}px;
            padding: ${theme.space.xl}px;
          `}

          .app-sub-heading {
            margin-bottom: ${theme.space.md}px;
          }
        }

        &-price {
          font-size: 1rem;
          line-height: 1.31rem;
          color: #908e8e;

          margin-bottom: ${theme.space.xxxl}px;

          span {
            max-width: 114px;
            display: inline-block;
          }

          em {
            font-size: 2.5rem;
            font-weight: ${theme.weight.semibold};
            line-height: 1.31rem;
            color: #ff6b00;
          }
        }

        &-buy {
          width: 100%;
          text-transform: uppercase;
        }
      }
    }

    form {
      margin-bottom: ${theme.space.sm}px;

      ${media.sm`
        display: flex;
      `}

      & + p {
        font-size: 0.875rem;
        line-height: 1.31rem;
        color: #009834;

        &.error {
          color: ${theme.colors.error};
        }
      }

      input {
        width: 100%;
        height: 48px;

        font-size: 1rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.31rem;
        color: #908e8e;

        padding: 0 ${theme.space.md}px;

        background: #ffffff;
        border: 1px solid #ede8e9;
        border-radius: 8px;

        margin-bottom: ${theme.space.md}px;

        ${media.sm`
          max-width: 387px;
          margin-right: ${theme.space.lg}px;
        `}

        &:focus {
          outline: 0;
          box-shadow: 0;
          border: 1px solid ${theme.colors.primary};
        }

        &::placeholder {
          font-size: 1rem;
          font-weight: ${theme.weight.bold};
          line-height: 1.31rem;
          color: #908e8e;

          opacity: 1;
        }
      }

      button {
        width: 100%;
        height: 48px;

        ${media.sm`
          width: 134px;
        `}
      }
    }
  `}
`
