import { AsyncThunk, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'state/createStore';
import client from './client'

const loadCategory = (inputValue: number, callback: any): void => {
  client(`platform/categories/${inputValue}`)
    .errorType('json')
    .get()
    .json((json) => {
      const { data } = json
      console.log('data: ', data)
      callback(data)
    })
    .catch((error) => {
      console.log(error)
    })
}

export default loadCategory
