import React from 'react';
import { SCategoryPill } from './CategoryPill.styled';
import { renderIcon } from 'utils/renderIcon';
import type { CategoryPillData } from 'types';

type CategoryPillProps = CategoryPillData;

const CategoryPill = ({
  displayName,
  background,
  textColor,
  active,
  onSelect,
  clickable = true,
  hasIcon = true,
  size = 'small',
}: CategoryPillProps) => (
  
  <SCategoryPill
    onClick={clickable ? onSelect : undefined}
    background={background}
    textColor={textColor}
    active={active}
    size={size}
  >
    {hasIcon && renderIcon(displayName, active)}
    <span>{displayName}</span>
  </SCategoryPill>
);

export default CategoryPill;
