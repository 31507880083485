import styled, { css } from 'styled-components';

export const SReviews = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    margin: 40px 0 0;
    row-gap: 26px;

    .read-more {
      display: inline;
      font-size: 14px;
      color: ${theme.colors.primary};
      font-weight: ${theme.weight.bold};
      line-height: 27px;
      padding: 0;
      position: relative;
      width: max-content;
      cursor: pointer;

      &:after {
        background-color: rgba(255, 107, 0, 0.5);
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        width: 100%;
      }
    }

    .opinion {
      display: flex;
      color: ${theme.colors.black};
      flex-flow: column;
      width: 100%;

      &__rating {
        display: flex;
        column-gap: 4px;
      }

      &__text {
        font-size: 14px;
        line-height: 21px;
        margin: 10px 0 12px;
      }

      &__author {
        align-items: baseline;
        display: flex;
        column-gap: 12px;
        color: ${theme.colors.black};
      }

      &__date {
        font-size: 12px;
      }

      &__author-name {
        font-size: 14px;
        font-weight: ${theme.weight.bold};
      }

      &__divider {
        background-color: #e6e8f0;
        height: 1px;
        margin-top: 27px;
        width: 100px;
      }
    }
  `}
`;
