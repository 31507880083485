import React from 'react';
import { SEmptyState } from './EmptyState.styled';
import empty from '../../assets/images/profile-empty-state.svg';
import { Button } from '../index';
import useLocalizeRoute from '../../utils/useLocalizeRoute';

interface EmptyStateProps {
  title: string;
  description: string;
  image?: any;
  button: {
    label: string;
    href: string;
  };
}

const EmptyState = ({ title, image, button, description }: EmptyStateProps) => {
  const { localizeRouteKey } = useLocalizeRoute();

  return (
    <SEmptyState>
      <div className="empty">
        <img src={image || empty} alt="empty state graphic" />
        <p className="empty-heading">{title}</p>
        <p className="empty-description">{description}</p>
        <Button to={button.href} className="empty-link">
          {button.label}
        </Button>
      </div>
    </SEmptyState>
  );
};

export default EmptyState;
