import { useEffect, FC, useState } from 'react';

import { Reservation, Slot } from 'const';
import { Button, Datepicker, SalonServiceItem } from 'components';
import { SReservationEditor } from './ReservationEditor.styled';
import { DateTime, Duration } from 'luxon';
import { getSalonSlots } from '../../state/reducers/salons/salonsActions';
import { getServiceProposedHours } from '../../state/reducers/reservation/reservationActions';
import { useAppDispatch } from '../../Root';
import { updateSlotId } from '../../state/reducers/reservation/reservationActions';
import ReservationEmployees from '../ReservationEmployees/ReservationEmployees';

interface IReservationEditor {
  index: number;
  reservation: Reservation;
  removeService: (index: number) => void;
  updateService: (payload: {
    index: number;
    reservation: Reservation;
    toggle?: boolean;
  }) => void;
  hours: undefined | string[];
  error: string | null;
}
const ReservationEditor: FC<IReservationEditor> = ({
  index,
  reservation,
  removeService,
  updateService,
  hours,
  error,
}) => {
  const dispatch = useAppDispatch();
  const [availableEmployees, setAvailableEmployees] = useState<Slot[]>([]);

  const setSlotId = (id: number) => {
    dispatch(
      updateSlotId({
        salonId: reservation.salonId,
        slotId: id,
        serviceId: reservation.service.id,
      })
    );

    dispatch(
      getServiceProposedHours({
        index,
        reservation: { ...reservation, slotId: id },
      })
    );
  };

  const toggleExpanded = () => {
    updateService({
      index,
      reservation: { ...reservation, expanded: !reservation.expanded },
      toggle: true,
    });
  };
  const handleSelectDate = (date: Date) => () => {
    updateService({
      index,
      reservation: { ...reservation, time: [date.getTime()] },
    });
  };

  const handleSelectTime = (selected: string) => () => {
    const [hour, minute] = selected.split(':').map((o) => parseInt(o));
    const updatedReservation: Reservation = {
      ...reservation,
      time: [
        DateTime.fromMillis(reservation.time?.[0] ?? new Date().getTime())
          .set({ hour, minute })
          .toMillis(),
      ],
      hour: selected,
    };
    updateService({
      index,
      reservation: updatedReservation,
    });
  };

  useEffect(() => {
    const fetchSlots = async () => {
      // @ts-ignore
      const { payload } = await dispatch(
        getSalonSlots({
          salonId: reservation.salonId,
          serviceId: reservation.service.id,
        })
      );

      setAvailableEmployees(payload);
    };

    fetchSlots();
  }, []);

  useEffect(() => {
    if (!reservation.time) return;
    if (reservation.hour && !hours?.includes(reservation.hour)) {
      updateService({
        index,
        reservation: {
          ...reservation,
          time: [
            DateTime.fromMillis(reservation.time[0])
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
              .toMillis(),
          ],
          hour: null,
          expanded: true,
        },
        toggle: true,
      });
    }
  }, [reservation, hours]);

  const reservationTimestamp = reservation.time?.[0] ?? null;
  const reservationTime =
    reservationTimestamp &&
    DateTime.fromMillis(reservationTimestamp, { setZone: true });
  const reservationHour =
    reservationTime &&
    (reservationTime as DateTime).toFormat('HH:mm:ss', {
      hour12: false,
      hourCycle: 'h24',
    });

  const serviceTime = reservation.service.duration
    ? Duration.fromObject({
        minutes: reservation.service.duration,
      }).toFormat("h'h' mm'min'")
    : '';

  return (
    <SReservationEditor>
      <div className="reservation-editor-header">
        <SalonServiceItem time={serviceTime} {...reservation.service} />
        <Button
          className="reservation-editor-remove"
          onClick={removeService(index)}
        >
          usuń
        </Button>
        <button
          type="button"
          onClick={toggleExpanded}
          className="reservation-editor-expand"
        >
          {reservation.expanded ? (
            <span className="material-icons">expand_less</span>
          ) : (
            <span className="material-icons">expand_more</span>
          )}
        </button>
      </div>

      {reservation.expanded ? (
        <div className="reservation-editor-content">
          <ReservationEmployees
            selectedEmployee={reservation.slotId}
            employees={availableEmployees}
            onSelect={setSlotId}
          />

          <Datepicker
            selectedDate={reservation.time as [number]}
            onSelectDate={handleSelectDate}
          />
          <div className="reservation-editor-hours">
            <div className="reservation-editor-hours-header">
              <p>Proponowana godzina</p>
            </div>
            {hours?.length ? (
              <div className="reservation-editor-hours-list">
                {hours?.map((hour: string) => (
                  <Button
                    key={`${reservation.service.id}-${hour}`}
                    bordered={hour !== reservationHour}
                    type="button"
                    className="reservation-editor-hour"
                    onClick={handleSelectTime(hour)}
                  >
                    {hour.slice(0, 5)}
                  </Button>
                ))}
              </div>
            ) : (
              <p className="reservation-editor-hours-empty">
                Brak wolnych godzin w wybranym terminie
              </p>
            )}
          </div>

          {error ? (
            <p className="reservation-editor-error">
              <span className="icon">
                <span className="material-icons">error</span>
              </span>
              {error}
            </p>
          ) : null}
        </div>
      ) : null}
    </SReservationEditor>
  );
};

export default ReservationEditor;
