import classNames from 'classnames';
import { Button } from 'components';
import { AppRoute, Booking } from 'const';
import { DateTime, Duration } from 'luxon';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'Root';
import {
  getClientReservations,
  showReservationCancelation,
  showReviewEditor,
} from 'state/reducers/client/clientActions';
import { addServiceToReservation } from 'state/reducers/reservation/reservationActions';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import { SProfileReservationItem } from './ProfileReservationItem.styled';

interface IProfileReservationItem {
  reservation: Booking;
}
const ProfileReservationItem: FC<IProfileReservationItem> = ({
  reservation,
}) => {
  const dispatch = useAppDispatch();
  const { localizeRouteKey } = useLocalizeRoute();

  const date = DateTime.fromMillis(reservation.timeStart * 1000);
  const endDate =
    reservation.service?.appointmentType === 'range'
      ? DateTime.fromMillis(reservation.timeEnd * 1000)
      : null;
  const avatar = reservation.salon?.images?.[0]?.path ?? null;

  const openReviewEditor = () => {
    dispatch(
      showReviewEditor({
        appointment: reservation,
        review: null,
      })
    );
  };

  const openReservationCancelation = () => {
    dispatch(showReservationCancelation(reservation));
  };

  const onReservate = () => {
    const { salon, service } = reservation;

    dispatch(
      addServiceToReservation({
        salonId: salon.id,
        service: {
          id: service.id,
          name: service.name,
          appointmentType: service.appointmentType,
          price: service.price,
          description: service.description,
        },
        slotId: 0,
      })
    );

    dispatch(getClientReservations());
  };

  if (!reservation.salon) return null;

  const reservationStateName =
    reservation.state.toLowerCase() === 'incoming'
      ? 'Potwierdzone'
      : 'Zakończone';

  const renderReservationActions = () => {
    switch (reservation.state.toLowerCase()) {
      case 'incoming':
        return (
          <>
            <Button
              type="button"
              className="gray cancel"
              onClick={openReservationCancelation}
            >
              Odwołaj wizytę
            </Button>
          </>
        );
      case 'finished':
      default:
        return (
          <>
            {!!reservation.review || reservation.state === 'CANCELED' ? null : (
              <Button
                type="button"
                className="gray add"
                onClick={openReviewEditor}
              >
                Dodaj opinie
              </Button>
            )}
            <Button type="button" onClick={onReservate}>
              Umów ponownie
            </Button>
          </>
        );
    }
  };

  return (
    <SProfileReservationItem>
      <div className="profile-reservation-status">
        <p className="profile-reservation-date">
          {date.toFormat("LLLL dd',' kkkk")}
        </p>
        {!endDate ? (
          <p className="profile-reservation-time">Godz: {date.toFormat('T')}</p>
        ) : (
          <>
            <p className="profile-reservation-date">-</p>
            <p className="profile-reservation-date">
              {endDate.toFormat("LLLL dd',' kkkk")}
            </p>
          </>
        )}

        <span
          className={classNames(
            'profile-reservation-state',
            `profile-reservation-state-${reservation.state.toLowerCase()}`
          )}
        >
          {reservationStateName}
        </span>
      </div>
      <div className="profile-reservation-content">
        {avatar ? (
          <Link
            to={localizeRouteKey(AppRoute.Salon).replace(
              ':id',
              String(reservation.salon.id)
            )}
          >
            <div className="profile-reservation-thumbnail">
              <img src={avatar} alt="salon avatar" />
            </div>
          </Link>
        ) : null}
        <div>
          <p className="profile-reservation-name">{reservation.salon.name}</p>
          <p className="profile-reservation-address">
            {reservation.salon.city?.name}, {reservation.salon.street}{' '}
            {reservation.salon.buildingNumber}
          </p>
          <p className="profile-reservation-service">
            {reservation.service.name}{' '}
            <span>
              {Duration.fromObject({
                minutes: reservation.service.duration,
              }).toFormat("h'h' mm'min'")}
            </span>
          </p>
        </div>
      </div>
      <div className="profile-reservation-actions">
        {renderReservationActions()}
      </div>
    </SProfileReservationItem>
  );
};

export default ProfileReservationItem;
