import React, { useEffect, useState } from 'react';
import StarIcon from '../icons/StarIcon';
import DropdownChevron from '../icons/DropdownChevron';
import { useBreakpoint } from 'modules/breakpoint';

interface ISectionTemplateProps {
  title: string;
  children: any;
  rating?: string;
}

const SectionTemplate = ({
  title,
  children,
  rating = '',
}: ISectionTemplateProps) => {
  const [expanded, setExpanded] = useState(true);

  const { sm } = useBreakpoint();

  useEffect(() => {
    if (sm && !expanded) {
      setExpanded(true);
    }
  }, [sm]);

  return (
    <section className="section-template">
      <div
        role="button"
        onClick={() => setExpanded((prevState) => !prevState)}
        className="section-template__header"
      >
        <div className="section-template__header-left">
          <h2 className="section-template__heading">{title}</h2>
          {!!rating && (
            <div className="section-template__rating-wrapper">
              <StarIcon />
              <p>{rating}</p>
            </div>
          )}
        </div>
        <button className="section-template__toggle-button">
          <DropdownChevron open={expanded} />
        </button>
      </div>
      <span className="section-template__divider" />
      {expanded && <>{children}</>}
    </section>
  );
};

export default SectionTemplate;
