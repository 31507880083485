import styled, { css } from 'styled-components';

interface IStyledTextInput {
  focused: boolean;
  empty: boolean;
  error: boolean | undefined;
}

export const SAutocomplateInput = styled.label<IStyledTextInput>`
  ${({ theme, focused, empty, error }) => css`
    width: 100%;
    height: 72px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    position: relative;

    padding: 0 ${theme.space.md}px;

    border: 1px solid #ede8e9;
    border-radius: 8px;

    .label {
      position: absolute;
      top: ${theme.space.md}px;
      left: ${theme.space.md + 2}px;

      font-size: 0.75rem;
      text-align: left;
      color: #908e8e;
    }

    .placeholder {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      font-size: 0.875rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.3rem;
      color: #908e8e;

      pointer-events: none;
      touch-action: none;
    }

    input {
      width: 100%;

      border: none;
      box-shadow: none;
      background: transparent;

      font-weight: ${theme.weight.bold};
      font-size: 0.75rem;
      color: #3a3335;

      margin-right: ${theme.space.md}px;

      &:focus {
        outline: 0;
      }
    }

    .icon {
      font-size: 1.125rem;
      color: #908e8e;

      margin-bottom: 1px;
    }

    ${focused &&
    css`
      border-color: ${theme.colors.primary};
    `}

    ${(focused || !empty) &&
    css`
      align-items: flex-end;

      padding-bottom: 10px;

      .icon {
        color: #3a3335;
      }
    `}

    ${!empty &&
    css`
      border-color: #c1c1c1;
    `}

    ${error &&
    css`
      border-color: ${theme.colors.error};
      .icon {
        color: ${theme.colors.error};
      }
    `}
  `}
`;
