import { AppRoute } from 'const';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import useLocalizeRoute from 'utils/useLocalizeRoute';

import { SPopularTile } from './PopularTile.styled';
import StarIcon from '../icons/StarIcon';

interface IPopularTile {
  image: string;
  title: string;
  company?: {
    id: number;
    name: string;
  };
  price: number;
  rate: number;
  opinions: number;
}

const PopularTile: FC<IPopularTile> = ({
  image,
  title,
  company,
  price,
  rate,
  opinions,
  ...res
}) => {
  const { localizeRouteKey } = useLocalizeRoute();
  return (
    <SPopularTile {...res}>
      <Link
        to={localizeRouteKey(AppRoute.Salon).replace(
          ':id',
          String(company?.id ?? 0)
        )}
      >
        <div className="thumbnail">
          <div className="review">
            <StarIcon /> 5.0
          </div>
          <img src={image} alt={title} loading="lazy"/>
        </div>

        <div className="header">
          <h3 className="heading">{title}</h3>
          {company?.name ? (
            <p className="popular-text">{company?.name}</p>
          ) : (
            <p className="text">&nbsp;</p>
          )}
        </div>
      </Link>
    </SPopularTile>
  );
};

export default PopularTile;
