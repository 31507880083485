import React from 'react';

const CloseFiltersIcon = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="22" cy="22" r="21.5" fill="white" stroke="#EDE8E9" />
    <line
      x1="15.0607"
      y1="14"
      x2="31"
      y2="29.9393"
      stroke="#FF6B00"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <line
      x1="0.75"
      y1="-0.75"
      x2="23.2916"
      y2="-0.75"
      transform="matrix(-0.707107 0.707107 0.707107 0.707107 31 14)"
      stroke="#FF6B00"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default CloseFiltersIcon;
