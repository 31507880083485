import React from 'react';
import CloseFiltersIcon from '../../icons/CloseFiltersIcon';

interface FilterHeaderProps {
  header: string;
  onClose: () => void;
}

const FiltersHeader = ({ header, onClose }: FilterHeaderProps) => {
  return (
    <div className="filters-overlay__header">
      {header}
      <button onClick={onClose} className="filters-overlay__close">
        <CloseFiltersIcon />
      </button>
    </div>
  );
};

export default FiltersHeader;
