import React from 'react';

const BehavioristIcon = ({
  active,
  size,
}: {
  active?: boolean;
  size?: number;
}) => (
  <svg
    width={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1825_1346)">
      <path
        d="M15.3197 3.15869C14.839 2.67827 14.1345 2.46951 13.412 2.58817C13.5303 1.86534 13.3219 1.16018 12.8411 0.680439C12.6228 0.459496 12.3617 0.285277 12.0739 0.168377C11.7861 0.051478 11.4776 -0.0056589 11.167 0.000441805C10.557 0.0171017 9.9831 0.292161 9.5513 0.774279C8.8798 1.52398 8.8713 2.33487 8.86552 3.12027C8.85838 3.91281 8.85159 4.66115 8.18043 5.3323L5.33226 8.18047C4.66144 8.85163 3.91276 8.85809 3.12023 8.86523C2.33585 8.87237 1.52461 8.87985 0.774235 9.55135C0.292116 9.98314 0.0170572 10.5571 0.000397262 11.1674C-0.00537999 11.4778 0.0519083 11.7863 0.16879 12.074C0.285673 12.3617 0.459718 12.6227 0.680395 12.8412C1.16115 13.3219 1.86563 13.5307 2.58813 13.412C2.46981 14.1349 2.67823 14.84 3.15899 15.3198C3.58636 15.7509 4.16696 15.9953 4.77398 15.9998H4.83314C5.4431 15.9828 6.01702 15.708 6.44881 15.2259C7.12031 14.4762 7.12881 13.665 7.13459 12.8799C7.14173 12.0874 7.14853 11.3387 7.81969 10.6679L10.6679 7.81939C11.3387 7.14858 12.0874 7.14178 12.8799 7.13464C13.6643 7.12784 14.4755 7.12036 15.2259 6.44886C15.708 6.01672 15.9831 5.44314 15.9997 4.83284C16.0055 4.52231 15.9482 4.21383 15.8313 3.92607C15.7144 3.63831 15.5404 3.37725 15.3197 3.15869Z"
        fill={active ? '#fff' : '#8F03C1'}
      />
    </g>
    <defs>
      <clipPath id="clip0_1825_1346">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BehavioristIcon;
