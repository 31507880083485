import styled, { css } from 'styled-components';

export const SAppReservationModal = styled.div<{ hotel?: boolean }>`
  ${({ theme, hotel }) => css`
    max-width: 486px;
    width: 100%;

    display: flex;
    flex-direction: column;

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: ${theme.space.md}px ${theme.space.lg}px ${theme.space.xl}px
      ${theme.space.lg}px;

    background-color: white;
    box-shadow: 0px 10px 34px rgba(122, 121, 121, 0.5);
    border-radius: 8px;

    z-index: 3;

    .close {
      height: 24px;

      flex-shrink: 0;
      align-self: flex-end;

      padding: 0;
      margin: 0;
      margin-bottom: ${theme.space.md}px;

      background: none;
      box-shadow: none;
      border: 0;

      cursor: pointer;

      span {
        font-size: 15px;
        font-weight: bold;
      }
    }

    form {
      display: flex;
      flex-flow: column;
      gap: ${theme.space.md}px;
      justify-content: center;

      .name {
        grid-area: name;
      }

      .service {
        grid-area: service;
      }

      .duration {
        display: flex;
        flex-flow: row;
        gap: ${theme.space.xs}px;
      }

      .date {
        grid-area: date;

        button {
          width: 100%;
          justify-content: space-between;
        }
      }

      .timeEnd {
        grid-area: timeEnd;
      }

      .timeStart {
        grid-area: timeStart;
      }

      .actions {
        display: flex;
        justify-content: space-between;
        grid-area: actions;

        .button {
          width: 134px;
          height: 40px;
        }
      }
    }

    .input {
      width: 100%;
      height: 48px;

      font-size: 0.875rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.31rem;
      color: #3a3335;

      padding: 0 ${theme.space.md}px;

      background: #ffffff;
      border: 1px solid #ede8e9;
      border-radius: 8px;

      &:disabled {
        background-color: #f1f1f1;
        cursor: not-allowed;
      }

      &::placeholder {
        font-size: 0.875rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.31rem;
        color: #908e8e;

        opacity: 1;
      }

      &:focus {
        outline: 0;
        border-color: ${theme.colors.primary};
      }
    }
  `}
`;
