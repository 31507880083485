import { useState } from 'react';
import { useIntl } from 'react-intl';

import { AppAuthLayout, Button, TextInput } from 'components';

import client from 'api/client';
import { AppRoute, SalonForgotPassword } from 'const';
import { FormProvider, useForm } from 'react-hook-form';
import formErrorsHandler from 'utils/formErrorsHandler';

import background from 'assets/images/salon-auth-background-2.jpg';

const defaultFormValues = { email: '' };
function AppAuthForgotPassword() {
  const { formatMessage } = useIntl();

  const [success, setSuccess] = useState<boolean>(false);
  const methods = useForm<SalonForgotPassword>({
    defaultValues: defaultFormValues,
  });

  const handleSubmit = async (values: SalonForgotPassword) => {
    await client('provider/auth/password-reset')
      .errorType('json')
      .catcher(
        422,
        formErrorsHandler<SalonForgotPassword>(
          Object.keys(defaultFormValues),
          methods.setError
        )
      )
      .post(values)
      .res(() => {
        methods.reset(defaultFormValues);
        setSuccess(true);
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
      });
  };

  return (
    <AppAuthLayout
      {...{
        background,
        icon: success ? 'done' : 'lock',
        back: AppRoute.AppAuthLogin,
        backgroundHeading: 'app-auth.forgot-password-background-heading',
        backgroundDescription:
          'app-auth.forgot-password-background-description',
      }}
    >
      <p className="heading">
        {formatMessage({
          id: success
            ? 'app-auth.forgot-password-heading-success'
            : 'app-auth.forgot-password-heading',
        })}
      </p>
      <p className="description">
        {formatMessage({
          id: success
            ? 'app-auth.forgot-password-description-success'
            : 'app-auth.forgot-password-description',
        })}
      </p>

      {!success ? (
        <div className="content">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <TextInput {...{ id: 'email', icon: 'email' }} className="row" />
              <Button
                wide
                disabled={methods.formState.isSubmitting}
                loading={methods.formState.isSubmitting}
                className="row-action"
              >
                {formatMessage({ id: 'general.recover' })}
              </Button>
            </form>
          </FormProvider>
        </div>
      ) : null}
    </AppAuthLayout>
  );
}

export { AppAuthForgotPassword };
