import styled, { css } from 'styled-components'
import media from 'theme/respondTo'

export default styled.h2`
  ${({ theme }) => css`
    font-size: 1.5rem;
    line-height: 1.7rem;

    color: #3a3335;

    position: relative;

    margin-top: ${theme.space.xl}px;
    margin-bottom: ${theme.space.sm}px;

    padding-left: 52px;
    padding-bottom: ${theme.space.lg}px;

    border-bottom: 1px solid #ede8e9;

    &::before,
    &::after {
      content: '';

      width: 28px;
      height: 28px;

      position: absolute;
      top: 0;
      left: 0;

      border-radius: 50%;
    }

    &::before {
      background: rgba(255, 107, 0, 0.2);
    }

    &::after {
      border: 1px solid ${theme.colors.primary};
      left: 14px;
    }

    ${media.lg`
      font-size: 2rem;
      line-height: 2.25rem;
    
      margin-top: 80px;

      &::before, &::after {
        top: 4px;
      }
    `}
  `}
`
