import { useIntl } from 'react-intl';

function useLocalizeRoute() {
  const { formatMessage, locale } = useIntl();

  function localizeRouteKey(path) {
    return `/${locale}` + formatMessage({ id: path });
  }

  return { localizeRouteKey };
}

export default useLocalizeRoute;
