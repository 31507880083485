import { useMemo, FC } from 'react';

import { Reservation } from 'const';
import { Button, Datepicker, SalonServiceItem } from 'components';
import { SReservationHotelEditor } from './ReservationHotelEditor.styled';
import { DateTime, Interval } from 'luxon';
import { useAppDispatch, useAppSelector } from '../../Root';
import { updateSlotId } from '../../state/reducers/reservation/reservationActions';
import ReservationEmployees from '../ReservationEmployees/ReservationEmployees';

interface IReservationHotelEditor {
  index: number;
  reservation: Reservation;
  removeService: (index: number) => void;
  updateService: (payload: {
    index: number;
    reservation: Reservation;
    toggle?: boolean;
  }) => void;
  onChangeMonth: (payload: {
    month: number;
    index: number;
    reservation: Reservation;
  }) => void;
  availableDays: undefined | number[];
  error: string | null;
}
const ReservationHotelEditor: FC<IReservationHotelEditor> = ({
  index,
  reservation,
  removeService,
  updateService,
  onChangeMonth,
  availableDays,
  error,
}) => {
  const dispatch = useAppDispatch();

  const { slots, reservations } = useAppSelector(
    ({ reservation }) => reservation
  );

  const setSlotId = (id: number) => {
    dispatch(
      updateSlotId({
        salonId: reservation.salonId,
        slotId: id,
        serviceId: reservation.service.id,
      })
    );
  };

  const toggleExpanded = () => {
    updateService({
      index,
      reservation: { ...reservation, expanded: !reservation.expanded },
      toggle: true,
    });
  };

  const handleSelectDate = (date: Date) => () => {
    let time = reservation.time;

    if (!time) {
      time = [date.getTime()];
    } else if (time && time.length === 1) {
      if (date.getTime() < time[0] || date.getTime() === time[0]) {
        time = [date.getTime()];
      } else {
        time = [time[0], date.getTime()];
      }
    } else {
      time = [date.getTime()];
    }

    updateService({
      index,
      reservation: { ...reservation, time },
      toggle: time.length !== 2,
    });
  };

  const reservationLength = useMemo(() => {
    if (reservation.time?.length === 2) {
      const startDate = DateTime.fromMillis(reservation.time[0]);
      const endDate = DateTime.fromMillis(reservation.time[1]);

      const diff = Interval.fromDateTimes(startDate, endDate);
      return diff.count('days');
    }

    return 1;
  }, [reservation.time]);

  return (
    <SReservationHotelEditor>
      <div className="reservation-editor-header">
        <SalonServiceItem
          {...{
            ...reservation.service,
            price: reservation.service.price * reservationLength,
          }}
        />
        <Button
          className="reservation-editor-remove"
          onClick={removeService(index)}
        >
          usuń
        </Button>
        <button
          type="button"
          onClick={toggleExpanded}
          className="reservation-editor-expand"
        >
          {reservation.expanded ? (
            <span className="material-icons">expand_less</span>
          ) : (
            <span className="material-icons">expand_more</span>
          )}
        </button>
      </div>

      {reservation.expanded && (
        <div className="reservation-editor-content">
          {reservation.service.appointmentType === 'normal' && (
            <ReservationEmployees
              selectedEmployee={reservation.slotId}
              onSelect={setSlotId}
              employees={slots}
            />
          )}

          <Datepicker
            multi
            onChangeMonth={(month) =>
              onChangeMonth({ month, index, reservation })
            }
            selectedDate={reservation.time as [number, number]}
            onSelectDate={handleSelectDate}
            availableDays={availableDays}
          />
        </div>
      )}

      {error && (
        <p className="reservation-editor-error">
          <span className="icon">
            <span className="material-icons">error</span>
          </span>
          {error}
        </p>
      )}
    </SReservationHotelEditor>
  );
};

export default ReservationHotelEditor;
