import React from 'react';
import NavigationIcon from '../icons/NavigationIcon';
import { Geolocation } from '../../const';

interface ISidebarAddressProps {
  address?: string;
  geolocation?: Geolocation;
}

const SidebarAddress = ({ address, geolocation }: ISidebarAddressProps) => {
  return (
    <div className="salon-details-sidebar-short-address-wrapper">
      <div className="salon-details-sidebar-short-address">
        <div className="salon-details-sidebar-short-address-content">
          <h4 className="salon-details-sidebar-short-address-heading">Adres</h4>
          <p className="salon-details-sidebar-short-address-street">
            {geolocation?.latitude !== null && geolocation?.longitude !== null
              ? address
              : 'Brak adresu'}
          </p>
        </div>

        <a
          target="_blank"
          href={`https://maps.google.com?q=${geolocation?.latitude},${geolocation?.longitude}`}
          className="salon-details-sidebar-navigation-button"
        >
          <NavigationIcon />
        </a>
      </div>
    </div>
  );
};

export default SidebarAddress;
