import React from 'react';
import { SSuggestedCity } from './SuggestedCity.styled';
import PinIcon from '../../icons/PinIcon';

interface SuggestedCityProps {
  name: string;
  onClick: () => void;
}

const SuggestedCity = ({ name, onClick }: SuggestedCityProps) => (
  <SSuggestedCity>
    <button onClick={onClick}>
      <PinIcon /> {name}
    </button>
  </SSuggestedCity>
);

export default SuggestedCity;
