import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SDatepicker = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .picker {
      &-weekdays {
        display: flex;
        justify-content: space-between;
        margin: 8px 0 0;
        width: 100%;
      }

      &-weekday {
        color: ${theme.colors.black};
        display: flex;
        font-size: 14px;
        justify-content: center;
        width: 83px;

        &--saturday {
          opacity: 0.7;
        }

        &--sunday {
          color: #df3333;
        }
      }

      &-months {
        width: 100%;
        min-height: 54px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0 ${theme.space.sm}px;

        background: rgba(237, 232, 233, 0.5);
        border-radius: 10px;

        &-selected {
          font-size: 0.875rem;
          font-weight: ${theme.weight.semibold};
          line-height: 1.31rem;
          text-transform: capitalize;
          color: #000000;
        }

        &-prev,
        &-next {
          width: 32px;
          height: 32px;

          display: flex;
          align-items: center;
          justify-content: center;

          background: none;
          box-shadow: none;
          border: 0;

          cursor: pointer;

          span {
            font-size: 1.25rem;
            color: #908e8e;
          }
        }
      }

      &-days {
        width: 100%;

        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-gap: ${theme.space.sm}px;

        margin-top: ${theme.space.sm}px;
      }

      &-day {
        width: 100%;
        height: 54px;

        border: 1px solid #ede8e9;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 0;
      }

      &-toggle {
        font-size: 0.875rem;
        line-height: 1.31rem;
        color: ${theme.colors.primary};

        margin-top: ${theme.space.md}px;

        background: none;
        box-shadow: none;
        border: 0;

        cursor: pointer;
      }
    }
  `}
`;
