import styled, { css } from 'styled-components';

export const SEmployee = styled.li<{ isSelected: boolean }>`
  ${({ theme, isSelected }) => css`
    opacity: ${isSelected ? 1 : 0.25};

    .employee {
      align-items: center;
      display: flex;
      flex-flow: column;
    }

    .wrapper {
      height: 40px;
      position: relative;
      width: 40px;
    }

    .selected-indicator {
      align-items: center;
      background-color: ${theme.colors.primary};
      outline: 2px solid ${theme.colors.white};
      border-radius: 50%;
      height: 12px;
      display: flex;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 12px;
    }

    .avatar-wrapper {
      align-items: center;
      display: flex;
      border-radius: 50%;
      height: 40px;
      justify-content: center;
      overflow: hidden;
      width: 40px;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }

    .name {
      color: #908e8e;
      font-size: 14px;
      margin-top: 8px;
      width: 78px;
    }
  `}
`;
