import React from 'react';
import { CITIES } from '../../const/predefined-cities';
import SitemapColumn from './SitemapColumn';

const SERVICES = [
  'Groomer',
  'Hotele dla pupila',
  'Szkolenia psów',
  'Petsitter',
  'Fizjoterapeuta',
];

const columns = SERVICES.map((service) => ({
  name: service,
  links: CITIES.map((city) => {
    return { service, city };
  }),
}));

const Sitemap = () => (
  <section className="sitemap">
    <ul className="listing">
      {columns.map(({ name, links }) => (
        <SitemapColumn key={name} title={name} links={links} />
      ))}
    </ul>
  </section>
);

export default Sitemap;
