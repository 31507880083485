import styled, { css } from 'styled-components'
import Modal from 'styled-react-modal'

export const SContent = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space.lg}px;

    background-color: white;
    border-radius: 21px;

    line-height: 1.5rem;
    text-align: center;

    .actions {
      display: grid;
      grid-template-columns: auto auto;
      grid-template-rows: 1fr;
      grid-gap: 0 ${theme.space.lg}px;

      margin-top: ${theme.space.xl}px;
    }
  `}
`
export default Modal.styled`
  max-width: 300px;
  width: 100%;
`
