import { createReducer } from '@reduxjs/toolkit';
import { Image, Slot, User, WorkplaceCategory } from 'const';
import { without } from 'lodash';
import {
  activeSalonOffer,
  addSubscriptionCode,
  getSalonWorkplace,
  getUser,
  logoutUser,
  removeSalonImage,
  updateCompanyImages,
  updateSalonUser,
  addSalonSlot,
  setPartnerProgram,
} from './userActions';

interface UserState {
  user: User | null;
  isAuthorized: boolean;
  triedAuthorize: boolean;

  salon: {
    removeImagesLoading: number[];

    settings: {
      partnerProgram: boolean;
    };
    workplace: {
      categories: WorkplaceCategory[];
      slots: Slot[];
      loading: boolean;
    };
  };
}

const initialState: UserState = {
  user: null,
  isAuthorized: false,
  triedAuthorize: false,

  salon: {
    removeImagesLoading: [],
    settings: {
      partnerProgram: false,
    },
    workplace: {
      categories: [],
      slots: [],
      loading: false,
    },
  },
};

export default createReducer<UserState>(initialState, {
  [setPartnerProgram.type]: (state, { payload }) => ({
    ...state,
    salon: {
      ...state.salon,
      settings: {
        partnerProgram: payload,
      },
    },
  }),
  [getUser.pending.toString()]: (state, action) => ({
    ...state,
    isAuthorized: false,
  }),
  [getUser.fulfilled.toString()]: (state, action) => ({
    ...state,
    user: action.payload,
    isAuthorized: true,
    triedAuthorize: true,
  }),
  [getUser.rejected.toString()]: (state) => ({
    ...state,
    isAuthorized: false,
    triedAuthorize: true,
  }),
  [logoutUser.type]: (state) => ({
    ...state,
    user: null,
    isAuthorized: false,
  }),
  [updateCompanyImages.type]: (state, action) => {
    if (!state.user?.company) return state;
    const currentImages = state.user.company.images || [];

    return {
      ...state,
      user: {
        ...state.user,
        company: {
          ...state.user?.company,
          images: [...currentImages, ...action.payload],
        },
      },
    };
  },
  [addSalonSlot.fulfilled.toString()]: (state, action) => ({
    ...state,
    salon: {
      ...state.salon,
      workplace: {
        ...state.salon.workplace,
        slots: [action.payload, ...state.salon.workplace.slots],
      },
    },
  }),
  [removeSalonImage.pending.toString()]: (state, action) => ({
    ...state,
    salon: {
      ...state.salon,
      removeImagesLoading: [
        ...state.salon.removeImagesLoading,
        action.meta.arg,
      ],
    },
  }),
  [removeSalonImage.fulfilled.toString()]: (state, action) => {
    const user = state.user?.company
      ? {
          ...state.user,
          company: {
            ...state.user.company,
            images:
              state.user.company?.images?.filter(
                (image: Image) => image.id !== action.meta.arg
              ) ?? state.user.company.images,
          },
        }
      : state.user;

    return {
      ...state,
      user,
      salon: {
        ...state.salon,
        removeImagesLoading: without(
          state.salon.removeImagesLoading,
          action.meta.arg
        ),
      },
    };
  },
  [removeSalonImage.rejected.toString()]: (state, action) => ({
    ...state,
    salon: {
      ...state.salon,
      removeImagesLoading: [
        ...state.salon.removeImagesLoading,
        action.meta.arg,
      ],
    },
  }),
  [updateSalonUser.fulfilled.toString()]: (state, action) => ({
    ...state,
    user: action.payload,
  }),
  [activeSalonOffer.fulfilled.toString()]: (state, action) => ({
    ...state,
  }),

  [getSalonWorkplace.pending.toString()]: (state) => ({
    ...state,
    salon: {
      ...state.salon,
      workplace: {
        ...state.salon.workplace,
        loading: true,
      },
    },
  }),
  [getSalonWorkplace.fulfilled.toString()]: (state, action) => ({
    ...state,
    salon: {
      ...state.salon,
      workplace: {
        ...state.salon.workplace,
        categories: action.payload.categories,
        slots: action.payload.slots,
        loading: false,
      },
    },
  }),
  [getSalonWorkplace.rejected.toString()]: (state) => ({
    ...state,
    salon: {
      ...state.salon,
      workplace: {
        ...state.salon.workplace,
        loading: false,
      },
    },
  }),
  [addSubscriptionCode.fulfilled.toString()]: (state, action) => ({
    ...state,
    user: {
      ...state.user,
      company: {
        ...state.user?.company,
        subscription: {
          ...state.user?.company.subscription,
          endDate: action.payload.endDate,
        },
      },
    },
  }),
});
