import React from 'react';
import { UserProgramSalon } from '../../const';
import { SProgramSalon } from './ProgramSalon.styled';

type ProgramSalonProps = UserProgramSalon;

const ProgramSalon = ({ points_amount, discount, name }: ProgramSalonProps) => {
  return (
    <SProgramSalon>
      <p>{points_amount} łapek</p>
      <p>{name}</p>
      <p>{discount}% zniżki</p>
    </SProgramSalon>
  );
};

export default ProgramSalon;
