import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import SwiperCore, {
  Controller,
  Pagination,
  Swiper,
  Navigation,
  Autoplay,
} from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';

import { Wrapper, PopularTile } from 'components';
import { SMostPopular } from './MostPopular.styled';

import { useAppSelector } from 'Root';

import thumbnail from '../../assets/images/most-popular-1.jpg';
import SliderPagination from '../SliderPagination/SliderPagination';

SwiperCore.use([Controller, Pagination, Navigation]);

const MostPopular: FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [swiper, setSwiper] = useState<Swiper>();
  const { formatMessage } = useIntl();
  const { data } = useAppSelector(
    ({ app }) => app.homepage.recommendedServices
  );

  const initializeSwiper = (swiperInstance: Swiper) => {
    setSwiper(swiperInstance);
  };

  return (
    <SMostPopular>
      <Wrapper>
        <h2 className="section-heading">
          {formatMessage({ id: 'app.most-popular.heading' })}
        </h2>

        <ul className="content">
          {data.map((service) => (
            <PopularTile
              key={`popular-service-${service.id}`}
              image={service?.image ?? thumbnail}
              title={service.name}
              company={service.company}
              price={120.0}
              rate={service.company?.reviewsSummary?.total ?? 0}
              opinions={service.company?.reviewsSummary?.count ?? 0}
            />
          ))}
        </ul>

        <div className="most-popular__slider-wrapper">
          <div className="slider">
            <SwiperComponent
              onSwiper={initializeSwiper}
              onSlideChange={({ activeIndex }: Swiper) => {
                setCurrentSlide(activeIndex);
              }}
              controller={{ control: swiper }}
              slidesPerView={1}
              breakpoints={{
                700: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              spaceBetween={10}
            >
              {data.map((service) => (
                <SwiperSlide key={`popular-service-${service.id}`}>
                  <PopularTile
                    image={service?.image ?? thumbnail}
                    title={service.name}
                    company={service.company}
                    price={120.0}
                    rate={service.company?.reviewsSummary?.total ?? 0}
                    opinions={service.company?.reviewsSummary?.count ?? 0}
                  />
                </SwiperSlide>
              ))}
            </SwiperComponent>
          </div>

          <div className="pagination-wrapper">
            <SliderPagination
              slides={data}
              currentSlide={currentSlide}
              onClick={(index) => swiper?.slideTo(index)}
            />
          </div>
        </div>

        <p className="text text__first">
          W Dogzi wiemy, jak ważny jest dla Ciebie dobrostan Twojego psa lub
          kota. Dlatego, bez względu na to, czy szukasz weterynarza, groomera a
          może hotelu dla zwierząt, u nas znajdziesz wszystkie usługi w
          najwyższej jakości. Nie ważne też, w jakim mieście akurat jesteś:
          Warszawa, Wrocław lub Toruń – zrzeszamy specjalistów z całej Polski,
          dzięki czemu Ty – zyskujesz wygodę.
        </p>

        <p className="text">
          Sami mamy zwierzęta i bardzo je kochamy. Rozumiemy, więc, że kiedy w
          grę wchodzi ich pielęgnacja, strzyżenie, czy leczenie – nie ma miejsca
          na przypadek. Specjaliści na Dogzi są weryfikowani, ich kompetencje –
          sprawdzone, a umiejętności – potwierdzone: tysiącami mokrych nosków i
          merdających ogonków. Dogzi pozwoli Ci znaleźć dokładnie to, czego
          potrzebuje Twój pies lub kot: od weterynarza przez pet sittera, po
          groomera. Ty zaś zyskasz wygodę, dzięki szybkiemu i łatwemu dostępowi
          do umawiania wizyt. Coś Ci wypadło? Nie ma problemu. Korzystając ze
          strony niemal natychmiastowo możesz odwołać wizytę lub zmienić jej
          datę. Spokojnie, nie musisz o tym pamiętać, serwis przypomni o
          zbliżającym się, umówionym terminie.
        </p>

        <p className="text">
          Dogzi to jednak nie tylko wygoda. To również poczucie bezpieczeństwa.
          Dyplomowani specjaliści podlegają jeszcze jednej jakże ważnej
          weryfikacji – Twojej. Dziel się opinią i zostawiaj rzetelne oceny
          punktom usługowym i ekspertom. Twoje zdanie ma znaczenie! Nie tylko
          pozwoli nam stale ulepszać serwis i jakość usług, ale też może stać
          się pomocne dla innych użytkowników. Dzięki niemu z większą łatwością
          wybiorą weterynarza, fizjoterapeutę, groomera lub behawiorystę dla
          swojego pupila.
        </p>

        <p className="text">
          Co ważne – Dogzi to nie tylko oszczędność czasu – dzięki możliwości
          błyskawicznemu umawianiu wizyt, ale również oszczędność pieniędzy.
          Rejestracja i korzystanie z serwisu jest całkowicie darmowe, a jedyne
          czego potrzebujesz, by z niego korzystać to sprawne urządzenie i
          dostęp do internetu.
        </p>

        <p className="text">Dołącz do nas, bo to niezwykle proste.</p>

        <p className="text">Twój Pupil wybrałby Dogzi! :)</p>
      </Wrapper>
    </SMostPopular>
  );
};

export default MostPopular;
