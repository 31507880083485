import styled, { css } from 'styled-components';

export const SUpdatePointsModal = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    display: flex;
    flex-flow: column;
    padding: 20px;

    .controls {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  `}
`;
