import { createReducer } from '@reduxjs/toolkit';
import { AuthModalType } from 'components/Header/Header';
import { Booking, Client, UserProgramSalon, Review, Salon } from 'const';
import { without } from 'lodash';
import { favoriteSalon } from '../salons/salonsActions';

import {
  cancelReservation,
  createReview,
  getClientFavorites,
  getClientReservations,
  getClientReviews,
  getClientUser,
  hideAuthModal,
  hideReservationCancelation,
  hideReviewEditor,
  logoutClient,
  setReservationsFilter,
  showAuthModal,
  showReservationCancelation,
  showReviewEditor,
  updateClientUser,
  updateReview,
  getUsersDiscounts,
  updatePhoneNumber,
  updateDogName,
  showReservationCancelationModal,
  confirmReservation,
} from './clientActions';

interface ClientState {
  user: Client | null;
  isAuthorized: boolean;
  triedAuthorize: boolean;
  dogName?: string | null;
  phoneNumber?: string | null;
  authProcess: {
    modalVisible: boolean;
    modalType: AuthModalType;
  };

  discountedSalons: {
    totalPages: number;
    items: UserProgramSalon[];
    loading: boolean;
  };

  favorite: {
    list: {
      data: Salon[];
      loading: boolean;
      pendingFavorite: number[];
    };
  };

  reservations: {
    list: {
      data: Booking[];
      filter: null | 'finished' | 'incoming';
      loading: boolean;
    };
    cancelation: {
      data: Booking | null;
      loading: boolean;
      visible: boolean;
    };
  };

  reviews: {
    list: {
      data: Review[];
      loading: boolean;
    };
    editor: {
      data: { appointment?: Booking; review: Review | null } | null;
      loading: boolean;
      visible: boolean;
    };
  };
}

const initialState: ClientState = {
  user: null,
  isAuthorized: false,
  triedAuthorize: false,
  phoneNumber: null,
  authProcess: {
    modalVisible: false,
    modalType: AuthModalType.SIGN_IN,
  },

  discountedSalons: { totalPages: 0, items: [], loading: true },

  favorite: {
    list: {
      data: [],
      loading: false,
      pendingFavorite: [],
    },
  },

  reservations: {
    list: {
      data: [],
      filter: null,
      loading: false,
    },
    cancelation: {
      visible: false,
      data: null,
      loading: false,
    },
  },

  reviews: {
    list: {
      data: [],
      loading: false,
    },
    editor: {
      data: null,
      loading: false,
      visible: false,
    },
  },
};

export default createReducer<ClientState>(initialState, {
  [getUsersDiscounts.fulfilled.toString()]: (state, action) => {
    return {
      ...state,
      discountedSalons: { ...action.payload, loading: false },
    };
  },
  [logoutClient.type]: (state) => ({
    ...state,
    user: null,
    isAuthorized: false,
  }),
  [getClientUser.pending.toString()]: (state, action) => ({
    ...state,
    isAuthorized: false,
  }),
  [getClientUser.fulfilled.toString()]: (state, action) => ({
    ...state,
    user: action.payload,
    isAuthorized: true,
    triedAuthorize: true,
  }),
  [getClientUser.rejected.toString()]: (state) => ({
    ...state,
    isAuthorized: false,
    triedAuthorize: true,
  }),
  [updateClientUser.fulfilled.toString()]: (state, action) => ({
    ...state,
    user: action.payload,
  }),
  [showAuthModal.type]: (state, action) => ({
    ...state,
    authProcess: {
      ...state.authProcess,
      modalVisible: true,
      modalType: action.payload,
    },
  }),
  [hideAuthModal.type]: (state) => ({
    ...state,
    authProcess: {
      ...state.authProcess,
      modalVisible: false,
    },
  }),
  [getClientFavorites.pending.toString()]: (state) => ({
    ...state,
    favorite: {
      ...state.favorite,
      list: {
        ...state.favorite.list,
        loading: true,
      },
    },
  }),
  [getClientFavorites.fulfilled.toString()]: (state, action) => ({
    ...state,
    favorite: {
      ...state.favorite,
      list: {
        ...state.favorite.list,
        data: action.payload,
        loading: false,
      },
    },
  }),
  [getClientFavorites.rejected.toString()]: (state) => ({
    ...state,
    favorite: {
      ...state.favorite,
      list: {
        ...state.favorite.list,
        loading: false,
      },
    },
  }),
  [favoriteSalon.pending.toString()]: (state, action) => ({
    ...state,
    favorite: {
      ...state.favorite,
      list: {
        ...state.favorite.list,
        pendingFavorite: [
          ...state.favorite.list.pendingFavorite,
          action.meta.arg,
        ],
      },
    },
  }),
  [favoriteSalon.fulfilled.toString()]: (state, action) => ({
    ...state,
    favorite: {
      ...state.favorite,
      list: {
        ...state.favorite.list,
        data: state.favorite.list.data.map((salon: Salon) => {
          if (salon.id === action.meta.arg) {
            return { ...salon, isFavourite: !salon.isFavourite };
          }
          return salon;
        }),
        pendingFavorite: without(
          state.favorite.list.pendingFavorite,
          action.meta.arg
        ),
      },
    },
  }),
  [favoriteSalon.rejected.toString()]: (state, action) => ({
    ...state,
    favorite: {
      ...state.favorite,
      list: {
        ...state.favorite.list,
        pendingFavorite: without(
          state.favorite.list.pendingFavorite,
          action.meta.arg
        ),
      },
    },
  }),
  [setReservationsFilter.type]: (state, action) => ({
    ...state,
    reservations: {
      ...state.reservations,
      list: {
        ...state.reservations.list,
        filter: action.payload,
      },
    },
  }),
  [getClientReservations.pending.toString()]: (state) => ({
    ...state,
    reservations: {
      ...state.reservations,
      list: {
        ...state.reservations.list,
        loading: true,
      },
    },
  }),
  [getClientReservations.fulfilled.toString()]: (state, action) => ({
    ...state,
    reservations: {
      ...state.reservations,
      list: {
        ...state.reservations.list,
        data: action.payload,
        loading: false,
      },
    },
  }),
  [getClientReservations.rejected.toString()]: (state) => ({
    ...state,
    reservations: {
      ...state.reservations,
      list: {
        ...state.reservations.list,
        loading: false,
      },
    },
  }),
  [getClientReviews.pending.toString()]: (state) => ({
    ...state,
    reviews: {
      ...state.reviews,
      list: {
        ...state.reviews.list,
        loading: true,
      },
    },
  }),
  [getClientReviews.fulfilled.toString()]: (state, action) => ({
    ...state,
    reviews: {
      ...state.reviews,
      list: {
        ...state.reviews.list,
        data: action.payload,
        loading: false,
      },
    },
  }),
  [getClientReviews.rejected.toString()]: (state) => ({
    ...state,
    reviews: {
      ...state.reviews,
      list: {
        ...state.reviews.list,
        loading: false,
      },
    },
  }),
  [showReviewEditor.type]: (state, action) => ({
    ...state,
    reviews: {
      ...state.reviews,
      editor: {
        ...state.reviews.editor,
        data: action.payload,
        visible: true,
      },
    },
  }),
  [hideReviewEditor.type]: (state) => ({
    ...state,
    reviews: {
      ...state.reviews,
      editor: {
        ...state.reviews.editor,
        data: null,
        loading: false,
        visible: false,
      },
    },
  }),
  [updateReview.pending.toString()]: (state) => ({
    ...state,
    reviews: {
      ...state.reviews,
      editor: {
        ...state.reviews.editor,
        loading: true,
      },
    },
  }),
  [updateReview.fulfilled.toString()]: (state, action) => ({
    ...state,
    reviews: {
      ...state.reviews,
      list: {
        ...state.reviews.list,
        data: state.reviews.list.data.map((review: Review) => {
          if (review.id === action.payload.id) return action.payload;
          return review;
        }),
      },
      editor: {
        ...state.reviews.editor,
        loading: false,
        data: null,
        visible: false,
      },
    },
  }),
  [updateReview.rejected.toString()]: (state) => ({
    ...state,
    reviews: {
      ...state.reviews,
      editor: {
        ...state.reviews.editor,
        loading: false,
      },
    },
  }),
  [createReview.pending.toString()]: (state) => ({
    ...state,
    reviews: {
      ...state.reviews,
      editor: {
        ...state.reviews.editor,
        loading: true,
      },
    },
  }),
  [createReview.fulfilled.toString()]: (state, action) => ({
    ...state,
    reviews: {
      ...state.reviews,
      editor: {
        ...state.reviews.editor,
        loading: false,
        data: null,
        visible: false,
      },
    },
  }),
  [createReview.rejected.toString()]: (state) => ({
    ...state,
    reviews: {
      ...state.reviews,
      editor: {
        ...state.reviews.editor,
        loading: false,
      },
    },
  }),
  [showReservationCancelation.type]: (state, action) => ({
    ...state,
    reservations: {
      ...state.reservations,
      cancelation: {
        ...state.reservations.cancelation,
        visible: true,
        data: action.payload,
      },
    },
  }),

  [showReservationCancelationModal.type]: (state) => ({
    ...state,
    reservations: {
      ...state.reservations,
      cancelation: {
        ...state.reservations.cancelation,
        visible: true,
      },
    },
  }),

  [hideReservationCancelation.type]: (state) => ({
    ...state,
    reservations: {
      ...state.reservations,
      cancelation: {
        ...state.reservations.cancelation,
        visible: false,
        data: null,
        loading: false,
      },
    },
  }),
  [cancelReservation.pending.toString()]: (state) => ({
    ...state,
    reservations: {
      ...state.reservations,
      cancelation: {
        ...state.reservations.cancelation,
        loading: true,
      },
    },
  }),
  [cancelReservation.fulfilled.toString()]: (state) => ({
    ...state,
    reservations: {
      ...state.reservations,
      cancelation: {
        ...state.reservations.cancelation,
        data: null,
        loading: false,
      },
    },
  }),
  [cancelReservation.rejected.toString()]: (state) => ({
    ...state,
    reservations: {
      ...state.reservations,
      cancelation: {
        ...state.reservations.cancelation,
        loading: false,
      },
    },
  }),
  [confirmReservation.fulfilled.toString()]: (state, action) => ({
    ...state,
  }),
  [updatePhoneNumber.type]: (state, action) => ({
    ...state,
    phoneNumber: action.payload,
  }),
  [updateDogName.type]: (state, action) => ({
    ...state,
    dogName: action.payload,
  }),
});
