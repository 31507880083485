import styled, { css } from 'styled-components'

export const SReviewEditor = styled.div`
  ${({ theme }) => css`
    .review-editor {
      &-header {
        height: 72px;

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        border-bottom: 2px solid #eceef5;
      }

      &-heading {
        font-size: 1.125rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.68rem;

        color: #3a3335;
      }

      &-close {
        position: absolute;
        top: 50%;
        right: ${theme.space.lg}px;

        margin: 0;
        padding: 0;

        transform: translateY(-50%);

        background: none;
        border: 0;
        box-shadow: none;

        cursor: pointer;

        span {
          color: #3a3335;
        }
      }

      &-name {
        font-size: 0.875rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.5rem;
        color: #3a3335;

        margin-bottom: ${theme.space.xs}px;
      }

      &-address {
        font-size: 0.875rem;
        line-height: 1.31rem;
        color: #908e8e;
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: ${theme.space.lg}px;
        padding-bottom: ${theme.space.xxxl}px;

        overflow-y: auto;

        form {
          max-width: 502px;
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;

          margin-top: ${theme.space.xl}px;

          label {
            margin-top: ${theme.space.xxl}px;
          }
        }
      }

      &-submit {
        width: 212px;
        height: 54px;

        margin-top: ${theme.space.xxl}px;
      }
    }
  `}
`
