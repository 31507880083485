import React from 'react';

const HotelReservationsIcon = () => (
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.15 3.632L8.628.754a.917.917 0 01.745 0l6.476 2.878a2.5 2.5 0 011.485 2.285V14A3.333 3.333 0 0114 17.334H4A3.333 3.333 0 01.666 14V5.917a2.5 2.5 0 011.485-2.285zM9 2.412L2.83 5.155a.833.833 0 00-.495.762V14c0 .92.746 1.667 1.667 1.667h10c.92 0 1.666-.746 1.666-1.667V5.917a.833.833 0 00-.495-.762L9.001 2.412zm1.667 7.422A.833.833 0 009.834 9H8.167a.833.833 0 00-.833.834V11.5c0 .46.373.833.833.833h1.667c.46 0 .833-.373.833-.833V9.834z"
      fill="#FF6B00"
    />
  </svg>
);

export default HotelReservationsIcon
