import styled, { css } from 'styled-components';
import media from '../../theme/respondTo';
import type { CategoryPillData } from '../../types';

export const SCategoryPill = styled.button<
  Pick<CategoryPillData, 'background' | 'textColor' | 'active' | 'size'>
>`
  ${({ background, textColor, active, size }) => css`
    background: linear-gradient(
      90deg,
      rgba(${background.from}, ${active ? 1 : 0.15}),
      rgba(${background.to}, ${active ? 1 : 0.15})
    );

    color: ${active ? '#fff' : textColor};

    ${media.md`
    &:hover {
      background-color: ${textColor};
      opacity: 0.7;
      color: #fff;

      svg path {
        fill: #fff;
      }
    }
    `}
    align-items: center;

    border: none;
    border-radius: 50px;
    column-gap: 8px;
    line-height: 28px;
    height: ${size === 'regular' ? '44px' : 'max-content'};
    cursor: pointer;
    display: flex;
    padding: 6px 17px;
  `}
`;
