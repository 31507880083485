import styled, { css } from 'styled-components';

import Button from './Button';

interface IStyledButton {
  bordered?: boolean;
  wide?: boolean;
  white?: boolean;
  small?: boolean;
}

export const SButton = styled(
  ({ bordered, wide, white, small, colorUnderline, ...res }) => (
    <Button bordered={bordered} {...res} />
  )
)<IStyledButton>`
  ${({ theme, bordered, wide, white, small, colorUnderline }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;

    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-decoration: none;
    color: white;

    padding: 12px 23px;

    background-color: ${theme.colors.primary};
    border: 1px solid ${theme.colors.primary};
    border-radius: 10px;

    cursor: pointer;

    &:hover:not(:disabled) {
      background-color: #bb4f01;
    }

    &:disabled {
      background-color: #a8a8a8;
      border-color: #a8a8a8;

      cursor: not-allowed;
    }

    ${small &&
    css`
      padding: 10px 20px;
    `}

    ${colorUnderline &&
    css`
      display: inline;
      font-size: 14px;
      color: ${theme.colors.primary};
      font-weight: ${theme.weight.bold};
      line-height: 27px;
      padding: 0;
      position: relative;
      width: max-content;
      cursor: pointer;

      &:after {
        background-color: rgba(255, 107, 0, 0.5);
        content: '';
        display: block;
        height: 1px;
        position: absolute;
        width: 100%;
      }
    `}

    ${white &&
    css`
      color: #3a3335;

      background-color: #f7f4f8;
      border-color: white;

      &:hover:not(:disabled) {
        background-color: white;
        border-color: white;
      }
    `}

    ${bordered &&
    css`
      color: ${theme.colors.primary};
      background-color: transparent;

      &:hover:not(:disabled) {
        background-color: transparent;
        border-color: #bb4f01;
        color: #bb4f01;
      }

      &:disabled {
        background-color: transparent;
        border-color: #a8a8a8;
        color: #a8a8a8;

        cursor: not-allowed;
      }
    `}

    ${wide &&
    css`
      width: 100%;
    `}
  `}
`;
