/* eslint-disable react-hooks/rules-of-hooks */
import React, { FC, useState, useEffect } from 'react';
import Loader from 'react-spinners/PuffLoader';
import theme from 'theme';

import { ListingMap, Pagination, Wrapper, MostPopular } from 'components';
import { SListing } from './Listing.styled';
import { Reservation, Salon } from 'const';
import ListingItem from 'components/ListingItem/ListingItem';
import { useFitBounds } from '../../hooks/useFitBounds';
import { useBreakpoint } from 'modules/breakpoint';
import { useSalonsMarkers } from '../../hooks/useSalonsMarkers';
import { useAppSelector, useAppDispatch } from '../../Root';
import type { SalonSearch } from '../../types/store';
import MapIcon from '../icons/MapIcon';
import { openReservationsModal } from '../../state/reducers/reservation/reservationActions';
import MarkerDetails from '../Shared/MarkerDetails/MarkerDetails';
import { setSingleSalon } from 'state/reducers/salons/salonsActions';
import { IPickedMarker } from 'types';

interface IListing {
  data: Salon[];
  loading: boolean;
  page: number;
  lastPage: number;
  total: number;
  search: SalonSearch;
  prevPage: () => void;
  setPage: (page: number) => void;
  nextPage: () => void;
  reservations: Reservation[];
  allSalons?: Salon[];
}

const Listing: FC<IListing> = ({
  data,
  loading,
  page,
  lastPage,
  prevPage,
  setPage,
  nextPage,
  allSalons,
}) => {
  const [filteredSalons, setFilteredSalons] = useState<Salon[]>([]);
  const [isMapVisible, setMapVisible] = useState(false);
  const { search } = useAppSelector(({ salons }) => salons);

  const { reservations } = useAppSelector(({ reservation }) => reservation);
  const { user } = useAppSelector(({ client }) => client);

  const dispatch = useAppDispatch();

  const breakpoint = useBreakpoint();

  const { markers, pickedSalon } = allSalons
    ? useSalonsMarkers(allSalons)
    : useSalonsMarkers(data);
  const [pickedMarkerState, setPickedMarkerState] =
    useState<IPickedMarker | null>(pickedSalon);

  const fitBounds = useFitBounds(data);

  useEffect(() => {
    setFilteredSalons(data);
  }, [data]);

  useEffect(() => {
    setPickedMarkerState(pickedSalon);
  }, [pickedSalon]);

  useEffect(() => {
    if (reservations.length === 0 || !user) return;

    dispatch(openReservationsModal());
  }, [user]);

  useEffect(() => {
    if (search.categories.length === 0) {
      setFilteredSalons(data);
    }
  }, [search.categories]);

  if (loading) {
    return (
      <SListing>
        <Wrapper className="wrapper">
          <div className="loader">
            <Loader color={theme.colors.primary} />
          </div>
        </Wrapper>
      </SListing>
    );
  }

  return (
    <SListing>
      <Wrapper className="wrapper">
        <div className="content">
          <ul className="list">
            {filteredSalons.length > 0 ? (
              filteredSalons.map((salon) => (
                <ListingItem
                  showImage={breakpoint.sm || !isMapVisible}
                  key={`salon-item-${salon.id}`}
                  {...salon}
                />
              ))
            ) : (
              <div>Brak wyników wyszukiwania</div>
            )}

            {lastPage > 1 && (
              <div className="pagination">
                <Pagination
                  {...{ page, lastPage, prevPage, setPage, nextPage }}
                />
              </div>
            )}
          </ul>

          {filteredSalons.length > 0 && (
            <div className="sticky-map-wrapper">
              {isMapVisible ? (
                <div className="listing-map-wrapper">
                  <button
                    onClick={() => setMapVisible(false)}
                    className="close-map-button"
                  >
                    <MapIcon />
                    Zamknij mapę
                  </button>
                  {pickedMarkerState && (
                    <MarkerDetails
                      id={0}
                      name={pickedMarkerState.name}
                      address={`${pickedMarkerState.salon?.city?.name}, ${pickedMarkerState.salon.street} ${pickedMarkerState.salon.buildingNumber}`}
                      onCloseClick={() => setPickedMarkerState(null)}
                      onLinkClick={() =>
                        dispatch(
                          setSingleSalon({ base: pickedMarkerState?.salon })
                        )
                      }
                    />
                  )}
                  <ListingMap markers={markers} fitBounds={fitBounds} />
                </div>
              ) : (
                <div
                  role="button"
                  onClick={() => setMapVisible(true)}
                  className="map-placeholder"
                >
                  <button>
                    <MapIcon />
                    <p>Pokaż mapę</p>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>

        <MostPopular />
      </Wrapper>
    </SListing>
  );
};

export default Listing;
