const config = {

  API_URL: process.env.REACT_APP_API,
  APP_ENV: process.env.REACT_APP_ENV,
  MAPBOX_TOKEN: process.env.REACT_APP_MAPBOX_TOKEN,
  FB_APP_ID: process.env.REACT_APP_FB_APP_ID,

  TOKEN_KEY: 'dogziatoken', // salon token
  TOKEN_CLIENT_KEY: 'dogziclienttoken', // client token

  HOME_DISTRICTS_PER_PAGE: 9,

}

export default config
