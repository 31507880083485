import GroomerIcon from '../components/icons/GroomerIcon';
import HotelIcon from '../components/icons/HotelIcon';
import TrainerIcon from '../components/icons/TrainerIcon';
import BehavioristIcon from '../components/icons/BehavioristIcon';
import PhysiotherapistIcon from '../components/icons/PhysiotherapistIcon';
import HandelerIcon from '../components/icons/HandelerIcon';
import PhotographerIcon from '../components/icons/PhotographerIcon';
import React from 'react';

export const renderIcon = (
  displayName: string,
  active: boolean,
  size: number = 15
) => {
  if (displayName === 'Groomer') {
    return <GroomerIcon size={size} active={active} />;
  }
  if (['Hotel', 'Hotele dla pupila'].includes(displayName)) {
    return <HotelIcon size={size} active={active} />;
  }
  if (['Trener', 'Szkolenie psów'].includes(displayName)) {
    return <TrainerIcon size={size} active={active} />;
  }
  if (['Behawiorysta', 'Behawiorystyka'].includes(displayName)) {
    return <BehavioristIcon size={size} active={active} />;
  }
  if (['Fizjoterapeuta'].includes(displayName.trim())) {
    return <PhysiotherapistIcon size={size} active={active} />;
  }
  if (['Handler', 'Handeler'].includes(displayName)) {
    return <HandelerIcon size={size} active={active} />;
  }
  if (['Fotograf'].includes(displayName)) {
    return <PhotographerIcon size={size} active={active} />;
  }
  return null;
};
