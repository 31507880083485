import React from 'react';

const DropdownChevron = ({ open }: { open: boolean }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: open ? 'rotate(0deg)' : 'rotate(180deg)' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0303 15.7803C18.7374 16.0732 18.2626 16.0732 17.9697 15.7803L12.1768 9.98744C12.0791 9.88981 11.9209 9.88981 11.8232 9.98744L6.03033 15.7803C5.73744 16.0732 5.26256 16.0732 4.96967 15.7803C4.67678 15.4874 4.67678 15.0126 4.96967 14.7197L10.7626 8.92678C11.446 8.24336 12.554 8.24336 13.2374 8.92678L19.0303 14.7197C19.3232 15.0126 19.3232 15.4874 19.0303 15.7803Z"
      fill="#304659"
    />
  </svg>
);

export default DropdownChevron;
