import { AsyncThunk, createAction, createAsyncThunk } from '@reduxjs/toolkit';

import client from 'api/client';
import { AppointmentDTO, Image, ServiceCreatorDTO } from 'const';
import config from 'const/config';
import { DateTime, Interval } from 'luxon';
import { defaultFormValues } from 'pages';
import { RootState } from 'state/createStore';
import applySalonProfileData from 'utils/applySalonProfileData';
import formErrorsHandler from 'utils/formErrorsHandler';
import { showAppModal } from '../app/appActions';
import { AppDialogCloseType } from 'components/UserModal/AppDialogModal';

const UPDATE_COMPANY_IMAGES = 'salon/updateImages';
export const updateCompanyImages = createAction<Image[]>(UPDATE_COMPANY_IMAGES);

export const setPartnerProgram = createAction<boolean>(
  'salon/setPartnerProgram'
);

const LOGOUT_USER = 'user/logout';
export const logoutUser = createAction(LOGOUT_USER);

const GET_USER = 'user/get';

export const getUser = createAsyncThunk(GET_USER, async (_, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(true);
  }

  return await client('provider/user')
    .auth(`Bearer ${token}`)
    .errorType('json')
    .get()
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(true);
    })
    .json((json) => {
      const { data } = json;
      return data;
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(true);
    });
});

const UPDATE_SALON_USER = 'salon/update';
export const updateSalonUser = createAsyncThunk(
  UPDATE_SALON_USER,
  async (payload: any, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_KEY);
    if (!token) {
      return thunkAPI.rejectWithValue(true);
    }

    return await client('provider/account')
      .auth(`Bearer ${token}`)
      .errorType('json')
      .patch(payload.body)
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(true);
      })
      .json((json) => {
        const { data } = json;
        payload.methods.reset(applySalonProfileData(data, defaultFormValues));
        return data;
      })
      .catch((error) => {
        formErrorsHandler(
          Object.keys(defaultFormValues),
          payload.methods.setError
        )(error);
        return thunkAPI.rejectWithValue(true);
      });
  }
);

const ACTIVE_SALON_OFFER = 'salon/activeOffer';
export const activeSalonOffer = createAsyncThunk(
  ACTIVE_SALON_OFFER,
  async (payload: any, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_KEY);
    return await client(`offers/accept`)
      .auth(`Bearer ${token}`)
      .post({ company: payload.id })
      .unauthorized(() => {
        thunkAPI.dispatch(
          showAppModal({
            appModalVisible: true,
            appModalType: 'error',
            appModalHeader: 'Błąd',
            appModalButtonText: 'Wróć do strony głównej',
            appModalMessage:
              'Wystąpił błąd podczas aktywacji oferty. Spróbuj ponownie później.',
            appModalCloseType: AppDialogCloseType.GO_HOME,
          })
        );
        return thunkAPI.rejectWithValue(true);
      })
      .notFound(() => {
        thunkAPI.dispatch(
          showAppModal({
            appModalVisible: true,
            appModalType: 'error',
            appModalHeader: 'Błąd',
            appModalButtonText: 'Wróć do strony głównej',
            appModalMessage:
              'Wystąpił błąd podczas aktywacji oferty. Spróbuj ponownie później.',
            appModalCloseType: AppDialogCloseType.GO_HOME,
          })
        );
        return thunkAPI.rejectWithValue(true);
      })
      .fetchError(() => {
        thunkAPI.dispatch(
          showAppModal({
            appModalVisible: true,
            appModalType: 'error',
            appModalHeader: 'Błąd',
            appModalButtonText: 'Wróć do strony głównej',
            appModalMessage:
              'Wystąpił błąd podczas aktywacji oferty. Spróbuj ponownie później.',
            appModalCloseType: AppDialogCloseType.GO_HOME,
          })
        );
        return thunkAPI.rejectWithValue(true);
      })
      .json((json) => {
        const { data } = json;
        thunkAPI.dispatch(
          showAppModal({
            appModalVisible: true,
            appModalType: 'success',
            appModalHeader: 'Brawo!',
            appModalButtonText: 'Wróć do strony głównej',
            appModalMessage: 'Oferta została aktywowana.',
            appModalCloseType: AppDialogCloseType.GO_HOME,
          })
        );
        return data;
      })
      .catch(() => {
        thunkAPI.dispatch(
          showAppModal({
            appModalVisible: true,
            appModalType: 'error',
            appModalHeader: 'Błąd',
            appModalButtonText: 'Wróć do strony głównej',
            appModalMessage:
              'Wystąpił błąd podczas aktywacji oferty. Spróbuj ponownie później.',
            appModalCloseType: AppDialogCloseType.GO_HOME,
          })
        );
        return thunkAPI.rejectWithValue(true);
      });
  }
);

const REMOVE_SALON_IMAGE = 'salon/removeImage';
export const removeSalonImage = createAsyncThunk(
  REMOVE_SALON_IMAGE,
  async (id: number, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_KEY);
    if (!token) {
      return thunkAPI.rejectWithValue(true);
    }

    return await client(`provider/account/images/${id}`)
      .auth(`Bearer ${token}`)
      .errorType('json')
      .delete()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(true);
      })
      .res(() => {
        return true;
      })
      .catch(() => {
        return thunkAPI.rejectWithValue(true);
      });
  }
);

const GET_SALON_WORKPLACE = 'salon/getWorkplace';
export const getSalonWorkplace = createAsyncThunk(
  GET_SALON_WORKPLACE,
  async (_, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_KEY);
    if (!token) {
      return thunkAPI.rejectWithValue(true);
    }

    return await client(`provider/workplace`)
      .auth(`Bearer ${token}`)
      .errorType('json')
      .get()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(true);
      })
      .json((json) => {
        const { data } = json;
        return data;
      })
      .catch(() => {
        return thunkAPI.rejectWithValue(true);
      });
  }
);

const ADD_SALON_SLOT = 'salon/addSlot';
export const addSalonSlot: AsyncThunk<
  any,
  { id?: number; name: string; avatar?: File | null },
  { state: RootState }
> = createAsyncThunk(ADD_SALON_SLOT, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(true);
  }

  const { name, avatar, id } = payload;
  const formData = new FormData();

  formData.append('name', name);

  if (id) {
    formData.append('id', JSON.stringify(id));
  }
  if (avatar) {
    formData.append('avatar', avatar);
  }

  const response = await fetch(`${config.API_URL}/provider/slots`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });

  await response.json();

  thunkAPI.dispatch(getSalonWorkplace());

  return true;

  /*

  return await client(`provider/slots`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .post(name)
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(true);
    })
    .res(() => {
      thunkAPI.dispatch(getSalonWorkplace());
      return true;
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(true);
    });

  */
});

const REMOVE_SALON_SLOT = 'salon/removeSlot';
export const removeSalonSlot: AsyncThunk<
  boolean,
  number,
  { state: RootState }
> = createAsyncThunk(REMOVE_SALON_SLOT, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(true);
  }

  return await client(`provider/slots/${payload}`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .delete()
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(true);
    })
    .res(() => {
      thunkAPI.dispatch(getSalonWorkplace());
      return true;
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(true);
    });
});

const REMOVE_SALON_SERVICE = 'salon/removeService';
export const removeSalonService: AsyncThunk<
  boolean,
  number,
  { state: RootState }
> = createAsyncThunk(REMOVE_SALON_SERVICE, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(true);
  }

  return await client(`provider/services/${payload}`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .delete()
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(true);
    })
    .res(() => {
      thunkAPI.dispatch(getSalonWorkplace());
      return true;
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(true);
    });
});

const ADD_CATEGORY_SERVICE = 'salon/addCategoryService';
export const addCategoryService: AsyncThunk<
  any,
  {
    categoryId: number;
    service: ServiceCreatorDTO;
  },
  { state: RootState }
> = createAsyncThunk(ADD_CATEGORY_SERVICE, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(true);
  }

  return await client(`provider/services`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .post({ categoryId: payload.categoryId, ...payload.service, accepted: 0 })
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(true);
    })
    .res(async () => {
      await thunkAPI.dispatch(getSalonWorkplace());
      return true;
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(true);
    });
});

const UPDATE_CATEGORY_SERVICE = 'salon/updateCategoryService';
export const updateCategoryService: AsyncThunk<
  any,
  ServiceCreatorDTO,
  { state: RootState }
> = createAsyncThunk(UPDATE_CATEGORY_SERVICE, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(true);
  }

  return await client(`provider/services/${payload.id}`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .patch(payload)
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(true);
    })
    .res(async () => {
      await thunkAPI.dispatch(getSalonWorkplace());
      return true;
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(true);
    });
});

const DELETE_APPOINTMENT = 'salon/deleteAppointment';
export const deleteAppointment: AsyncThunk<
  any,
  number | string,
  { state: RootState }
> = createAsyncThunk(DELETE_APPOINTMENT, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(false);
  }

  return await client(`provider/appointments/${payload}`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .delete()
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(false);
    })
    .res(async () => {
      return true;
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(false);
    });
});

const UPDATE_APPOINTMENT = 'salon/updateAppointment';
export const updateAppointment: AsyncThunk<
  any,
  AppointmentDTO,
  { state: RootState }
> = createAsyncThunk(UPDATE_APPOINTMENT, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(false);
  }

  const { id, ...res } = payload;

  return await client(`provider/appointments/${id}`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .put(res)
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(false);
    })
    .res(async () => {
      return true;
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(false);
    });
});

const CREATE_APPOINTMENT = 'salon/createAppointment';
export const createAppointment: AsyncThunk<
  any,
  AppointmentDTO,
  { state: RootState }
> = createAsyncThunk(CREATE_APPOINTMENT, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_KEY);

  if (!token) {
    return thunkAPI.rejectWithValue(false);
  }

  return await client(`provider/appointments`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .post(payload)
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(false);
    })
    .res(async () => {
      return true;
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(false);
    });
});

const ADD_SUBSCRIPTION = 'salon/subscription';
export const addSubscription: AsyncThunk<
  { paymentUrl: string; status: string },
  string,
  { state: RootState }
> = createAsyncThunk(ADD_SUBSCRIPTION, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(false);
  }

  return await client(`provider/account/subscription`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .post({ continueUri: payload })
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(false);
    })
    .json((json) => {
      const { data } = json;
      return data;
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(false);
    });
});

const ADD_SUBSCRIPTION_CODE = 'salon/subscriptionCode';
export const addSubscriptionCode: AsyncThunk<
  boolean,
  string,
  { state: RootState }
> = createAsyncThunk(ADD_SUBSCRIPTION_CODE, async (payload, thunkAPI) => {
  const token = localStorage.getItem(config.TOKEN_KEY);
  if (!token) {
    return thunkAPI.rejectWithValue(false);
  }

  return await client(`provider/account/subscription/code`)
    .auth(`Bearer ${token}`)
    .errorType('json')
    .post({ code: payload })
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(false);
    })
    .json((json) => {
      const { data } = json;
      const startDateTimestamp =
        // @ts-ignore
        thunkAPI.getState().user.user?.company.subscription.endDate ||
        DateTime.local().toMillis() / 1000;

      if (data.endDate) {
        const startDate = DateTime.fromMillis(startDateTimestamp * 1000);
        const endDate = DateTime.fromMillis(data.endDate * 1000);

        const length = Interval.fromDateTimes(startDate, endDate).length(
          'days'
        );
        return { ...data, length };
      }

      return data;
    })
    .catch((error) => {
      return thunkAPI.rejectWithValue(error.json);
    });
});
