import { FC, useMemo } from 'react';
import classNames from 'classnames';
import { useBreakpoint } from '../../modules/breakpoint';
import { SPagination } from './Pagination.styled';
import { AppRoute } from '../../const';
import { useHistory } from 'react-router-dom';
import useLocalizeRoute from '../../utils/useLocalizeRoute';

interface IPagination {
  page: number;
  lastPage: number;
  prevPage: () => void;
  setPage: (page: number) => void;
  nextPage: () => void;
}

const Pagination: FC<IPagination> = ({
  page,
  lastPage,
  prevPage,
  setPage,
  nextPage,
}) => {
  const breakpoint = useBreakpoint();

  const { localizeRouteKey } = useLocalizeRoute();

  const history = useHistory();

  const handleChangePage = (page: number) => () => {
    setPage(page);

    history.push(
      localizeRouteKey(AppRoute.List).replace(':page?', String(page))
    );
  };

  const paginate = (items: number[], page = 1) => {
    let sliceParam = page - 1 + 5;
    let offset = page - 2;
    offset = offset < 0 ? 0 : offset;
    return items.slice(offset, sliceParam);
  };

  const pages = useMemo(() => {
    const temp = new Array(lastPage).fill(true).map((_, index) => index + 1);
    return paginate(temp, page);
  }, [page, lastPage]);

  return (
    <SPagination>
        <button className="prev" onClick={prevPage}>
          <span className="material-icons">chevron_left</span> Poprzedni
        </button>
      <ul>
        <li
         className={classNames({
          active: page === 1,
        })}
        key={`pagination-${1}`}
        >
          <button type="button" onClick={handleChangePage(1)}>
            1
          </button>
        </li>
        { page > (lastPage / 2 - 1) && <li><button type="button">
          ...
        </button></li> }
        {pages.map((paginationPage) => {
          return (
            ((paginationPage !== lastPage && lastPage > 5) && paginationPage !== 1) && <li
              className={classNames({
                active: paginationPage === page,
              })}
              key={`pagination-${paginationPage}`}
            >
              <button type="button" onClick={handleChangePage(paginationPage)}>
                {paginationPage}
              </button>
            </li>
          );
        })}
        { page < (lastPage / 2 - 1) && <li><button type="button">
          ...
        </button></li> }
        <li
        className={classNames({
          active: page === lastPage,
        })}
        key={`pagination-${lastPage}`}>
        <button type="button" onClick={handleChangePage(lastPage)}>
          {lastPage}
        </button>
        </li>
      </ul>
        <button className="next" onClick={nextPage}>
          Następny <span className="material-icons">chevron_right</span>
        </button>
    </SPagination>
  );
};

export default Pagination;
