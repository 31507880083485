import styled from 'styled-components';

export const SAddSlotForm = styled.form`
  background-color: rgba(255, 107, 0, 0.13);
  padding: 20px;

  .input-wrapper {
    display: flex;
    flex-flow: column;
  }

  fieldset {
    display: flex;
    margin: 30px 0;
    justify-content: space-between;
  }
`;
