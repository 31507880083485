import { FC } from 'react';
import { useIntl } from 'react-intl';
import { SCategoryTile } from './CategoryTile.styled';
import { useAppDispatch } from 'Root';
import { useHistory } from 'react-router';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import { setSalonsSearch } from 'state/reducers/salons/salonsActions';
import { AppRoute } from 'const';

interface CategoryTileProps {
  id: number;
  image: string;
  name: string;
  companiesNumber: number;
}

const CategoryTile: FC<CategoryTileProps> = ({
  id,
  image,
  name,
  companiesNumber,
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { formatMessage } = useIntl();
  const { localizeRouteKey } = useLocalizeRoute();

  const handleClick = async () => {
    await dispatch(
      setSalonsSearch({
        location: null,
        service: null,
        categories: [{ value: id, label: name }],
      })
    );

    history.push(localizeRouteKey(AppRoute.List).replace(':page?', String(1)));
  };

  return (
    <SCategoryTile onClick={handleClick}>
      <div className="thumbnail">
        <img src={image} alt={name} loading="lazy"/>
      </div>

      <div className="tile-content">
        <h2 className="heading">{name}</h2>
        <p className="text">
          {companiesNumber || 0} {formatMessage({ id: 'general.salons' })}
        </p>
      </div>
    </SCategoryTile>
  );
};

export default CategoryTile;
