import React from 'react';

const color = '#029B9B';
const activeColor = '#fff';

const PhysiotherapistIcon = ({
  active,
  size = 15,
}: {
  active?: boolean;
  size?: number;
}) => (
  <svg
    width={`${size}px`}
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1825_1338)">
      <path
        d="M7.32167 5.17039C8.27045 4.87532 8.69829 3.49768 8.27727 2.09335C7.85625 0.689022 6.74581 -0.210209 5.79703 0.0848642C4.84824 0.379937 4.4204 1.75758 4.84142 3.16191C5.26244 4.56624 6.37288 5.46547 7.32167 5.17039Z"
        fill={active ? activeColor : color}
      />
      <path
        d="M17.1513 7.99727C18.0544 6.85278 18.1597 5.41186 17.3865 4.77888C16.6133 4.1459 15.2543 4.56056 14.3511 5.70505C13.448 6.84955 13.3427 8.29048 14.1159 8.92346C14.8891 9.55643 16.2481 9.14177 17.1513 7.99727Z"
        fill={active ? activeColor : color}
      />
      <path
        d="M4.34698 8.59433C4.99925 7.83305 4.64747 6.43326 3.56125 5.46782C2.47504 4.50237 1.06572 4.33687 0.413454 5.09815C-0.238813 5.85943 0.112971 7.25921 1.19918 8.22465C2.2854 9.1901 3.69471 9.35561 4.34698 8.59433Z"
        fill={active ? activeColor : color}
      />
      <path
        d="M13.8554 3.1547C14.2764 1.75037 13.8486 0.372726 12.8998 0.0776524C11.951 -0.217421 10.8406 0.681811 10.4195 2.08614C9.99853 3.49047 10.4264 4.86811 11.3752 5.16318C12.3239 5.45826 13.4344 4.55903 13.8554 3.1547Z"
        fill={active ? activeColor : color}
      />
      <path
        d="M9.06545 16.4997C12.6416 16.4997 15.7981 14.5504 15.2274 12.5322C14.6566 10.5141 12.0876 10.4715 11.333 8.61569C10.8562 7.51967 10.019 7.25977 9.06545 7.25977C8.11194 7.25977 7.27467 7.51967 6.79791 8.61569C6.04282 10.4715 3.47379 10.5118 2.90305 12.5322C2.33231 14.5527 5.48888 16.4997 9.06545 16.4997Z"
        fill={active ? activeColor : color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1825_1338">
        <rect width="18" height="16.5" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PhysiotherapistIcon;
