import { Category } from './app-types';

export interface ICategory {
  id: number;
  name: string;
  displayName: string;
  textColor: string;
  background: {
    from: string;
    to: string;
  };
}

export const availableCategories: ICategory[] = [
  {
    name: 'Groomer',
    displayName: 'Groomer',
    id: 1,
    textColor: '#FF6B00',
    background: { from: '205, 86, 0', to: '255, 107, 0' },
  },
  {
    name: 'Hotele dla pupila',
    displayName: 'Hotel',
    id: 2,
    textColor: '#018EF4',
    background: { from: '3, 115, 195', to: '0, 143, 246' },
  },
  {
    id: 3,
    name: 'Szkolenie psów',
    displayName: 'Trener',
    textColor: '#2CA401',
    background: { from: '38, 146, 0', to: '44, 166, 1' },
  },
  {
    id: 4,
    name: 'Behawiorystyka',
    displayName: 'Behawiorysta',
    textColor: '#8F03C1',
    background: { from: '114, 0, 154', to: '146, 2, 197' },
  },
  {
    id: 5,
    name: 'Fizjoterapeuta',
    displayName: 'Fizjoterapeuta',
    textColor: '#029B9B',
    background: { from: '2, 141, 141', to: '0, 174, 174' },
  },
  {
    id: 6,
    name: 'Handler',
    displayName: 'Handler',
    textColor: '#B20342',
    background: { from: '141, 2, 52', to: '224, 3, 82' },
  },
  {
    id: 7,
    name: 'Fotograf',
    displayName: 'Fotograf',
    textColor: '#D17E02',
    background: { from: '176, 106, 0', to: '253, 152, 3' },
  },
];

export const parseCategories = (categories: Category[]): ICategory[] => {
  return categories.map((category) => {
    const data = availableCategories.find((item) => item.id === category.id);

    return {
      id: category.id,
      name: category.name,
      displayName: data ? data.displayName : '',
      textColor: data ? data.textColor : '',
      background: data ? data?.background : { from: '', to: '' },
    };
  });
};
