import React, { ReactElement, useEffect } from 'react';
import { Header, Footer } from 'components';
import Sticky from 'react-stickynode';
import { useAppDispatch, useAppSelector } from '../../Root';
import { getSalons } from '../../state/reducers/salons/salonsActions';

interface LayoutProps {
  children: ReactElement | ReactElement[];
  sticky?: {
    zIndex: number;
  };
  hasHeader: boolean;
  skipLoadSalons?: boolean;
  showFilters?: boolean;
}

const Layout = ({
  children,
  sticky,
  hasHeader,
  skipLoadSalons,
  showFilters = false,
}: LayoutProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if(!skipLoadSalons){
      dispatch(getSalons({ page: '1', pagination: true }));
    }
  }, []);

  return (
    <>
      {sticky && hasHeader && (
        <Sticky innerZ={sticky.zIndex}>
          {() => (
            <Header
              status={Sticky.STATUS_FIXED}
              hasColor
              showFilters={showFilters}
            />
          )}
        </Sticky>
      )}
      {!sticky && hasHeader && <Header />}
      {children}
      <Footer />
    </>
  );
};

export default Layout;
