import React, { useState } from 'react';
import { SUpdatePointsModal } from './UpdatePointsModal.styled';
import Modal from 'styled-react-modal';
import { Button } from '../index';

interface UpdatePointsModalProps {
  id: number;
  onClose: () => void;
  onSubmit: (id: number, pointsAmount: number) => void;
  pointsAmount: number;
}

const UpdatePointsModal = ({
  onClose,
  id,
  pointsAmount,
  onSubmit,
}: UpdatePointsModalProps) => {
  const [points, setPoints] = useState(pointsAmount);

  return (
    <Modal isOpen>
      <SUpdatePointsModal>
        <button onClick={onClose}>close</button>
        <div>Ustaw punkty użytkownika</div>
        <input
          type="number"
          onChange={({ target }) => setPoints(parseInt(target.value))}
          value={points}
          min={0}
          max={10}
        />
        <div className="controls">
          <Button type="button" bordered onClick={onClose}>
            Anuluj
          </Button>
          <Button onClick={() => onSubmit(id, points)}>Zapisz</Button>
        </div>
      </SUpdatePointsModal>
    </Modal>
  );
};

export default UpdatePointsModal;
