function autoFormatTimeInput(value: string): string {
  if (!value) return ''

  let inputValue: string[] = value.split('')
  let correctValue = value

  switch (inputValue.length) {
    case 1:
      correctValue = `${correctValue}0:00`
      break
    case 3:
      correctValue = `${correctValue}00`
      break
    case 4:
      correctValue = `${correctValue}0`
      break
    default:
      correctValue = correctValue
  }

  return correctValue
}

export default autoFormatTimeInput
