import { useEffect } from 'react'

function useBlockScroll(active, className = 'no-scroll') {
  useEffect(() => {
    if (active && !document.body.classList.contains(className)) {
      document.body.classList.add(className)
    } else {
      document.body.classList.remove(className)
    }

    return () => {
      document.body.classList.remove(className)
    }
  }, [active])
}

export default useBlockScroll
