import React from 'react';
import type { SalonReview } from '../../const';
import StarIcon from '../icons/StarIcon';
import { format } from 'date-fns';

interface IReviewProps extends SalonReview {
  isLast: boolean;
}

const FILLED_COLOR = '#FFB800';
const INACTIVE_COLOR = '#D1D1D1';

const Opinion = ({
  user,
  content,
  createdAt,
  rating,
  isLast,
}: IReviewProps) => {
  const totalStars = Array.from({ length: 5 });

  return (
    <li className="opinion">
      <div className="opinion__rating">
        {totalStars.map((_, index) => (
          <StarIcon
            key={index}
            size={18}
            color={index + 1 <= rating ? FILLED_COLOR : INACTIVE_COLOR}
          />
        ))}
      </div>
      <p className="opinion__text">{content}</p>

      <div className="opinion__author">
        <p className="opinion__author-name">{user?.name}</p>
        <p className='opinion__date'>{format(new Date(createdAt), 'MMM dd, yyyy')}</p>
      </div>

      {!isLast && <span className="opinion__divider" />}
    </li>
  );
};

export default Opinion;
