import { FC } from 'react'
import CookieConsent from 'react-cookie-consent'
import theme from 'theme'

import { SCookiesAlert } from './CookiesAlert.styled'

const CookiesAlert: FC = () => {
  return (
    <CookieConsent
      location="none"
      buttonText="OK, rozumiem"
      cookieName="dogzi-cookies-consent"
      style={{
        maxWidth: 1144,
        minHeight: 80,
        left: '50%',
        bottom: '16px',
        alignItems: 'center',
        transform: 'translateX(-50%)',
        background: 'white',
        borderRadius: 6,
        boxShadow: '0px 20px 24px rgba(153, 155, 168, 0.15)',
      }}
      buttonStyle={{
        width: 132,
        height: 40,
        fontSize: '.75rem',
        fontWeight: theme.weight.bold,
        color: 'white',
        background: theme.colors.primary,
        borderRadius: 8,
        padding: 0,
        marginRight: 20,
      }}
      expires={150}
    >
      <SCookiesAlert>
        <p>
          Na tej stronie korzystamy z plików cookie, aby zadbać o jak najlepsze
          doświadczenia naszych użytkowników.
        </p>
        <span>
          Kontynuując po wyświetleniu tej wiadomości, wyrażasz na to zgodę.
        </span>
      </SCookiesAlert>
    </CookieConsent>
  )
}

export default CookiesAlert
