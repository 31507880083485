import React, { useEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import {
  ProtectedRoute,
  PageLoader,
  AppLayout,
  ProfileLayout,
  AppDialogModal,
} from 'components';
import { LocalizedSwitch } from 'modules/i18n';

import { AppRoute } from 'const';
import { useAppDispatch, useAppSelector } from 'Root';
import useLocalizeRoute from 'utils/useLocalizeRoute';

import * as pages from 'pages';
import { getUser } from 'state/reducers/user/userActions';
import {
  getClientUser,
  showAuthModal,
} from 'state/reducers/client/clientActions';
import { getCategories, getHomepage } from 'state/reducers/app/appActions';
import { AuthModalType } from 'components/Header/Header';
import TagManager from 'react-gtm-module';

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const { localizeRouteKey } = useLocalizeRoute();
  const {
    categoriesLoading,
    homepage: { loading: homepageLoading },
  } = useAppSelector(({ app }) => app);

  const {
    isAuthorized: isUserAuthenticated,
    triedAuthorize: triedAuthorizeUser,
  } = useAppSelector(({ user }) => user);

  const {
    isAuthorized: isClientAuthenticated,
    triedAuthorize: triedAuthorizeClient,
  } = useAppSelector(({ client }) => client);

  const appAuthPath = localizeRouteKey(AppRoute.AppAuthLogin);
  const clientAuthPath = localizeRouteKey(AppRoute.Home);

  const checkForPasswordReset = () => {
    const search = new URLSearchParams(location.search);
    const email = search.get('email');
    const token = search.get('reset-password-token');

    if (email && token) {
      dispatch(showAuthModal(AuthModalType.RESET_PASSWORD));
    }
  };

  useEffect(() => {
    dispatch(getUser());
    dispatch(getClientUser());
    dispatch(getCategories());
    dispatch(getHomepage());
    checkForPasswordReset();
  }, []);

  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('571232744941142');
        ReactPixel.pageView();
      });
  }, []);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-NVGLNRH',
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  const { appDialogModal } = useAppSelector(({ app }) => app);

  if (
    !triedAuthorizeUser ||
    !triedAuthorizeClient ||
    categoriesLoading ||
    homepageLoading
  ) {
    return <PageLoader />;
  }
  return (
    <>
      {appDialogModal.appModalVisible && <AppDialogModal />}
      <LocalizedSwitch>
        <Route exact path={AppRoute.Home}>
          <pages.Home />
        </Route>

        <Route exact path={AppRoute.List}>
          <pages.Salons />
        </Route>

        <Route exact path={AppRoute.Salon}>
          <pages.Salon />
        </Route>

        <Route exact path={AppRoute.Regulations}>
          <pages.Regulations />
        </Route>

        <Route exact path={AppRoute.Search}>
          <pages.Search />
        </Route>

        <Route path={AppRoute.Profile}>
          <LocalizedSwitch>
            <Route path={AppRoute.Profile}>
              <ProfileLayout>
                <LocalizedSwitch>
                  <ProtectedRoute
                    exact
                    path={AppRoute.Profile}
                    {...{
                      isAuthenticated: isClientAuthenticated,
                      authenticationPath: clientAuthPath,
                    }}
                  >
                    <pages.ProfileReservations />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={AppRoute.ProfileSettings}
                    {...{
                      isAuthenticated: isClientAuthenticated,
                      authenticationPath: clientAuthPath,
                    }}
                  >
                    <pages.ProfileSettings />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={AppRoute.ProfilePassword}
                    {...{
                      isAuthenticated: isClientAuthenticated,
                      authenticationPath: clientAuthPath,
                    }}
                  >
                    <pages.ProfilePassword />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={AppRoute.ProfilePartnersProgram}
                    isAuthenticated={isUserAuthenticated}
                    authenticationPath={clientAuthPath}
                  >
                    <pages.ProfilePartnersProgram />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={AppRoute.ProfileFavorite}
                    {...{
                      isAuthenticated: isClientAuthenticated,
                      authenticationPath: clientAuthPath,
                    }}
                  >
                    <pages.ProfileFavorite />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={AppRoute.ProfileReviews}
                    {...{
                      isAuthenticated: isClientAuthenticated,
                      authenticationPath: clientAuthPath,
                    }}
                  >
                    <pages.ProfileReviews />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={AppRoute.Logout}
                    {...{
                      isAuthenticated: isClientAuthenticated,
                      authenticationPath: clientAuthPath,
                    }}
                  >
                    <pages.Logout />
                  </ProtectedRoute>
                </LocalizedSwitch>
              </ProfileLayout>
            </Route>
          </LocalizedSwitch>
        </Route>

        <Route path={AppRoute.App}>
          <LocalizedSwitch>
            <Route exact path={AppRoute.AppAuthLogin}>
              <pages.AppAuthLogin />
            </Route>
            <Route exact path={AppRoute.AppAuthForgotPassword}>
              <pages.AppAuthForgotPassword />
            </Route>
            <Route exact path={AppRoute.AppAuthResetPassword}>
              <pages.AppAuthResetPassword />
            </Route>

            <Route exact path={AppRoute.AppAuthRegister}>
              <pages.AppAuthRegister />
            </Route>
            <Route exact path={AppRoute.AppAuthRegisterSuccess}>
              <pages.AppAuthRegisterSuccess />
            </Route>
            <Route exact path={AppRoute.AppAuthRegisterProfile}>
              <pages.AppAuthRegisterProfile />
            </Route>

            <Route exact path={AppRoute.App}>
              <Redirect to={localizeRouteKey(AppRoute.AppProfile)} />
            </Route>

            <Route path={AppRoute.App}>
              <AppLayout>
                <LocalizedSwitch>
                  <ProtectedRoute
                    exact
                    path={AppRoute.AppReservations}
                    {...{
                      isAuthenticated: isUserAuthenticated,
                      authenticationPath: appAuthPath,
                    }}
                  >
                    <pages.AppReservations />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={AppRoute.AppHotelReservations}
                    {...{
                      isAuthenticated: isUserAuthenticated,
                      authenticationPath: appAuthPath,
                    }}
                  >
                    <pages.AppHotelReservations />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={AppRoute.AppProfile}
                    {...{
                      isAuthenticated: isUserAuthenticated,
                      authenticationPath: appAuthPath,
                    }}
                  >
                    <pages.AppProfile />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={AppRoute.AppWorkplace}
                    {...{
                      isAuthenticated: isUserAuthenticated,
                      authenticationPath: appAuthPath,
                    }}
                  >
                    <pages.AppWorkplace />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={AppRoute.AppPartnersProgram}
                    isAuthenticated={isUserAuthenticated}
                    authenticationPath={appAuthPath}
                  >
                    <pages.AppPartnersProgram />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={AppRoute.AppSubscription}
                    {...{
                      isAuthenticated: isUserAuthenticated,
                      authenticationPath: appAuthPath,
                    }}
                  >
                    <pages.AppSubscription />
                  </ProtectedRoute>
                  <ProtectedRoute
                    exact
                    path={AppRoute.AppLogout}
                    {...{
                      isAuthenticated: isUserAuthenticated,
                      authenticationPath: appAuthPath,
                    }}
                  >
                    <pages.AppLogout />
                  </ProtectedRoute>
                </LocalizedSwitch>
              </AppLayout>
            </Route>
          </LocalizedSwitch>
        </Route>

        <Route exact path={AppRoute.AppAdmin}>
          <pages.AppAdmin />
        </Route>

        <Route exact path={AppRoute.AppCancelReservation}>
          <pages.AppCancelReservation />
        </Route>

        <Route exact path={AppRoute.AppConfirmReservation}>
          <pages.AppConfirmReservation />
        </Route>

        <Route exact path={AppRoute.AppAcceptOffer}>
          <pages.AppAcceptOffer />
        </Route>

        <Route path="*">
          <p>404</p>
        </Route>
      </LocalizedSwitch>
    </>
  );
};

export default App;
