import styled, { css } from 'styled-components'

export const SAppAuthPageIcon = styled.div`
  ${({ theme }) => css`
    width: 181px;
    height: 124px;

    margin-bottom: ${theme.space.xl}px;

    .icon {
      width: 73px;
      height: 73px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: white;

      position: relative;

      margin: 0 auto;
      margin-top: -73px;

      border-radius: 50%;
      background-color: #c6d8d3;

      z-index: 1;

      span {
        font-size: 2rem;
      }
    }
  `}
`
