import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SMostPopular = styled.section`
  ${({ theme }) => css`
    padding: 44px 0 85px;

    ${media.lg`
        padding: 69px 0 85px;
      `}

    .section-heading {
      font-size: 28px;
      font-weight: ${theme.weight.bold};
      line-height: 38px;
      color: #304659;
      text-align: center;

      margin-bottom: 36px;

      ${media.lg`
        font-size: 32px;
        line-height: 36px;
      `}
    }

    .content {
      display: none;

      ${media.md`
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        grid-gap: ${theme.space.xl}px;

        justify-content: center;

        margin-bottom: ${theme.space.xxl}px;  
      `}
    }

    .most-popular {
      &__slider-wrapper {
        display: flex;
        flex-flow: column;
        overflow: hidden;

        ${media.md`
            display: none;
        `}
      }
    }

    .pagination-wrapper {
      display: flex;
      justify-content: center;
      margin: 24px 0 0;
      width: 100%;
    }

    .text {
      color: rgba(144, 142, 142, 1);
      font-size: 14px;
      line-height: 32px;

      &__first {
        margin-top: 45px;
      }
    }

    .action {
      display: flex;
      justify-content: center;
    }

    .load-more {
      height: 46px;

      text-transform: lowercase;
      line-height: 1.31rem;

      padding: 0 ${theme.space.md + 5}px;

      .icon {
        font-size: 1.125rem;

        margin-top: 1px;
        margin-right: ${theme.space.sm}px;
      }
    }

    ${media.lg`
      .content {
        grid-template-columns: repeat(3, 1fr);
      }
    `}
  `}
`;
