import { createReducer } from '@reduxjs/toolkit';
import { Booking, Reservation, ReservationModalStatus, Slot } from 'const';
import {
  addServiceToReservation,
  clearReservation,
  closeReservationModal,
  getAvailableDays,
  getServiceProposedHours,
  removeServiceFromReservation,
  submitReservation,
  updateServiceInReservation,
  updateSlotId,
  openReservationsModal,
  submitReservationWithoutLoggin,
} from './reservationActions';

interface ReservationState {
  visible: boolean;
  loading: boolean;
  status: ReservationModalStatus;

  summary: Booking[];
  error: { message: string; index: number } | null;

  reservations: Reservation[];
  reservationHours: Record<number, string[]> | null;
  reservationDays: Record<number, number[]> | null;
  slots: Slot[];

  changed: boolean;
}

const initialState: ReservationState = {
  visible: false,
  loading: false,
  status: ReservationModalStatus.IDLE,

  summary: [],
  error: null,

  slots: [],
  reservations: [],
  reservationHours: null,
  reservationDays: null,
  changed: false,
};

export default createReducer<ReservationState>(initialState, {
  [updateSlotId.type]: (state, { payload }) => {
    const item = state.reservations.find((r) => r.salonId === payload.salonId);

    if (!item) return state;

    const updatedReservations = state.reservations.map((item) => {
      if (item.service.id === payload.serviceId) {
        return {
          ...item,
          slotId: payload.slotId,
        };
      }

      return item;
    });

    return {
      ...state,
      reservations: updatedReservations,
    };
  },
  [closeReservationModal.type]: (state) => ({
    ...state,
    visible: false,
  }),
  [addServiceToReservation.fulfilled.toString()]: (state, { payload }) => {
    const alreadyExist = state.reservations.find(
      (item) =>
        item.salonId === payload.salonId &&
        item.service.id === payload.service.id
    );

    if (!!alreadyExist) return state;

    return {
      ...state,
      visible: true,
      reservations: [
        ...state.reservations,
        { ...payload, time: null, expanded: true },
      ],
    };
  },
  [openReservationsModal.type]: (state) => ({
    ...state,
    visible: true,
  }),
  [removeServiceFromReservation.type]: (state, action) => {
    const reservationHours = { ...state.reservationHours };
    delete reservationHours[action.payload];

    const reservationDays = { ...state.reservationDays };
    delete reservationDays[action.payload];

    return {
      ...state,
      reservations: state.reservations.filter(
        (_, index) => index !== action.payload
      ),
      reservationHours,
      reservationDays,
    };
  },
  [updateServiceInReservation.type]: (state, action) => ({
    ...state,
    changed: !action.payload.toggle,
    reservations: state.reservations.map(
      (reservation: Reservation, index: number) => {
        if (index === action.payload.index) return action.payload.reservation;
        return reservation;
      }
    ),
    error: action.payload.index === state.error?.index ? null : state.error,
  }),
  [getServiceProposedHours.pending.toString()]: (state) => ({
    ...state,
    loading: true,
  }),
  [getServiceProposedHours.fulfilled.toString()]: (state, action) => ({
    ...state,
    loading: false,
    changed: false,
    reservationHours: {
      ...state.reservationHours,
      [action.payload.index]: action.payload.data,
    },
  }),
  [getServiceProposedHours.rejected.toString()]: (state, action) => ({
    ...state,
    loading: false,
    changed: false,
    reservationHours: {
      ...state.reservationHours,
      [String(action.meta.arg.index)]: undefined,
    },
  }),
  [getAvailableDays.pending.toString()]: (state) => ({
    ...state,
    loading: true,
  }),
  [getAvailableDays.fulfilled.toString()]: (state, action) => ({
    ...state,
    loading: false,
    changed: false,
    reservationDays: {
      ...state.reservationDays,
      [action.payload.index]: action.payload.data.map((o: number) => o * 1000),
    },
  }),
  [getAvailableDays.rejected.toString()]: (state, action) => ({
    ...state,
    loading: false,
    changed: false,
    reservationDays: {
      ...state.reservationDays,
      [String(action.meta.arg.index)]: undefined,
    },
  }),
  [submitReservation.pending.toString()]: (state) => ({
    ...state,
    loading: true,
  }),
  [submitReservation.fulfilled.toString()]: (state, action) => ({
    ...state,
    loading: false,
    status: ReservationModalStatus.SUCCESS,

    summary: action.payload,

    reservations: [],
    reservationDays: null,
    reservationHours: null,
  }),
  [submitReservation.rejected.toString()]: (state, action) => ({
    ...state,
    loading: false,
    status: ReservationModalStatus.ERROR,
    error: action.payload.json,
    reservations: state.reservations.map((o: Reservation, index) => {
      if (index === action.payload.json?.index) return o;
      return { ...o, expanded: false };
    }),
  }),
  [submitReservationWithoutLoggin.pending.toString()]: (state) => ({
    ...state,
    loading: true,
  }),
  [submitReservationWithoutLoggin.fulfilled.toString()]: (state, action) => ({
    ...state,
    loading: false,
    status: ReservationModalStatus.SUCCESS,

    summary: action.payload,

    reservations: [],
    reservationDays: null,
    reservationHours: null,
  }),
  [submitReservationWithoutLoggin.rejected.toString()]: (state, action) => ({
    ...state,
    loading: false,
    status: ReservationModalStatus.ERROR,
    error: action.payload.json,
    reservations: state.reservations.map((o: Reservation, index) => {
      if (index === action.payload.json?.index) return o;
      return { ...o, expanded: false };
    }),
  }),
  [clearReservation.type]: () => ({
    ...initialState,
  }),
});
