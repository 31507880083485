import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SHero = styled.section`
  ${({ theme }) => css`
    min-height: 494px;

    display: flex;
    height: 680px;
    flex-direction: column;
    position: relative;

    ${media.md`
        height: 90vh;
    `}

    ${media.lg`
        height: 90vh;
    `}

    .hero-container {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      height: 100%;
      position: relative;
      overflow: hidden;
      width: 100%;
    }

    .hero-content {
      align-self: center;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 80px 30px 0;
      margin: 30px auto;
      position: absolute;
      max-width: 1200px;
      width: 100%;
      z-index: 1;

      ${media.lg`
        padding: 120px 30px 0;
      `}
      ${media.xl`
        padding: 120px 0 0;
      `}
    }

    .hero-image {
      justify-self: flex-start;
      display: flex;
      height: 90%;
      object-fit: cover;
      width: 100%;
    }

    .hero-image-overlay {
      background-color: rgba(0, 0, 0, 0.3);
      height: 90%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .hero-heading {
      font-weight: ${theme.weight.bold};
      font-size: 32px;
      line-height: 40px;

      margin-bottom: ${theme.space.xl}px;
      width: 100%;

      ${media.md`
        line-height: 67px;
        font-size: 60px;
        max-width: 572px;
        width: 100%;
      `}
    }

    .hero-description {
      font-size: 16px;
      line-height: 24px;
      max-width: 344px;

      ${media.md`
        max-width: 492px;
        width: 50%;
      `}
    }

    .see-video {
      text-decoration: none;
      color: #3a3335;

      display: flex;
      align-self: flex-start;
      align-items: center;

      .icon {
        width: 42px;
        height: 42px;

        display: flex;
        align-items: center;
        justify-content: center;

        background: ${theme.colors.primary};
        border-radius: 10px;

        margin-right: ${theme.space.md}px;

        & > span {
          font-size: 1.3rem;
          color: white;
        }
      }

      p {
        font-size: 0.875rem;
        line-height: 1.2rem;
        color: #908e8e;
      }

      strong {
        font-size: 1rem;
        font-weight: ${theme.weight.bold};
        color: #3a3335;
      }
    }

    ${media.lg`
      min-height: 694px;
    `}

    ${media.xl`
      .heading {
        font-size: 3.125rem;
        line-height: 3.875rem;
      }
    `}
  `};
`;
