import { FC } from 'react'

const CalendarIcon: FC = (props) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.334 1.5v.834H5.667V1.5A.833.833 0 104 1.5v.834A3.333 3.333 0 00.667 5.667V14A3.333 3.333 0 004 17.334h10A3.333 3.333 0 0017.334 14V5.667A3.333 3.333 0 0014 2.334V1.5a.833.833 0 10-1.666 0zm3.333 4.167c0-.92-.746-1.667-1.666-1.667H4c-.921 0-1.667.746-1.667 1.667h13.333zm0 1.667H2.334V14c0 .92.746 1.667 1.666 1.667h10c.921 0 1.667-.746 1.667-1.667V7.333zm-10 3.333a.833.833 0 110-1.667.833.833 0 010 1.667zm2.5-.833a.833.833 0 101.667 0 .833.833 0 00-1.667 0zm4.167.833a.833.833 0 110-1.667.833.833 0 010 1.667zm-.833 2.5a.833.833 0 101.666 0 .833.833 0 00-1.666 0zM9 14a.833.833 0 110-1.667A.833.833 0 019 14zm-4.167-.833a.833.833 0 101.667 0 .833.833 0 00-1.667 0z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CalendarIcon
