import { FC } from 'react';
import { useIntl } from 'react-intl';

import { useBreakpoint } from 'modules/breakpoint';

import { SAppAuthLayout } from './AppAuthLayout.styled';
import AppAuthPageIcon from 'components/AppAuthPageIcon/AppAuthPageIcon';
import { AppRoute } from 'const';
import { useHistory } from 'react-router-dom';
import useLocalizeRoute from 'utils/useLocalizeRoute';

interface IAppAuthLayout {
  back?: AppRoute;
  background: string;
  icon?: string;
  backgroundHeading?: string;
  backgroundDescription?: string;
}

const AppAuthLayout: FC<IAppAuthLayout> = ({
  back,
  background,
  icon,
  children,
  backgroundDescription,
  backgroundHeading,
}) => {
  const history = useHistory();

  const breakpoints = useBreakpoint();
  const { formatMessage } = useIntl();
  const { localizeRouteKey } = useLocalizeRoute();

  const handleBack = () => {
    history.push(localizeRouteKey(back));
  };

  return (
    <SAppAuthLayout>
      {back ? (
        <button type="button" onClick={handleBack} className="go-back">
          <span className="go-back-icon material-icons">
            arrow_back_ios_new
          </span>
          <span className="go-back-text">Powrót</span>
        </button>
      ) : null}
      <div className="container">
        {icon ? (
          <AppAuthPageIcon>
            <span className="material-icons">{icon}</span>
          </AppAuthPageIcon>
        ) : null}
        <div className="wrapper">{children}</div>
      </div>
      {!breakpoints.lg ? (
        <div
          className="background"
          style={{ backgroundImage: `url(${background})` }}
        >
          <div className="content">
            <p className="heading">
              {formatMessage({
                id:
                  backgroundHeading ||
                  'app-auth.registration-background-heading',
              })}
            </p>
            <p className="description">
              {formatMessage({
                id:
                  backgroundDescription ||
                  'app-auth.registration-background-description',
              })}
            </p>
          </div>
        </div>
      ) : null}
    </SAppAuthLayout>
  );
};

export default AppAuthLayout;
