import { FC, ReactNode } from 'react';

import { Button } from 'components';
import Modal, { SContent } from './ConfirmationModal.styled';

interface IConfirmationModal {
  disabled?: boolean;
  text: ReactNode | string;
  confirm: () => void;
  cancel: () => void;
}
const ConfirmationModal: FC<IConfirmationModal> = ({
  disabled,
  text,
  confirm,
  cancel,
}) => {
  return (
    <Modal isOpen onBackgroundClick={cancel} onEscapeKeydown={cancel}>
      <SContent>
        <p>{text}</p>
        <div className="actions">
          <Button bordered onClick={cancel} disabled={disabled}>
            Anuluj
          </Button>
          <Button onClick={confirm} disabled={disabled}>
            Potwierdź
          </Button>
        </div>
      </SContent>
    </Modal>
  );
};

export default ConfirmationModal;
