import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const SSalonTile = styled(Link)`
  ${({ theme }) => css`
    width: 100%;
    height: 380px;

    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.01);

    text-decoration: none;

    display: flex;
    flex-direction: column;

    background: white;
    border-radius: 16px;
    position: relative;

    -webkit-backface-visibility: hidden;

    &:hover {
      .thumbnail {
        img {
          transform: scale(1.06);
          transition: all 200ms ease-in;
        }
      }
    }

    .thumbnail {
      width: 100%;
      height: calc(100% - 127px);
      border-radius: 16px 16px 0 0;

      position: relative;

      overflow: hidden;
      will-change: transform;
      z-index: 0;

      img {
        object-fit: cover;
        width: 100%;
        min-height: 100%;

        transition: all 200ms ease-in;
      }
    }

    .salon-review {
      background-color: ${theme.colors.white};
      border-radius: 8px;
      align-items: center;
      display: flex;
      column-gap: 8px;
      font-size: 14px;
      line-height: 40px;
      position: absolute;
      right: 12px;
      top: 12px;
      color: ${theme.colors.black};
      font-weight: ${theme.weight.semibold};
      padding: 0px 10px;
      z-index: 2;
    }

    .tile-content {
      background-color: ${theme.colors.white};
      bottom: 0;

      border-radius: 0 0 16px 16px;
      min-height: 127px;
      padding: 21px 24px;
      position: absolute;
      width: 100%;
      z-index: 1;

      .salon-name {
        font-size: 18px;
        line-height: 27px;
        font-weight: ${theme.weight.bold};
        color: ${theme.colors.black};
      }
      .salon-address {
        font-size: 0.875rem;
        line-height: 21px;
        color: #696f8c;

        margin-top: ${theme.space.sm}px;
      }
    }
  `}
`;
