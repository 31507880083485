import { createContext } from 'react'
import { Breakpoints } from '..'

export const defaultValue = {
  sm: false,
  md: false,
  lg: false,
  xl: false,
  xxl: false,
}

const BreakpointContext = createContext<Breakpoints<boolean>>(defaultValue)

export { BreakpointContext }
