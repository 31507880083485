import { useParams } from 'react-router-dom';
import { FC, useRef, useEffect, useState } from 'react';
import { AppRoute } from 'const';
import { Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import { Listing } from 'components';
import { useAppDispatch, useAppSelector } from 'Root';
import {
  getSalons,
  setSalonsSearch,
} from 'state/reducers/salons/salonsActions';
import useCalculateSectionHeight from 'utils/useCalculateSectionHeight';
import Layout from '../components/Shared/Layout';
import { OptionTypeBase } from 'react-select';
import loadCategory from 'api/loadCategory';

type queryParams = {
  category: string;
  localization: string;
};

const Search: FC = () => {
  let cityName = '';

  const [categoryState, setCategoryState] = useState(0);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { localizeRouteKey } = useLocalizeRoute();
  const match = useRouteMatch<{ page: string }>();
  const { params } = match;

  const sectionRef = useRef<HTMLDivElement>(null);
  useCalculateSectionHeight(sectionRef);

  const search = useAppSelector(({ salons }) => salons.search);
  console.log('search: ', search);
  const allSalons = useAppSelector(({ salons }) => salons);
  const reservations = useAppSelector(
    ({ reservation }) => reservation.reservations
  );

  const { localization, category } = useParams<queryParams>();

  const prevPage = () => {
    const currentPage = allSalons.page;
    const newPage = currentPage > 1 ? currentPage - 1 : 1;
    history.push(
      localizeRouteKey(AppRoute.Search)
        .replace(':localization', String(localization))
        .replace(':category', String(category))
        .replace(':page?', String(newPage))
    );
  };

  const setPage = (page: number) => {
    const newPage = page > 0 && page <= allSalons.lastPage ? page : 1;
    history.push(
      localizeRouteKey(AppRoute.Search)
        .replace(':localization', String(localization))
        .replace(':category', String(category))
        .replace(':page?', String(newPage))
    );
  };

  const nextPage = () => {
    const currentPage = allSalons.page;
    const newPage = currentPage < allSalons.lastPage ? currentPage + 1 : 1;
    history.push(
      localizeRouteKey(AppRoute.Search)
        .replace(':localization', String(localization))
        .replace(':category', String(category))
        .replace(':page?', String(newPage))
    );
  };

  useEffect(() => {
    if (!params.page) {
      setPage(1);
    }
    if (category) {
      loadCategory(parseInt(category, 10), setCategoryState);
    }
    dispatch(
      setSalonsSearch({
        ...search,
        categories: [{ value: parseInt(category, 10) }],
        location: { value: localization },
      })
    );
    dispatch(getSalons({ page: params.page, pagination: true }));
  }, [params.page]);

  if (allSalons.data && allSalons.data[0]) {
    if (allSalons.data[0].city && allSalons.data[0].city.name) {
      if (!cityName) {
        cityName = allSalons.data[0].city.name;
      }
    }
  }

  return (
    <Layout hasHeader sticky={{ zIndex: 3 }} skipLoadSalons={true}>
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        ref={sectionRef}
      >
        <div
          style={{
            width: '100%',
            maxWidth: '1190px',
            margin: '0 auto',
            padding: '0 25px',
            marginTop: '50px',
            marginBottom: '25px',
          }}
        >
          <h1 style={{ fontWeight: '700', fontSize: '32px', color: '#304659' }}>
            {categoryState[0] && categoryState[0].name}
            {cityName && ' - ' + cityName}
          </h1>
        </div>

        <Listing
          {...allSalons}
          prevPage={prevPage}
          setPage={setPage}
          nextPage={nextPage}
          reservations={reservations}
        />
      </div>
    </Layout>
  );
};

export { Search };
