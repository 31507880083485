import { FC } from 'react';

import { UserModal } from 'components';
import { SAuthProcess } from './AuthProcess.styled';
import { useAppDispatch, useAppSelector } from 'Root';
import AuthSignIn from './AuthSignIn';
import AuthSignUp from './AuthSignUp';
import {
  hideAuthModal,
  showAuthModal,
} from 'state/reducers/client/clientActions';
import { AuthModalType } from 'components/Header/Header';
import AuthSignUpSuccess from './AuthSignUpSuccess';
import AuthForgot from './AuthForgot';
import AuthReset from './AuthReset';
import { SUserModalOverlay } from 'components/UserModal/UserModal.styled';
import AuthPhoneReservation from './AuthPhoneReservation';

const AuthProcess: FC = () => {
  const dispatch = useAppDispatch();
  const { modalType, modalVisible } = useAppSelector(
    ({ client }) => client.authProcess
  );
  const { appLinks } = useAppSelector(({ app }) => app.homepage);

  const close = () => {
    dispatch(hideAuthModal());
  };

  const showSignIn = () => {
    dispatch(showAuthModal(AuthModalType.SIGN_IN));
  };

  const showSignUp = () => {
    dispatch(showAuthModal(AuthModalType.SIGN_UP));
  };

  const renderSignUp = () => (
    <>
      <div className="auth-process-header">
        <button className="auth-process-back" onClick={showSignIn}>
          <span className="material-icons">arrow_back</span>
        </button>
        <h2 className="auth-process-heading">Zapisz się</h2>
        <button className="auth-process-close" onClick={close}>
          <span className="material-icons">close</span>
        </button>
      </div>
      <div className="auth-process-content">
        <AuthSignUp {...{ appLinks }} />
      </div>
    </>
  );

  const renderSignUpSuccess = () => (
    <>
      <div className="auth-process-header">
        <h2 className="auth-process-heading">Zapisz się</h2>
        <button className="auth-process-close" onClick={close}>
          <span className="material-icons">close</span>
        </button>
      </div>
      <div className="auth-process-content">
        <AuthSignUpSuccess
          {...{ close }}
          text="Wysłaliśmy wiadomość e-mail podany podczas rejestracji. Kliknij link
        potwierdzający w wiadomości e-mail, aby zweryfikować swoje konto"
          buttonText="Otwórz aplikację e-mail i potwierdź"
        />
      </div>
    </>
  );

  const renderPhoneNumberLeadSuccess = () => (
    <>
      <div className="auth-process-header">
        <h2 className="auth-process-heading">Termin zarezerwowany</h2>
        <button className="auth-process-close" onClick={close}>
          <span className="material-icons">close</span>
        </button>
      </div>
      <div className="auth-process-content">
        <AuthSignUpSuccess
          {...{ close }}
          text="Dziękujemy, wkrótce otrzymasz SMS z linkiem potwierdzającym wizyte. Kliknij w link, aby potwierdzić wizytę."
          buttonText="Zamknij"
        />
      </div>
    </>
  );

  const renderSignIn = () => (
    <>
      <div className="auth-process-header">
        <h2 className="auth-process-heading">Zaloguj się</h2>
        <button className="auth-process-close" onClick={close}>
          <span className="material-icons">close</span>
        </button>
      </div>
      <div className="auth-process-content">
        <AuthSignIn />
      </div>
      <div className="auth-process-footer">
        <p>
          Nie masz jeszcze konta?{' '}
          <button type="button" onClick={showSignUp}>
            Zarejestruj się
          </button>
        </p>
      </div>
    </>
  );

  const renderForgot = () => (
    <>
      <div className="auth-process-header">
        <h2 className="auth-process-heading">Odzyskaj hasło</h2>
        <button className="auth-process-close" onClick={close}>
          <span className="material-icons">close</span>
        </button>
      </div>
      <div className="auth-process-content">
        <AuthForgot />
      </div>
    </>
  );

  const renderReset = () => (
    <>
      <div className="auth-process-header">
        <h2 className="auth-process-heading">Ustal nowe hasło</h2>
        <button className="auth-process-close" onClick={close}>
          <span className="material-icons">close</span>
        </button>
      </div>
      <div className="auth-process-content">
        <AuthReset />
      </div>
    </>
  );

  const renderPhoneNumberLead = () => (
    <>
      <div className="auth-process-header">
        <h2 className="auth-process-heading">Zostaw do siebie kontakt</h2>
        <button className="auth-process-close" onClick={close}>
          <span className="material-icons">close</span>
        </button>
      </div>
      <div className="auth-process-content">
        <AuthPhoneReservation />
      </div>
    </>
  );

  const renderContent = () => {
    switch (modalType) {
      case AuthModalType.SIGN_UP:
        return renderSignUp();
      case AuthModalType.SIGN_UP_SUCCESS:
        return renderSignUpSuccess();
      case AuthModalType.FORGOT_PASSWORD:
        return renderForgot();
      case AuthModalType.RESET_PASSWORD:
        return renderReset();
      case AuthModalType.PHONE_NUMBER_RESERVATION:
        return renderPhoneNumberLead();
      case AuthModalType.PHONE_NUMBER_RESERVATION_SUCCESS:
        return renderPhoneNumberLeadSuccess();
      case AuthModalType.SIGN_IN:
      default:
        return renderSignIn();
    }
  };

  if (!modalVisible) return null;
  return (
    <UserModal>
      <SUserModalOverlay onClick={close} />
      <SAuthProcess>{renderContent()}</SAuthProcess>
    </UserModal>
  );
};

export default AuthProcess;
