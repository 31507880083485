import React from 'react';
import { SBenefit } from './Benefit.styled';
import BenefitIcon from '../icons/BenefitIcon';

interface BenefitProps {
  icon: string;
  name: string;
  description: string;
}

const Benefit = ({ icon, name, description }: BenefitProps) => (
  <SBenefit>
    <div className="icon-wrapper">
      <BenefitIcon />
    </div>
    <h3 className="title" dangerouslySetInnerHTML={{ __html: name }} />
    <p className="description">{description}</p>
  </SBenefit>
);

export default Benefit;
