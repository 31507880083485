import { FC, useState, useEffect } from 'react';

import {
  AppWorkplaceEdit,
  AppProfileHeading,
  Wrapper,
  Button,
  SectionLoader,
  ConfirmationModal,
  AppWorkplaceCategory,
} from 'components';
import { useAppDispatch, useAppSelector } from 'Root';
import {
  addSalonSlot,
  getSalonWorkplace,
  removeSalonSlot,
} from 'state/reducers/user/userActions';
import { Slot, WorkplaceCategory } from 'const';
import AddSlotForm, {
  EditData,
} from '../components/AppWorkplaceSlotManagement/AddSlotForm';
import WorkplaceSlot from '../components/AppWorkplaceSlotManagement/WorkplaceSlot';

const AppWorkplace: FC = () => {
  const [slotRemoveConfirmation, setSlotRemoveConfirmation] =
    useState<Slot | null>(null);
  const [slotRemoveLoading, setSlotRemoveLoading] = useState<boolean>(false);
  const [newSlotForm, setNewSlotForm] = useState(false);
  const [editSlot, setEditSlot] = useState<EditData | null>(null);

  const dispatch = useAppDispatch();
  const { slots, categories, loading } = useAppSelector(
    ({ user }) => user.salon.workplace
  );

  const openNewSlotForm = () => {
    setNewSlotForm(true);
  };

  const createSlot = (name: string, avatar: File | null) => {
    if (editSlot) {
      const payload = avatar
        ? { id: editSlot.id, name, avatar }
        : { id: editSlot.id, name };

      dispatch(addSalonSlot(payload));
      setNewSlotForm(false);
      setEditSlot(null);
      return;
    }

    dispatch(addSalonSlot({ name, avatar }));
    setNewSlotForm(false);
  };

  const activeSlotRemoveConfirmation = (slot: Slot) => () => {
    setSlotRemoveConfirmation(slot);
  };

  const clearSlotRemove = () => {
    setSlotRemoveConfirmation(null);
  };

  const handleRemoveSlot = async () => {
    if (!slotRemoveConfirmation?.id) return;
    setSlotRemoveLoading(true);
    await dispatch(removeSalonSlot(slotRemoveConfirmation?.id));
    setSlotRemoveConfirmation(null);
    setSlotRemoveLoading(false);
  };

  useEffect(() => {
    dispatch(getSalonWorkplace());
  }, []);

  if (loading) {
    return <SectionLoader />;
  }

  return (
    <Wrapper>
      <AppWorkplaceEdit>
        <AppProfileHeading>Dodaj oferte swojej firmy</AppProfileHeading>
        {(newSlotForm || editSlot) && (
          <AddSlotForm
            editData={editSlot}
            onClose={() => {
              setNewSlotForm(false);
              setEditSlot(null);
            }}
            onSubmit={createSlot}
          />
        )}
        <div className="slots">
          {slots.map((slot) => (
            <WorkplaceSlot
              key={`slot-${slot.id}`}
              onEdit={(id, name, avatar) => setEditSlot({ id, name, avatar })}
              onClick={activeSlotRemoveConfirmation(slot)}
              {...slot}
            />
          ))}
          <Button bordered onClick={openNewSlotForm}>
            Dodaj nowe stanowisko
          </Button>
        </div>

        <div className="categories">
          {categories.map((category: WorkplaceCategory) => {
            return (
              <AppWorkplaceCategory
                key={`category-${category.id}`}
                category={category}
                slots={slots}
              />
            );
          })}
        </div>
      </AppWorkplaceEdit>
      {slotRemoveConfirmation && (
        <ConfirmationModal
          disabled={slotRemoveLoading}
          text={
            slotRemoveLoading ? (
              <SectionLoader marginless />
            ) : (
              'Czy napewno chcesz usunąć to stanowisko? Utracisz niezapisane zmiany.'
            )
          }
          cancel={clearSlotRemove}
          confirm={handleRemoveSlot}
        />
      )}
    </Wrapper>
  );
};

export { AppWorkplace };
