import { FC, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'Root';

import { SProfileReservationCancelation } from './ProfileReservationCancelation.styled';
import { AppAuthPageIcon, Button, CalendarIcon, UserModal } from 'components';
import {
  cancelReservation,
  getClientReservations,
  hideReservationCancelation,
} from 'state/reducers/client/clientActions';
import { DateTime, Duration } from 'luxon';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import { AppRoute } from 'const';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { SUserModalOverlay } from 'components/UserModal/UserModal.styled';
import client from 'api/client';
import { showAppModal } from '../../state/reducers/app/appActions';
import { AppDialogCloseType } from 'components/UserModal/AppDialogModal';

const ReviewEditor: FC = () => {
  const dispatch = useAppDispatch();
  const {
    cancelation: { data, loading, visible },
  } = useAppSelector(({ client }) => client.reservations);

  const location = useLocation();
  const history = useHistory();
  const { reservationId } = useParams<{ reservationId: string }>();
  const { localizeRouteKey } = useLocalizeRoute();

  const handleCancelWithoutAccount = async () => {
    await client(`cancel/${reservationId}`)
      .errorType('json')
      .post({})
      .res(() => {
        dispatch(
          showAppModal({
            appModalVisible: true,
            appModalType: 'success',
            appModalMessage: 'Rezerwacja została anulowana.',
            appModalButtonText: 'OK',
            appModalHeader: 'Anulowano rezerwację',
            appModalCloseType: AppDialogCloseType.GO_HOME,
          })
        );
      })
      .catch((error) => {
        console.log('[POST] auth reset error', error);
        dispatch(
          showAppModal({
            appModalVisible: true,
            appModalType: 'error',
            appModalMessage:
              'Wystąpił błąd podczas anulowania rezerwacji. Spróbuj ponownie później.',
            appModalButtonText: 'OK',
            appModalHeader: 'Anulowano rezerwację',
            appModalCloseType: AppDialogCloseType.GO_HOME,
          })
        );
      });
    history.push('/');
  };

  const handleStay = () => {
    if (!data?.id) {
      history.push('/');
      return;
    }
    close();
  };

  const submit = () => {
    if (!data?.id) {
      handleCancelWithoutAccount();
      return;
    }
    dispatch(cancelReservation(data.id));
    dispatch(getClientReservations());
    close();
  };
  const close = () => dispatch(hideReservationCancelation());

  useEffect(() => {
    close();
  }, [location]);

  if (!visible) return null;
  const date = data
    ? DateTime.fromMillis(data.timeStart * 1000)
    : DateTime.now();
  const avatar = data?.salon.images?.[0]?.path ?? null;
  return (
    <UserModal wide>
      <SUserModalOverlay onClick={handleStay} />
      <SProfileReservationCancelation>
        <div className="reservation-cancelation-header">
          <h2 className="reservation-cancelation-heading">Odwołanie wizyty</h2>
          <button
            className="reservation-cancelation-close"
            type="button"
            onClick={handleStay}
          >
            <span className="material-icons">close</span>
          </button>
        </div>
        <div className="reservation-cancelation-content">
          <AppAuthPageIcon className="reservation-cancelation-icon">
            <CalendarIcon />
          </AppAuthPageIcon>
          <h3 className="reservation-cancelation-icon-heading">
            Czy napewno odwołać?
          </h3>

          {data && (
            <div className="reservation-cancelation-service-content">
              {avatar ? (
                <Link
                  to={localizeRouteKey(AppRoute.Salon).replace(
                    ':id',
                    String(data.salon.id)
                  )}
                >
                  <div className="reservation-cancelation-service-thumbnail">
                    <img src={avatar} alt="salon avatar" />
                  </div>
                </Link>
              ) : null}
              <div>
                <p className="reservation-cancelation-service-name">
                  {data.service.name}{' '}
                  <span>
                    {Duration.fromObject({
                      minutes: data?.service.duration,
                    }).toFormat("h'h' mm'min'")}
                  </span>
                </p>
                <p className="reservation-cancelation-service-address">
                  {data.salon.city?.name}, {data.salon.street}{' '}
                  {data.salon.buildingNumber}
                </p>
                <p className="reservation-cancelation-service-date">
                  {date.toFormat('LLLL d, kkkk')}
                  <span>Godz: {date.toFormat('hh:mm')}</span>
                </p>
              </div>
            </div>
          )}

          <div className="reservation-cancelation-actions">
            <Button
              disabled={loading}
              loading={loading}
              type="button"
              className="reservation-cancelation-confirm"
              onClick={submit}
            >
              Tak
            </Button>
            <Button
              disabled={loading}
              loading={loading}
              type="button"
              className="reservation-cancelation-cancel"
              onClick={handleStay}
            >
              Jednak nie!
            </Button>
          </div>
        </div>
      </SProfileReservationCancelation>
    </UserModal>
  );
};

export default ReviewEditor;
