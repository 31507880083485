import React from 'react';
import LogoWhite from '../icons/LogoWhite';
import LogoColor from '../icons/LogoColor';

interface LogoProps {
  mode: 'white' | 'color';
  size: number;
}

const Logo = ({ mode, size }: LogoProps) => {
  return mode === 'white' ? (
    <LogoWhite size={size} />
  ) : (
    <LogoColor size={size} />
  );
};

export default Logo;
