import React from 'react';

const LinkedInIcon = () => (
  <svg viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 20.5C0 9.178 9.178 0 20.5 0S41 9.178 41 20.5 31.822 41 20.5 41 0 31.822 0 20.5zm10.506-3.46v13.71h4.357V17.04h-4.357zm-.256-4.356c0 1.41 1.025 2.435 2.434 2.435 1.41 0 2.435-1.025 2.435-2.435 0-1.409-1.025-2.434-2.435-2.434-1.28 0-2.434 1.025-2.434 2.434zM26.394 30.75h4.1v-8.456c0-4.228-2.563-5.638-4.997-5.638-2.178 0-3.716 1.41-4.1 2.306v-1.921h-4.1V30.75h4.356v-7.303c0-1.922 1.281-2.947 2.563-2.947 1.28 0 2.178.64 2.178 2.819v7.431z"
      fill="#696F8C"
    />
  </svg>
);

export default LinkedInIcon;
