import wretch from 'wretch';
import apiUrlMiddleware from 'utils/apiUrlMiddleware';

const client = (endpoint: string, file?: boolean) =>
  wretch(endpoint)
    .headers({
      Accept: 'application/json',
      'Content-Type': file ? 'multipart/form-data' : 'application/json',
    })
    .middlewares([apiUrlMiddleware()]);

export default client;
