import { createGlobalStyle, css } from 'styled-components';

export default createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  html, body, input, button, textarea {
    font-family: inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
  
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  ${({ theme }) =>
    css`
      body {
        &.no-scroll {
          overflow: hidden;
        }
      }

      .sticky-outer-wrapper.active {
        z-index: 10;
      }
    `}
`;
