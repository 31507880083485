import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'Root';
import { confirmReservation } from 'state/reducers/client/clientActions';

export const AppConfirmReservation: React.FC<{}> = () => {
  const dispatch = useAppDispatch();
  const { appointmentId } = useParams<{ appointmentId: string }>();
  const queryParams = new URLSearchParams(window.location.search);
  const token = String(queryParams.get('token'));

  useEffect(() => {
    dispatch(confirmReservation({ appointmentId, token }));
  }, []);

  return <></>;
};
