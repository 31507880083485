import styled, { css } from 'styled-components'
import media from 'theme/respondTo'

export const SFavoriteSalonItem = styled.div`
  ${({ theme }) => css`
    display: flex;

    padding: ${theme.space.xl}px;
    padding-left: 0;
    border-bottom: 1px solid #ede8e9;

    .favorite-salon {
      &-thumbnail {
        display: none;

        width: 146px;
        height: 146px;

        margin-right: ${theme.space.lg}px;

        border-radius: 6px;
        overflow: hidden;

        ${media.sm`
          display: block;
        `}

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
      }

      &-name {
        font-size: 0.875rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.5rem;
        color: #3a3335;
      }

      &-address {
        font-size: 0.875rem;
        line-height: 1.31rem;
        color: #908e8e;

        position: relative;

        padding-bottom: ${theme.space.sm}px;
        margin-top: ${theme.space.xs}px;

        &::before {
          content: '';
          width: 42px;
          height: 1px;

          position: absolute;
          left: 0;
          bottom: 0;

          background-color: ${theme.colors.primary};
        }
      }

      &-favorite {
        min-width: 193px;
        height: 46px;

        color: #908e8e;
        background: linear-gradient(
            0deg,
            rgba(58, 51, 53, 0.04),
            rgba(58, 51, 53, 0.04)
          ),
          #ffffff;
        border-color: transparent;

        margin-top: ${theme.space.md}px;

        span:not(.icon) {
          border-color: ${theme.colors.primary};
        }

        &:hover,
        &.active {
          background: linear-gradient(
              0deg,
              rgba(58, 51, 53, 0.04),
              rgba(58, 51, 53, 0.04)
            ),
            #ffffff;
          .icon {
            color: ${theme.colors.primary};
          }
        }

        .icon {
          font-size: 18px;
          margin-right: ${theme.space.sm}px;
        }
      }
    }
  `}
`
