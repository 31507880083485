import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SListingItem = styled.li<{ showImage: boolean }>`
  ${({ theme, showImage }) => css`
    background-color: ${theme.colors.white};
    border: 1px solid #ede8e9;
    border-radius: 16px;

    flex-flow: column;
    column-gap: 30px;
    position: relative;
    width: 100%;

    ${showImage
      ? css`
          display: flex;
          flex-flow: column;
          ${media.md`
            display: grid;
            grid-template-columns: 247px auto;
            padding: 16px 16px 17px;
             `}
        `
      : css`
          display: flex;
        `}
    ${media.md`
        flex-flow: row;
        padding: 24px 24px 22px;
    `}
    &:hover {
      .listing-item {
        &__thumbnail {
          img {
            transform: scale(1.06);
            transition: all 200ms ease-in;
          }
        }
      }
    }

    a {
      text-decoration: none;
    }

    .partner-program {
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;

      &__tooltip {
        background-color: #fff;
        border: 1px solid #cecece;
        border-radius: 8px;
        position: absolute;
        display: none;
        top: 105%;
        line-height: 20px;
        padding: 4px 8px;
        white-space: nowrap;
        font-size: 12px;
      }

      &:hover {
        .partner-program__tooltip {
          ${media.sm`
         display: flex; 
          `}
        }
      }
    }

    .left-side {
      display: flex;
      row-gap: 16px;
      flex-flow: column;
      width: 100%;

      ${media.md`
        width: 247px;
    `}
    }

    .listing-item {
      position: relative;

      &__thumbnail {
        width: 100%;
        height: 225px;

        position: relative;

        border-radius: 16px 16px 0 0;

        overflow: hidden;
        ${media.sm`
            border-radius: 8px;
            width: 100%;
            height: 196px;
        `}
      }

      &__image {
        transform: scale(1);
        transition: all 200ms ease-in;

        height: 100%;
        width: 100%;
        object-fit: cover;
        ${media.sm`
            width: 100%;
            height: 100%;
          `}
      }

      &__mobile-review {
        align-items: center;
        background-color: #fff;
        border-radius: 8px;
        display: flex;
        column-gap: 8px;
        padding: 10px;
        position: absolute;
        right: 12px;
        top: 12px;
        z-index: 2;

        p {
          color: ${theme.colors.black};
          font-size: 14px;
          font-weight: 600;
        }
      }

      &__heading {
        display: flex;
        flex-flow: column;
      }

      &__description {
        display: none;

        ${media.sm`
            color: ${theme.colors.black};
            display: flex;
            flex-flow: column;
            font-size: 15px;
            line-height: 28px;
            margin-top: 21px;
        `}
      }

      &__toggle-description {
        display: inline;
        font-size: 14px;
        color: ${theme.colors.primary};
        font-weight: ${theme.weight.bold};
        line-height: 27px;
        padding: 0;
        position: relative;
        width: max-content;
        cursor: pointer;

        &:hover {
          color: #cc5400;
        }

        &:after {
          background-color: rgba(255, 107, 0, 0.5);
          content: '';
          display: block;
          height: 1px;
          position: absolute;
          width: 100%;
        }
      }

      &__tags {
        display: none;

        ${media.sm`
            display: flex;
            flex-flow: row-wrap;
            column-gap: 12px;
            row-gap: 11px;
        `}
      }

      &__mobile-tags {
        align-items: center;
        display: flex;
        margin: 8px 0 0;

        ${media.sm`
            display: none;
        `}
      }

      &__mobile-tags-label {
        color: ${theme.colors.black};
        line-height: 40px;
        font-size: 14px;
      }

      &__mobile-tags-list {
        display: flex;
        flex-flow: wrap;
        column-gap: 12px;
        row-gap: 12px;
        margin-left: 12px;
      }

      &__content {
        display: flex;
        flex-flow: column;
        padding: 24px 26px;
        width: 100%;

        ${media.md`
            padding: 0;
        `}
      }

      &__info {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }

      &__services {
        display: none;

        li:last-child {
          border: none;
        }

        > * {
          &:not(:last-child) {
            border-bottom: 1px solid #ddd;
          }
        }

        ${media.sm`
          display: flex;
          flex-flow: column;
          margin-top: 20px;
          row-gap: 6px;  
        `}
      }

      &__services-show-more {
        display: none;

        ${media.sm`
            background-color: rgba(247, 244, 248, 1);
            border: none;   
            border-radius: 8px;
            color: rgba(58, 51, 53, 1);
            display: block;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            padding: 6px 0;
            width: 100%;
            
            &:hover {
               background-color: rgb(231, 228, 232);
            }
        `}
      }

      &__name {
        align-items: center;
        display: flex;
        column-gap: 12px;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        color: ${theme.colors.black};

        ${media.md`
        line-height: 40px;
             margin-top: 0;
             font-size: 20px;
        `}
      }

      &__address {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: #696f8c;

        ${media.md`
            line-height: 24px;
        `}
      }

      &__reviews-summary {
        display: none;

        ${media.sm`
            width: 100%;
        height: 56px;

        display: flex;
        align-items: center;
        column-gap: 12px;

        font-size: 0.75rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.125rem;
        color: #3a3335;

        padding: 0 18px;

        background-color: rgba(89, 40, 122, 0.05);
        border-radius: 8px;

        svg {
          padding-bottom: 2px;
        }            
        `}
      }
    }
  `}
`;
