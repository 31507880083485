const EmployeeAvatarPlaceholder = () => (
  <svg
    width="53"
    height="53"
    viewBox="0 0 53 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="26.5" cy="26.5" r="26.5" fill="#56276A" fillOpacity="0.15" />
    <g clipPath="url(#clip0_2255_1502)">
      <path
        d="M27 49C29.842 48.9984 32.6507 48.3891 35.2378 47.2128C37.8249 46.0365 40.1306 44.3205 42 42.18V39.69C42.0008 38.518 41.6507 37.3726 40.9948 36.4013C40.339 35.43 39.4074 34.6773 38.32 34.24L33.58 32.33C32.7926 32.0147 32.1176 31.471 31.6417 30.7688C31.1659 30.0667 30.9111 29.2382 30.91 28.39C32.78 26.54 33.52 23.33 33.52 20.17C33.52 16.57 31.52 13 27 13C22.48 13 20.48 16.57 20.48 20.17C20.48 23.33 21.22 26.54 23.09 28.39C23.0889 29.2382 22.8341 30.0667 22.3583 30.7688C21.8824 31.471 21.2074 32.0147 20.42 32.33L15.68 34.24C14.5926 34.6773 13.661 35.43 13.0052 36.4013C12.3493 37.3726 11.9992 38.518 12 39.69V42.18C13.8694 44.3205 16.1751 46.0365 18.7622 47.2128C21.3493 48.3891 24.158 48.9984 27 49Z"
        fill="#57276A"
      />
    </g>
    <defs>
      <clipPath id="clip0_2255_1502">
        <rect
          width="30"
          height="36"
          fill="white"
          transform="translate(12 13)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default EmployeeAvatarPlaceholder;
