import styled, { css } from 'styled-components';

export const SCategoryTile = styled.a`
  ${({ theme }) => css`
    width: 100%;

    display: flex;
    flex-direction: column;

    text-decoration: none;

    padding: 0;
    margin: 0;
    border: 0;

    cursor: pointer;

    &:hover {
      img {
        transform: scale(1.06);
      }
    }

    .thumbnail {
      border-radius: 16px;
      width: 100%;
      height: 200px;

      position: relative;
      will-change: transform;

      overflow: hidden;

      img {
        object-fit: cover;
        height: 110%;
        width: 100%;

        transition: all 200ms ease-in;
      }
    }

    .tile-content {
      align-items: center;
      display: flex;
      flex-flow: column;
      justify-content: center;

      .heading {
        color: ${theme.colors.black};
        font-size: 1.125rem;
        font-weight: ${theme.weight.bold};
        line-height: 23px;
        margin-top: 11px;
      }

      .text {
        font-size: 0.875rem;
        text-align: left;
        color: #696f8c;
        line-height: 17px;
        margin-top: 9px;
      }

      .button {
        font-size: 0.75rem;
        min-width: 100px;
        height: 40px;
      }
    }
  `}
`;
