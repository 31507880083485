import { FC } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { Button, ProfileSettingsEditor, TextInput, Wrapper } from 'components';
import { useAppDispatch, useAppSelector } from 'Root';
import { FormProvider, useForm } from 'react-hook-form';
import { updateClientPassword } from 'state/reducers/client/clientActions';
import formErrorsHandler from 'utils/formErrorsHandler';

const ProfilePassword: FC = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ client }) => client);
  const methods = useForm<{
    currentPassword: string;
    password: string;
    passwordConfirmation: string;
  }>({
    defaultValues: {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    },
  });

  const handleBack = () => history.goBack();

  const onSubmit = async (values: {
    currentPassword: string;
    password: string;
    passwordConfirmation: string;
  }) => {
    const response: any = await dispatch(updateClientPassword(values));
    if (response.error) {
      formErrorsHandler<{
        currentPassword: string;
        password: string;
        passwordConfirmation: string;
      }>(
        Object.keys({
          currentPassword: '',
          password: '',
          passwordConfirmation: '',
        }),
        methods.setError
      )(response.payload);
      return;
    }

    handleBack();
  };

  if (!user) return <Redirect to={'/'} />;
  return (
    <Wrapper>
      <ProfileSettingsEditor>
        <div className="settings-header">
          <button className="settings-header-back" onClick={handleBack}>
            <span className="material-icons">arrow_back</span>
          </button>
          <h2 className="settings-heading">Zmiana hasła</h2>
        </div>

        <div className="settings-container">
          <div className="settings-content">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <TextInput
                  type="password"
                  {...{
                    id: 'currentPassword',
                    label: 'Aktualne hasło',
                    icon: 'lock',
                  }}
                  className="settings-field"
                />
                <TextInput
                  type="password"
                  {...{ id: 'password', label: 'Nowe hasło' }}
                  className="settings-field"
                />
                <TextInput
                  type="password"
                  {...{
                    id: 'passwordConfirmation',
                    label: 'Powtórz nowe hasło',
                  }}
                  className="settings-field"
                />

                <Button
                  disabled={
                    !methods.formState.isDirty || methods.formState.isSubmitting
                  }
                  loading={methods.formState.isSubmitting}
                  type="submit"
                  className="settings-submit"
                >
                  Aktualizacja hasła
                </Button>
              </form>
            </FormProvider>
          </div>
          <div className="settings-sidebar">
            <div className="settings-sidebar-content">
              <div className="settings-sidebar-icon">
                <span className="material-icons-outlined">devices</span>
              </div>
              <p className="settings-sidebar-heading">Password and questions</p>
              <p className="settings-sidebar-description">
                From ads that dance or sing to MTV-like commercials, online
                advertisers are now using a new type of technology called media
              </p>
            </div>
          </div>
        </div>
      </ProfileSettingsEditor>
    </Wrapper>
  );
};

export { ProfilePassword };
