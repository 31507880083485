import React, { FC, useMemo, useState } from 'react';

import { Image, Salon } from 'const';
import { getAverageReviewsRate } from 'utils/getAverageReviewsRate';

import { MostPopular, Wrapper } from 'components';
import { SSalonDetails } from './SalonDetails.styled';
import { useBreakpoint } from 'modules/breakpoint';
import About from './About';
import Sidebar from './Sidebar';
import StarIcon from '../icons/StarIcon';
import Reviews from './Reviews';
import ShareIcon from '../icons/ShareIcon';
import Services from './Services';
import Gallery from './Gallery';
import TopSlider from './TopSlider';
import ShareModal from './ShareModal';

interface ISalonDetails {
  data: Salon;
  showGallery: (image: Image) => void;
}

const SalonDetails: FC<ISalonDetails> = ({ data, showGallery }) => {
  const [openShareModal, setShareModal] = useState(false);

  const reviewsAverage = useMemo(() => {
    return getAverageReviewsRate(data.reviews || []);
  }, [data.reviews]);

  const breakpoint = useBreakpoint();

  const address =
    data.city?.name &&
    `${data.city?.name}, ${data.street} ${data.buildingNumber}`;

  return (
    <SSalonDetails servicesEmpty={!data?.categoriesWithServices?.length}>
      {openShareModal && (
        <ShareModal salonId={data.id} onClose={() => setShareModal(false)} />
      )}

      <Wrapper>
        <div className="salon-details-header">
          <div>
            <h2 className="salon-details-heading">{data.name}</h2>
            <div className="salon-details-address">
              {parseInt(reviewsAverage) > 0 && (
                <span className="salon-details-rating">
                  <StarIcon />
                  <p>{reviewsAverage}</p>
                </span>
              )}
              {parseInt(reviewsAverage) > 0 && (
                <span className="salon-details-address-divider" />
              )}
              <p>{address}</p>
            </div>
          </div>
          <button
            onClick={() => setShareModal(true)}
            className="salon-details-share-button"
          >
            <div className="salon-details-share-icon-wrapper">
              <ShareIcon />
            </div>
            <p className="salon-details-share-label">Share</p>
          </button>
        </div>
      </Wrapper>

      <TopSlider onImageClick={showGallery} images={data.images} />

      <Wrapper>
        <div className="salon-details-container">
          <div className="salon-details-content">
            <About description={data.description} employees={data.slots} />

            <Services name={data.name} id={data.id} services={data.services} />

            {data.reviews && data.reviews?.length > 0 && (
              <Reviews items={data.reviews || []} />
            )}

            <div className="salon-details-desktop-gallery">
              <Gallery images={data.images} onImageClick={showGallery} />
            </div>
          </div>

          {/* {!breakpoint.lg && ( */}
          <Sidebar
            categories={data.categories.map(({ name }) => name)}
            openingHours={data.openingHours}
            geolocation={data.geolocation}
            address={address}
            phone={data.phone}
          />
          {/* )} */}
        </div>
      </Wrapper>

      <div className="salon-details-mobile-gallery">
        <div className="section-template__header gallery-padding">
          <div className="section-template__header-left">
            <h2 className="section-template__heading">Galeria</h2>
          </div>
        </div>
        <span className="section-template__divider mobile-divider" />

        <TopSlider onImageClick={showGallery} images={data.images} />
      </div>

      <div className="salon-details-divider" />

      <MostPopular />
    </SSalonDetails>
  );
};

export default SalonDetails;
