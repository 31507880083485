import { useIntl } from 'react-intl'
import { FormProvider, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'

import client from 'api/client'
import { AppAuthLayout, Button, TextInput } from 'components'
import useLocalizeRoute from 'utils/useLocalizeRoute'

import { AppRoute, SalonResetPassword, SalonResetPasswordDTO } from 'const'

import background from 'assets/images/salon-auth-background-1.jpg'
import formErrorsHandler from 'utils/formErrorsHandler'

const defaultFormValues = {
  password: '',
}

function AppAuthResetPassword() {
  const history = useHistory()

  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const email = search.get('email')
  const token = search.get('token')

  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  const methods = useForm<SalonResetPassword>({
    defaultValues: defaultFormValues,
  })

  const handleSubmit = async (values: SalonResetPassword) => {
    if (!email || !token) return

    const body: SalonResetPasswordDTO = {
      email,
      token,
      ...values,
      passwordConfirmation: values.password,
    }

    await client('provider/auth/set-password')
      .errorType('json')
      .catcher(
        422,
        formErrorsHandler<SalonResetPassword>(
          Object.keys(defaultFormValues),
          methods.setError
        )
      )
      .patch(body)
      .res(() => {
        methods.reset(defaultFormValues)
        history.push(localizeRouteKey(AppRoute.App))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <AppAuthLayout {...{ background, back: AppRoute.AppAuthLogin }}>
      <p className="heading">
        {formatMessage({ id: 'app-auth.reset-password-heading' })}
      </p>
      <p className="description">
        {formatMessage({
          id: 'app-auth.reset-password-description',
        })}
      </p>

      <div className="content">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <TextInput
              {...{
                id: 'password',
                type: 'password',
                label: formatMessage({ id: 'form.label-password' }),
                icon: 'lock',
              }}
              className="row"
            />
            <Button
              type="submit"
              wide
              disabled={methods.formState.isSubmitting || !email || !token}
              loading={methods.formState.isSubmitting}
              className="row-action"
            >
              {formatMessage({ id: 'general.confirm' })}
            </Button>
          </form>
        </FormProvider>
      </div>
    </AppAuthLayout>
  )
}

export { AppAuthResetPassword }
