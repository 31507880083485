import styled, { css } from 'styled-components';
import media from '../../theme/respondTo';

export const SFilters = styled.section<{ expanded: boolean }>`
  ${({ theme, expanded }) => css`
    display: none;

    ${media.md`
    align-items: flex-end;
    border-top: 1px solid rgba(237, 232, 233, 1);
    display: flex;
    flex-flow: column;
    padding: 20px 0 ${expanded ? '16px' : '36px'};
    position: relative;
    width: 100%;
  `}
    .top {
      display: flex;
      column-gap: 12px;
      justify-content: center;
      margin: 0 auto;
      max-width: 1138px;
      width: 100%;
      position: relative;
    }

    .bottom {
      display: flex;
      justify-content: flex-end;
      margin: 0 auto;
      max-width: 1200px;
      width: 100%;
    }

    .categories {
      display: flex;
      flex-flow: wrap;
      gap: 12px;
      overflow: hidden;
      max-width: 830px;
      width: 100%;
    }

    .swiper-wrapper {
      width: 100%;
    }

    .swiper-slide {
      width: auto;
    }

    .slider-control {
      align-items: center;
      border: 1px solid #eeeeee;
      border-radius: 50%;
      display: flex;
      height: 40px;
      justify-content: center;
      width: 40px;

      &:hover {
        background-color: #fff2ed;
      }
    }

    .right-side-wrapper {
      display: flex;
      justify-content: space-between;
      max-width: 930px;
      width: 100%;
    }

    .control-wrapper {
      justify-self: flex-end;
      display: flex;
      column-gap: 10px;
    }

    .all-filters-button {
      color: ${theme.colors.primary};
      font-size: 12px;
      font-weight: 500;
      position: absolute;
      right: 0;
      bottom: ${expanded ? 0 : '-100%'};
      line-height: 40px;
    }
  `}
`;
