import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SReservationHotelEditor = styled.div`
  ${({ theme }) => css`
    max-width: 630px;

    margin: 0 auto;
    padding: ${theme.space.lg}px 0;
    border-bottom: 1px solid #f6f3f4;

    &:last-of-type {
      border-bottom: 0;
    }

    .reservation-editor {
      &-header {
        display: flex;
      }

      &-remove {
        flex-shrink: 0;

        width: 64px;
        height: 32px;

        margin-left: ${theme.space.md}px;

        ${media.md`
          width: 110px;
        `}
      }

      &-expand {
        width: 32px;
        height: 32px;

        display: flex;
        align-items: center;
        justify-content: center;

        background: none;
        box-shadow: none;
        border: 0;

        margin-left: ${theme.space.md}px;

        cursor: pointer;

        span {
          font-size: 1.25rem;
          color: #3a3335;
        }
      }

      &-content {
        padding-top: ${theme.space.xxl}px;
        padding-bottom: ${theme.space.xxxl}px;
      }

      &-hours {
        margin-top: ${theme.space.xl}px;
        padding-top: ${theme.space.xl}px;
        border-top: 1px solid #f6f3f4;

        &-list {
          display: flex;
          flex-flow: wrap;
          grid-gap: ${theme.space.sm}px;
          margin-top: ${theme.space.sm}px;
          width: 100%;
        }

        &-header {
          width: 100%;
          min-height: 54px;

          display: flex;
          align-items: center;
          justify-content: center;

          padding: 0 ${theme.space.sm}px;

          background: rgba(237, 232, 233, 0.5);
          border-radius: 10px;

          font-size: 0.875rem;
          font-weight: ${theme.weight.semibold};
          line-height: 1.31rem;
          text-transform: capitalize;
          color: #000000;
        }
      }

      &-error {
        display: flex;
        align-items: center;

        font-size: 0.875rem;
        font-weight: ${theme.weight.bold};
        color: #eb5e55;

        margin-top: ${theme.space.sm}px;

        .icon {
          margin-top: ${theme.space.xs}px;
          margin-right: ${theme.space.xs}px;
        }
      }
    }
  `}
`;
