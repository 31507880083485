import React from 'react';
import { SMarkerDetails } from './MarkerDetails.styled';
import useLocalizeRoute from '../../../utils/useLocalizeRoute';
import { AppRoute } from '../../../const';
import { Link } from 'react-router-dom';
import CloseIcon from '../../icons/CloseIcon';

interface MarkerDetailsProps {
  id: number;
  name: string;
  address: string;
  onCloseClick: () => void;
  onLinkClick: () => void;
}

const MarkerDetails = ({
  name,
  address,
  id,
  onCloseClick,
  onLinkClick,
}: Partial<MarkerDetailsProps>) => {
  const { localizeRouteKey } = useLocalizeRoute();

  return (
    <SMarkerDetails>
      <div className="wrapper">
        <button onClick={onCloseClick} className="close-button">
          <CloseIcon color="#FF6B00" size={17} />
        </button>
        <Link
          to={localizeRouteKey(AppRoute.Salon).replace(':id', String(id))}
          onClick={onLinkClick}
        >
          <p className="name">{name}</p>
        </Link>
        <p className="address">{address}</p>
      </div>
    </SMarkerDetails>
  );
};

export default MarkerDetails;
