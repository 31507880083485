import { FC } from 'react';
import { SalonService } from 'const';
import { SSalonServiceItem } from './SalonServiceItem.styled';
import { useIntl } from 'react-intl';

const SalonServiceItem: FC<SalonService> = ({
  name,
  appointmentType,
  price,
  time,
}) => {
  const { formatNumber } = useIntl();

  return (
    <SSalonServiceItem>
      <p className="salon-service-name">
        {name}
        {time && appointmentType !== 'range' ? <span> {time}</span> : null}
      </p>
      <p className="salon-service-price">
        +
        {formatNumber(price, {
          style: 'currency',
          currency: 'PLN',
          currencyDisplay: 'narrowSymbol',
        })}
      </p>
    </SSalonServiceItem>
  );
};

export default SalonServiceItem;
