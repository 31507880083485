import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, TextInput } from 'components'
import { useAppDispatch } from 'Root'
import formErrorsHandler from 'utils/formErrorsHandler'
import client from 'api/client'
import AppAuthPageIcon from 'components/AppAuthPageIcon/AppAuthPageIcon'
import {
  hideAuthModal,
  showAuthModal,
} from 'state/reducers/client/clientActions'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthModalType } from 'components/Header/Header'

const defaultFormValues = {
  password: '',
  passwordConfirmation: '',
}

const AuthReset: FC = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const history = useHistory()

  const methods = useForm<{ password: string; passwordConfirmation: string }>({
    defaultValues: defaultFormValues,
  })

  const reset = async (values: any) => {
    const search = new URLSearchParams(location.search)
    const email = search.get('email')
    const token = search.get('reset-password-token')

    const payload = {
      ...values,
      email,
      token,
    }

    await client('client/auth/set-password')
      .errorType('json')
      .catcher(
        422,
        formErrorsHandler<{ password: string; passwordConfirmation: string }>(
          Object.keys(defaultFormValues),
          methods.setError
        )
      )
      .patch(payload)
      .res(() => {
        methods.reset(defaultFormValues)
        history.replace('/')
        dispatch(showAuthModal(AuthModalType.SIGN_IN))
      })
      .catch((error) => {
        console.log('[POST] auth reset error', error)
      })
  }

  return (
    <FormProvider {...methods}>
      <div className="forgot">
        <AppAuthPageIcon>
          <span className="material-icons">lock</span>
        </AppAuthPageIcon>
        <h3 className="forgot-heading">Ustal nowe hasło</h3>
      </div>
      <form onSubmit={methods.handleSubmit(reset)}>
        <TextInput
          {...{
            id: 'password',
            name: 'password',
            label: 'Hasło',
            type: 'password',
            className: 'field',
          }}
        />
        <TextInput
          {...{
            id: 'passwordConfirmation',
            name: 'passwordConfirmation',
            label: 'Powtórz Hasło',
            type: 'password',
            className: 'field',
          }}
        />
        <Button
          primary
          className="button"
          loading={methods.formState.isSubmitting}
          disabled={methods.formState.isSubmitting}
        >
          Potwierdź
        </Button>
      </form>
    </FormProvider>
  )
}

export default AuthReset
