import { FC } from 'react';

import { Reservation, ReservationModalStatus } from 'const';
import { useAppDispatch, useAppSelector } from 'Root';
import {
  hideAuthModal,
  showAuthModal,
} from '../../state/reducers/client/clientActions';

import { SReservation } from './ReservationModal.styled';
import {
  Button,
  ReservationEditor,
  ReservationHotelEditor,
  ReservationSuccess,
  UserModal,
} from 'components';
import {
  clearReservation,
  closeReservationModal,
  getAvailableDays,
  getServiceProposedHours,
  removeServiceFromReservation,
  submitReservation,
  submitReservationWithoutLoggin,
  updateServiceInReservation,
} from 'state/reducers/reservation/reservationActions';
import { SUserModalOverlay } from 'components/UserModal/UserModal.styled';
import { AuthModalType } from 'components/Header/Header';

const ReservationModal: FC = () => {
  const dispatch = useAppDispatch();
  const {
    visible,
    loading,
    status,
    summary,
    error,
    reservations,
    reservationHours,
    reservationDays,
  } = useAppSelector(({ reservation }) => reservation);
  const { phoneNumber, user } = useAppSelector(({ client }) => client);

  const close = () => dispatch(closeReservationModal());
  const removeService = (serviceIndex: number) => () => {
    dispatch(removeServiceFromReservation(serviceIndex));
  };

  const updateNormalService = (payload: {
    index: number;
    reservation: Reservation;
  }) => {
    dispatch(updateServiceInReservation(payload));
    dispatch(getServiceProposedHours(payload));
  };

  const onChangeMonth = (payload: {
    month: number;
    index: number;
    reservation: Reservation;
  }) => {
    dispatch(getAvailableDays({ ...payload }));
  };

  // @ts-ignore
  const submit = async () => {
    close();
    if (!phoneNumber && !user) {
      dispatch(showAuthModal(5));
      return;
    }
    try {
      const data = user
        ? await dispatch(submitReservation())
        : await dispatch(submitReservationWithoutLoggin());
      if (Object.keys(data).some((value) => value === 'error')) {
        throw new Error('Error while fetching data');
      }
      dispatch(showAuthModal(AuthModalType.PHONE_NUMBER_RESERVATION));
    } catch (error) {
      dispatch(hideAuthModal());
      alert('Wystąpił błąd podczas rezerwacji. Spróbuj ponownie później.');
      console.error(error);
    }
  };

  const closeSuccess = () => {
    dispatch(clearReservation());
  };

  const invalid = reservations.some(({ service, hour, time }: Reservation) => {
    if (service.appointmentType === 'normal') return !hour;
    return time?.length !== 2;
  });

  if (!visible) return null;
  if (status === ReservationModalStatus.SUCCESS)
    return <ReservationSuccess {...{ summary, close: closeSuccess }} />;

  return (
    <UserModal wide>
      <SUserModalOverlay
        onClick={() => {
          dispatch(clearReservation());
          close();
        }}
      />
      <SReservation>
        <div className="reservation-header">
          <h2 className="reservation-heading">Wybierz datę i godzinę</h2>
          <button
            className="reservation-close"
            type="button"
            onClick={() => {
              dispatch(clearReservation());
              close();
            }}
          >
            <span className="material-icons">close</span>
          </button>
        </div>
        <div className="reservation-content">
          {reservations.length ? (
            reservations.map((reservation: Reservation, index: number) =>
              reservation.service.appointmentType === 'normal' ? (
                <ReservationEditor
                  key={`${reservation.service.appointmentType}-${index}`}
                  {...{
                    index,
                    reservation,
                    removeService,
                    updateService: updateNormalService,
                    hours: reservationHours?.[index],
                    error: error?.index === index ? error.message : null,
                  }}
                />
              ) : (
                <ReservationHotelEditor
                  key={`${reservation.service.appointmentType}-${index}`}
                  {...{
                    index,
                    reservation,
                    removeService,
                    updateService: updateNormalService,
                    onChangeMonth,
                    availableDays: reservationDays?.[index],
                    error: error?.index === index ? error.message : null,
                  }}
                />
              )
            )
          ) : (
            <p>Brak wybranych usług</p>
          )}
        </div>
        <div className="reservation-footer">
          <Button
            disabled={loading || invalid || reservations.length === 0}
            loading={loading}
            className="reservation-submit"
            onClick={submit}
          >
            Potwierdź
          </Button>
        </div>
      </SReservation>
    </UserModal>
  );
};

export default ReservationModal;
