import { FC, useEffect } from 'react';

import { AppRoute, Booking } from 'const';
import { useAppDispatch, useAppSelector } from 'Root';
import useLocalizeRoute from 'utils/useLocalizeRoute';

import {
  Button,
  ProfileList,
  SectionLoader,
  Wrapper,
  ProfileReservationItem,
} from 'components';
import empty from '../assets/images/profile-empty-reservations-state.svg';
import {
  getClientReservations,
  setReservationsFilter,
} from 'state/reducers/client/clientActions';
import classNames from 'classnames';
import EmptyState from '../components/EmptyState/EmptyState';

const ProfileReservations: FC = () => {
  const dispatch = useAppDispatch();
  const { data, loading, filter } = useAppSelector(
    ({ client }) => client.reservations.list
  );

  const { localizeRouteKey } = useLocalizeRoute();

  const setFilter = (name: null | 'finished' | 'incoming') => () => {
    dispatch(setReservationsFilter(name));
  };

  useEffect(() => {
    dispatch(getClientReservations());
  }, [filter]);

  const getCorrectHeading = () => {
    switch (filter) {
      case 'finished':
        return 'Rezerwacje zakończone';
      case 'incoming':
        return 'Rezerwacje nadchodzące';
      default:
      case null:
        return 'Rezerwacje';
    }
  };

  if (loading) {
    return (
      <Wrapper>
        <ProfileList>
          <SectionLoader />
        </ProfileList>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <ProfileList base>
        <div className="header">
          <h2 className="heading">{getCorrectHeading()}</h2>
          <div className="filters">
            <button
              type="button"
              className={classNames({
                active: !filter,
              })}
              onClick={setFilter(null)}
            >
              Wszystkie
            </button>
            <button
              type="button"
              className={classNames({
                active: filter === 'incoming',
              })}
              onClick={setFilter('incoming')}
            >
              Nadchodzące
            </button>
            <button
              type="button"
              className={classNames({
                active: filter === 'finished',
              })}
              onClick={setFilter('finished')}
            >
              Zakończone
            </button>
          </div>
        </div>
        {!data.length ? (
          <EmptyState
            title="Brak rezerwacji w tym momencie"
            description="Create new apartments and add details about your location. You
              will get notified once it will be booked."
            image={empty}
            button={{
              label: 'Umów wizytę',
              href: '/pl/list/1',
            }}
          />
        ) : (
          <>
            <div className="list">
              {data.map((reservation: Booking) => (
                <ProfileReservationItem
                  key={`profile-reservation-${reservation.id}`}
                  {...{ reservation }}
                />
              ))}
            </div>
          </>
        )}
      </ProfileList>
    </Wrapper>
  );
};

export { ProfileReservations };
