import React, { useState } from 'react';
import SectionTemplate from './SectionTemplate';
import Employees from './Employees';
import { useBreakpoint } from '../../modules/breakpoint';
import type { Slot } from '../../const';

interface IAboutProps {
  description: string | undefined;
  employees: Slot[];
}

const About = ({ description, employees }: IAboutProps) => {
  const [expand, setExpand] = useState(false);

  const breakpoint = useBreakpoint();

  if (!description) return null;

  const isDesktop = !breakpoint.sm || !breakpoint.md;

  const WORDS_LIMIT = 250;

  const displayText = () => {
    if (isDesktop) return description;

    if (description.length > WORDS_LIMIT && !expand) {
      return `${description.slice(0, WORDS_LIMIT)}...`;
    }

    return description;
  };

  if (!description) return null;

  return (
    <SectionTemplate title="O nas">
      {displayText()
        ?.split(/\n/g)
        .map((text, index) => (
          <p key={index} className="salon-details-about-description">
            {text}
          </p>
        ))}

      {!isDesktop && description?.length > WORDS_LIMIT && (
        <button
          className="salon-details-about-read-more"
          onClick={() => setExpand((prevState) => !prevState)}
        >
          {expand ? 'Czytaj mniej' : 'Czytaj więcej'}
        </button>
      )}
      {employees.length > 0 ? <Employees items={employees} /> : null}
    </SectionTemplate>
  );
};

export default About;
