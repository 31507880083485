import styled, { css } from 'styled-components'
import media from 'theme/respondTo'

export const SSalonServiceItem = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 20px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${media.lg`
      flex-direction: row;
      align-items: center;
    `}

    position: relative;

    &::before {
      width: 20px;
      height: 20px;

      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      background-color: #c6d8d3;
      border-radius: 50%;
    }

    ${media.md`
      padding-left: ${theme.space.xl}px;
      &::before {
        content: '';
      }
    `}

    .salon-service {
      &-name {
        font-size: 0.875rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.31rem;
        color: #3a3335;

        span {
          font-weight: ${theme.weight.normal};
          color: ${theme.colors.primary};
        }
      }

      &-price {
        font-size: 1rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.5rem;
        color: #3a3335;
      }
    }
  `}
`
