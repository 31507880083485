import { FC, HTMLAttributes } from 'react'

import { SAppAuthPageIcon } from './AppAuthPageIcon.styled'

const AppAuthPageIcon: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...res
}) => {
  return (
    <SAppAuthPageIcon {...res}>
      <svg viewBox="0 0 181 124" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M174.613 124c10.734-26.816 7.487-56.758-6.357-79.755L23.335 64.295l138.88-28.92C103.683-37.558-10.242 10.817.74 102.9L174.613 124z"
          fill="#fff"
        />
        <path
          d="M174.613 124c10.734-26.816 7.487-56.758-6.357-79.755L23.335 64.295l138.88-28.92C103.683-37.558-10.242 10.817.74 102.9L174.613 124z"
          fill="#3A3335"
          fillOpacity={0.04}
        />
      </svg>

      <div className="icon">{children}</div>
    </SAppAuthPageIcon>
  )
}

export default AppAuthPageIcon
