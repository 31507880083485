import { useIntl } from 'react-intl';
import { Redirect, useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';

import {
  AppAuthLayout,
  AutocompleteInput,
  Button,
  TextInput,
} from 'components';

import client from 'api/client';
import config from 'const/config';
import { useAppDispatch, useAppSelector } from 'Root';

import { AppRoute, SalonRegisterProfile } from 'const';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import background from 'assets/images/salon-auth-background-3.jpg';
import formErrorsHandler from 'utils/formErrorsHandler';
import loadAutocompleteLocations from 'api/loadAutocompleteLocations';
import { getUser } from 'state/reducers/user/userActions';

const defaultFormValues = {
  city: null,
  postal: '',
  street: '',
  buildingNumber: '',
  phone: '',
};

function AppAuthRegisterProfile() {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();
  const { localizeRouteKey } = useLocalizeRoute();

  const methods = useForm<SalonRegisterProfile>({
    defaultValues: defaultFormValues,
  });

  const registrationProcessStarted = useAppSelector(
    ({ app }) => app.salonAuthRegistrationProcess
  );

  const handleSubmit = async (values: SalonRegisterProfile) => {
    const token = localStorage.getItem(config.TOKEN_KEY);
    if (!token) {
      history.push(AppRoute.AppAuthLogin);
      return;
    }

    const body = {
      ...values,
      city: values?.city?.value ?? null,
    };

    await client('provider/account')
      .auth(`Bearer ${token}`)
      .errorType('json')
      .catcher(
        422,
        formErrorsHandler<SalonRegisterProfile>(
          Object.keys(defaultFormValues),
          methods.setError
        )
      )
      .patch(body)
      .unauthorized(() => {
        history.push(AppRoute.AppAuthLogin);
        return;
      })
      .res(async (res) => {
        if (res.ok) {
          methods.reset(defaultFormValues);
          await dispatch(getUser());
          sessionStorage.removeItem('phone');
          history.push(localizeRouteKey(AppRoute.AppProfile));
        }

        throw new Error('Response error');
      })
      .catch((error) => {
        console.log('[PATCH] auth register profile error', error);
      });
  };

  if (!registrationProcessStarted) {
    return <Redirect to={localizeRouteKey(AppRoute.AppAuthRegister)} />;
  }

  return (
    <AppAuthLayout
      {...{
        background,
        backgroundHeading: 'app-auth.registration-profile-background-heading',
        backgroundDescription:
          'app-auth.registration-profile-background-description',
      }}
    >
      <p className="heading">
        {formatMessage({ id: 'app-auth.registration-profile-heading' })}
      </p>
      <p className="description">
        {formatMessage({
          id: 'app-auth.registration-profile-description',
        })}
      </p>

      <div className="content">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <TextInput {...{ id: 'phone' }} className="row" />
            <div className="row">
              <AutocompleteInput
                {...{
                  id: 'city',
                  placeholder: formatMessage({
                    id: 'app.basic-search.location-placeholder',
                  }),
                  label: formatMessage({ id: 'form.label-city' }),
                  load: loadAutocompleteLocations,
                  control: methods.control,
                }}
              />
            </div>
            <TextInput {...{ id: 'postal' }} className="row" />
            <TextInput {...{ id: 'street' }} className="row" />
            <TextInput {...{ id: 'buildingNumber' }} className="row" />

            <Button
              type="submit"
              wide
              disabled={methods.formState.isSubmitting}
              loading={methods.formState.isSubmitting}
              className="submit-button"
            >
              {formatMessage({ id: 'general.next' })}
            </Button>
          </form>
        </FormProvider>
      </div>
    </AppAuthLayout>
  );
}

export { AppAuthRegisterProfile };
