import styled from 'styled-components';
import media from '../../theme/respondTo';

export const SSliderArrow = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;

  ${media.lg`
     height: 220px;
     
     svg {
    &:hover {
      path {
        fill: #6d738c;
        transition: color 0.3s ease-in-out;
      }
    }
  }
  `}
`;
