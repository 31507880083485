import styled, { css } from 'styled-components'
import media from 'theme/respondTo'

export const SAppWorkplaceServiceEditor = styled.form`
  ${({ theme }) => css`
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto 1fr 1fr auto 1fr;
    gap: ${theme.space.md}px ${theme.space.lg}px;
    grid-template-areas:
      'name name name name'
      'duration-o-count duration-o-count price price'
      'description description description description'
      'service-slots service-slots service-slots service-slots'
      'actions actions actions actions';
    justify-content: center;

    margin-top: ${theme.space.md}px;

    input,
    textarea {
      width: 100%;

      font-size: 1rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.31rem;
      color: #3a3335;

      padding: 0 ${theme.space.md}px;

      background: #ffffff;
      border: 1px solid #ede8e9;
      border-radius: 8px;

      &::placeholder {
        font-size: 1rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.31rem;
        color: #908e8e;

        opacity: 1;
      }

      &:focus {
        outline: 0;
        border-color: ${theme.colors.primary};
      }
    }

    input {
      height: 48px;
    }

    textarea {
      min-height: 48px;
      resize: none;

      padding: 12px ${theme.space.md}px;
    }

    .name {
      grid-area: name;
    }
    .duration-o-count {
      grid-area: duration-o-count;
    }
    .price {
      grid-area: price;
    }
    .description {
      grid-area: description;
    }
    .service-slots {
      grid-area: service-slots;

      input {
        height: 32px;
      }
    }

    .actions {
      grid-area: actions;
      display: flex;
      justify-content: flex-end;

      button {
        min-width: 100px;
        height: 48px;
      }

      .cancel-button {
        margin-right: ${theme.space.sm}px;
      }
    }

    ${media.md`
      grid-auto-columns: 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-rows: auto 1fr auto 1fr;
      gap: ${theme.space.md}px ${theme.space.lg}px;
      grid-template-areas:
        'name name duration-o-count price'
        'description description description description'
        'service-slots service-slots service-slots service-slots'
        'actions actions actions actions';
    `}
  `}
`
