import React from 'react';
import { useBreakpoint } from '../../modules/breakpoint';
import type { Geolocation, OpeningHour } from '../../const';
import { StaticMap } from 'react-map-gl';
import config from '../../const/config';
import SidebarAddress from './SidebarAddress';
import MapMarker from '../Shared/MapMarker';
import OpeningHours from './Sidebar/OpeningHours';
import Contact from './Sidebar/Contact';

interface ISidebarProps {
  openingHours?: OpeningHour[];
  geolocation?: Geolocation;
  address?: string;
  categories: string[];
  phone: string | null;
}

const Sidebar = ({
  openingHours,
  geolocation,
  address,
  categories,
  phone,
}: ISidebarProps) => {
  const breakpoint = useBreakpoint();

  return (
    <div className="salon-details-sidebar">
      <div className="salon-details-sidebar-map-hours">
        <div className="salon-details-sidebar-map">
          {geolocation?.latitude && geolocation?.longitude && (
            <StaticMap
              mapboxApiAccessToken={config.MAPBOX_TOKEN}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              {...{
                width: !breakpoint.lg ? 360 : '100%',
                height: 200,
                ...geolocation,
                zoom: 14,
              }}
            >
              {geolocation ? (
                <MapMarker {...geolocation} categories={categories} />
              ) : null}
            </StaticMap>
          )}

          <SidebarAddress address={address} geolocation={geolocation} />
        </div>
        <OpeningHours openingHours={openingHours} />

        <Contact phone={phone} />
      </div>
    </div>
  );
};

export default Sidebar;
