import { ChangeEvent, FC, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AppRoute, Image } from 'const'
import config from 'const/config'

import { SAppProfileImages } from './AppProfileImages.styled'
import { useDispatch } from 'react-redux'
import {
  removeSalonImage,
  updateCompanyImages,
} from 'state/reducers/user/userActions'
import SectionLoader from 'components/SectionLoader/SectionLoader'
import Loader from 'react-spinners/PuffLoader'
import theme from 'theme'
import { useAppSelector } from 'Root'

interface IAppProfileImages {
  images: Image[]
}

const AppProfileImages: FC<IAppProfileImages> = ({ images }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState<boolean>(false)
  const removeImagesLoading = useAppSelector(
    ({ user }) => user.salon.removeImagesLoading
  )

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    const { files } = event.target
    const token = localStorage.getItem(config.TOKEN_KEY)

    if (!token) {
      history.push(AppRoute.AppAuthLogin)
      setLoading(false)
      return
    }
    if (!files) {
      setLoading(false)
      return
    }

    try {
      const images: Image[] = await Promise.all(
        [...files].map(async (file) => {
          const formData = new FormData()
          formData.append('image', file)

          const response = await fetch(
            `${config.API_URL}/provider/account/images`,
            {
              method: 'POST',
              headers: {
                Authorization: `Bearer ${token}`,
              },
              body: formData,
            }
          )
          return response.json()
        })
      )

      dispatch(updateCompanyImages(images))
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[POST] upload images error', error)
    }
  }

  const handleRemoveImage = (id: number) => () => {
    dispatch(removeSalonImage(id))
  }

  return (
    <SAppProfileImages>
      <div className="images">
        {images.map((image: Image) => {
          return (
            <div key={`profile-images-${image.id}`} className="thumbnail">
              <button
                type="button"
                className="remove"
                onClick={handleRemoveImage(image.id)}
              >
                {removeImagesLoading.includes(image.id) ? (
                  <Loader color={theme.colors.primary} />
                ) : (
                  <span className="material-icons icon">close</span>
                )}
              </button>
              <img src={image.path} alt="" />
            </div>
          )
        })}
      </div>
      <div className="uploader">
        {loading ? (
          <SectionLoader />
        ) : (
          <>
            <label htmlFor="company-images">
              <span className="material-icons icon">cloud_upload</span>
              Upload photos
            </label>
            <input
              type="file"
              multiple
              accept="image/*"
              id="company-images"
              onChange={handleChange}
            />
          </>
        )}
      </div>
    </SAppProfileImages>
  )
}

export default AppProfileImages
