import styled, { css } from 'styled-components'

export const SDatePickerInput = styled.button`
  ${({ theme }) => css`
    height: 48px;

    display: flex;
    align-items: center;

    font-size: 0.875rem;
    font-weight: ${theme.weight.bold};
    line-height: 1.31rem;
    color: #3a3335;

    padding: 0 ${theme.space.md}px;

    background: #ffffff;
    border: 1px solid #ede8e9;
    border-radius: 8px;

    cursor: pointer;

    .icon {
      width: 17px;
      height: 17px;

      color: #908e8e;

      margin-left: ${theme.space.sm}px;
    }
  `}
`
