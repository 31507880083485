import { FC, useEffect, useState, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import mapboxgl, { Map } from 'mapbox-gl';
import { useDebouncedCallback } from 'use-debounce';
import { Marker, StaticMap } from 'react-map-gl';

import {
  AppProfileCategories,
  AppProfileEdit,
  AppProfileHeading,
  AppProfileImages,
  OpeningHoursEditor,
  AutocompleteInput,
  TextInput,
  Wrapper,
  Button,
} from 'components';
import loadAutocompleteLocations from 'api/loadAutocompleteLocations';
import { useAppDispatch, useAppSelector } from 'Root';
import { Category, OpeningHour } from 'const';
import Sticky from 'react-stickynode';
import { updateSalonUser } from 'state/reducers/user/userActions';
import applySalonProfileData from 'utils/applySalonProfileData';

import config from 'const/config';
import PartnersProgram from '../components/AppProfile/PartnersProgram';

interface FormValues {
  name: string;
  email: string;
  password?: string;
  phone: string;

  company_name: string;
  nip: string;
  city: { label: string; value: number; district: boolean } | null;
  postal: string;
  street: string;
  buildingNumber: string;

  categories: Category[];
  openingHours: OpeningHour[];

  description: string;
  partner_program: boolean;
}

export const defaultFormValues = {
  name: '',
  email: '',
  password: 'secret_password',
  phone: '',

  company_name: '',
  nip: '',

  city: null,
  postal: '',
  street: '',
  buildingNumber: '',

  categories: [],

  openingHours: [],

  description: '',
  partner_program: false,
};

const AppProfile: FC = () => {
  const [lat, setLat] = useState(52.229676);
  const [lng, setLng] = useState(21.012229);

  const dispatch = useAppDispatch();
  const { user } = useAppSelector(({ user }) => user);
  const { categories } = useAppSelector(({ app }) => app);

  const { formatMessage } = useIntl();
  const methods = useForm<FormValues>({
    defaultValues: applySalonProfileData(user, {
      ...defaultFormValues,
      partner_program: user?.company.partner_program,
    }),
  });

  const addressWatch = methods.watch([
    'city',
    'buildingNumber',
    'postal',
    'street',
  ]);

  const submitSettings = async (values: FormValues) => {
    if (values.phone.length > 9) {
      return alert('Numer telefonu jest za długi');
    }

    const body = {
      ...values,
      city: values.city?.value ?? null,
    };

    if (body.password === defaultFormValues.password) {
      delete body.password;
    }

    await dispatch(updateSalonUser({ body, methods }));
  };

  const getAddressGeolocation = async () => {
    try {
      const [city, buildingNumber, postal, street] = addressWatch;

      const response = await fetch(
        `${mapboxgl.baseApiUrl}/geocoding/v5/mapbox.places/${
          city?.label ?? ''
        } ${postal} ${street} ${buildingNumber}.json?types=address&language=pl&country=pl&limit=1&access_token=${
          mapboxgl.accessToken
        }`
      );

      const data = await response.json();
      if (data.features) {
        const [location] = data.features;
        const [newLng, newLat] = location.center;
        setLng(newLng);
        setLat(newLat);
      }
    } catch (error) {
      console.log('[GET] address geolocation error', error);
    }
  };

  const debounced = useDebouncedCallback(getAddressGeolocation, 1000);

  useEffect(() => {
    debounced();
  }, [addressWatch]);

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <AppProfileEdit>
          <form
            id="profile-edit-form"
            onSubmit={methods.handleSubmit(submitSettings)}
          >
            <AppProfileHeading>Twoje dane firmy</AppProfileHeading>
            <div className="fields">
              <TextInput
                {...{
                  label: formatMessage({ id: 'form.label-companyName' }),
                  id: 'company_name',
                }}
                className="field"
                rules={{ required: formatMessage({ id: 'form.required' }) }}
              />
              <TextInput
                {...{
                  label: formatMessage({ id: 'form.label-nip' }),
                  id: 'nip',
                }}
                className="field"
                rules={{
                  required: formatMessage({ id: 'form.required' }),
                  pattern: {
                    value: /^[0-9]{10}$/i,
                    message: formatMessage({ id: 'form.invalid-nip' }),
                  },
                }}
              />
              <TextInput
                {...{
                  label: formatMessage({ id: 'form.label-fullName' }),
                  id: 'name',
                }}
                className="field"
                rules={{ required: formatMessage({ id: 'form.required' }) }}
              />
              <TextInput
                {...{ id: 'email' }}
                className="field"
                rules={{
                  required: formatMessage({ id: 'form.required' }),
                  email: formatMessage({ id: 'form.invalid-email' }),
                }}
              />
              <TextInput
                {...{
                  label: formatMessage({ id: 'app-auth.login-password' }),
                  id: 'password',
                  type: 'password',
                }}
                className="field"
                rules={{
                  required: formatMessage({ id: 'form.required' }),
                  minLength: {
                    value: 6,
                    message: formatMessage({ id: 'form.invalid-password' }),
                  },
                }}
              />
              <TextInput
                {...{
                  label: formatMessage({ id: 'form.label-phone' }),
                  id: 'phone',
                  type: 'text',
                }}
                className="field"
              />
            </div>

            <AppProfileHeading>Adres firmy</AppProfileHeading>
            <div className="fields">
              <AutocompleteInput
                {...{
                  id: 'city',
                  label: formatMessage({ id: 'form.label-city' }),
                  load: loadAutocompleteLocations,
                  control: methods.control,
                }}
              />
              <TextInput
                {...{
                  label: formatMessage({ id: 'form.label-postal' }),
                  id: 'postal',
                }}
                className="field"
                rules={{ required: formatMessage({ id: 'form.required' }) }}
              />
              <TextInput
                {...{
                  label: formatMessage({ id: 'form.label-street' }),
                  id: 'street',
                }}
                className="field"
                rules={{ required: formatMessage({ id: 'form.required' }) }}
              />
              <TextInput
                {...{
                  label: formatMessage({ id: 'form.label-buildingNumber' }),
                  id: 'buildingNumber',
                }}
                className="field"
                rules={{ required: formatMessage({ id: 'form.required' }) }}
              />
            </div>

            <div className="map">
              <StaticMap
                mapboxApiAccessToken={config.MAPBOX_TOKEN}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                {...{
                  width: '100%',
                  height: 309,
                  latitude: lat,
                  longitude: lng,
                  zoom: 16,
                }}
              >
                {lng && lat && (
                  <Marker longitude={lng} latitude={lat}>
                    <div className="marker">
                      <svg
                        width={22}
                        height={20}
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.64 3.926c.04-1.303.416-2.461 1.41-3.32 1.151-.993 2.601-.74 3.413.568 1.023 1.649.684 4.196-.733 5.498-1.137 1.046-2.633.853-3.477-.457-.444-.69-.623-1.463-.613-2.29zm10.356 4.65c-.042 1.137-.384 2.15-1.201 2.945-1.214 1.182-2.813.812-3.435-.784-.561-1.44-.085-3.407 1.067-4.4 1.394-1.205 3.143-.475 3.496 1.46.047.256.09.514.073.779zM10.36 3.926c-.039-1.303-.416-2.461-1.41-3.32-1.151-.993-2.601-.74-3.413.568-1.023 1.649-.683 4.196.733 5.498 1.137 1.046 2.633.853 3.477-.457.444-.69.624-1.463.613-2.29zM.003 8.576c.043 1.137.385 2.15 1.202 2.945 1.214 1.182 2.813.812 3.435-.784.561-1.44.084-3.407-1.067-4.4C2.18 5.131.43 5.861.077 7.796c-.047.256-.09.514-.074.779zM11 18.11c4.289 4.761 9.788-.409 6.188-5.21a7.134 7.134 0 00-.67-.764l-4.063-4.058a2.03 2.03 0 00-2.91 0l-4.062 4.058c-.24.24-.467.492-.67.764-3.601 4.8 1.898 9.97 6.187 5.21z"
                          fill="#FF6B00"
                        />
                      </svg>
                    </div>
                  </Marker>
                )}
              </StaticMap>
            </div>

            <AppProfileHeading>
              Wybierz kategorie Twojej firmy
            </AppProfileHeading>
            <div className="fields">
              <AppProfileCategories
                control={methods.control}
                categories={categories}
              />
            </div>

            <AppProfileHeading>Godziny otwarcia</AppProfileHeading>
            <OpeningHoursEditor control={methods.control} />

            <AppProfileHeading>Dodaj zdjęcia</AppProfileHeading>
            <AppProfileImages images={user?.company?.images ?? []} />

            <AppProfileHeading>Dodaj opis firmy</AppProfileHeading>
            <div className="fields single textarea-type">
              <TextInput
                textarea
                {...{
                  label: formatMessage({ id: 'form.label-description' }),
                  id: 'description',
                }}
                className="field"
                rules={{ required: formatMessage({ id: 'form.required' }) }}
              />
            </div>

            {/* partners program */}
          </form>
          <div className="actions">
            <Sticky>
              <Button
                disabled={methods.formState.isSubmitting}
                loading={methods.formState.isSubmitting}
                type="submit"
                form="profile-edit-form"
              >
                Zapisz
              </Button>
            </Sticky>
          </div>
        </AppProfileEdit>
      </FormProvider>
    </Wrapper>
  );
};

export { AppProfile };

/*
  <PartnersProgram
              checked={methods.watch('partner_program')}
              inputProps={methods.register('partner_program')}
            />
 */
