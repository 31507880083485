import { useIntl } from 'react-intl';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import client from 'api/client';
import { AppAuthLayout, Button, Checkbox, TextInput } from 'components';

import { AppRoute, SalonRegisterBase, SalonRegisterBaseDTO } from 'const';
import background from 'assets/images/salon-auth-background-2.jpg';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import config from 'const/config';
import { setSalonRegistrationProcess } from 'state/reducers/app/appActions';
import formErrorsHandler from 'utils/formErrorsHandler';
import { useAppSelector } from 'Root';

const defaultFormValues = {
  companyName: '',
  fullName: '',
  email: '',
  password: '',
  phone: '',
};

function AppAuthRegister() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();
  const { localizeRouteKey } = useLocalizeRoute();
  const { links, appLinks } = useAppSelector(({ app }) => app.homepage);

  const methods = useForm<SalonRegisterBase>({
    defaultValues: defaultFormValues,
  });
  const agreement = methods.watch('agreement');

  const handleSubmit = async (values: SalonRegisterBase) => {
    if (!agreement) return;
    const payload = JSON.parse(
      JSON.stringify({ ...values, agreement: undefined })
    );

    const body: SalonRegisterBaseDTO = {
      ...payload,
      passwordConfirmation: payload.password,
    };

    await client('provider/auth/register')
      .errorType('json')
      .catcher(
        422,
        formErrorsHandler<SalonRegisterBase>(
          Object.keys(defaultFormValues),
          methods.setError
        )
      )
      .post(body)
      .json((json) => {
        const token = json?.data?.token ?? null;
        localStorage.setItem(config.TOKEN_KEY, token);

        dispatch(setSalonRegistrationProcess(true));

        methods.reset(defaultFormValues);
        history.push(localizeRouteKey(AppRoute.AppAuthRegisterSuccess));

      })
      .catch((error) => {
        console.log('[POST] auth register error', error);
      });
  };

  return (
    <AppAuthLayout {...{ background, back: AppRoute.AppAuthLogin }}>
      <p className="heading">
        {formatMessage({ id: 'app-auth.registration-heading' })}
      </p>
      <p className="description">
        {formatMessage({
          id: 'app-auth.registration-description',
        })}
      </p>

      <div className="content">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <TextInput {...{ id: 'companyName' }} className="row" />
            <TextInput {...{ id: 'fullName' }} className="row" />
            <TextInput {...{ id: 'email' }} className="row" />
            <TextInput
              {...{
                id: 'password',
                type: 'password',
              }}
              className="row"
            />
            <div className="row">
              <Controller
                control={methods.control}
                name="agreement"
                render={({ field: { onChange, onBlur, ref, value } }) => (
                  <Checkbox circle className="agreement">
                    <input
                      type="checkbox"
                      id="agreement"
                      {...{ onChange, onBlur, ref, checked: !!value }}
                    />
                    <label htmlFor="agreement">
                      <span>
                        Rejestrując się, zgadzasz się na{' '}
                        <a
                          href={appLinks.terms}
                          target="_blank"
                          rel="noreferrer"
                        >
                          warunki
                        </a>{' '}
                        korzystania z serwisu oraz polityka prywatności
                      </span>
                    </label>
                  </Checkbox>
                )}
              />
            </div>
            <Button
              type="submit"
              wide
              disabled={methods.formState.isSubmitting || !agreement}
              loading={methods.formState.isSubmitting}
              className="submit-button"
            >
              {formatMessage({ id: 'app-auth.registration-createaccount' })}
            </Button>
          </form>
        </FormProvider>
      </div>
    </AppAuthLayout>
  );
}

export { AppAuthRegister };
