import React, { FC, useState } from 'react';

import { useAppSelector } from 'Root';
import { CategoryTile } from 'components';
import { SCategories } from './Categories.styled';
import SwiperCore, { Navigation, Controller, Swiper } from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';

import SliderArrow from '../SliderArrow/SliderArrow';

SwiperCore.use([Controller, Navigation]);

const Categories: FC = () => {
  const [swiper, setSwiper] = useState<Swiper>();
  const categories = useAppSelector(({ app }) => app.categories);

  const updateSlide = (direction: 'left' | 'right') => {
    if (direction === 'left') swiper?.slidePrev();
    if (direction === 'right') swiper?.slideNext();
  };

  return (
    <SCategories>
      <div className="content">
        <SliderArrow direction="left" onClick={updateSlide} />

        <SwiperComponent
          onSwiper={setSwiper}
          slidesPerView={1.5}
          breakpoints={{
            600: {
              slidesPerView: 3,
            },
            900: {
              slidesPerView: 5,
            },
          }}
          spaceBetween={24}
        >
          {categories.map(({ id, image, name, providersCount }) => (
            <SwiperSlide key={`home-category-${id}`}>
              <CategoryTile
                id={id}
                name={name}
                image={image}
                companiesNumber={providersCount}
              />
            </SwiperSlide>
          ))}
        </SwiperComponent>

        <SliderArrow direction="right" onClick={updateSlide} />
      </div>
    </SCategories>
  );
};

export default Categories;
