import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SEmployees = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-flow: column;
    margin: 36px 0 0;

    .employees {
      &__heading {
        font-size: 16px;
        font-weight: ${theme.weight.medium};
        color: ${theme.colors.black};
      }

      &__list {
        display: flex;
        flex-flow: column;
        margin: 5px 0 0;

        ${media.lg`
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 16px;
        margin: 30px 0 0;
        `}
      }
    }
  `}
`;
