import { FC } from 'react';
import { OptionTypeBase, StylesConfig } from 'react-select';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useIntl } from 'react-intl';

type SelectIsMulti = boolean;

const selectStyles: StylesConfig<OptionTypeBase, SelectIsMulti> = {
  container: (styles) => ({
    ...styles,
    width: '100%',
  }),
  control: (styles) => ({
    ...styles,
    borderColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    fontSize: '.875rem',
    minHeight: 24,
    '&:hover': {
      borderColor: 'transparent',
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
  }),
  singleValue: (styles) => ({
    ...styles,
    lineHeight: '16px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    paddingTop: 4,
    paddingBottom: 4,
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: 0,
  }),
};

export interface IAutocomplete {
  load?: (inputValue: string, callback: any) => void;
  customStyles?: StylesConfig<OptionTypeBase, SelectIsMulti>;
  placeholder?: string;
  onChange?: (value: OptionTypeBase) => void;
  options?: any[];
  value?: OptionTypeBase | null;
}

const Autocomplete: FC<IAutocomplete> = ({
  load,
  customStyles,
  placeholder,
  onChange,
  value,
  options,
  ...res
}) => {
  const { formatMessage } = useIntl();
  const styles = customStyles || selectStyles;

  if (load) {
    return (
      <AsyncSelect
        noOptionsMessage={() => formatMessage({ id: 'general.noResults' })}
        loadOptions={load}
        styles={styles}
        options={options}
        defaultOptions
        placeholder={placeholder}
        isClearable={true}
        onChange={onChange}
        value={value}
        {...res}
      />
    );
  }

  return (
    <Select
      noOptionsMessage={() => formatMessage({ id: 'general.noResults' })}
      styles={styles}
      options={options}
      defaultOptions
      placeholder={placeholder}
      isClearable={true}
      onChange={onChange}
      value={value}
      {...res}
    />
  );
};

export default Autocomplete;
