import React from 'react';
import { SEmployees } from './Employees.styled';
import type { Slot } from '../../const';
import Employee from './Employee';

interface EmployeeProps {
  items: Slot[];
}

const Employees = ({ items }: EmployeeProps) => (
  <SEmployees>
    <h3 className="employees__heading">Pracownicy</h3>
    {items.length > 0 ? (
      <ul className="employees__list">
        {items.map((employee, index) => (
          <Employee
            key={employee.id}
            {...employee}
            isLast={index + 1 === items.length}
          />
        ))}
      </ul>
    ) : (
      <p>Brak pracowników</p>
    )}
  </SEmployees>
);

export default Employees;
