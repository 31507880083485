import { useIntl } from 'react-intl';

import { AppAuthLayout, Button } from 'components';

import background from 'assets/images/salon-auth-background-4.jpg';
import useLocalizeRoute from 'utils/useLocalizeRoute';
import { AppRoute } from 'const';
import { useAppSelector } from 'Root';
import { Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

function AppAuthRegisterSuccess() {

  const { formatMessage } = useIntl();
  const { localizeRouteKey } = useLocalizeRoute();

  const registrationProcessStarted = useAppSelector(
    ({ app }) => app.salonAuthRegistrationProcess
  );

  if (!registrationProcessStarted) {
    return <Redirect to={localizeRouteKey(AppRoute.AppAuthRegister)} />;
  }

  return (
    <AppAuthLayout
      {...{
        background,
        icon: 'done',
        backgroundHeading: 'app-auth.registration-success-background-heading',
        backgroundDescription:
          'app-auth.registration-success-background-description',
      }}
    >
      <p className="heading">
        {formatMessage({ id: 'app-auth.registration-success-heading' })}
      </p>
      <p className="description">
        {formatMessage({
          id: 'app-auth.registration-success-description',
        })}
        { ReactPixel.track('CompleteRegistration', true) }
      </p>
      <Button
        wide
        to={localizeRouteKey(AppRoute.AppAuthRegisterProfile)}
        className="submit-button"
      >
        {formatMessage({ id: 'general.next' })}
      </Button>
    </AppAuthLayout>
  );
}

export { AppAuthRegisterSuccess };
