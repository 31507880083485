import React, { useState } from 'react';
import DropdownChevron from 'components/icons/DropdownChevron';
import PhoneIcon from 'components/icons/PhoneIcon';

interface ContactProps {
  phone: string | null;
}

const Contact = ({ phone }: ContactProps) => {
  const [isContactOpen, setContactOpen] = useState(true);
  const [displayPhone, setDisplayPhone] = useState(false);

  const shortenPhone = displayPhone ? phone : `${phone?.slice(0, 6)}...`;

  if (!phone) return null;

  return (
    <div className="salon-details-sidebar-opening-hours">
      <div
        role="button"
        onClick={() => setContactOpen((prevState) => !prevState)}
        className="salon-details-sidebar-opening-hours-header"
      >
        <h3 className="salon-details-sidebar-heading">Dane kontaktowe</h3>
        <DropdownChevron open={isContactOpen} />
      </div>
      {isContactOpen && (
        <div className="salon-details-sidebar-phone-wrapper">
          <PhoneIcon />

          <div className="salon-details-sidebar-phone-container">
            <a className="salon-details-sidebar-phone" href={`tel:${phone}`}>
              {shortenPhone}
            </a>
            {!displayPhone && (
              <button
                onClick={() => setDisplayPhone(true)}
                className="salon-details-sidebar-phone-button"
              >
                Wyświetl
              </button>
            )}
          </div>
        </div>
      )}

      {isContactOpen && displayPhone && (
        <p className="salon-details-sidebar-phone-hint">
          Powołaj się na stronę Dogzi.pl i zapytaj o wolny termin
        </p>
      )}
    </div>
  );
};

export default Contact;
