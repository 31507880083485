import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SBasicSearch = styled.div<{
  visible?: boolean;
  fromHeader?: boolean;
}>`
  ${({ theme, visible = true, fromHeader }) => css`
    width: 90%;
    left: 50%;
    margin-left: -45%;
    z-index: 1;

    ${!visible &&
    css`
      display: none;
    `}

    background-color: ${theme.colors.white};
    border-radius: 24px;
    box-shadow: 0 16px 35px rgba(193, 196, 214, 0.15);

    ${!fromHeader &&
    css`
      padding: 24px;
      position: absolute;
      bottom: -50px;

      ${media.md`
        bottom: 0;
        padding: 20px 57px 40px;
      `}

      ${media.xxl`
        height: 185px;
        width: 1259px;
        margin-left: -629.5px;
      `}
    `}

    form {
      align-items: stretch;
      display: flex;
      flex-flow: column;
      column-gap: 24px;
      row-gap: 16px;
      justify-content: space-between;

      ${!fromHeader &&
      css`
        ${media.md`
        flex-flow: row;
      margin-top: 32px;
    `}
      `}
    }

    .group {
      width: 100%;

      & > p {
        font-size: 0.75rem;
        line-height: 1.125rem;
        color: #908e8e;
        padding-left: 3px;
      }
    }

    .search-button {
      height: 56px;
      width: 100%;
      font-size: 15px;
      line-height: 16px;
      margin-top: ${theme.space.md}px;

      & > span {
        margin-right: ${theme.space.sm}px;
      }
    }

    ${media.lg`
      form {
        flex-direction: row;
        align-items: center;
      }

      .group {
         width: 439px;
      }

      .search-button {
        margin-top: 0;
       width: 214px !important;

        & > span {
          margin-right: 0;
        }
      }
    `}
  `}
`;
