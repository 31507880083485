import React from 'react';

const WalletIcon = () => (
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.583 8.25h4.583v5.5h-4.583a2.75 2.75 0 110-5.5zm0 3.667a.917.917 0 100-1.834.917.917 0 000 1.834z"
      fill="#FF6B00"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.112 3.666h7.775c.739 0 1.347 0 1.843.04.516.043.99.133 1.434.36.69.351 1.251.912 1.603 1.602.226.445.317.919.359 1.434.04.496.04 1.105.04 1.843v4.109c0 .738 0 1.347-.04 1.843-.042.515-.133.989-.36 1.434a3.667 3.667 0 01-1.602 1.602c-.445.227-.918.317-1.434.36-.496.04-1.104.04-1.843.04H7.112c-.738 0-1.347 0-1.843-.04-.515-.043-.99-.133-1.434-.36a3.666 3.666 0 01-1.602-1.602c-.227-.445-.317-.919-.36-1.434-.04-.496-.04-1.105-.04-1.843v-4.11c0-.737 0-1.346.04-1.842.043-.515.133-.99.36-1.434.351-.69.912-1.25 1.602-1.602.445-.227.919-.317 1.434-.36.496-.04 1.105-.04 1.843-.04zM5.418 5.534c-.402.033-.607.092-.75.165a1.833 1.833 0 00-.802.801c-.073.144-.132.35-.165.751-.034.413-.035.946-.035 1.732v4.033c0 .785.001 1.319.035 1.732.033.402.092.607.165.75.176.345.456.626.801.801.144.074.35.133.751.166.413.034.946.034 1.732.034h7.7c.785 0 1.319 0 1.731-.034.402-.033.608-.092.751-.166.345-.175.625-.456.801-.8.073-.144.133-.35.166-.751.033-.413.034-.947.034-1.732V8.983c0-.786 0-1.32-.034-1.732-.033-.402-.093-.607-.166-.75a1.833 1.833 0 00-.801-.802c-.143-.073-.349-.132-.75-.165-.413-.034-.947-.035-1.732-.035h-7.7c-.786 0-1.32.001-1.732.035z"
      fill="#FF6B00"
    />
  </svg>
);

export default WalletIcon
