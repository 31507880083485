import styled, { css } from 'styled-components'
import media from 'theme/respondTo'

export const SGallery = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100vh;

    position: fixed;
    top: 0;
    left: 0;

    padding: ${theme.space.md}px;

    background-color: white;

    z-index: 20;

    ${media.lg`
      padding: ${theme.space.xxl}px;
    `}

    .gallery {
      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-count {
        font-weight: 300;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #908e8e;

        strong {
          font-size: 1.125rem;
          font-weight: ${theme.weight.bold};
          color: #3a3335;
        }
      }

      &-close {
        width: 110px;
        height: 46px;

        color: #908e8e;

        background: linear-gradient(
            0deg,
            rgba(58, 51, 53, 0.04),
            rgba(58, 51, 53, 0.04)
          ),
          #ffffff;
        border-radius: 10px;
        border: 0;

        &:hover {
          background: linear-gradient(
              0deg,
              rgba(58, 51, 53, 0.1),
              rgba(58, 51, 53, 0.1)
            ),
            #ffffff;
        }
      }

      &-swiper {
        position: relative;
        margin-top: ${theme.space.xxl}px;

        .swiper-slide {
          text-align: center;
          height: auto;

          img {
            max-width: 100%;
            width: auto;
            height: 100%;
            max-height: calc(100vh - 256px);
            object-fit: cover;
            cursor: pointer;
          }
        }

        &-arrows {
          width: 100%;

          & > div {
            display: flex;
            justify-content: space-between;
            position: relative;
          }

          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);

          z-index: 1;

          button {
            width: 46px;
            height: 46px;

            position: absolute;

            display: flex;
            align-items: center;
            justify-content: center;

            background: linear-gradient(
                0deg,
                rgba(58, 51, 53, 0.04),
                rgba(58, 51, 53, 0.04)
              ),
              #ffffff;
            box-shadow: none;
            border: 0;
            border-radius: 10px;

            cursor: pointer;

            span {
              font-size: 18px;
              color: #908e8e;
            }

            &:first-of-type {
              left: ${theme.space.md}px;
            }

            &:last-of-type {
              right: ${theme.space.md}px;
            }

            ${media.lg`
              &:first-of-type {
                left: 0;
              }
              &:last-of-type {
                right: 0;
              }
            `}
          }
        }
      }
    }
  `}
`
