import styled, { css } from 'styled-components';
import media from 'theme/respondTo';

export const SProfileReservationCancelation = styled.div`
  ${({ theme }) => css`
    .reservation-cancelation {
      &-header {
        height: 72px;

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        border-bottom: 2px solid #eceef5;
      }

      &-heading {
        font-size: 1.125rem;
        font-weight: ${theme.weight.bold};
        line-height: 1.68rem;

        color: #3a3335;
      }

      &-close {
        position: absolute;
        top: 50%;
        right: ${theme.space.lg}px;

        margin: 0;
        padding: 0;

        transform: translateY(-50%);

        background: none;
        border: 0;
        box-shadow: none;

        cursor: pointer;

        span {
          color: #3a3335;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        padding: ${theme.space.lg}px;
        padding-bottom: ${theme.space.xxxl}px;

        overflow-y: auto;
      }

      &-icon {
        .icon {
          background-color: #979797;
          svg {
            width: 22px;
          }
        }
      }

      &-icon-heading {
        font-size: 2.125rem;
        font-weight: ${theme.weight.heavy};
        line-height: 3rem;
        color: #3a3335;
        text-align: center;
        margin-bottom: ${theme.space.lg}px;
      }

      &-service {
        &-thumbnail {
          width: 92px;
          height: 92px;

          margin-right: ${theme.space.md}px;

          border-radius: 6px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &-content {
          max-width: 502px;
          width: 100%;

          flex: 1;
          display: flex;
          justify-content: center;

          padding: ${theme.space.xl}px 0;
          margin-bottom: ${theme.space.xl}px;

          border-top: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          ${media.lg`
            margin-top: 0;
          `}
        }

        &-name {
          font-size: 0.875rem;
          font-weight: ${theme.weight.bold};
          line-height: 1.5rem;
          color: #3a3335;

          span {
            font-weight: ${theme.weight.normal};
            color: ${theme.colors.primary};
          }
        }

        &-address {
          font-size: 0.875rem;
          line-height: 1.31rem;
          color: #908e8e;

          position: relative;

          padding-bottom: ${theme.space.sm}px;
          /* margin-top: ${theme.space.xs}px; */

          &::before {
            content: '';
            width: 42px;
            height: 1px;

            position: absolute;
            left: 0;
            bottom: 0;

            background-color: ${theme.colors.primary};
          }
        }

        &-date {
          font-size: 0.875rem;
          font-weight: ${theme.weight.bold};
          line-height: 1.31rem;
          text-transform: capitalize;
          color: #3a3335;

          margin-top: ${theme.space.sm}px;

          span {
            font-weight: ${theme.weight.normal};
            color: #908e8e;

            margin-left: ${theme.space.sm}px;
          }
        }
      }

      &-actions {
        max-width: 502px;
        width: 100%;

        display: flex;
        justify-content: space-between;
        gap: 20px;
        button {
          width: 212px;
          height: 54px;
        }
      }

      &-confirm {
        color: ${theme.colors.primary};

        background-color: #eef3f2;
        border-color: #eef3f2;

        &:not(:disabled):hover {
          background-color: #d8dbda;
          border-color: #d8dbda;
        }
      }

      &-cancel {
        text-transform: uppercase;
      }
    }
  `}
`;
