import { AsyncThunk, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import client from 'api/client';
import { Reservation, SalonService } from 'const';
import { RootState } from 'state/createStore';
import config from 'const/config';
import { DateTime } from 'luxon';
import { showAuthModal } from '../client/clientActions';
import { AuthModalType } from 'components/Header/Header';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';

const CLOSE_RESERVATION_MODAL = 'reservation/modalClose';
export const closeReservationModal = createAction(CLOSE_RESERVATION_MODAL);

export const updateSlotId = createAction<{
  salonId: number;
  slotId: number;
  serviceId: number;
}>('reservation/updateSlot');

const ADD_SERVICE_TO_RESERVATION = 'reservation/addService';
export const addServiceToReservation: AsyncThunk<
  {
    salonId: number;
    service: SalonService;
    slotId: number;
  },
  {
    salonId: number;
    slotId: number;
    service: SalonService;
  },
  { state: RootState }
> = createAsyncThunk(ADD_SERVICE_TO_RESERVATION, async (payload, thunkAPI) => {
  return payload;
});

export const openReservationsModal = createAction('reservation/openModal');

const REMOVE_SERVICE_FROM_RESERVATION = 'reservation/removeService';
export const removeServiceFromReservation = createAction<number>(
  REMOVE_SERVICE_FROM_RESERVATION
);

const UPDATE_SERVICE_IN_RESERVATION = 'reservation/updateService';
export const updateServiceInReservation = createAction<{
  index: number;
  reservation: Reservation;
  toggle?: boolean;
}>(UPDATE_SERVICE_IN_RESERVATION);

const GET_SERVICE_PROPOSED_HOURS = 'reservation/getProposedHours';
export const getServiceProposedHours: AsyncThunk<
  { index: number; data: string[] },
  { index: number; reservation: Reservation },
  { state: RootState }
> = createAsyncThunk(
  GET_SERVICE_PROPOSED_HOURS,
  async ({ index, reservation }, thunkAPI) => {
    let endpoint = `client/appointments/proposedDates?serviceId=${reservation.service.id}&slotId=${reservation.slotId}`;
    let time = '';

    if (reservation.service.appointmentType === 'normal') {
      time = `&date[]=${
        (reservation.time?.[0] || new Date().getTime()) / 1000
      }`;
    } else {
      time = `&date[]=${
        (reservation.time as [number, number])[0] / 1000
      }&date[]=${(reservation.time as [number, number])[1] / 1000}`;
    }

    const reservations = (thunkAPI.getState() as RootState).reservation
      .reservations;
    const reservationsQuery = reservations
      .filter((o, i) => i !== index && o.service.appointmentType === 'normal')
      .map((item: Reservation, i) => {
        return `&exceptions[${i}][serviceId]=${
          item.service.id
        }&exceptions[${i}][timeStart]=${
          (item.time?.[0] ?? new Date().getTime()) / 1000
        }&exceptions[${i}][timeEnd]=null`;
      })
      .filter(Boolean)
      .join('&');

    endpoint = `${endpoint}${time}${reservationsQuery}`;

    return await client(endpoint)
      .errorType('json')
      .get()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(false);
      })
      .json(async (json) => {
        const { data } = json;
        return { index, data };
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const GET_AVAILABLE_DAYS = 'reservation/getAvailableDays';
export const getAvailableDays: AsyncThunk<
  { index: number; data: number[] },
  { index: number; reservation: Reservation; month?: number },
  { state: RootState }
> = createAsyncThunk(
  GET_AVAILABLE_DAYS,
  async ({ index, reservation, month }, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);

    let endpoint = `client/appointments/proposedDates?serviceId=${reservation.service.id}`;

    let time = '';

    if (month || (reservation.time && reservation?.time.length > 1)) {
      const date = DateTime.fromObject({ month });
      const monthStartDate = parseInt(
        String(date.startOf('month').toMillis() / 1000)
      );
      const monthEndDate = parseInt(
        String(date.endOf('month').toMillis() / 1000)
      );

      time = `&date[]=${monthStartDate}&date[]=${monthEndDate}`;
    } else {
      const startDate = reservation.time?.[0]
        ? DateTime.fromMillis(reservation.time?.[0])
        : DateTime.local();
      const endtDate = reservation.time?.[1]
        ? DateTime.fromMillis(reservation.time?.[1])
        : DateTime.local();

      const startDateMonthStart = parseInt(
        String(startDate.startOf('month').toMillis() / 1000)
      );
      const startDateMonthEnd = parseInt(
        String(endtDate.endOf('month').toMillis() / 1000)
      );

      time = `&date[]=${startDateMonthStart}&date[]=${startDateMonthEnd}`;
    }

    const reservations = (thunkAPI.getState() as RootState).reservation
      .reservations;
    const reservationsQuery = reservations
      .filter(
        (o, i) =>
          i !== index &&
          o.service.appointmentType === 'range' &&
          o.time?.length === 2
      )
      .map((item: Reservation, i) => {
        return `&exceptions[${i}][serviceId]=${
          item.service.id
        }&exceptions[${i}][timeStart]=${
          (item.time?.[0] ?? new Date().getTime()) / 1000
        }&exceptions[${i}][timeEnd]=${
          (item.time?.[1] ?? new Date().getTime()) / 1000
        }`;
      })
      .filter(Boolean)
      .join('&');

    endpoint = `${endpoint}${time}${reservationsQuery}`;

    return await client(endpoint)
      .auth(`Bearer ${token}`)
      .errorType('json')
      .get()
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(false);
      })
      .json(async (json) => {
        const { data } = json;
        return { index, data };
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error);
      });
  }
);

const SUBMIT_RESERVATION = 'reservation/submit';
export const submitReservation: AsyncThunk<any, void, { state: RootState }> =
  createAsyncThunk(SUBMIT_RESERVATION, async (_, thunkAPI) => {
    const token = localStorage.getItem(config.TOKEN_CLIENT_KEY);
    const { isAuthorized, user } = (thunkAPI.getState() as RootState).client;
    if (!token) {
      return thunkAPI.rejectWithValue(false);
    }

    if (!isAuthorized) {
      thunkAPI.dispatch(showAuthModal(AuthModalType.SIGN_IN));
      return;
    }

    const reservations = (thunkAPI.getState() as RootState).reservation
      .reservations;

    const body = reservations.map(({ service, time, slotId }) => {
      const basicPayload = {
        serviceId: service.id,
        date: time?.map((t) => t / 1000),
        email: user?.email,
        phone: user?.phone,
      };

      if (service.category?.appointmentType === 'range') {
        return basicPayload;
      }

      return {
        ...basicPayload,
        slotId,
      };
    });

    return await client('client/appointments')
      .auth(`Bearer ${token}`)
      .errorType('json')
      .post({ appointments: body })
      .unauthorized(() => {
        return thunkAPI.rejectWithValue(false);
      })
      .json(async (json) => {
        const { data } = json;

        let price = 0;

        if (json.data[0].service.price) {
          price = json.data[0].service.price;
        }

        ReactPixel.track('Purchase', { value: price, currency: 'PLN' });

        TagManager.dataLayer({
          dataLayer: {
            event: 'click_potwierdz',
          },
        });

        return data;
      })
      .catch((error) => {
        console.log(error);
        return thunkAPI.rejectWithValue(error);
      });
  });

const SUBMIT_RESERVATION_WITHOUT_LOGGIN = 'reservation/submitWithoutLoggin';
export const submitReservationWithoutLoggin: AsyncThunk<
  any,
  void,
  { state: RootState }
> = createAsyncThunk(SUBMIT_RESERVATION_WITHOUT_LOGGIN, async (_, thunkAPI) => {
  const { user, phoneNumber, dogName } = (thunkAPI.getState() as RootState)
    .client;

  const reservations = (thunkAPI.getState() as RootState).reservation
    .reservations;

  const body = reservations.map(({ service, time, slotId }) => {
    const basicPayload = {
      serviceId: service.id,
      date: time?.map((t) => t / 1000),
      email: user?.email,
      phone: phoneNumber,
      note: dogName,
    };

    if (service.category?.appointmentType === 'range') {
      return basicPayload;
    }

    return {
      ...basicPayload,
      slotId,
    };
  });

  return await client('appointments')
    .errorType('json')
    .post({ appointments: body })
    .unauthorized(() => {
      return thunkAPI.rejectWithValue(false);
    })
    .json(async (json) => {
      const { data } = json;

      let price = 0;

      if (json.data[0].service.price) {
        price = json.data[0].service.price;
      }

      ReactPixel.track('Purchase', { value: price, currency: 'PLN' });

      TagManager.dataLayer({
        dataLayer: {
          event: 'click_potwierdz',
        },
      });

      return data;
    })
    .catch((error) => {
      console.log(error);
      return thunkAPI.rejectWithValue(error);
    });
});
const CLEAR_RESERVATION = 'reservation/clear';
export const clearReservation = createAction(CLEAR_RESERVATION);
