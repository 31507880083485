import styled, { css } from 'styled-components';
import media from '../../../theme/respondTo';

export const SMobileFilters = styled.div<{ categoriesLength: number }>`
  ${({ theme, categoriesLength }) => css`
    border-top: 1px solid rgba(237, 232, 233, 1);
    display: flex;
    flex-flow: column;
    row-gap: 16px;
    min-height: 60px;
    padding: 18px 23px;
    width: 100%;

    ${media.md`
    display: none;
  `}

    .default-view {
      align-items: center;
      display: flex;
      column-gap: 8px;
    }

    .filter-buttons {
      align-items: center;
      color: ${theme.colors.black};
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
    }

    .filter-button {
      align-items: center;
      background-color: transparent;
      border: 1px solid #ede8e9;
      border-radius: 25px;
      color: #696f8c;
      display: flex;
      justify-content: space-between;
      line-height: 28px;
      padding: 6px 10px;
      position: relative;
      text-align: left;

      &--category {
        ${categoriesLength > 0 &&
        css`
          border: 1px solid ${theme.colors.primary};
        `}
      }
    }

    .selected-filters-length {
      align-items: center;
      background-color: ${theme.colors.primary};
      border-radius: 50%;
      display: flex;
      color: #fff;
      height: 18px;
      justify-content: center;
      font-size: 9px;
      position: absolute;
      right: 0;
      width: 18px;
      top: -8px;
    }

    .map-button {
      align-items: center;
      border: 1px solid #ede8e9;
      border-radius: 50%;
      display: flex;
      height: 40px;
      justify-content: center;
      width: 40px;
      padding: 0;
    }

    .categories-list {
      display: flex;
      flex-flow: row wrap;
      column-gap: 16px;
      row-gap: 21px;
    }

    .filters-overlay {
      background-color: #fff;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      ${media.md`
    display: none;
  `}

      &__voivodeship {
        padding: 16px 23px 0;
        width: 100%;
      }

      &__header {
        align-items: center;
        display: flex;
        color: ${theme.colors.black};
        font-weight: 500;
        font-size: 20px;
        justify-content: space-between;
        padding: 22px 23px 12px;
        width: 100%;
      }

      &__close {
        background-color: transparent;
        border: none;
        cursor: pointer;
        align-items: center;
        display: flex;
        padding: 0;
        justify-content: center;
      }

      &__filters {
        display: flex;
        padding: 16px 23px 0;
        column-gap: 24px;
      }

      &__categories-wrapper {
        margin: 0 23px;
      }

      &__categories {
        display: flex;
        flex-flow: column;
        margin: 11px 0 220px;
        height: 450px;
        overflow: scroll;
      }
    }

    .pick-all-button {
      background: rgba(97, 126, 169, 0.15);
      color: #727272;
      border-radius: 40px;
      line-height: 28px;
      padding: 6px 18px;
    }

    .cities-wrapper {
      display: flex;
      flex-flow: column;
      height: 100%;
    }

    .cities-list {
      padding: 8px 25px 0;
      margin: 0 0 220px;
      height: 450px;
      overflow: scroll;
    }

    .button-wrapper {
      align-items: center;
      background-color: #fff;
      box-shadow: 0 -3px 27px 0px rgba(0, 0, 0, 0.07);
      bottom: 0;
      left: 0;
      display: flex;
      height: 74px;
      justify-content: center;
      padding: 0 23px;
      position: absolute;
      width: 100%;
      z-index: 2;
    }

    .submit-button {
      align-items: center;
      background-color: ${theme.colors.primary};
      border-radius: 40px;
      color: ${theme.colors.white};
      display: flex;
      font-weight: 500;
      height: 40px;
      justify-content: center;
      position: relative;
      width: 100%;
    }

    .selected-amount {
      align-items: center;
      background-color: #fff;
      border-radius: 50%;
      display: flex;
      color: ${theme.colors.primary};
      font-size: 14px;
      font-weight: 500;
      justify-content: center;
      height: 25px;
      position: absolute;
      right: 7px;
      width: 25px;
    }

    .map-filters {
      display: flex;
      column-gap: 16px;
      overflow-x: scroll;
      padding: 0 0 16px 24px;
      width: 100%;
    }

    .show-map {
      background-color: #fff;
      border: 1px solid #ede8e9;
      border-radius: 24px;
      color: #696f8c;
      cursor: pointer;
      line-height: 28px;
      padding: 6px 0;
      width: 100%;
    }
  `}
`;
