import React from 'react';
import type { Slot } from '../../const';
import EmployeeAvatarPlaceholder from '../../components/icons/EmployeeAvatarPlaceholder';
import { SEmployee } from './Employee.styled';

interface IEmployeeProps extends Slot {
  isLast: boolean;
}

const Employee = ({ name, avatar, isLast }: IEmployeeProps) => (
  <SEmployee isLast={isLast}>
    <div className="employee__image-wrapper">
      {!!avatar ? (
        <img
          className="employee__avatar"
          src={`https://javol.smallhost.pl/storage/${avatar}`}
          alt={name}
        />
      ) : (
        <EmployeeAvatarPlaceholder />
      )}
    </div>
    <p className="employee__name">{name}</p>
  </SEmployee>
);

export default Employee;
