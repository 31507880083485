import React from 'react';

const color = '#D17E02';
const activeColor = '#fff';

const PhotographerIcon = ({
  active,
  size = 15,
}: {
  active?: boolean;
  size?: number;
}) => (
  <svg
    width={`${size}px`}
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1825_1326)">
      <path
        d="M9.50019 3.94336C8.63717 3.94336 7.79354 4.19368 7.07597 4.66268C6.35841 5.13168 5.79913 5.79828 5.46887 6.57819C5.13861 7.3581 5.0522 8.2163 5.22056 9.04425C5.38893 9.8722 5.80451 10.6327 6.41475 11.2296C7.02499 11.8266 7.80248 12.2331 8.64891 12.3977C9.49534 12.5624 10.3727 12.4779 11.17 12.1549C11.9673 11.8318 12.6488 11.2847 13.1283 10.5828C13.6077 9.88094 13.8636 9.05573 13.8636 8.21156C13.8623 7.07997 13.4021 5.99511 12.5841 5.19495C11.7661 4.39479 10.657 3.94468 9.50019 3.94336ZM9.41322 5.36693C8.69042 5.36792 7.9975 5.64922 7.4864 6.14917C6.97529 6.64911 6.68771 7.3269 6.6867 8.03393C6.68196 8.1016 6.65113 8.16499 6.60045 8.21129C6.54977 8.25759 6.48301 8.28334 6.41366 8.28334C6.34431 8.28334 6.27755 8.25759 6.22687 8.21129C6.17619 8.16499 6.14536 8.1016 6.14062 8.03393C6.14164 7.18512 6.48671 6.37134 7.10019 5.77102C7.71366 5.17071 8.54547 4.83285 9.41322 4.83153C9.48241 4.83616 9.54721 4.86631 9.59454 4.91589C9.64187 4.96546 9.6682 5.03077 9.6682 5.0986C9.6682 5.16643 9.64187 5.23174 9.59454 5.28131C9.54721 5.33089 9.48241 5.36104 9.41322 5.36568V5.36693Z"
        fill={active ? activeColor : color}
      />
      <path
        d="M18.1317 2.76207H14.6583L14.2043 0.705527C14.2048 0.613263 14.1867 0.521811 14.151 0.436397C14.1153 0.350983 14.0628 0.273287 13.9964 0.207755C13.93 0.142224 13.8511 0.0901448 13.7641 0.0544957C13.6771 0.0188465 13.5837 0.000327608 13.4894 0H5.5106C5.41638 0.000491511 5.32319 0.0191321 5.23634 0.0548545C5.14949 0.0905769 5.07068 0.14268 5.00442 0.208194C4.93815 0.273709 4.88573 0.35135 4.85014 0.436683C4.81455 0.522016 4.79649 0.613368 4.797 0.705527L4.33661 2.76207H0.869626C0.390054 2.76207 0 3.17613 0 3.68526V14.0768C0 14.5872 0.390054 15 0.869626 15H18.1342C18.6138 15 19.0026 14.5872 19.0026 14.0768V3.68526C19 3.17613 18.6112 2.76207 18.1317 2.76207ZM9.50064 13.0123C8.52993 13.012 7.5811 12.7302 6.77411 12.2025C5.96711 11.6748 5.33821 10.9249 4.96691 10.0476C4.59561 9.17029 4.4986 8.20498 4.68813 7.27374C4.87766 6.3425 5.34523 5.48715 6.03171 4.81583C6.71819 4.14451 7.59276 3.68737 8.54483 3.50222C9.49691 3.31707 10.4837 3.41222 11.3805 3.77564C12.2773 4.13906 13.0438 4.75443 13.5831 5.54394C14.1223 6.33345 14.4102 7.26164 14.4102 8.21116C14.4088 9.4843 13.8911 10.7049 12.9706 11.605C12.0502 12.5052 10.8022 13.0113 9.50064 13.0123Z"
        fill={active ? activeColor : color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1825_1326">
        <rect width="19" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PhotographerIcon;
