import { createReducer } from '@reduxjs/toolkit';
import { Category, District, LinkGroup, Salon, Service } from 'const';
import {
  getCategories,
  getHomepage,
  getServices,
  setSalonRegistrationProcess,
  getCities,
  showAppModal,
  closeAppModal,
} from './appActions';

import heroBackground from '../../../assets/images/hero-background.jpg';
import { AppModalDialogPayload } from '../../../types/index';

interface AppState {
  salonAuthRegistrationProcess: boolean;
  appDialogModal: AppModalDialogPayload;
  homepage: {
    loading: boolean;
    hero: {
      background: string;
      heading: string;
    };
    categories: Category[];
    links: LinkGroup[];
    appLinks: { [key: string]: string };
    cities: { id: number; name: string }[];
    districts: District[];
    promoted: Salon[];
    recommendedServices: {
      data: Service[];
      page: number;
      loading: boolean;
      endOfList: boolean;
    };
  };

  categories: Category[];
  categoriesLoading: boolean;
}

const initialState: AppState = {
  salonAuthRegistrationProcess: false,
  categories: [],
  categoriesLoading: false,
  appDialogModal: {
    appModalVisible: false,
    appModalType: 'success',
    appModalHeader: '',
    appModalMessage: '',
    appModalButtonText: '',
    appModalCloseType: undefined,
  },
  homepage: {
    loading: false,
    hero: {
      background: heroBackground,
      heading: 'Znajdź najlepszą usługę dla Twojego pupila',
    },
    categories: [],
    links: [],
    appLinks: {},
    districts: [],
    cities: [],
    promoted: [],
    recommendedServices: {
      data: [],
      page: 1,
      loading: false,
      endOfList: false,
    },
  },
};

export default createReducer<AppState>(initialState, {
  [showAppModal.type]: (state, action) => ({
    ...state,
    appDialogModal: {
      ...state.appDialogModal,
      ...action.payload,
    },
  }),
  [closeAppModal.type]: (state) => ({
    ...state,
    appDialogModal: {
      ...state.appDialogModal,
      appModalVisible: false,
      appModalMessage: '',
      appModalButtonText: '',
      appModalCloseType: undefined,
    },
  }),
  [setSalonRegistrationProcess.type]: (state, action) => ({
    ...state,
    salonAuthRegistrationProcess: action.payload,
  }),
  [getCities.fulfilled.toString()]: (state, action) => ({
    ...state,
    homepage: {
      ...state.homepage,
      cities: action.payload,
    },
  }),
  [getCategories.pending.toString()]: (state) => ({
    ...state,
    categoriesLoading: true,
  }),
  [getCategories.fulfilled.toString()]: (state, action) => ({
    ...state,
    categories: action.payload,
    categoriesLoading: false,
  }),
  [getCategories.rejected.toString()]: (state) => ({
    ...state,
    categoriesLoading: false,
  }),
  [getHomepage.pending.toString()]: (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      loading: true,
    },
  }),
  [getHomepage.fulfilled.toString()]: (state, action) => ({
    ...state,
    homepage: {
      ...state.homepage,
      hero: action?.payload?.hero ?? { ...state.homepage.hero },
      categories: action.payload?.categories ?? [],
      links: action.payload?.links ?? [],
      appLinks: action.payload?.appLinks ?? {},
      districts: action.payload?.districts ?? [],
      promoted: action.payload?.promoted ?? [],
      recommendedServices: {
        ...state.homepage.recommendedServices,
        data: action.payload?.recommendedServices ?? [],
        page: state.homepage.recommendedServices.page + 1,
      },
      loading: false,
    },
  }),
  [getHomepage.rejected.toString()]: (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      loading: false,
    },
  }),
  [getServices.pending.toString()]: (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      recommendedServices: {
        ...state.homepage.recommendedServices,
        loading: true,
      },
    },
  }),
  [getServices.fulfilled.toString()]: (state, action) => ({
    ...state,
    homepage: {
      ...state.homepage,
      recommendedServices: {
        ...state.homepage.recommendedServices,
        data: [
          ...state.homepage.recommendedServices.data,
          ...action.payload.data,
        ],
        page: state.homepage.recommendedServices.page + 1,
        loading: false,
        endOfList: action.payload.endOfList,
      },
    },
  }),
  [getServices.rejected.toString()]: (state) => ({
    ...state,
    homepage: {
      ...state.homepage,
      recommendedServices: {
        ...state.homepage.recommendedServices,
        loading: false,
      },
    },
  }),
});
