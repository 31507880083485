import React from 'react';
import { SSliderArrow } from './SliderArrow.styled';

type Direction = 'left' | 'right';

interface SliderArrowProps {
  direction: Direction;
  onClick: (direction: Direction) => void;
}

const SliderArrow = ({ direction, onClick }: SliderArrowProps) => {
  if (direction === 'left') {
    return (
      <SSliderArrow onClick={() => onClick('left')}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 32L16.4476 31.9938C25.0729 31.7565 32 24.6823 32 16C32 7.184 24.832 -2.17088e-06 16 -1.39876e-06C7.184 -6.28045e-07 -2.16948e-06 7.184 -1.39876e-06 16C-6.26646e-07 24.832 7.184 32 16 32ZM19.168 22.4C18.688 22.88 17.936 22.88 17.472 22.4L11.888 16.848C11.664 16.624 11.536 16.32 11.536 16C11.536 15.68 11.664 15.376 11.888 15.152L17.472 9.6C17.696 9.36 18 9.248 18.304 9.248C18.624 9.248 18.928 9.36 19.168 9.6C19.632 10.08 19.632 10.832 19.152 11.296L14.432 16L19.152 20.704C19.632 21.168 19.632 21.936 19.168 22.4Z"
            fill="#8F95B2"
          />
        </svg>
      </SSliderArrow>
    );
  }

  return (
    <SSliderArrow onClick={() => onClick('right')}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 32L15.5524 31.9938C6.92708 31.7565 6.39733e-07 24.6823 1.39876e-06 16C2.16948e-06 7.184 7.168 -2.17088e-06 16 -1.39876e-06C24.816 -6.28045e-07 32 7.184 32 16C32 24.832 24.816 32 16 32ZM12.832 22.4C13.312 22.88 14.064 22.88 14.528 22.4L20.112 16.848C20.336 16.624 20.464 16.32 20.464 16C20.464 15.68 20.336 15.376 20.112 15.152L14.528 9.6C14.304 9.36 14 9.248 13.696 9.248C13.376 9.248 13.072 9.36 12.832 9.6C12.368 10.08 12.368 10.832 12.848 11.296L17.568 16L12.848 20.704C12.368 21.168 12.368 21.936 12.832 22.4Z"
          fill="#8F95B2"
        />
      </svg>
    </SSliderArrow>
  );
};

export default SliderArrow;
