import React from 'react';

const BenefitIcon = () => (
  <svg
    width="92"
    height="92"
    viewBox="0 0 92 92"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59.6029 58.6137C59.6129 58.6137 59.6209 58.6218 59.6209 58.6317C59.6093 61.247 57.0981 63.3934 53.9787 63.3934H35.2193C32.0928 63.3934 29.5771 61.273 29.5771 58.6137V4.88711C29.5771 2.26367 32.0928 0.107422 35.2193 0.107422H53.9428C57.0693 0.107422 59.585 2.22773 59.585 4.88711V58.5957C59.585 58.6056 59.593 58.6137 59.6029 58.6137Z"
      fill="#FF6647"
      fillOpacity="0.3"
    />
    <path
      d="M78.8107 33.2777C76.8701 33.2777 75.2529 31.6965 75.2529 29.7199C75.2529 27.7793 76.8342 26.1621 78.8107 26.1621C80.7514 26.1621 82.3686 27.7434 82.3686 29.7199C82.3686 31.6605 80.7873 33.2777 78.8107 33.2777ZM78.8107 27.5996C77.6607 27.5996 76.6904 28.534 76.6904 29.7199C76.6904 30.8699 77.6248 31.8402 78.8107 31.8402C79.9967 31.8402 80.9311 30.9059 80.9311 29.7199C80.9311 28.5699 79.9967 27.5996 78.8107 27.5996Z"
      fill="#3A6AD5"
    />
    <path
      d="M22.1367 79.0977H20.6992V88.5133H22.1367V79.0977Z"
      fill="#304659"
    />
    <path
      d="M26.1266 83.0879H16.7109V84.5254H26.1266V83.0879Z"
      fill="#304659"
    />
    <path
      d="M67.5986 34.7871H66.1611V42.8371H67.5986V34.7871Z"
      fill="#304659"
    />
    <path
      d="M52.1094 78.2723H29.9359C26.4141 78.2723 23.5391 75.3973 23.5391 71.8754V10.7816C23.5391 7.25977 26.4141 4.38477 29.9359 4.38477H61.2016C64.7234 4.38477 67.5984 7.25977 67.5984 10.7816V24.4379H66.1609V10.7816C66.1609 8.05039 63.9328 5.82227 61.2016 5.82227H29.9359C27.2047 5.82227 24.9766 8.05039 24.9766 10.7816V71.8754C24.9766 74.6066 27.2047 76.8348 29.9359 76.8348H52.1094V78.2723Z"
      fill="#304659"
    />
    <path
      d="M54.8049 68.9281H29.1455V13.6562H61.9924V33.5656H60.5549V15.0938H30.583V67.4906H54.8049V68.9281Z"
      fill="#304659"
    />
    <path
      d="M45.1375 76.0797C43.3406 76.0797 41.8672 74.6063 41.8672 72.8094C41.8672 71.0125 43.3406 69.5391 45.1375 69.5391C46.9344 69.5391 48.4078 71.0125 48.4078 72.8094C48.4078 74.6063 46.9344 76.0797 45.1375 76.0797ZM45.1375 71.0125C44.1312 71.0125 43.3047 71.8391 43.3047 72.8453C43.3047 73.8516 44.1312 74.6781 45.1375 74.6781C46.1437 74.6781 46.9703 73.8516 46.9703 72.8453C46.9344 71.8031 46.1437 71.0125 45.1375 71.0125Z"
      fill="#304659"
    />
    <path
      d="M52.0379 11.2844H39.0645V7.72656H52.0379V11.2844ZM40.502 9.84687H50.6004V9.2H40.502V9.84687Z"
      fill="#304659"
    />
    <path
      d="M23.9697 71.5879C20.4838 71.4082 16.1713 71.1566 16.315 66.7004C16.351 65.802 16.7103 65.0113 17.3931 64.4004C19.4056 62.6035 23.6822 62.927 23.8619 62.927L23.7541 64.3645C22.676 64.2926 19.6572 64.3285 18.3635 65.4785C17.9681 65.802 17.7885 66.2332 17.7525 66.7363C17.6447 69.3598 19.5135 69.8629 24.0416 70.1145L23.9697 71.5879Z"
      fill="#304659"
    />
    <path
      d="M23.9696 62.8176C20.4837 62.6379 16.1353 62.4223 16.315 57.966C16.3509 57.0676 16.7103 56.277 17.3931 55.666C19.4056 53.8691 23.6821 54.1926 23.8618 54.1926L23.754 55.6301C22.7118 55.5582 19.6571 55.5941 18.3634 56.7441C17.9681 57.0676 17.7884 57.4988 17.7525 58.002C17.6446 60.6254 19.5134 61.1285 24.0415 61.3801L23.9696 62.8176Z"
      fill="#304659"
    />
    <path
      d="M23.9696 54.05C20.4837 53.8703 16.1353 53.6547 16.315 49.1984C16.3509 48.3 16.7103 47.5094 17.3931 46.8984C19.4056 45.1016 23.6821 45.425 23.8618 45.425L23.754 46.8625C22.7118 46.7906 19.6571 46.8266 18.3634 47.9766C17.9681 48.3 17.7884 48.7313 17.7525 49.2344C17.6446 51.8578 19.5134 52.3609 24.0415 52.6125L23.9696 54.05Z"
      fill="#304659"
    />
    <path
      d="M24.0425 47.1846C23.2878 46.6815 22.2456 46.1424 21.1316 45.5315C16.855 43.2315 11.5362 40.3565 13.2612 36.6549C13.6566 35.7924 14.3394 35.1815 15.3097 34.894C18.6519 33.8518 24.4378 37.0862 24.6534 37.2299L23.9347 38.4877C22.4253 37.6252 18.005 35.5409 15.7409 36.2596C15.2019 36.4393 14.8066 36.7627 14.5909 37.2659C13.4409 39.7456 18.2925 42.369 21.8503 44.2737C22.9644 44.8846 24.0425 45.4596 24.8691 45.9987L24.0425 47.1846Z"
      fill="#304659"
    />
    <path
      d="M77.1219 91.9992L43.1609 91.9273V88.8008C41.0406 88.082 33.3859 85.0273 31.4453 77.6242L32.8469 77.2648C34.8594 84.8836 43.9875 87.543 44.0953 87.5789L44.6344 87.7227V90.4898H56.7812C52.2172 74.3898 61.2375 64.9383 62.8906 63.357V57.032H56.1344C53.6187 56.7445 52.0016 55.8102 51.2469 54.2289C49.7016 51.0305 52.4688 46.5383 52.5766 46.3586L52.7922 46.0352H70.4016L77.1219 57.607V91.9992ZM58.2906 90.4898L75.7203 90.5617V57.9664L69.6109 47.4367H53.6547C53.1156 48.443 51.6063 51.5336 52.5766 53.582C53.1156 54.6961 54.3375 55.343 56.2422 55.5945H64.3281V64.0039L64.0766 64.2195C63.6094 64.6148 53.2594 73.5633 58.2906 90.4898Z"
      fill="#304659"
    />
  </svg>
);

export default BenefitIcon;
