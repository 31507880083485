import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import config from 'const/config';

import app from './reducers/app/appReducer';
import client from './reducers/client/clientReducer';
import user from './reducers/user/userReducer';
import salons from './reducers/salons/salonsReducer';
import gallery from './reducers/gallery/galleryReducer';
import reservation from './reducers/reservation/reservationReducer';

const reducer = combineReducers({
  app,
  client,
  user,
  salons,
  gallery,
  reservation,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
  devTools: config.APP_ENV === 'dev',
});

const createStore = () => store;

export type RootState = ReturnType<typeof store.getState>;

export default createStore;
