import React from 'react';

const SliderArrow = ({ direction }: { direction: 'left' | 'right' }) => (
  <svg
    width="11"
    height="21"
    viewBox="0 0 11 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: direction === 'left' ? 'rotate(0)' : 'rotate(180deg)' }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2832 0.306666C10.6921 0.715554 10.6921 1.37849 10.2832 1.78738L2.19611 9.87444C2.05982 10.0107 2.05982 10.2317 2.19611 10.368L10.2832 18.4551C10.6921 18.864 10.6921 19.5269 10.2832 19.9358C9.87429 20.3447 9.21135 20.3447 8.80246 19.9358L0.715396 11.8487C-0.238678 10.8947 -0.238675 9.3478 0.715397 8.39373L8.80246 0.306666C9.21135 -0.102222 9.87429 -0.102222 10.2832 0.306666Z"
      fill="#8B99A6"
    />
  </svg>
);

export default SliderArrow;
