import React from 'react';

const NavigationIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.075 30.5212L29.845 12.2562C30.3417 10.9162 29.0367 9.61119 27.6967 10.1079L9.42332 16.8829C7.88332 17.4545 8.00499 19.6712 9.59665 20.0712L17.83 22.1395L19.885 30.3462C20.285 31.9395 22.5033 32.0612 23.075 30.5212V30.5212Z"
      stroke="#FF6B00"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NavigationIcon;
