import { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'Root';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Button } from 'components';
import { SGallery } from './Gallery.styled';
import useBlockScroll from 'utils/useBlockScroll';
import { clearGalleryImages } from 'state/reducers/gallery/galleryActions';
import { Image } from 'const';

const Gallery: FC = () => {
  const dispatch = useAppDispatch();
  const { images, defaultActive } = useAppSelector(({ gallery }) => gallery);

  const [controlledSwiper, setControlledSwiper] = useState<SwiperClass | null>(
    null
  );

  const [activeIndex, setActiveIndex] = useState<number>(0);
  useBlockScroll(images.length);

  const closeGallery = () => {
    dispatch(clearGalleryImages());
  };

  const handleGalleryPrev = () => {
    controlledSwiper?.slidePrev();
  };

  const handleGalleryNext = () => {
    controlledSwiper?.slideNext();
  };

  const handleInitSwiper = (swiper: SwiperClass) => {
    setControlledSwiper(swiper);
    swiper.slideTo(
      images.findIndex((image: Image) => image.id === defaultActive?.id)
    );
  };

  const updateActiveIndex = (swiper: SwiperClass) => {
    setActiveIndex(swiper.activeIndex);
  };

  if (!images.length) return null;
  return (
    <SGallery>
      <div className="gallery-header">
        <span className="gallery-count">
          <strong>{activeIndex + 1}</strong> / {images.length}
        </span>

        <Button type="button" className="gallery-close" onClick={closeGallery}>
          <span className="material-icons">close</span>
          Zamknij
        </Button>
      </div>

      <div className="gallery-swiper">
        <Swiper
          onSwiper={handleInitSwiper}
          slidesPerView={1}
          centeredSlides
          onSlideChange={updateActiveIndex}
        >
          {images?.map((image: Image) => (
            <SwiperSlide key={image.path}>
              <img src={image.path} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="gallery-swiper-arrows">
          <button type="button" onClick={handleGalleryPrev}>
            <span className="material-icons">arrow_back</span>
          </button>
          <button type="button" onClick={handleGalleryNext}>
            <span className="material-icons">arrow_forward</span>
          </button>
        </div>
      </div>
    </SGallery>
  );
};

export default Gallery;
