import React, { useMemo, useState, useRef, useEffect } from 'react';
import ReactMapGL, { WebMercatorViewport } from 'react-map-gl';
import config from '../../const/config';
import MarkersCluster from '../Shared/MarkersCluster';
import MapMarker from '../Shared/MapMarker';
import useSupercluster from 'use-supercluster';
import { useAppDispatch, useAppSelector } from '../../Root';
import { IPickedMarker } from '../../types';
import { POLAND_CENTER_COORDINATES } from '../../const/predefined-cities';
import { setSingleSalon } from '../../state/reducers/salons/salonsActions';
import MarkerDetails from '../Shared/MarkerDetails/MarkerDetails';

interface Cluster {
  type: 'Feature';
  id?: number;
  properties: {
    cluster: boolean;
    salonId: number;
    cluster_id?: number;
    point_count: number;
    point_count_abbreviated: number;
    categories?: string[];
    name: string;
    address: string;
  };
  geometry: { type: 'Point'; coordinates: number[] };
}

interface MapProps {
  points: any;
  bounds: any;
}

const DEFAULT_LOCATION = { latitude: 50.980726, longitude: 15.894682 };

const DiscoverMap = ({ points, bounds }: MapProps) => {
  const mapRef = useRef(null);
  const [pickedSalon, setPickedSalon] = useState<IPickedMarker | null>(null);

  const dispatch = useAppDispatch();

  const search = useAppSelector(({ salons }) => salons.search);

  const view = new WebMercatorViewport({
    width: 800,
    height: 600,
  }).fitBounds(bounds);

  const [viewport, setViewport] = useState({
    ...view,
    ...DEFAULT_LOCATION,
    zoom: 6,
  });

  useEffect(() => {
    setViewport({ ...viewport, ...view });
  }, [bounds]);

  useEffect(() => {
    if (points.length > 0) return;

    setViewport({
      ...view,
      ...POLAND_CENTER_COORDINATES,
      zoom: 5.5,
    });
  }, [points]);

  const { clusters } = useSupercluster({
    points,
    zoom: viewport.zoom,
    // @ts-ignore
    bounds: mapRef.current?.getMap().getBounds().toArray().flat(),
    options: { radius: 100, maxZoom: 7 },
  });

  const zoomIn = () => {
    setViewport((prevState) => ({
      ...viewport,
      zoom: prevState.zoom + 0.5,
    }));
  };

  const zoomOut = () => {
    setViewport((prevState) => ({
      ...viewport,
      zoom: prevState.zoom - 0.5,
    }));
  };

  const allClusters: Cluster[] = useMemo(() => {
    return clusters;
  }, [clusters, search]);

  return (
    <div className="map-wrapper">
      {pickedSalon && (
        <MarkerDetails
          id={0}
          name={pickedSalon.name}
          address={`${pickedSalon.salon.city?.name}, ${pickedSalon.salon.street} ${pickedSalon.salon.buildingNumber}`}
          onCloseClick={() => setPickedSalon(null)}
          onLinkClick={() =>
            dispatch(setSingleSalon({ base: pickedSalon?.salon }))
          }
        />
      )}

      <div className="map-controls">
        <button className="control-button" onClick={zoomIn}>
          +
        </button>
        <button className="control-button" onClick={zoomOut}>
          -
        </button>
      </div>
      <ReactMapGL
        {...viewport}
        height={640}
        width="100%"
        mapStyle="mapbox://styles/mapbox/streets-v11"
        mapboxApiAccessToken={config.MAPBOX_TOKEN}
        minZoom={4.5}
        maxZoom={24}
        doubleClickZoom
        onViewportChange={(updated: any) => {
          setViewport(updated);
        }}
        ref={mapRef}
      >
        {allClusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster } = cluster.properties;

          if (isCluster) {
            return (
              <MarkersCluster
                key={`salon-marker-${cluster.properties?.cluster_id}`}
                latitude={latitude}
                longitude={longitude}
                pointsCount={cluster.properties.point_count}
                onClick={(coords) => {
                  setViewport({
                    ...view,
                    ...coords,
                    zoom: 9.5,
                  });
                }}
              />
            );
          }
          return (
            <MapMarker
              key={`salon-marker-${cluster.properties?.salonId}-${cluster.properties?.categories}`}
              latitude={latitude}
              longitude={longitude}
              categories={cluster.properties?.categories || ['']}
              {...cluster.properties}
              selected={pickedSalon?.name === cluster.properties.name}
              onClick={(coords, salon) => {
                console.log('coords', coords);
                setPickedSalon({
                  ...coords,
                  name: salon.name,
                  salon,
                });
              }}
              onClose={() => setPickedSalon(null)}
            />
          );
        })}
      </ReactMapGL>
    </div>
  );
};

export default DiscoverMap;
