import React, { useState } from 'react';
import config from '../const/config';
import client from '../api/client';
import { Event } from '../types';
import { useAppDispatch } from '../Root';
import {
  deleteAppointment,
  updateAppointment,
  createAppointment,
} from '../state/reducers/user/userActions';
import { AppointmentCreator, AppointmentDTO, Slot } from '../const';
import { DateTime } from 'luxon';
import autoFormatTimeInput from '../utils/autoFormatTimeInput';
import zeroPad from '../utils/zeroPad';
import { AppointmentClickEvent, CellClickEvent } from 'devextreme/ui/scheduler';
import { getHours, getMinutes, format } from 'date-fns';

interface AppointmentPayload extends AppointmentCreator {
  companyId?: number;
}

interface Payload {
  id: number;
  name: string;
  email: string;
  phone: string;
  service: any;
}

export const useCalendarEvents = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [appointment, setAppointment] = useState<AppointmentCreator | null>(
    null
  );
  const [currentDate, setCurrentDate] = useState<{
    start: number;
    end: number;
  }>({ start: 0, end: 0 });
  const [activeSlot, setActiveSlot] = useState<number | null>(null);

  const dispatch = useAppDispatch();

  const clearAppointment = () => setAppointment(null);

  const setDateRange = (action: any) => {
    setCurrentDate({
      start: action?.lastDateRange?.startDate,
      end: action?.lastDateRange?.endDate,
    });
  };

  const updateActiveSlot = (slot: Slot | null) => {
    setActiveSlot(slot?.id ?? null);
  };

  const handleDeleteAppointment = async () => {
    if (!appointment?.id) return;

    setDeleteLoading(true);
    // @ts-ignore
    const { payload } = await dispatch(deleteAppointment(appointment.id));

    if (payload) {
      await getEvents(activeSlot, currentDate);
      clearAppointment();
    }

    setDeleteLoading(false);
  };

  const addAppointment = () => {
    setAppointment({
      appointmentKind: 'normal',
      additionalNote: '',
      email: '',
      phone: '',
      date: new Date(),
      timeStart: '',
      timeEnd: '',
      service: null,
    });
  };

  const getEvents = async (
    activeSlot: number | null,
    { start, end }: { start: number; end: number }
  ) => {
    const token = localStorage.getItem(config.TOKEN_KEY);
    if (!token) return;

    let slotsParams = '';

    if (activeSlot !== 0) {
      slotsParams = `&filters[slots][]=${activeSlot}`;
    } else {
      slotsParams = `&filters[range]=1`;
    }

    client(
      `provider/appointments?filters[startTime]=${start}&filters[endTime]=${end}${slotsParams}`
    )
      .auth(`Bearer ${token}`)
      .errorType('json')
      .get()
      .unauthorized(() => {
        return;
      })
      .json((json) => {
        const { data } = json;

        setEvents(data);
        return data;
      })
      .catch(() => {
        return;
      });
  };

  const updateCalendarAppointment = async (
    values: AppointmentPayload,
    serviceType: 'normal' | 'range'
  ) => {
    if (!appointment || !activeSlot || !values.timeStart) return;

    const PHONE_REGEX = /^[0-9]*$/;

    if (!!values.phone && !PHONE_REGEX.test(values.phone)) {
      return;
    }
    if (!!values.phone && values.phone.length !== 9) {
      return;
    }

    const [startHour, startMinutes] = values.timeStart.split(':');
    const timeStart = values.date
      ? DateTime.fromJSDate(values.date, { zone: 'Europe/Warsaw' })
          .set({
            hour: parseInt(startHour),
            minute: parseInt(startMinutes),
          })
          .toMillis() / 1000
      : 0;

    const [endHour, endMinutes] = values.timeEnd.split(':');
    const timeEnd = values.date
      ? DateTime.fromJSDate(values.date, { zone: 'Europe/Warsaw' })
          .set({
            hour: parseInt(endHour),
            minute: parseInt(endMinutes),
          })
          .toMillis() / 1000
      : 0;

    const payload: AppointmentDTO = {
      ...values,
      appointmentKind: serviceType,
      timeStart,
      timeEnd,
      slotId: activeSlot,
    };

    const conditionalPayload = values?.service?.id
      ? { ...payload, serviceId: values.service.id }
      : payload;

    if (appointment.id) {
      conditionalPayload.id = appointment?.id;
      await dispatch(updateAppointment(conditionalPayload));
    } else {
      await dispatch(createAppointment(conditionalPayload));
    }

    await getEvents(activeSlot, currentDate);
    clearAppointment();
  };

  const clickDate = (arg: any) => {
    const timeStart = arg?.date
      ? autoFormatTimeInput(
          `${zeroPad(arg?.date.getHours(), 2)}:${zeroPad(
            arg?.date.getMinutes(),
            2
          )}`
        )
      : '';

    setAppointment({
      appointmentKind: 'normal',
      additionalNote: '',
      date: arg.date,
      timeStart,
      email: '',
      phone: '',
      timeEnd: '',
      service: null,
    });
  };

  const clickDayCell = (event: CellClickEvent) => {
    const hour = getHours(new Date(event.cellData.startDate));
    const minutes = getMinutes(new Date(event.cellData.startDate));

    const pickedTime = `${hour < 10 ? `0${hour}` : hour}:${
      minutes === 0 ? '00' : minutes
    }`;

    setAppointment({
      appointmentKind: 'normal',
      additionalNote: '',
      date: event.cellData.startDate,
      timeStart: pickedTime,
      email: '',
      phone: '',
      timeEnd: '',
      service: null,
    });
  };

  const clickEvent = (event: AppointmentClickEvent) => {
    const data = event.appointmentData.html as string;

    if (!data) return;

    const payload: Payload = JSON.parse(data);

    const startDate = format(event.appointmentData.startDate as Date, 'HH:mm');
    const endDate = format(event.appointmentData.endDate as Date, 'HH:mm');

    setAppointment({
      id: payload.id.toString(),
      appointmentKind: 'normal',
      additionalNote: payload.name,
      date: (event.appointmentData.startDate as Date) || null,
      timeStart: startDate,
      email: payload.email,
      phone: payload.phone,
      timeEnd: endDate,
      service: payload.service,
    });
  };

  const eventClick = (data: any) => {
    const { startDate, endDate, id, name, service, email, phone } =
      data.eventRecord.data;

    const timeStart = startDate
      ? autoFormatTimeInput(
          `${zeroPad(startDate.getHours(), 2)}:${zeroPad(
            startDate.getMinutes(),
            2
          )}`
        )
      : '';
    const timeEnd = endDate
      ? autoFormatTimeInput(
          `${zeroPad(endDate.getHours(), 2)}:${zeroPad(
            endDate.getMinutes(),
            2
          )}`
        )
      : '';

    setAppointment({
      id,
      appointmentKind: 'normal',
      additionalNote: name,
      date: startDate,
      email,
      phone,
      timeStart,
      timeEnd,
      service,
    });
  };

  return {
    appointment,
    events,
    deleteLoading,
    currentDate,
    activeSlot,
    eventClick,
    clickDate,
    clickEvent,
    clickDayCell,
    setActiveSlot,
    updateActiveSlot,
    setDateRange,
    getEvents,
    addAppointment,
    handleDeleteAppointment,
    updateCalendarAppointment,
    clearAppointment,
  };
};
