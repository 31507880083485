import styled, { css } from 'styled-components';

export const SReservationEmployees = styled.ul`
  ${({ theme }) => css`
    align-items: flex-start;
    display: flex;
    margin-bottom: 32px;

    .random-employee {
      align-items: center;
      display: flex;
      flex-flow: column;

      &__name {
        color: #908e8e;
        font-size: 14px;
        line-height: 16px;
        margin-top: 12px;
        width: 61px;
      }
    }

    .wrapper {
      height: 40px;
      position: relative;
      width: 40px;
    }

    .selected-indicator {
      align-items: center;
      background-color: ${theme.colors.primary};
      outline: 2px solid ${theme.colors.white};
      border-radius: 50%;
      height: 12px;
      display: flex;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 12px;
    }

    .divider {
      background-color: #f6f3f4;
      display: block;
      height: 87px;
      margin: 0 20px;
      width: 1px;
    }
  `}
`;
