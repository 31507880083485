import React, { useState } from 'react';
import DropdownChevron from 'components/icons/DropdownChevron';
import OpeningHoursDay from './OpeningHoursDay';
import type { OpeningHour } from '../../../const';

interface OpeningHoursProps {
  openingHours?: OpeningHour[];
}

const OpeningHours = ({ openingHours }: OpeningHoursProps) => {
  const [isHoursOpen, setHoursOpen] = useState(true);

  if (!openingHours) return null;

  return (
    <div className="salon-details-sidebar-opening-hours">
      <div
        role="button"
        className="salon-details-sidebar-opening-hours-header"
        onClick={() => setHoursOpen((prevState) => !prevState)}
      >
        <h3 className="salon-details-sidebar-heading">Godziny otwarcia</h3>
        <DropdownChevron open={isHoursOpen} />
      </div>
      {isHoursOpen && (
        <ul className="salon-details-sidebar-hours">
          {openingHours?.map((hour) => {
            return <OpeningHoursDay key={hour.dayOfWeek} {...hour} />;
          })}
        </ul>
      )}
    </div>
  );
};

export default OpeningHours;
