import React from 'react';

const AvatarPlaceholder = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#F6F3F4" />
    <g clipPath="url(#clip0_2421_2110)">
      <path
        d="M20.5 37C22.6788 36.9988 24.8322 36.5418 26.8157 35.6596C28.7991 34.7774 30.5668 33.4904 32 31.885V30.0175C32.0006 29.1385 31.7322 28.2795 31.2294 27.551C30.7265 26.8225 30.0123 26.2579 29.1787 25.93L25.5447 24.4975C24.941 24.261 24.4235 23.8532 24.0587 23.3266C23.6939 22.8 23.4985 22.1786 23.4977 21.5425C24.9313 20.155 25.4987 17.7475 25.4987 15.3775C25.4987 12.6775 23.9653 10 20.5 10C17.0347 10 15.5013 12.6775 15.5013 15.3775C15.5013 17.7475 16.0687 20.155 17.5023 21.5425C17.5015 22.1786 17.3061 22.8 16.9413 23.3266C16.5765 23.8532 16.059 24.261 15.4553 24.4975L11.8213 25.93C10.9877 26.2579 10.2735 26.8225 9.77063 27.551C9.26781 28.2795 8.99942 29.1385 9 30.0175V31.885C10.4332 33.4904 12.2009 34.7774 14.1843 35.6596C16.1678 36.5418 18.3212 36.9988 20.5 37Z"
        fill="#C6D8D3"
      />
    </g>
    <defs>
      <clipPath id="clip0_2421_2110">
        <rect width="23" height="27" fill="white" transform="translate(9 10)" />
      </clipPath>
    </defs>
  </svg>
);

export default AvatarPlaceholder;
