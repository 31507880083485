import { AsyncThunk, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import client from 'api/client';
import { Service } from 'const';
import { RootState } from 'state/createStore';
import { AppModalDialogPayload } from 'types';

const SET_SALON_REGISTRATION_PROCESS = 'salon/registrationProcess';
export const setSalonRegistrationProcess = createAction<boolean>(
  SET_SALON_REGISTRATION_PROCESS
);

export const getCities = createAsyncThunk(
  'app/getCities',
  async (_, thunkAPI) => {
    return await client('platform/location/suggestions')
      .errorType('json')
      .get()
      .json((json) => {
        return json.data;
      })
      .catch(() => {
        return thunkAPI.rejectWithValue(true);
      });
  }
);

const SHOW_APP_MODAL = 'app/showAppModal';
export const showAppModal = createAction<AppModalDialogPayload>(SHOW_APP_MODAL);

const CLOSE_APP_MODAL = 'app/closeAppModal';
export const closeAppModal = createAction(CLOSE_APP_MODAL);

const GET_HOMEPAGE = 'app/getHomepage';
export const getHomepage = createAsyncThunk(
  GET_HOMEPAGE,
  async (_, thunkAPI) => {
    return await client('platform/homepage')
      .errorType('json')
      .get()
      .json((json) => {
        const { data } = json;
        return data;
      })
      .catch(() => {
        return thunkAPI.rejectWithValue(true);
      });
  }
);

const GET_CATEGORIES = 'app/getCategories';
export const getCategories = createAsyncThunk(
  GET_CATEGORIES,
  async (_, thunkAPI) => {
    return await client('platform/categories')
      .errorType('json')
      .get()
      .json((json) => {
        const { data } = json;
        return data;
      })
      .catch(() => {
        return thunkAPI.rejectWithValue(true);
      });
  }
);

const GET_SERVICES = 'app/getServices';

export const getServices: AsyncThunk<
  { data: Service[]; endOfList: boolean },
  void,
  { state: RootState }
> = createAsyncThunk(GET_SERVICES, async (_, thunkAPI) => {
  const {
    // @ts-ignore
    app: {
      homepage: {
        recommendedServices: { page },
      },
    },
  } = thunkAPI.getState();

  return await client(`platform/services?per_page=3&page=${page}`)
    .errorType('json')
    .get()
    .json((json) => {
      const { data, meta } = json;
      const endOfList = meta.to === meta.total;

      return { data, endOfList };
    })
    .catch(() => {
      return thunkAPI.rejectWithValue(true);
    });
});
