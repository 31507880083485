import styled, { css } from 'styled-components'
import media from 'theme/respondTo'

interface ISAppWorkplaceService {
  edit: boolean
}
export const SAppWorkplaceService = styled.div<ISAppWorkplaceService>`
  ${({ theme, edit }) => css`
    margin-bottom: ${theme.space.md}px;

    .service {
      min-height: 72px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      position: relative;

      padding: ${theme.space.md}px;
      padding-left: ${theme.space.xxl}px;

      border: 1px solid #ede8e9;
      border-radius: 8px;

      cursor: pointer;

      &:hover {
        border-color: ${theme.colors.primary};
      }

      &-name,
      &-text {
        font-weight: ${theme.weight.bold};
        font-size: 0.875rem;
        line-height: 1.31rem;

        color: #3a3335;
      }

      &-text {
        font-size: 0.75rem;
        font-weight: ${theme.weight.normal};
        color: #b0b0b0;

        margin-right: ${theme.space.lg}px;
      }

      &-time-price {
        flex-shrink: 0;

        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .time {
          font-size: 0.875rem;
          line-height: 1.31rem;

          color: #ff6b00;
        }

        .price {
          font-weight: ${theme.weight.bold};
          font-size: 1rem;
          line-height: 1.5rem;

          color: #3a3335;
        }
      }

      &::before {
        content: '';

        width: 20px;
        height: 20px;

        position: absolute;
        top: 50%;
        left: ${theme.space.md}px;
        transform: translateY(-50%);

        background-color: #ede8e9;
        border-radius: 50%;
      }

      &-editor {
        margin-top: ${theme.space.md}px;
        margin-bottom: ${theme.space.xxxl}px;
      }
    }

    ${edit &&
    css`
      .service {
        border-color: ${theme.colors.primary};

        &::before {
          background-color: ${theme.colors.primary};
        }
      }
    `}

    ${media.lg`
      .service {
        &-time-price {
          flex-direction: row;
          align-items: center;
  
          .time {
            margin-right: ${theme.space.lg}px;
          }
        }
      }
    `}
  `}
`
