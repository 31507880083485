import { get } from 'lodash'
import { Path, UseFormSetError } from 'react-hook-form'
import { WretcherError } from 'wretch'

const formErrorsHandler =
  <T>(fields: string[], setter: UseFormSetError<T>) =>
  (error: WretcherError) => {
    if (!error.json) return
    const {
      json: { errors },
    } = error

    fields.forEach((fieldName) => {
      const field = get(errors, fieldName)
      if (!field) return

      let [message] = field;

      if(fieldName === 'passwordConfirmation'){
        message = 'Pola "hasło" i "powtórz hasło" muszą mieć taką samą wartość.'
      }else{
        [message] = field
      }

      setter(fieldName as Path<T>, { type: 'required', message })
    })
  }

export default formErrorsHandler
