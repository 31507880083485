import { useEffect, useState, FC, ChangeEvent, HTMLProps } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { get } from 'lodash';

import { SContainer, SError, STextInput } from './TextInput.styled';

interface ITextInput {
  id: string;
  label?: string;
  icon?: string;
  textarea?: boolean;
  rules?: any;
}

const TextInput: FC<
  ITextInput & HTMLProps<HTMLInputElement | HTMLTextAreaElement>
> = ({ id, label, icon, className, type, textarea, rules }) => {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    watch,
  } = useFormContext();

  const error = get(errors, id);
  const { onBlur, onChange, ref } = register(id);
  const watchValue = watch(id);

  const { formatMessage } = useIntl();

  const [focused, setFocused] = useState<boolean>(false);
  const [empty, setEmpty] = useState<boolean>(true);

  const handleFocus = () => setFocused(true);

  const handleBlur = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    if (value.length) {
      setEmpty(false);
    } else {
      setEmpty(true);
    }

    setFocused(false);
    onBlur(e);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = e.target;
    setValue(id, value, { shouldTouch: true });
    onChange(e);
  };

  useEffect(() => {
    setEmpty(!get(getValues(), id));
  }, []);

  useEffect(() => {
    if (watchValue.length && empty) {
      setEmpty(false);
    }
  }, [watchValue]);

  const labelKey = `form.label-${id}`;
  const fieldLabel = label || formatMessage({ id: labelKey });

  return (
    <SContainer className={className}>
      <STextInput
        focused={focused}
        empty={empty}
        error={!!error}
        htmlFor={labelKey}
      >
        {focused || !empty ? <p className="label">{fieldLabel}</p> : null}
        {!focused && empty ? (
          <span className="placeholder">{fieldLabel}</span>
        ) : null}
        {textarea ? (
          <TextareaAutosize
            {...{
              ref,
              id: labelKey,
              onFocus: handleFocus,
              onBlur: handleBlur,
              onChange: handleChange,
            }}
          />
        ) : !rules ? (
          <input
            {...{
              ref,
              type,
              id: labelKey,
              onFocus: handleFocus,
              onBlur: handleBlur,
              onChange: handleChange,
            }}
          />
        ) : (
          <input
            {...{
              type,
              id: labelKey,
              onFocus: handleFocus,
              ...register(id, { ...rules }),
            }}
          />
        )}
        <span className="icon material-icons">{error ? 'error' : icon}</span>
      </STextInput>
      {error ? <SError>{error.message}</SError> : null}
    </SContainer>
  );
};

export default TextInput;
