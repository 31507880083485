import styled, { css } from 'styled-components';
import respondTo from 'theme/respondTo';

export const SAppAuthLayout = styled.section`
  ${({ theme }) => css`
    height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 ${theme.space.md}px;

    & > *:not(.go-back) {
      flex: 1;
    }

    .go-back {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: flex-start;

      color: #3a3335;

      background: none;
      border: none;
      border-radius: 50%;

      margin-top: ${theme.space.md}px;
      margin-bottom: ${theme.space.xxl}px;

      cursor: pointer;

      &-text {
        font-size: 1rem;
      }

      &-icon {
        font-size: 1.2rem;
        margin-right: ${theme.space.sm}px;
      }

      &:hover {
        .go-back-text {
          text-decoration: underline;
        }
      }
    }

    .heading {
      font-weight: ${theme.weight.heavy};
      font-size: 2.125rem;
      line-height: 3rem;
    }

    .description {
      font-size: 0.875rem;
      line-height: 1.31rem;
      letter-spacing: -0.1px;
      color: #908e8e;

      margin-top: ${theme.space.sm}px;
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      text-align: center;

      .heading {
        color: #3a3335;
      }

      .wrapper {
        max-width: 420px;
        width: 100%;
      }

      .submit-button {
        margin: 29px 0 0;
        height: 54px;
      }

      form {
        display: flex;
        flex-flow: column;
        row-gap: 10px;
      }

      .content {
        margin-top: ${theme.space.xl}px;
        .row {
          margin-top: ${theme.space.md}px;
          &:first-of-type {
            margin-top: 0;
          }
        }

        .agreement {
          text-align: left;
          label {
            display: flex;

            font-size: 0.875rem;
            font-weight: ${theme.weight.semibold};

            span {
              display: block;
              margin-top: -2px;
            }
          }
        }

        .information {
          font-size: 0.875rem;
          line-height: 1.5rem;
          text-align: center;
          color: #908e8e;

          margin-top: ${theme.space.md}px;

          a {
            font-weight: ${theme.weight.bold};
            text-decoration: none;
            color: inherit;
          }
        }
      }

      .sign-in-helpers {
        display: flex;
        justify-content: flex-end;

        margin-top: ${theme.space.md}px;

        a {
          font-size: 0.875rem;
          font-weight: ${theme.weight.bold};
          text-decoration: none;
          color: ${theme.colors.primary};

          &:hover {
            color: #bb4f01;
          }
        }
      }

      .register-success-next {
        margin-top: ${theme.space.lg}px;
      }
    }

    .background {
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      text-align: center;
      color: white;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      position: relative;

      &::before {
        content: '';

        width: 100%;
        height: 373px;

        position: absolute;
        left: 0;
        bottom: 0;

        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.9) 70.83%
        );
      }

      .content {
        max-width: 420px;

        position: relative;

        padding-bottom: 130px;

        z-index: 1;
      }

      .heading {
        color: white;
      }
    }

    ${respondTo.lg`
      flex-direction: row;
      align-items: stretch;
      
      padding: 0;

      .go-back {
        position: absolute;
        top: ${theme.space.xl}px;
        left: ${theme.space.xl}px;

        margin-bottom: 0;
      }
    `}
  `}
`;
