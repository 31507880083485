export type Breakpoints<T> = {
  sm: T
  md: T
  lg: T
  xl: T
  xxl: T
}

export const defaultQueries: Breakpoints<string> = {
  sm: '(max-width: 567px)',
  md: '(max-width: 768px)',
  lg: '(max-width: 992px)',
  xl: '(max-width: 1140px)',
  xxl: '(max-width: 1400px)',
}

export * from './components/BreakpointProvider'
