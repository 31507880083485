import React from 'react';

const ClockIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6673 12.1507L10.3486 10.832"
      stroke="#FF6B00"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.65072 10.832L2.33203 12.1507"
      stroke="#FF6B00"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.21294 3.27168C6.95697 2.549 8.9646 2.94809 10.2996 4.28284C11.6347 5.61759 12.0342 7.62514 11.3119 9.36932C10.5896 11.1135 8.88771 12.2509 6.99988 12.2509C5.11206 12.2509 3.41007 11.1138 2.6876 9.36974C1.70117 6.98845 2.83176 4.25836 5.21294 3.27168"
      stroke="#FF6B00"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.37437 1.82751C3.70574 1.63079 2.98297 1.78732 2.45563 2.24303C1.92829 2.69875 1.66865 3.3912 1.76637 4.08128"
      stroke="#FF6B00"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.625 1.82731C10.2936 1.63088 11.0162 1.78751 11.5435 2.24315C12.0707 2.6988 12.3304 3.39107 12.233 4.08108"
      stroke="#FF6B00"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9998 7.4354V5.93359"
      stroke="#FF6B00"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.0742 9.15898C7.0742 9.18907 7.05607 9.2162 7.02827 9.22772C7.00047 9.23923 6.96846 9.23287 6.94718 9.21159C6.9259 9.19031 6.91954 9.1583 6.93105 9.1305C6.94257 9.1027 6.9697 9.08457 6.9998 9.08457"
      stroke="#FF6B00"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.9997 9.08466C7.0408 9.08466 7.07411 9.11798 7.07411 9.15907"
      stroke="#FF6B00"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ClockIcon;
