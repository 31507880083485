import React from 'react';

const PhoneIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 4V5.8C10 6.131 10.224 6.4 10.5 6.4H13.5C13.776 6.4 14 6.131 14 5.8V4"
      stroke="#304659"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8799 17.5H13.1199"
      stroke="#304659"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4H15C16.105 4 17 4.895 17 6V18C17 19.105 16.105 20 15 20H9C7.895 20 7 19.105 7 18V6C7 4.895 7.895 4 9 4Z"
      stroke="#304659"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PhoneIcon;
