import styled from 'styled-components';

export const SUsersList = styled.table`
  display: flex;
  flex-flow: column;
  margin: 60px 0 0;
  width: 100%;

  .users-list {
    display: flex;
    flex-flow: column;
    width: 100%;

    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
`;
