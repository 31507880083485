import { number } from 'prop-types';
import { OptionTypeBase } from 'react-select';

export enum RequestStatus {
  IDLE,
  PENDING,
  SUCCESS,
  ERROR,
}

export type SalonLogin = {
  email: string;
  password: string;
};

export type SalonForgotPassword = {
  email: string;
};

export type SalonResetPassword = {
  password: string;
};

export type SalonResetPasswordDTO = {
  password: string;
  passwordConfirmation: string;
  email: string;
  token: string;
};

export type SalonRegisterBase = {
  fullName: string;
  email: string;
  companyName: string;
  password: string;
  agreement: boolean;
  phone: string;
};

export type SalonRegisterBaseDTO = {
  companyName: string;
  fullName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
};

export type SalonRegisterProfile = {
  city: OptionTypeBase | null;
  postal: string;
  street: string;
  buildingNumber: string;
  phone: string;
};

export type Image = {
  id: number;
  path: string;
};

export type User = {
  id: number;
  name: string;
  email: string;
  photo?: string;

  company: Salon & {
    subscription: SalonSubscription;
    subscriptionPrice: number;
  };
};

export type SalonSubscription = {
  active: boolean;
  endDate: number | null;
};

export interface ICategoriesWithServices {
  name: string;
  services: SalonService[];
}

export interface SalonOwner {
  company_id: number;
  created_at: string;
  current_team_id: number | null;
  deleted_at: string | null;
  email: string;
  email_notifications: number;
  email_verified_at: string;
  id: number;
  name: string;
  phone: string | null;
  profile_photo_path: null | string;
  profile_photo_url: string;
  type: string;
  updated_at: string;
}

export type Salon = {
  id: number;
  name: string;
  nip?: string;
  phone: string | null;
  city?: LocationSuggestion;
  email?: string;
  postal?: string;
  buildingNumber?: string;
  street?: string;
  description?: string;
  geolocation?: Geolocation;
  openingHours?: OpeningHour[];
  categories: Category[];
  images: Image[];
  reviews?: SalonReview[];
  reviewsSummary?: ReviewsSummary;
  services?: SalonService[];
  categoriesWithServices?: ICategoriesWithServices[];
  isFavourite?: boolean;
  partner_program: boolean;
  published?: boolean;
  employees: SalonOwner[];
  slots: Slot[];
};

export interface UserProgramSalon {
  company_id: number;
  points_amount: number;
  name: string;
  discount: string;
}

export type SalonService = {
  category?: {
    id: number;
    name: string;
    active: number;
    order: number;
    appointmentType: AppointmentType;
  };
  id: number;
  name: string;
  appointmentType: AppointmentType;
  price: number;
  time?: string;
  duration?: number;
  description: string;
};

export interface Review {
  id: number;
  title: string;
  content: string;
  rating: number;
  user: { id: number; name: string; avatar: string };
  createdAt: number;
  salon?: Salon;
}

export interface ReviewAuthor {
  companyId: null | number;
  createdAt: string;
  currentTeamId: null | number;
  deletedAt: null | string;
  email: string;
  emailNotifications: number;
  emailVerifiedAt: null | string;
  id: number;
  name: string;
  phone: null | string;
  profilePhotoPath: null | string;
  profilePhotoUrl: string;
  type: string;
  updatedAt: string;
}

export interface SalonReview {
  appointmentId: number | null;
  companyId: number;
  content: string;
  createdAt: string;
  id: number;
  rating: number;
  title: string;
  updatedAt: string | null;
  user: ReviewAuthor | null;
  userId: number;
}

export type ReviewsSummary = {
  count: number;
  total: number;
  positive: number;
  negative: number;
  neutral: number;
};

export type Geolocation = {
  latitude: number;
  longitude: number;
};

export type District = {
  id: number;
  name: string;
  image: string | null;
  companiesNumber: number;
};

export type LocationSuggestion = {
  id: number;
  name: string;
  type: 'city' | 'district';
  district?: District;
};

export type Category = {
  id: number;
  name: string;
  image: string;
  providersCount: number;

  active?: boolean;
};

export type OpeningHour = {
  timeStart: string;
  timeEnd: string;
  dayOfWeek: '1' | '2' | '3' | '4' | '5' | '6' | '7';
};

export type LinkGroup = {
  id: number;
  name: string;
  links: Link[];
};

export type Link = {
  id: number;
  title: string;
  url: string;
};

export type Service = {
  id: number;
  category_id: number;

  image?: string;

  name: string;
  price: number;
  duration?: number;
  placesAmount?: number;
  description: string;

  slots?: Slot[];

  appointmentType: AppointmentType;

  company?: {
    id: number;
    name: string;
    reviewsSummary?: ReviewsSummary;
  };
};

export interface Discount {
  points: number;
  discount: number;
}

export type ServiceCreator = {
  name: string;
  price: number;
  duration?: string;
  placesAmount?: number;
  description?: string;

  slots?: Slot[];
};

export type ServiceCreatorDTO = {
  id?: number;
  category_id?: number;

  name: string;
  price: number;
  duration?: number;
  placesAmount?: number;
  description?: string;

  slots?: number[] | null;
};

export type WorkplaceCategory = {
  id: number;
  name: string;
  appointment_type: AppointmentType;
  services: Service[];
};

export type Slot = {
  id: number;
  name: string;
  number: number;
  avatar: string | null;
};

export type AppointmentCreator = {
  id?: string;
  email: string;
  phone: string;

  appointmentKind: 'normal' | 'time_block';
  appointmentType?: 'range';
  additionalNote: string;

  date: Date | null;
  timeStart?: string;
  timeEnd: string;
  service: Service | null;
};

export type AppointmentDTO = {
  id?: number | string;
  slotId?: number | string;

  appointmentKind?: 'normal' | 'time_block' | 'range';
  additionalNote?: string;

  timeStart?: number;
  email: string;
  phone: string;
  timeEnd: number;
  serviceId?: number;
  companyId?: number;
};

export type ClientRegisterBase = {
  email: string;
  name: string;
  surname: string;
  password: string;
  passwordConfirmation: string;
  phone: string;
  terms: boolean;
};

export type Client = {
  id: number;
  name: string;
  email: string;
  phone: string | null;
  photo: string;
  emailNotifications: number;
};

export type Reservation = {
  salonId: number;
  slotId: number;
  service: SalonService;
  time: number[] | null;
  hour: string | null;
  expanded: boolean;
};

export type Booking = {
  id: number;
  timeStart: number;
  timeEnd: number;
  service: Service;
  additionalNote: string | null;
  appointmentKind: 'normal' | 'time_block';
  slot: string;
  state: 'CANCELED' | 'INCOMING' | 'FINISHING';
  salon: Salon;
  review: Review;
};

export type AppointmentType = 'normal' | 'range';

export enum ReservationModalStatus {
  IDLE,
  PENDING,
  SUCCESS,
  ERROR,
}
