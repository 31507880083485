import styled, { css } from 'styled-components'

export const SCookiesAlert = styled.div`
  ${({ theme }) => css`
    p {
      font-size: 1rem;
      font-weight: ${theme.weight.bold};
      line-height: 1.68rem;
      color: #3a3335;

      & + span {
        font-size: 0.875rem;
        font-weight: ${theme.weight.normal};
        line-height: 1.31rem;
        color: #908e8e;
      }
    }
  `}
`
