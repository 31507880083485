import styled from 'styled-components';

export const SSuggestedCity = styled.li`
  height: 62px;
  border-bottom: 1px solid #ede8e9;

  button {
    align-items: center;
    background: transparent;
    border: none;
    display: flex;
    color: #696f8c;
    column-gap: 19px;
    cursor: pointer;
    height: 100%;
  }
`;
