export enum AppRoute {
  Home = 'routes.home',

  List = 'routes.list',
  Salon = 'routes.salon',
  Logout = 'routes.logout',

  Regulations = 'routes.regulations',
  Search = 'routes.search',

  Profile = 'routes.profile',
  ProfilePassword = 'routes.profile-password',
  ProfileFavorite = 'routes.profile-favorite',
  ProfilePartnersProgram = 'routes.profile-partners',
  ProfileReviews = 'routes.profile-reviews',
  ProfileSettings = 'routes.profile-settings',

  App = 'routes.app',
  AppProfile = 'routes.app-profile',
  AppReservations = 'routes.app-reservations',
  AppHotelReservations = 'routes.app-hotel-reservations',
  AppWorkplace = 'routes.app-workplace',
  AppPartnersProgram = 'routes.app-partners-program',
  AppSubscription = 'routes.app-subscription',
  AppCancelReservation = 'routes.cancel-reservation',
  AppConfirmReservation = 'routes.confirm-reservation',
  AppAcceptOffer = 'routes.accept-offer',

  AppAuthLogin = 'routes.app-auth-login',
  AppAuthRegister = 'routes.app-auth-register',
  AppAuthRegisterSuccess = 'routes.app-auth-register-success',
  AppAuthRegisterProfile = 'routes.app-auth-register-profile',
  AppAuthForgotPassword = 'routes.app-auth-forgot-password',
  AppAuthResetPassword = 'routes.app-auth-reset-password',

  AppAdmin = 'routes.app-admin',

  AppLogout = 'routes.app-logout',
}

export const AppRouteTitles = new Map([[AppRoute.Home, 'home.title']]);
